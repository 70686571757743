import {
  actionColumns as allActionColumns,
  allColumns,
  defaultFixedColumns as allDefaultFixedColumns,
  defaultScrollingColumns as allDefaultScrollingColumns,
  allSelectedColumnOptions,
  localStorageKey as localStorageKeyAllApplications,
} from '@/components/Applications/All/allConsts';

import {
  newlyDiscoveredColumns,
  newlyDiscoveredSelectedColumnOptions,
} from '@/components/Applications/NewlyDiscovered/newlyDiscoveredConsts';

import {
  approvedColumns,
  approvedSelectedColumnOptions,
} from '@/components/Applications/Approved/approvedConsts';

import {
  unapprovedColumns,
  unapprovedSelectedColumnOptions,
} from '@/components/Applications/Unapproved/unapprovedConsts';

import {
  computed, onMounted, ref, watch,
} from 'vue';

import {
  ignoredColumns,
  ignoredSelectedColumnOptions,
} from '@/components/Applications/Ignored/ignoredConsts';

import { AccessRestrictionType, type ApplicationItem } from '@/api/shared/types';
import { Constants } from '@jumpcloud/ui-components';
import { snakeCase } from 'lodash';
import { useApplicationsStore } from '@/stores/Applications';
import { useLoadingState } from '@/util/useLoadingState';
import { useRoute } from 'vue-router';
import moment from 'moment';

const { sortDirections } = Constants;
const defaultSortBy = 'appId';

type ApplicationItemWithSelected = ApplicationItem & Partial<{ selected: boolean }>;

type SelectedColumnOption = {
  name: string;
  value: string;
};

type SortDirection = 'ascending' | 'descending';

type Filter = {
  relatedField: string;
  name: string;
  value: string;
};

type LastUsedOptionsKeys = 'IN_LAST_7_DAYS';

type RenewalOptionsKeys = 'IN_NEXT_30_DAYS';

type ApplicationStatus = 'NEWLY_DISCOVERED' | 'APPROVED' | 'UNAPPROVED' | 'IGNORED' | '';

export function useSaasApplicationsTable(status: ApplicationStatus = 'NEWLY_DISCOVERED') {
  const { isLoading, runAsyncWithLoading } = useLoadingState();
  const applicationsStore = useApplicationsStore();
  const route = useRoute();
  const applicationsData = computed(() => applicationsStore.applications);
  const applicationsTableFilters = computed(() => applicationsStore.applicationsTableFilters);
  const selectAll = ref(false);
  const indeterminateSelectAll = ref(false);
  const searchValue = ref('');
  const tableData = ref<ApplicationItemWithSelected[]>(applicationsData?.value.apps || []);
  const selectedCategories = ref<Filter[]>([]);
  const selectedDiscoveryDate = ref();
  const selectedLastUsed = ref();
  const defaultAccessRestriction = computed(() => applicationsData.value?.defaultAccessRestriction
  || AccessRestrictionType.DEFAULTACTION);
  const selectedRenewal = ref();
  const selectedStatus = ref<Filter[]>([]);
  const selectedOwners = ref<Filter[]>([]);
  const selectedSsoConnections = ref<Filter[]>([]);
  const appliedFilters = ref<Filter[]>([]);
  const currentPageNumber = ref(0);
  const rowsPerPage = ref(50);
  const totalCount = computed(() => applicationsData.value?.count || 0);
  const sortBy = ref(defaultSortBy);
  const sortDirection = ref<SortDirection>(sortDirections.ascending);
  const actionDropdownItems = ref([]);

  const sortValue = computed(() => (sortDirection.value === sortDirections.ascending
    ? snakeCase(sortBy.value)
    : `-${snakeCase(sortBy.value)}`));

  const handlePageChange = ({
    currentPageNum: newCurrentPageNum,
    rowsPerPage: newRowsPerPage,
  }: {
    currentPageNum: number;
    rowsPerPage: number;
  }) => {
    currentPageNumber.value = newCurrentPageNum;
    rowsPerPage.value = newRowsPerPage;
  };

  const localStorageKey = computed(() => {
    switch (status) {
      case 'NEWLY_DISCOVERED':
        return null;
      case 'APPROVED':
        return null;
      case 'UNAPPROVED':
        return null;
      case 'IGNORED':
        return null;
      default:
        return localStorageKeyAllApplications;
    }
  });

  const columns = computed(() => {
    switch (status) {
      case 'NEWLY_DISCOVERED':
        return newlyDiscoveredColumns;
      case 'APPROVED':
        return approvedColumns;
      case 'UNAPPROVED':
        return unapprovedColumns;
      case 'IGNORED':
        return ignoredColumns;
      default:
        return allColumns;
    }
  });

  const actionColumns = computed(() => {
    switch (status) {
      case 'NEWLY_DISCOVERED':
        return [];
      case 'APPROVED':
        return [];
      case 'UNAPPROVED':
        return [];
      case 'IGNORED':
        return [];
      default:
        return allActionColumns;
    }
  });

  const defaultFixedColumns = computed(() => {
    switch (status) {
      case 'NEWLY_DISCOVERED':
        return [];
      case 'APPROVED':
        return [];
      case 'UNAPPROVED':
        return [];
      case 'IGNORED':
        return [];
      default:
        return allDefaultFixedColumns;
    }
  });

  const defaultScrollingColumns = computed(() => {
    switch (status) {
      case 'NEWLY_DISCOVERED':
        return [];
      case 'APPROVED':
        return [];
      case 'UNAPPROVED':
        return [];
      case 'IGNORED':
        return [];
      default:
        return allDefaultScrollingColumns;
    }
  });

  const visibleColumns = ref(
    columns.value.map((column) => ({
      ...column,
      visible: true,
    })),
  );

  const selectedColumnOptionsByStatus = computed<SelectedColumnOption[]>(() => {
    switch (status) {
      case 'NEWLY_DISCOVERED':
        return newlyDiscoveredSelectedColumnOptions;
      case 'APPROVED':
        return approvedSelectedColumnOptions;
      case 'UNAPPROVED':
        return unapprovedSelectedColumnOptions;
      case 'IGNORED':
        return ignoredSelectedColumnOptions;
      default:
        return allSelectedColumnOptions;
    }
  });

  const selectedColumnOptions = ref(selectedColumnOptionsByStatus.value);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelect = (selected: any) => {
    const selectedId = selected.appId;
    tableData.value = tableData.value?.map((item) => {
      if (item.appId === selectedId) {
        return { ...item, selected: !item?.selected };
      }
      return item;
    });
    if (tableData.value.every((item) => item?.selected)) {
      selectAll.value = true;
      indeterminateSelectAll.value = false;
    } else if (tableData.value.some((item) => item?.selected)) {
      selectAll.value = false;
      indeterminateSelectAll.value = true;
    } else {
      selectAll.value = false;
      indeterminateSelectAll.value = false;
    }
  };

  const handleSelectAll = (selected: boolean) => {
    tableData.value = tableData.value?.map((item) => ({
      ...item,
      selected,
    }));
    selectAll.value = selected;
    indeterminateSelectAll.value = false;
  };

  const handleUpdateColumnOptions = (value: SelectedColumnOption[]) => {
    selectedColumnOptions.value = value;
    visibleColumns.value = columns.value.map((column) => ({
      ...column,
      visible: !!value.find((v) => v.value === column.dataFieldName),
    }));
  };

  const fetchApplications = async () => {
    const fetchApplicationsCallback = async () => {
      await applicationsStore.fetchApplications(applicationsTableFilters.value);
    };

    await runAsyncWithLoading(fetchApplicationsCallback);
  };

  const handleSortColumn = ({
    dataFieldName,
    sortDirection: newSortDirection,
  }: {
    dataFieldName: string;
    sortDirection: SortDirection;
  }) => {
    sortBy.value = dataFieldName;
    sortDirection.value = newSortDirection;
  };

  watch(
    [currentPageNumber, searchValue, appliedFilters, rowsPerPage, sortValue],
    async (
      [newCurrentPageNumber, newSearchValue, newAppliedFilters, newRowsPerPage, newSortValue],
      [oldCurrentPageNumber],
    ) => {
      const pageStart = newCurrentPageNumber * newRowsPerPage;
      if (newCurrentPageNumber === oldCurrentPageNumber && newCurrentPageNumber !== 0) {
        currentPageNumber.value = 0;
        return;
      }

      const newFilters: Filter[] = newAppliedFilters;

      applicationsStore.setApplicationsTableFilters({
        // status will be '' for the All apps tab
        // so it will use newAppliedFilters in this case
        status: status || newFilters
          .filter((filter) => filter.relatedField === 'Status')
          .map((filter) => filter.value),
        search: newSearchValue,
        category: newFilters
          .filter((filter) => filter.relatedField === 'Category')
          .map((filter) => filter.value),
        owner: newFilters
          .filter((filter) => filter.relatedField === 'Owner')
          .map((filter) => filter.value),
        lastUsed: newFilters
          .filter((filter) => filter.relatedField === 'Last Used')
          .map((filter) => filter.value),
        renewal: newFilters
          .filter((filter) => filter.relatedField === 'Renewal')
          .map((filter) => filter.value),
        ssoConnectionStatus: newFilters
          .filter((filter) => filter.relatedField === 'SSO Connection')
          .map((filter) => filter.value),
        discoveryDate: newFilters
          .filter((filter) => filter.relatedField === 'Discovery Date')
          .map((filter) => filter.value),
        skip: newCurrentPageNumber !== oldCurrentPageNumber ? pageStart : 0,
        limit: newRowsPerPage,
        sort: newSortValue,
      });

      await fetchApplications();
    },
  );

  const handleApplyFilters = () => {
    appliedFilters.value = [
      ...selectedStatus.value.map((filter: Filter) => ({
        relatedField: 'Status',
        name: filter.name,
        value: filter.value,
      })),
      ...selectedCategories.value.map((filter: Filter) => ({
        relatedField: 'Category',
        name: filter.name,
        value: filter.value,
      })),
      ...selectedSsoConnections.value.map((filter: Filter) => ({
        relatedField: 'SSO Connection',
        name: filter.name,
        value: filter.value,
      })),
      ...selectedOwners.value.map((filter: Filter) => ({
        relatedField: 'Owner',
        name: filter.name,
        value: filter.value,
      })),
    ];

    if (selectedLastUsed.value) {
      appliedFilters.value.push({
        relatedField: 'Last Used',
        name: `${moment(selectedLastUsed.value.start).format(
          'LLL',
        )} -> ${moment(selectedLastUsed.value.end).format('LLL')}`,
        value: selectedLastUsed.value,
      });
    }

    if (selectedRenewal.value) {
      appliedFilters.value.push({
        relatedField: 'Renewal',
        name: `${moment(selectedRenewal.value.start).format(
          'LLL',
        )} -> ${moment(selectedRenewal.value.end).format('LLL')}`,
        value: selectedRenewal.value,
      });
    }

    if (selectedDiscoveryDate.value) {
      appliedFilters.value.push({
        relatedField: 'Discovery Date',
        name: `${moment(selectedDiscoveryDate.value.start).format(
          'LLL',
        )} -> ${moment(selectedDiscoveryDate.value.end).format('LLL')}`,
        value: selectedDiscoveryDate.value,
      });
    }
  };

  watch(applicationsData, (newValue) => {
    tableData.value = newValue.apps;
  });

  const handleRouteQuery = () => {
    const lastUsedOptions = {
      IN_LAST_7_DAYS: {
        start: moment().subtract(7, 'days').toDate(),
        end: moment().toDate(),
      },
    };

    const renewalOptions = {
      IN_NEXT_30_DAYS: {
        start: moment().toDate(),
        end: moment().add(30, 'days').toDate(),
      },
    };

    const lastUsedQuery = route.query?.lastUsed as LastUsedOptionsKeys;
    const renewalQuery = route.query?.renewal as RenewalOptionsKeys;

    if (typeof lastUsedQuery === 'string') {
      selectedLastUsed.value = lastUsedOptions[lastUsedQuery];
    }

    if (typeof renewalQuery === 'string') {
      selectedRenewal.value = renewalOptions[renewalQuery];
    }

    handleApplyFilters();
  };

  onMounted(async () => {
    applicationsStore.setApplicationsTableFilters({
      search: searchValue.value,
      status,
      skip: 0,
      limit: rowsPerPage.value,
      sort: sortValue.value,
    });
    handleRouteQuery();
  });

  const handleClearAllFilters = () => {
    selectedCategories.value = [];
    selectedOwners.value = [];
    selectedDiscoveryDate.value = null;
    selectedStatus.value = [];
    selectedSsoConnections.value = [];
    selectedLastUsed.value = null;
    selectedRenewal.value = null;
    appliedFilters.value = [];
  };

  const handleRemoveFilter = (filter: Filter) => {
    if (filter.relatedField === 'Category') {
      selectedCategories.value = selectedCategories.value.filter(
        (category) => category.value !== filter.value,
      );
    } else if (filter.relatedField === 'Owner') {
      selectedOwners.value = selectedOwners.value.filter(
        (owner) => owner.value !== filter.value,
      );
    } else if (filter.relatedField === 'SSO Connection') {
      selectedSsoConnections.value = selectedSsoConnections.value.filter(
        (ssoConnection) => ssoConnection.value !== filter.value,
      );
    } else if (filter.relatedField === 'Last Used') {
      selectedLastUsed.value = null;
    } else if (filter.relatedField === 'Renewal') {
      selectedRenewal.value = null;
    } else if (filter.relatedField === 'Discovery Date') {
      selectedDiscoveryDate.value = null;
    } else if (filter.relatedField === 'Status') {
      selectedStatus.value = selectedStatus.value.filter(
        (category) => category.value !== filter.value,
      );
    }

    appliedFilters.value = appliedFilters.value.filter(
      (appliedFilter) => appliedFilter.value !== filter.value,
    );
  };

  const exportData = async () => {
    const exportDataCallback = async () => {
      await applicationsStore.exportApps(applicationsTableFilters.value);
    };

    await runAsyncWithLoading(exportDataCallback);
  };

  const exportAllData = async () => {
    const exportAllDataCallback = async () => {
      await applicationsStore.exportApps({});
    };

    await runAsyncWithLoading(exportAllDataCallback);
  };

  return {
    applicationsData,
    appliedFilters,
    currentPageNumber,
    fetchApplications,
    handleApplyFilters,
    handleClearAllFilters,
    handlePageChange,
    handleRemoveFilter,
    handleSelect,
    handleSelectAll,
    handleSortColumn,
    handleUpdateColumnOptions,
    indeterminateSelectAll,
    isLoading,
    rowsPerPage,
    searchValue,
    selectAll,
    selectedCategories,
    selectedColumnOptions,
    selectedDiscoveryDate,
    selectedLastUsed,
    selectedOwners,
    selectedRenewal,
    selectedSsoConnections,
    selectedStatus,
    sortBy,
    sortDirection,
    tableData,
    totalCount,
    defaultAccessRestriction,
    visibleColumns,
    exportData,
    exportAllData,
    actionDropdownItems,
    actionColumns,
    defaultFixedColumns,
    defaultScrollingColumns,
    columns,
    localStorageKey,
  };
}
