import { computed } from 'vue';
import moment from 'moment';
import pluralize from 'pluralize';
import type { ComputedRef } from 'vue';

type SaasChartConfig = {
  data: ComputedRef<{
    atDay: string;
    accountCount: number;
    appCount: number;
  }[]>;
  tooltipSuffix: string;
  setSelectedDay: (day: {
    atDay: string;
    accountCount: number;
    appCount: number;
  } | null) => void;
};

const useSaasChartConfig = ({ data, tooltipSuffix, setSelectedDay }: SaasChartConfig) => {
  const series = computed(() => [
    {
      data: data.value?.map((item) => {
        const accountCount = item.accountCount || 0;
        const appCount = item.appCount || 0;

        return appCount || accountCount;
      }),
    },
  ]);

  const xAxis = computed(() => ({
    categories: data.value?.map(({ atDay }) => atDay),
    type: 'datetime',
  }));

  const tooltip = computed(() => ({
    enabled: true,
    custom: ({ dataPointIndex }: { dataPointIndex: number }) => {
      const currentPoint = data.value?.[dataPointIndex];
      if (!currentPoint) {
        return '';
      }
      const formattedAtDay = currentPoint.atDay.split('T')[0];
      const title = moment(formattedAtDay, 'YYYY-MM-DD').format('MMMM D, YYYY');

      const titleElement = `
        <div
          style="
            color: var(--jcGrey800);
            font-size: var(--jcBodySmall);
            font-weight: 400;
            line-height: 1.5;
          "
        >
          ${title}
        </div>`;

      let content = '';

      if (currentPoint.accountCount) {
        content = `${pluralize('account', currentPoint.accountCount, true)} ${tooltipSuffix}`;
      }

      if (currentPoint.appCount) {
        content = `${pluralize('app', currentPoint.appCount, true)} ${tooltipSuffix}`;
      }

      const contentElement = `
        <div
          style="
            color: var(--jcGrey800);
            font-size: var(--jcBody);
            font-weight: 600;
            line-height: 1.5;
          "
        >
          ${content}
        </div>`;

      return `
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: var(--jc-spacer-x-small);
            padding: var(--jc-spacer-medium);
          "
        >
          ${titleElement}${contentElement}
        </div>`;
    },
  }));

  const dataPointClick = (
    _event: MouseEvent,
    _chartContext: unknown,
    { dataPointIndex }: { dataPointIndex: number },
  ) => {
    const pointDay = data.value?.[dataPointIndex];
    const clickable = dataPointIndex !== null
      && dataPointIndex !== undefined
      && (pointDay?.accountCount || pointDay?.appCount);

    if (clickable) {
      setSelectedDay(data.value?.[dataPointIndex]);
    }
  };

  const onUsageModalClose = () => {
    setSelectedDay(null);
  };

  return {
    series,
    xAxis,
    tooltip,
    dataPointClick,
    onUsageModalClose,
  };
};

export default useSaasChartConfig;
