<script setup>
import {
  ButtonSecondary,
  ItemRow,
  ItemWithLabel,
  ParagraphBody,
} from '@jumpcloud/ui-components';

import { $testId } from '@/test/testIdHelper';
import { SaasConstants } from '@/util/Constants/SaasConstants';
import { computed, ref } from 'vue';
import { getDiffFromNowInDays } from '@/util/getDiffFromNowInDays';
import { missingText } from '@/util/Constants/missingText';
import { useApplicationsStore } from '@/stores/Applications';
import { useLoadingState } from '@/util/useLoadingState';
import AppAvatar from '@/components/AppAvatar.vue';
import ManualApp from '@/components/ManualApp/ManualApp.vue';
import SsoConnectionStatusBadge from '@/components/SsoConnectionStatusBadge.vue';
import SsoConnectionsModal from '@/components/SsoConnectionsModal/SsoConnectionsModal.vue';
import moment from 'moment';

const props = defineProps({
  columns: {
    type: Array,
    default: () => [],
  },

  itemData: {
    required: true,
    type: Object,
  },
});

const applicationsStore = useApplicationsStore();
const isModalOpen = ref(false);
const isSsoModalOpen = ref(false);

const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};

function navigateToOnClick(id) {
  return `${SaasConstants.tabs.applications.route}/${id}/overview?category=approved`;
}

const {
  isLoading: isLoadingAppReview,
  runAsyncWithLoading: runAsyncWithLoadingAppReview,
} = useLoadingState();
const onSaveReviewApp = async (payload) => {
  const onSaveCallback = async () => applicationsStore.reviewAppFromList(
    { id: props.itemData.appId, payload },
  );

  const res = await runAsyncWithLoadingAppReview(onSaveCallback);

  if (res?.status === 200) {
    closeModal();
  }
};

const formattedPrice = computed(() => {
  if (Number.isNaN(props.itemData?.license?.price?.amount)) {
    return missingText;
  }
  return `$${props.itemData.license.price.amount.toFixed(2)}
      / ${props.itemData.license.price.term.toLowerCase()}`;
});

const formattedRenewalDate = computed(() => {
  if (!props.itemData?.license?.renewalDate) {
    return missingText;
  }
  return `${moment(props.itemData.license.renewalDate).format('ll')}`;
});

</script>

<template>
  <ItemRow
    v-bind="$attrs"
    :selected="itemData.selected"
  >
    <ItemWithLabel
      v-for="(column, index) in columns"
      :key="column.dataFieldName"
      :class="['specialRowClass', column.dataFieldName === 'ssoApp' && 'overflowUnset']
        .filter(Boolean)"
      :column="column"
      :data-test-id="$testId(`cell-${index}`)"
    >
      <template v-if="column.dataFieldName === 'appId'">
        <AppAvatar
          :appId="itemData.appId"
          :category="itemData.category.name"
          :name="itemData.name"
          :to="navigateToOnClick(itemData.appId)"
        />
      </template>
      <template v-else-if="column.dataFieldName === 'ssoApp'">
        <SsoConnectionStatusBadge
          bodySize="small"
          :ssoApps="itemData.ssoApps"
          @open-sso-connections-modal="isSsoModalOpen = true"
        />
      </template>
      <template v-else-if="column.dataFieldName === 'lastUsedAt'">
        <span class="ellipsis">{{ getDiffFromNowInDays(itemData.lastUsedAt) }}</span>
      </template>
      <template v-else-if="column.dataFieldName === 'licensePrice'">
        <ParagraphBody
          v-if="!itemData.license"
          class="disableColor ellipsis"
        >
          Not Tracked
        </ParagraphBody>
        <template v-else>
          <ParagraphBody
            v-if="itemData.license.price.term === 'FREE'"
            class="ellipsis"
          >
            Free Subscription
          </ParagraphBody>
          <ParagraphBody
            v-else
            class="ellipsis"
            :hasMargin="false"
          >
            {{ formattedPrice }}
          </ParagraphBody>
        </template>
      </template>
      <template v-else-if="column.dataFieldName === 'licenseRenewalDateSort'">
        <ParagraphBody
          v-if="!itemData.license"
          class="disableColor ellipsis"
        >
          Not Tracked
        </ParagraphBody>
        <ParagraphBody
          v-else-if="itemData.license.price.term === 'FREE'"
          class="ellipsis"
        >
          {{ missingText }}
        </ParagraphBody>
        <ParagraphBody
          v-else
          class="ellipsis"
          :hasMargin="false"
        >
          {{ formattedRenewalDate }}
        </ParagraphBody>
      </template>
      <template v-else-if="column.dataFieldName === 'actions'">
        <ButtonSecondary
          text="Review"
          @click="openModal"
        />
      </template>
      <template v-else>
        {{ itemData[column.dataFieldName] }}
      </template>
    </ItemWithLabel>
    <ManualApp
      v-if="isModalOpen"
      :isLoading="isLoadingAppReview"
      :isModalOpen="isModalOpen"
      mode="REVIEW"
      :reviewAppInfo="itemData"
      @close="closeModal"
      @save="onSaveReviewApp"
    />
    <SsoConnectionsModal
      :data="itemData.ssoApps"
      :isModalOpen="isSsoModalOpen"
      @close="isSsoModalOpen = false"
    />
  </ItemRow>
</template>

<style scoped>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.specialRowClass {
  align-items: center;
  display: flex;
}

.overflowUnset {
  overflow: unset;
}

.disableColor {
  color: var(--jc-input-color-disabled);
}
</style>
