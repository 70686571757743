<script setup>
import { debounce } from 'lodash';
import { onMounted, ref } from 'vue';
import { useSystemUsersStore } from '@/stores/SystemUsers';
import SaasSelect from '@/components/SaasSelect.vue';

defineProps({
  appendToBody: Boolean,

  headerText: {
    type: String,
    default: 'Owner',
  },

  required: {
    type: Boolean,
    default: false,
  },

  selectedOwnerOptions: {
    type: [Array, Object],
    default: () => [],
  },

  multiple: {
    type: Boolean,
    default: false,
  },

  closeOnSelect: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:selectedOwnerOptions']);

const options = ref([]);
const ownerSearchTerm = ref('');
const ownerSortField = 'email';

const transformItemToOptionFn = user => ({
  name: user.displayname || `${user.firstname} ${user.lastname}`,
  value: user.id,
});

const systemUsersStore = useSystemUsersStore();

const fetchUsers = async () => {
  const { results } = await systemUsersStore
    .fetchSystemUsers(0, 100, ownerSortField, ownerSearchTerm.value);

  options.value = results.map(transformItemToOptionFn);
};

const handleSearchTermChange = debounce(async (searchTerm, loading, isOpen) => {
  if (isOpen) {
    loading(true);
  }

  ownerSearchTerm.value = searchTerm;
  await fetchUsers();
  loading(false);
}, 300);

const handleSelectionsUpdated = (newSelectedOptions) => {
  emit('update:selectedOwnerOptions', newSelectedOptions);
};

onMounted(() => {
  fetchUsers();
});
</script>

<template>
  <SaasSelect
    :appendToBody="appendToBody"
    :closeOnSelect="closeOnSelect"
    :inputLabel="headerText"
    label="name"
    :modelValue="selectedOwnerOptions"
    :multiple="multiple"
    :options="options"
    placeholder="Search users"
    :required="required"
    @search="handleSearchTermChange"
    @update:model-value="handleSelectionsUpdated"
  />
</template>
