<script setup>
import { $testId } from '@/test/testIdHelper';
import {
  Button,
  ButtonGroup,
  ButtonTertiary,
  HeadingMedium,
} from '@jumpcloud/ui-components';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import AppAvatar from '@/components/AppAvatar.vue';
import ChooseAppFromCatalog from '@/components/ManualApp/ChooseAppFromCatalog.vue';
import FullPageModal from '@/components/FullPageModal.vue';
import ManualAppHR from '@/components/ManualApp/ManualAppHR.vue';
import ManualAppInformation from '@/components/ManualApp/ManualAppInformation.vue';
import StatusAndOwner from '@/components/ManualApp/StatusAndOwner.vue';

const props = defineProps({
  editAppInfo: {
    type: Object,
    required: false,
    default: () => ({
      name: '',
      domains: [],
      description: '',
    }),
  },

  isActionButtonDisabled: Boolean,

  isLoading: Boolean,

  isModalOpen: Boolean,

  mode: {
    type: String,
    required: true,
  },

  reviewAppInfo: {
    type: Object,
    required: false,
    default: () => null,
  },
});

const emit = defineEmits(['close', 'save']);

const route = useRoute();
const headerText = {
  ADD: 'Add App',
  EDIT: 'Edit App',
  REVIEW: 'Review App',
}[props.mode];
const saveButtonText = {
  ADD: 'Add',
  EDIT: 'Save',
  REVIEW: 'Save',
}[props.mode];
const temporaryCustomAppName = ref('');

const defaultSelectedStatus = computed(() => {
  const status = props.reviewAppInfo?.status || route.hash;
  switch (status) {
    case '#unapproved':
    case 'UNAPPROVED':
      return { name: 'Unapproved', value: 'UNAPPROVED' };
    case '#ignored':
    case 'IGNORED':
      return { name: 'Ignored', value: 'IGNORED' };
    case '#approved':
    case 'APPROVED':
      return { name: 'Approved', value: 'APPROVED' };
    default:
      return null;
  }
});

const defaultValues = ref({
  selectedApp: null,
  isCustomApp: false,
  selectedOwner: null,
  status: props.reviewAppInfo ? {
    selectedAccessRestriction: props.reviewAppInfo.accessRestriction === 'DISMISSIBLE_WARNING' ? 'WARNING' : props.reviewAppInfo.accessRestriction,
    isCheckedRecommendation: !!props.reviewAppInfo.alternativeLink,
    alternativeButtonValue: props.reviewAppInfo.alternativeButton,
    alternativeLinkValue: props.reviewAppInfo.alternativeLink,
    isDismissibleWarning: props.reviewAppInfo.accessRestriction === 'DISMISSIBLE_WARNING',
    isOverrideDefaultMessage: Boolean(props.reviewAppInfo.customRestrictionMessage),
    restrictionMessage: props.reviewAppInfo.customRestrictionMessage,
    excludeUserGroupsFromAccessRestriction: Boolean(
      props.reviewAppInfo.restrictionExcludedGroups?.length,
    ),
    excludedUserGroups: props.reviewAppInfo.restrictionExcludedGroups?.map(group => ({
      name: group.name,
      value: group.userGroupId,
    })),
  } : null,
  appInfo: {
    name: '',
    domains: [],
    description: '',
  },
});

const selectedApp = ref(defaultValues.value.selectedApp);
const handleSelectApp = (app) => {
  selectedApp.value = app;
};

const isCustomApp = ref(defaultValues.value.isCustomApp);
const handleSelectCustomApp = (searchValue) => {
  isCustomApp.value = true;
  selectedApp.value = null;
  temporaryCustomAppName.value = searchValue;
};

const modalState = computed(() => {
  const isAdd = props.mode === 'ADD';
  const isEdit = props.mode === 'EDIT';
  const isReview = props.mode === 'REVIEW';

  if (isEdit) return 'Edit';
  if (isReview) return 'Review';

  if (isAdd) {
    if (isCustomApp.value) return 'Add-Custom';
    return 'Add-Catalog';
  }
  return null;
});

const selectedOwner = ref(
  props.reviewAppInfo?.owner?.id
    ? { name: props.reviewAppInfo.owner.name, value: props.reviewAppInfo.owner.id }
    : defaultValues.value.selectedOwner,
);
const handleSelectOwner = (owner) => {
  selectedOwner.value = owner;
};

const status = ref({
  ...defaultValues.value.status,
  selectedStatus: defaultValues.value.status?.selectedStatus
      || defaultSelectedStatus.value?.value,
});

const handleUpdateStatus = (statusData) => {
  status.value = statusData;
};

const appInfo = ref(defaultValues.value.appInfo);
const handleUpdateAppInfo = (newAppInfo) => {
  appInfo.value = newAppInfo;
  temporaryCustomAppName.value = newAppInfo.name;
};

const resetToDefaultValues = () => {
  selectedApp.value = defaultValues.value.selectedApp;
  isCustomApp.value = defaultValues.value.isCustomApp;
  selectedOwner.value = defaultValues.value.selectedOwner;
  status.value = {
    ...defaultValues.value.status,
    selectedStatus: defaultSelectedStatus.value?.value,
  };
  appInfo.value = defaultValues.value.appInfo;
  temporaryCustomAppName.value = '';
};

const onClose = () => {
  resetToDefaultValues();
  emit('close');
};

const appData = computed(() => ({
  selectedApp: selectedApp.value?.value,
  isCustomApp: isCustomApp.value,
  selectedOwner: selectedOwner.value?.value,
  status: status.value,
  appInfo: {
    ...appInfo.value,
    name: appInfo.value.name || temporaryCustomAppName.value,
  },
}));

const onSave = () => {
  emit('save', appData.value);
};

const isSaveButtonDisabled = computed(() => {
  const hasAppName = !!appInfo.value.name || !!temporaryCustomAppName.value;
  if (modalState.value === 'Edit') {
    return !hasAppName;
  }

  const hasStatus = status.value?.selectedStatus;
  const isIgnored = status.value?.selectedStatus === 'IGNORED';
  const hasOwner = selectedOwner.value;

  const isUnapproved = status.value?.selectedStatus === 'UNAPPROVED';
  const isWarning = status.value?.selectedAccessRestriction === 'WARNING';
  const isBlocked = status.value?.selectedAccessRestriction === 'BLOCK';
  const mightHaveRecommendation = isWarning || isBlocked;
  const hasRecommendation = status.value?.isCheckedRecommendation;
  const hasAlternativeButtonValue = !!status.value?.alternativeButtonValue;
  const hasAlternativeLinkValue = !!status.value?.alternativeLinkValue;
  const areAlternativesValid = hasAlternativeButtonValue && hasAlternativeLinkValue;

  const isCustom = modalState.value === 'Add-Custom';

  const isCatalog = modalState.value === 'Add-Catalog';
  const hasSelectedApp = !!selectedApp.value;

  // TODO: any other cases?
  return (isCatalog && !hasSelectedApp)
      || (isCustom && !hasAppName)
      || !hasStatus
      || (!isIgnored && !hasOwner)
      || (isUnapproved && mightHaveRecommendation && hasRecommendation && !areAlternativesValid);
},
);

const showChooseAppFromCatalog = computed(() => modalState.value === 'Add-Catalog');
const showManualAppInformation = computed(() => modalState.value === 'Add-Custom'
  || modalState.value === 'Edit');
const showStatusAndOwner = computed(() => modalState.value === 'Add-Custom'
|| modalState.value === 'Add-Catalog' || modalState.value === 'Review');

</script>

<template>
  <FullPageModal
    class="modal"
    isFullScreen
    :showModal="isModalOpen"
    @close="onClose"
  >
    <template v-slot:header>
      <div class="flex items-center justify-between space-x-6">
        <HeadingMedium
          class="header"
          :data-test-id="$testId('headerText')"
        >
          {{ headerText }}
        </HeadingMedium>
        <AppAvatar
          v-if="modalState === 'Review'"
          :appId="reviewAppInfo.appId"
          manualAppAppAvatar
          :name="reviewAppInfo.name"
        />
      </div>
    </template>

    <template v-slot:body>
      <div class="bodyContainer">
        <template v-if="showChooseAppFromCatalog">
          <ChooseAppFromCatalog
            :selectedApp="selectedApp"
            @select-app="handleSelectApp"
            @select-custom-app="handleSelectCustomApp"
          />
          <ManualAppHR />
        </template>
        <template v-if="showManualAppInformation">
          <ManualAppInformation
            :description="editAppInfo.description"
            :domains="editAppInfo.domains"
            :initialName="temporaryCustomAppName"
            :name="editAppInfo.name"
            @update-app-info="handleUpdateAppInfo"
          />
          <ManualAppHR v-if="modalState !== 'Edit'" />
        </template>
        <StatusAndOwner
          v-if="showStatusAndOwner"
          :owner="selectedOwner"
          :status="status"
          @select-owner="handleSelectOwner"
          @update-status="handleUpdateStatus"
        />
      </div>
    </template>

    <template v-slot:footer>
      <ButtonGroup
        class="footerButtonGroup"
        :isFullWidth="false"
      >
        <ButtonTertiary
          :data-test-id="$testId('cancel')"
          text="Cancel"
          @click="onClose"
        />
        <Button
          :data-test-id="$testId('save')"
          :isDisabled="isActionButtonDisabled || isSaveButtonDisabled"
          :isLoading="isLoading"
          :text="saveButtonText"
          @click="onSave"
        />
      </ButtonGroup>
    </template>
  </FullPageModal>
</template>

<style scoped>
.bodyContainer {
  background: var(--Surface-Light, #FFF);
  border: var(--jc-border-width) solid var(--Neutral-Stroke-Subdued, var(--jcGrey200));
  border-radius: var(--jc-border-radius);
  display: flex;
  flex-direction: column;
  padding: var(--jc-size-1p5);
}

.header {
  color: var(--jc-text-color);
  font-weight: 600;
  margin-bottom: 0 !important;
}

.footerButtonGroup {
  column-gap: var(--jc-spacer-small);
  width: 100%;
}

</style>
