<!--eslint-disable-->
<script setup>
import { Icon, ParagraphBody, ChartWrapper  } from '@jumpcloud/ui-components';
import { computed } from 'vue';
import moment from 'moment';

const props = defineProps({
  emptyStateText: {
    type: String,
    default: '',
  },

  series: {
    type: Array,
    required: true,
  },

  showEmptyState: Boolean,

  tooltip: {
    type: Object,
    required: true,
  },

  xAxis: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['dataPointClick']);

const options = computed(() => ({
  chart: {
    events: {
      click(...args) {
        emit('dataPointClick', ...args);
      },
    },
    height: 300,
    toolbar: {
      show: false,
    },
    type: 'line',
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    row: {
      colors: ['transparent', 'transparent'],
      opacity: 0.5,
    },
  },
  stroke: {
    curve: 'straight',
  },
  tooltip: props.tooltip,
  type: 'line',
  xaxis: {
    ...props.xAxis,
    labels: {
      formatter(val) {
        return moment(val).format('D MMM').toUpperCase();
      },
      style: {
        colors: 'var(--jc-color-neutral-text-descriptive)',
      },
    },
  },
  yaxis: {
    labels: {
      formatter(val) {
        return val.toFixed(0);
      },
      style: {
        colors: 'var(--jc-color-neutral-text-descriptive)',
      },
    },
    max(max) {
      return Math.min(Math.max(1, Math.ceil(max * 1.2)), max + 10);
    },
  },
}));
</script>

<template>
  <div
    v-if="showEmptyState"
    class="h-[315px] w-full p-4"
    :class="$style.emptyState"
  >
    <Icon
      :class="$style.colorGrey"
      icon="directoryInsights"
      :scale="2"
    />
    <ParagraphBody
      class="text-center"
      :class="$style.colorGrey"
    >
      {{ emptyStateText }}
    </ParagraphBody>
  </div>
  <ChartWrapper
    v-else
    :options="options"
    :series="series"
    :style="$style.chart"
    type="line"
  />
</template>

<style module scoped>
.chart {
  --chart-width: 30rem;
}

.emptyState {
  align-items: center;
  background-color: var(--jc-background);
  border-radius: var(--jc-border-radius);
  color: var(--jc-text-color-light);
  display: flex;
  flex-direction: column;
  gap: var(--jc-spacer);
  justify-content: center;
}

.colorGrey {
  color: var(--jc-text-color-light);
}
</style>
