import { Types } from '@jumpcloud/ui-components';

const { Column } = Types;
export const saasApplicationAccountColumns = [
  Column({
    label: 'Source', dataFieldName: 'source', width: '3fr', sortable: true,
  }),
  Column({
    label: 'Method', dataFieldName: 'method', width: '2fr',
  }),
  Column({
    label: 'Tenant', dataFieldName: 'tenant', width: '1fr',
  }),
  Column({
    label: 'Last Login', dataFieldName: 'lastLoginAt', width: '1fr', sortable: true,
  }),
  Column({
    label: 'Discovery Date', dataFieldName: 'discoveredAt', width: '2fr', sortable: true,
  }),
  Column({
    label: 'Device', dataFieldName: 'device', width: '2fr', sortable: true,
  }),
];
