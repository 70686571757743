<script setup lang="ts">
import { $testId } from '@/test/testIdHelper';
import {
  Card,
  HeadingMedium,
  HeadingSmall,
  Link,
  ParagraphBody,
  ToggleSwitch,
  Tooltip,
} from '@jumpcloud/ui-components';
import { defineEmits, defineProps } from 'vue';

defineProps({
  isSaasManagementEnabled: {
    type: Boolean,
    default: false,
  },
  isSaasManagementUserPortalEnabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'update:isSaasManagementEnabled',
  'update:isSaasManagementUserPortalEnabled',
  'update:isSaasManagementBrowserExtensionEnabled',
  'update:isSaasManagementDeviceAgentEnabled',
]);

const handleSaasManagementEnabledChange = (isChecked: boolean) => {
  emit('update:isSaasManagementEnabled', isChecked);

  if (!isChecked) {
    emit('update:isSaasManagementUserPortalEnabled', isChecked);
    emit('update:isSaasManagementBrowserExtensionEnabled', isChecked);
    emit('update:isSaasManagementDeviceAgentEnabled', isChecked);
  }
};
</script>

<template>
  <Card>
    <template v-slot:body>
      <div class="bodyContainer">
        <div class="header">
          <ToggleSwitch
            class="mt-half"
            :data-test-id="$testId('saasManagementEnabledSwitch')"
            :modelValue="isSaasManagementEnabled"
            @update:model-value="handleSaasManagementEnabledChange"
          />
          <HeadingMedium>
            SaaS Management {{ isSaasManagementEnabled ? 'Enabled' : 'Disabled' }}
          </HeadingMedium>
        </div>
        <ParagraphBody
          bodySize="large"
          class="lightGreyText"
        >
          Enhance security with SaaS app management.
        </ParagraphBody>
        <div class="subHeader">
          <ParagraphBody :hasMargin="false">
            <b class="font-semibold">Requires:</b> JumpCloud Go Browser Extension
          </ParagraphBody>
          <div class="divider" />
          <Link
            class="link"
            to="/settings/features"
          >
            Go To Settings
          </Link>
        </div>
      </div>

      <div>
        <div class="header">
          <Tooltip
            :isMarkdown="false"
            :noHover="isSaasManagementEnabled"
            orientation="bottom"
            text="Enable SaaS Management to access and modify other settings."
          >
            <ToggleSwitch
              class="mt-half"
              :data-test-id="$testId('saasManagementUserPortalEnabledSwitch')"
              :isDisabled="!isSaasManagementEnabled"
              :modelValue="isSaasManagementUserPortalEnabled"
              @update:model-value="$emit('update:isSaasManagementUserPortalEnabled', $event)"
            />
          </Tooltip>
          <HeadingSmall>
            SaaS Management on User Portal
            {{ isSaasManagementUserPortalEnabled ? 'Enabled' : 'Disabled' }}
          </HeadingSmall>
        </div>
        <ParagraphBody
          bodySize="large"
          class="lightGreyText"
        >
          Display a SaaS Management info card on the User Portal security page.
        </ParagraphBody>
      </div>
    </template>
  </Card>
</template>

<style scoped>
.bodyContainer {
  @apply mb-4 border-b border-b-neutral pb-4
}

.header {
  @apply mb-2 flex items-start gap-2
}

.mt-half {
  @apply mt-0.5
}

.subHeader {
  @apply flex flex-wrap items-center gap-2
}

.divider {
  @apply mt-0.5 hidden h-3 border-l border-neutral sm:block
}

.link{
  @apply mt-0.5 text-sm
}

.lightGreyText {
  @apply text-light
}
</style>
