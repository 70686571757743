import { Buffer } from 'buffer';
import { destroy, initialize } from './initialize';
import { mountStyles, unmountStyles } from 'virtual:mfe-css';

window.Buffer = Buffer;

export const bootstrap = () => Promise.resolve();

export const mount = async () => {
  await mountStyles();
  await initialize();
};

export const unmount = () => {
  unmountStyles();
  destroy();

  return Promise.resolve();
};
