<script setup>
import { $testId } from '@/test/testIdHelper';
import {
  HeadingLarge,
  Link,
  LoadingOverlay,
  ParagraphBody,
  Tabs,
  Types,
} from '@jumpcloud/ui-components';
import { SaasConstants } from '@/util/Constants/SaasConstants';
import {
  computed,
  onMounted,
  ref,
} from 'vue';
import { useNotifications } from '@jumpcloud-ap/notifications';
import { useSettingsStore } from '@/stores/Settings';
import { useUserGroupsStore } from '@/stores/UserGroups';
import Connectors from '@/pages/Settings/Connectors/Connectors.vue';
import GeneralSettings from '@/pages/Settings/GeneralSettings/GeneralSettings.vue';

const { TabItem } = Types;

const props = defineProps({
  activeTab: {
    type: String,
    default: 'overview',
  },
});

const isLoading = ref(false);
const settingsStore = useSettingsStore();
const userGroupsStore = useUserGroupsStore();

const showGeneralSettings = computed(() => props.activeTab === 'general_settings');
const showConnectors = computed(() => props.activeTab === 'connectors');

const { triggerErrorNotification } = useNotifications();

const tabListRouter = computed(() => [
  TabItem({
    key: 'general_settings',
    label: 'General Settings',
    to: SaasConstants.tabs.settings.routes?.generalSettings.route,
  }),
  TabItem({
    key: 'connectors',
    label: 'Connectors',
    to: SaasConstants.tabs.settings.routes?.connectors.route,
    disabled: !settingsStore.isEnabled,
  }),
]);

onMounted(async () => {
  isLoading.value = true;
  try {
    await Promise.all([settingsStore.fetchSettings(), userGroupsStore.fetchUserGroups()]);
  } catch (error) {
    triggerErrorNotification({
      message: 'Failed to fetch settings',
      showCloseButton: true,
      error,
    });
  }
  isLoading.value = false;
});
</script>

<template>
  <div class="settingsPanelContainer wrapper">
    <LoadingOverlay
      :visible="isLoading"
    />

    <div class="mainHeaderContainer">
      <div class="cardHeader">
        <HeadingLarge>SaaS Management Settings</HeadingLarge>
      </div>
      <ParagraphBody>
        Customize the SaaS Management settings for your organization.
        <Link
          href="https://jumpcloud.com/support/configure-saas-management"
          target="_blank"
        >
          Learn More
        </Link>
      </ParagraphBody>
    </div>
    <div class="tabsContainer">
      <Tabs
        :activeTab="activeTab"
        :data-test-id="$testId('tabsContainer')"
        :tabs="tabListRouter"
      />
    </div>
    <div class="contentContainer">
      <GeneralSettings v-if="showGeneralSettings && !isLoading" />
      <Connectors v-if="showConnectors && !isLoading" />
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  @apply h-full;
}

.wrapper :deep() span[class^="InputMessage__message"]:has(span[class^="Icon__container"]) {
  @apply mb-4;
}

.wrapper :deep() span[class^="InputMessage__message"] span[class^="Icon__container"] {
  @apply pt-[3px];
}

.settingsPanelContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.mainHeaderContainer {
  padding: var(--jc-spacer-medium) var(--jc-spacer) var(--jc-spacer-small);
}

.cardHeader {
  padding-bottom: var(--jc-spacer-small);
}

.contentContainer {
  flex: 1;
  height: 100%;
  overflow: auto;
}

.tabsContainer {
  border-bottom: var(--jc-border-width) solid var(--jc-color-neutral-stroke);
  height: var(--jc-size-3);
  padding: 0 var(--jc-spacer);
}

.tabsContainer :deep() li[class^='Tabs__tabItem'] {
  height: var(--jc-size-3);
  margin-right: var(--jc-spacer-small) !important;
  padding: 0 var(--jc-spacer);
}

.tabsContainer :deep() li[class^='Tabs__indicator'] {
  margin-bottom: var(--jc-size-negative-0p125);
}
</style>
