import { Types } from '@jumpcloud/ui-components';

const { Column } = Types;
export const ssoConnectionsTableColumns = [
  Column({
    label: 'App', dataFieldName: 'app', width: '1fr',
  }),
  Column({
    label: 'Display Label', dataFieldName: 'displayLabel', width: '1fr',
  }),
  Column({
    label: 'Status', dataFieldName: 'status', width: '1fr',
  }),
  Column({
    label: '', dataFieldName: 'actions', width: '1fr', alignment: 'center',
  }),
];
