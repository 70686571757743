import { type AxiosInstance, createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';

export type ApplicationStats = {
  approved: 0,
  ignored: 0,
  newlyDiscovered: 0,
  totalApps: 0,
  unapproved: 0
};

class ApplicationStatsApi {
  private baseUrl: string;
  private axiosInstance: AxiosInstance;

  constructor() {
    this.baseUrl = '/api/v2/saas/apps-stats';
    this.axiosInstance = createJcApiAxiosInstance();
  }

  async fetch(): Promise<ApplicationStats> {
    const response = await this.axiosInstance.get(this.baseUrl);
    return response?.data;
  }
}

export default new ApplicationStatsApi();
