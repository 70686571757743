import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { useNotification } from '@/composables/useNotification';
import ConnectorsApi from '@/api/ConnectorsApi';
import type { Connector, ConnectorMetaItem } from '@/api/ConnectorsApi';

export const useConnectorsStore = defineStore('saas-connectors', () => {
  const connectors = ref<Connector[]>([]);
  const connectorsMetaData = ref<ConnectorMetaItem[]>([]);
  const connectorDetailData = ref<Connector | null>(null);

  const {
    triggerErrorEnhancedNotification,
    triggerSuccessEnhancedNotification,
  } = useNotification();

  const fetchConnectors = async () => {
    try {
      connectors.value = await ConnectorsApi.fetch();
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'Failed to fetch connectors',
        error,
      });
    }
  };

  const fetchConnectorsMeta = async () => {
    try {
      connectorsMetaData.value = await ConnectorsApi.getConnectorsMeta();
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'Failed to fetch connectors metadata',
        error,
      });
    }
  };

  const createConnector = async (connector: Connector) => {
    let response;
    try {
      response = await ConnectorsApi.create(connector);
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'Connector could not be created.',
        error,
      });
    }
    return response;
  };

  const updateConnector = async ({
    id,
    name,
    config,
  }: {
    id: string,
    name: string,
    config: Connector['config'],
  }) => {
    let response;
    try {
      response = await ConnectorsApi.update({ id, name, config });
      triggerSuccessEnhancedNotification({
        message: 'Connector updated successfully.',
      });
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'Connector could not be updated.',
        error,
      });
    }
    return response;
  };

  const deleteConnector = async (id: string) => {
    let isSuccess;
    try {
      await ConnectorsApi.delete(id);
      triggerSuccessEnhancedNotification({
        message: 'Connector deleted successfully.',
      });
      isSuccess = true;
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'Connector could not be deleted.',
        error,
      });
      isSuccess = false;
    }
    return isSuccess;
  };

  const reconnectConnector = async (id: string) => {
    let response;
    try {
      response = await ConnectorsApi.reconnect(id);
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'Connector could not be reconnected.',
        error,
      });
    }
    return response;
  };

  const getConnector = async (id: string) => {
    try {
      connectorDetailData.value = await ConnectorsApi.getConnector(id);
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'Connector could not be fetched.',
        error,
      });
    }
  };

  const connectorsList = computed(() => connectors.value);
  const connectorsMeta = computed(() => connectorsMetaData.value);
  const connectorDetail = computed(() => connectorDetailData.value);

  return {
    connectorDetail,
    connectorsList,
    connectorsMeta,
    fetchConnectors,
    fetchConnectorsMeta,
    createConnector,
    updateConnector,
    deleteConnector,
    reconnectConnector,
    getConnector,
  };
});
