<script setup>
import {
  HeadingSmall, ItemListColumnPicker, ModalStructure, Types,
} from '@jumpcloud/ui-components';

const { PrimaryButtonProps, SecondaryButtonProps } = Types;

const props = defineProps({
  actionColumns: {
    type: Array,
    default: () => [],
  },

  columns: {
    type: Array,
    required: true,
  },

  defaultFixedColumns: {
    type: Array,
    default: () => [],
  },

  defaultScrollingColumns: {
    type: Array,
    default: () => [],
  },

  showModal: {
    type: Boolean,
    required: true,
  },
});

defineEmits(['close', 'applyColChange']);

const columnConfig = {
  disabledFixedColumnCount: 1,
  maxFixedColumnCount: 3,
};

const fixedColumns = [];
const scrollingColumns = [];
const columnsMapping = () => {
  const cols = [];

  props.columns.forEach((col) => {
    const colName = col.dataFieldName || col.dataField;

    if (!props.actionColumns.includes(colName)) {
      cols.push({ key: colName, name: col.label });
    }

    if (col.isSticky) {
      fixedColumns.push(colName);
    } else if (col.visible && !props.actionColumns.includes(colName)) {
      scrollingColumns.push(colName);
    }
  });

  return cols;
};

const showPrimaryButtonProps = PrimaryButtonProps({
  text: 'Apply',
  type: 'primary',
});

const showSecondaryButtonProps = SecondaryButtonProps({
  text: 'cancel',
  type: 'secondary',
});
</script>

<template>
  <ModalStructure
    class="modal"
    :showModal="showModal"
    @close="$emit('close')"
  >
    <template v-slot:header>
      <HeadingSmall
        class="header"
        :hasMargin="false"
      >
        Edit Columns
      </HeadingSmall>
    </template>
    <template v-slot:body>
      <ItemListColumnPicker
        class="columnPicker"
        :columnConfig="columnConfig"
        :columns="columnsMapping()"
        :defaultFixedColumns="defaultFixedColumns"
        :defaultScrollingColumns="defaultScrollingColumns"
        :primaryButtonProps="showPrimaryButtonProps"
        :secondaryButtonProps="showSecondaryButtonProps"
        :selectedFixedColumns="fixedColumns"
        :selectedScrollingColumns="scrollingColumns"
        @cancel="$emit('close')"
        @save="$emit('applyColChange', $event)"
      />
    </template>
  </ModalStructure>
</template>

<style scoped>
.columnPicker {
  padding: var(--jc-spacer-x-small) 0;
}

.header{
  margin: var(--jc-spacer) var(--jc-spacer) 0;
}

.modal :deep() div[class*="ModalContainer__modal"]{
  --modal-width: 100ch;
  --modal-padding: 0;

  height: calc(var(--jc-size-45) - var(--jc-spacer));
}
</style>
