import { Types } from '@jumpcloud/ui-components';

const { Column } = Types;
export const saasUserDetailColumns = [
  Column({
    label: 'App', dataFieldName: 'appId', width: '1fr', sortable: true,
  }),
  Column({
    label: 'Account', dataFieldName: 'account', width: '1fr',
  }),
  Column({
    label: 'Login Method', dataFieldName: 'loginMethods', width: '1fr', alignment: 'center',
  }),
  Column({
    label: 'Last Used', dataFieldName: 'lastLoginAt', width: '1fr', sortable: true,
  }),
];
