import { createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';

export type Overview = {
  features: string[]
  isCollectionFinished: boolean
  isEnabled: boolean
  isGoEnabled: boolean
  state: 'ENABLED' | 'DISABLED',
  stats: {
    discoveredApps: number,
    monitoredUsers: number,
    approvedApps: number,
    unapprovedApps: number,
    lessUsedApps: number,
    upcomingRenewals: number,
    totalApps: number,
    ignoredApps: number,
    unapprovedAppsUsedInLastWeek: number
  }
};

class OverviewApi {
  private baseUrl: string;
  private axiosInstance;

  constructor() {
    this.baseUrl = '/api/v2/saas/welcome';
    this.axiosInstance = createJcApiAxiosInstance();
  }

  async fetch(): Promise<Overview> {
    const response = await this.axiosInstance.get(this.baseUrl);
    return response?.data;
  }
}

export default new OverviewApi();
