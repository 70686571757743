<script setup>
import {
  Button,
  ButtonGroup,
  ButtonTertiary,
  LoadingOverlay,
  NotificationInPage,
  Types,
} from '@jumpcloud/ui-components';
import { SaasConstants } from '@/util/Constants/SaasConstants';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { startCase } from 'lodash';
import { useConnectorsStore } from '@/stores/Connectors';
import { useLoadingState } from '@/util/useLoadingState';
import { useNotification } from '@/composables/useNotification';
import { useRoute, useRouter } from 'vue-router';
import AppAvatar from '@/components/AppAvatar.vue';
import ConfigurationSection from '@/pages/Settings/Connectors/ConnectorDetail/ConfigurationSection.vue';
import ConnectorStatusBadge from '@/components/ConnectorStatusBadge.vue';
import DeleteConnector from '@/pages/Settings/Connectors/ConnectorDetail/DeleteConnector.vue';
import OverviewSection from '@/pages/Settings/Connectors/ConnectorDetail//OverviewSection.vue';
import SaasContentView from '@/components/SaasContentView.vue';
import moment from 'moment';

const { NotificationConfig } = Types;

defineEmits(['close', 'save']);

const router = useRouter();
const route = useRoute();
const connectorsStore = useConnectorsStore();

const { id } = route.params;
const connectorName = ref('');
const connectorConfig = ref([]);

const {
  isLoading: isLoadingConnector,
  runAsyncWithLoading: runAsyncWithLoadingConnector,
} = useLoadingState();

const {
  isLoading: isLoadingSave,
  runAsyncWithLoading: runAsyncWithLoadingSave,
} = useLoadingState();

const {
  isLoading: isLoadingReconnect,
  runAsyncWithLoading: runAsyncWithLoadingReconnect,
} = useLoadingState();

const {
  isLoading: isLoadingMetadata,
  runAsyncWithLoading: runAsyncWithLoadingMetadata,
} = useLoadingState();

const isLoading = computed(() => isLoadingConnector.value || isLoadingMetadata.value);

const fetchConnector = async () => {
  await runAsyncWithLoadingConnector(async () => {
    await connectorsStore.getConnector(id);
  });
};

const fetchConnectorMetadata = async () => {
  await runAsyncWithLoadingMetadata(async () => {
    await connectorsStore.fetchConnectorsMeta();
  });
};

const data = computed(() => connectorsStore.connectorDetail);

const metadata = computed(
  () => connectorsStore.connectorsMeta
    .find((connector) => connector.type === data.value?.type),
);

const isDataAndMetadataExist = computed(() => data.value && metadata.value);

watch([data, metadata], ([newData, newMetadata]) => {
  connectorName.value = newData?.name;
  connectorConfig.value = newData?.config.map((item) => {
    const metadataItem = newMetadata?.config
      ?.find((configItem) => configItem.id === item.key);

    const metaDataType = metadataItem?.type;

    if (metaDataType === 'select') {
      if (item.multiple) {
        return {
          ...metadataItem,
          value: metadataItem.options.filter((option) => item.value.includes(option.value)),
        };
      }
      return {
        ...metadataItem,
        value: metadataItem.options.find((option) => option.value === item.value),
      };
    }

    return {
      ...metadataItem,
      value: item.value,
    };
  }).sort((a, b) => a.id.localeCompare(b.id));
});

const isSaveButtonDisabled = computed(() => {
  let isDisabled = true;

  if (connectorConfig.value) {
    connectorConfig.value.forEach((connectorConfigItem) => {
      const connectorConfigItemId = connectorConfigItem.id;
      const connectorConfigItemValue = connectorConfigItem.value;
      const dataConfigItem = data.value?.config
        .find((item) => item.key === connectorConfigItemId);

      if (dataConfigItem.value !== connectorConfigItemValue) {
        isDisabled = false;
      }
    });
  }

  if (connectorName.value !== data.value?.name) {
    isDisabled = false;
  }

  return isDisabled;
});

const overviewData = computed(() => {
  const constantOverviewData = [
    {
      label: 'Created',
      value: moment(data.value?.createdAt).format('MMM DD, YYYY [@] hh:mma'),
    },
    {
      label: 'Last Updated',
      value: moment(data.value?.updatedAt).format('MMM DD, YYYY [@] hh:mma'),
    },
  ];

  const dynamicOverviewData = Object.values(data.value?.properties || {}).map((item) => ({
    label: item.key === 'customer_id' ? 'Customer ID' : startCase(item.key),
    value: item.value.string_value,
  })).sort((a, b) => a.label.localeCompare(b.label));

  return [...constantOverviewData, ...dynamicOverviewData];
});

const {
  triggerErrorNotification,
  triggerSuccessNotification,
} = useNotification();

const readUrlParamsAndNotify = async () => {
  const { status, message } = route.query;
  if (status?.toLowerCase() === 'success' && message) {
    triggerSuccessNotification({
      title: decodeURIComponent(message),
      icon: 'checkmarkCircle',
      showCloseButton: true,
    });
  }
  if (status?.toLowerCase() === 'error' && message) {
    triggerErrorNotification({
      title: decodeURIComponent(message),
      icon: 'error',
      showCloseButton: true,
    });
  }
  if (status) {
    await router.replace({ query: {} });
  }
};

onMounted(() => {
  Promise.allSettled([
    fetchConnectorMetadata().catch(() => {}),
    fetchConnector().catch(() => {}),
    readUrlParamsAndNotify().catch(() => {}),
  ]);
});

const onSave = async () => {
  const cb = async () => {
    const config = connectorConfig.value.map((item) => {
      if (item.type === 'select') {
        if (item.multiple) {
          return {
            key: item.id,
            value: item.value.map((option) => option.value),
          };
        }
        return {
          key: item.id,
          value: item.value.value,
        };
      }
      return {
        key: item.id,
        value: item.value,
      };
    });

    await connectorsStore.updateConnector({
      id,
      name: connectorName.value,
      config,
    });
  };

  await runAsyncWithLoadingSave(cb);
  await fetchConnector();
};

const onReconnect = async () => {
  if (isLoadingReconnect.value) {
    return;
  }
  await runAsyncWithLoadingReconnect(async () => {
    const response = await connectorsStore.reconnectConnector(id);

    if (response?.redirectUrl) {
      window.open(response.redirectUrl, '_self');
    } else {
      await fetchConnector();
    }
  });
};

const notificationConfig = computed(() => (
  NotificationConfig({
    icon: 'error',
    type: 'error',
    title: data.value?.statusReason,
    isSingleLineAction: true,
    showCloseButton: false,
  })
));

const redirectToConnectorsList = () => {
  router.push(SaasConstants.tabs.settings.routes.connectors.route);
};

const handleConfigUpdate = (config) => {
  connectorConfig.value = connectorConfig.value.map((item) => {
    if (item.id === config.id) {
      return config;
    }
    return item;
  });
};
</script>

<template>
  <div class="wrapper">
    <LoadingOverlay
      isLight
      :visible="isLoading"
    />
    <div
      v-if="isDataAndMetadataExist"
      class="container"
    >
      <div class="avatarAndStatusBadge">
        <AppAvatar
          :appId="data?.type"
          :category="metadata?.name"
          large
          :name="data?.name"
          onTitleClickDisabled
        />
        <div class="statusBadgeContainer">
          <ConnectorStatusBadge :status="data?.status" />
        </div>
      </div>
      <DeleteConnector
        :data="data"
        :onDeleteRedirect="redirectToConnectorsList"
      />
    </div>
    <SaasContentView singleContentClass="gap-6">
      <NotificationInPage
        :actionText="metadata?.authType === 'oauth' ? 'Reconnect' : ''"
        :notification="notificationConfig"
        :showNotification="data?.status === 'CONNECTOR_STATUS_ERROR'"
        @action="onReconnect"
      />
      <OverviewSection :overviewData="overviewData" />
      <ConfigurationSection
        v-model:connectorName="connectorName"
        :configData="connectorConfig"
        @update:config="handleConfigUpdate"
      />
    </SaasContentView>
    <div class="footer">
      <ButtonGroup
        class="footerButtonGroup"
        :isFullWidth="false"
      >
        <ButtonTertiary
          :data-test-id="$testId('cancel')"
          :isDisabled="isLoading"
          text="Cancel"
          @click="redirectToConnectorsList"
        />
        <Button
          :data-test-id="$testId('save')"
          :isDisabled="isSaveButtonDisabled"
          :isLoading="isLoadingSave"
          text="Save Connector"
          @click="onSave"
        />
      </ButtonGroup>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  @apply flex h-full flex-col justify-between overflow-hidden bg-white-500
}

.container {
  @apply flex flex-wrap items-start justify-between gap-4 border-b border-neutral bg-white p-6
}

.avatarAndStatusBadge {
  @apply flex items-start gap-4
}

.statusBadgeContainer{
  @apply mt-1.5
}

.footer {
  @apply flex items-center justify-end border-t border-neutral bg-white p-4
}

.footerButtonGroup {
  column-gap: var(--jc-spacer-small) !important;
  width: 100%;
}
</style>
