import { Types } from '@jumpcloud/ui-components';

const { Column } = Types;
export const accountsColumns = [
  Column({
    label: 'Account', dataFieldName: 'account', width: '300px', sortable: true, isSticky: true,
  }),
  Column({
    label: 'Owner', dataFieldName: 'username', width: '250px', sortable: true,
  }),
  Column({
    label: 'Application', dataFieldName: 'appId', width: '200px', sortable: true,
  }),
  Column({
    label: 'Risks', dataFieldName: 'findings', width: '280px',
  }),
  Column({
    label: 'Login Methods', dataFieldName: 'loginMethods', width: '200px',
  }),
  Column({
    label: 'Sources', dataFieldName: 'discoverMethods', width: '200px',
  }),
  Column({
    label: 'Last Used', dataFieldName: 'lastUsedAt', width: '200px', sortable: true,
  }),
  Column({
    label: 'Discovery Date', dataFieldName: 'discoveredAt', width: '200px', sortable: true,
  }),
];

export const actionColumns = [];
export const defaultFixedColumns = ['account'];
export const defaultScrollingColumns = ['username', 'appId', 'findings', 'loginMethods', 'discoveryMethods', 'lastUsed', 'discoveryDate'];
export const localStorageKey = 'saasAccountsColumns';

export const allSelectableColumnOptions = [
  { name: 'Account', value: 'account', disabled: true },
  { name: 'Owner', value: 'username', disabled: true },
  { name: 'Application', value: 'appId', disabled: true },
  { name: 'Risks', value: 'findings', disabled: false },
  { name: 'Login Methods', value: 'loginMethods', disabled: false },
  { name: 'Sources', value: 'discoveryMethods', disabled: false },
  { name: 'Last Used', value: 'lastUsed', disabled: false },
  { name: 'Discovery Date', value: 'discoveryDate', disabled: false },
];

export const allSelectedColumnOptions = [
  { name: 'Account', value: 'account' },
  { name: 'Owner', value: 'user' },
  { name: 'Application', value: 'appId' },
  { name: 'Risks', value: 'findings' },
  { name: 'Login Methods', value: 'loginMethods' },
  { name: 'Sources', value: 'discoveryMethods' },
  { name: 'Last Used', value: 'lastUsed' },
  { name: 'Discovery Date', value: 'discoveryDate' },
];

// Keep those sorted
export const LOGIN_METHODS_OPTIONS = [
  { value: 'SSO_GOOGLE', name: 'Google SSO Login' },
  { value: 'SSO', name: 'JumpCloud SSO Login' },
  { value: 'SSO_MICROSOFT', name: 'Microsoft SSO Login' },
  { value: 'APPLICATION', name: 'Username & Password Login' },
];

export const DISCOVERY_METHOD_OPTIONS = [
  { value: 'BROWSER_EXTENSION', name: 'Browser Extension' },
  { value: 'CONNECTOR', name: 'Connector' },
  { value: 'JUMPCLOUD_SSO', name: 'JumpCloud SSO' },
];

export const FINDING_OPTIONS = [
  { value: 'former', name: 'Former Employee' },
  { value: 'shadow', name: 'Shadow Account' },
  { value: 'shared', name: 'Shared Account' },
];
