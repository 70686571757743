<script setup>
import {
  ButtonDismissibleChip,
  ButtonTertiary,
  Dropdown,
  Grid,
  GridItem,
  IconButton,
  InputSearch,
  InputSearchFilter,
  Listbox,
} from '@jumpcloud/ui-components';
import { defineEmits, defineProps, ref } from 'vue';
import AddConnectorButton from '@/components/SaasListTable/AddConnectorButton.vue';
import AddManualAppWithButton from '@/components/SaasListTable/AddManualAppWithButton.vue';

defineProps({
  appliedFilters: {
    type: Array,
    default: () => [],
  },

  columnOptions: {
    type: Array,
    default: () => [],
  },

  filterLimit: {
    type: Number,
    default: 8,
  },

  filters: {
    type: Array,
    default: () => [],
  },

  hasAddConnectorButton: Boolean,

  hasAddManualAppButton: Boolean,

  selectedColumnOptions: {
    type: Array,
    default: () => [],
  },

  searchValue: {
    type: String,
    default: '',
  },

  showSearchBar: Boolean,

  showSearchFilterBar: Boolean,
});

defineEmits([
  'applyFilters',
  'clearAllFilters',
  'updateSearchTerm',
  'updateColumnOptions',
  'removeFilter',
  'exportData',
]);

const isMenuOpen = ref(false);
const showAllFilters = ref(false);

const handleClickAway = () => {
  isMenuOpen.value = false;
};

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const toggleShowAllFilters = () => {
  showAllFilters.value = !showAllFilters.value;
};
</script>

<template>
  <Grid
    class="toolbarContainer"
    :hasGap="false"
  >
    <GridItem :gridSpan="7">
      <div class="searchContainer">
        <AddManualAppWithButton v-if="hasAddManualAppButton" />
        <AddConnectorButton v-if="hasAddConnectorButton" />
        <div class="searchInputWrapper">
          <InputSearch
            v-if="showSearchBar"
            :modelValue="searchValue"
            placeholder="Search"
            @update:model-value="$emit('updateSearchTerm', $event)"
          />
          <InputSearchFilter
            v-if="showSearchFilterBar"
            @apply="$emit('applyFilters')"
            @clear-all="$emit('clearAllFilters')"
            @update-search-term="$emit('updateSearchTerm', $event)"
          >
            <template v-slot:content>
              <slot name="filterContent" />
            </template>
          </InputSearchFilter>
        </div>
      </div>
    </GridItem>
    <GridItem
      class="dropdownContainer"
      :gridSpan="5"
    >
      <Dropdown
        v-if="columnOptions.length > 0"
        :isMenuOpen="isMenuOpen"
        @clickaway="handleClickAway"
      >
        <template v-slot:button>
          <div class="flex gap-2">
            <ButtonTertiary
              :hasOutline="false"
              text="Export"
              @click="$emit('exportData')"
            />
            <IconButton
              class="filterButton"
              hasNoBackground
              icon="caretDown"
              iconPosition="right"
              :scale="0.5"
              text="Columns"
              @click="toggleMenu"
            />
          </div>
        </template>
        <template v-slot:menu>
          <div
            class="filterDropdown"
            @close="handleClickAway"
          >
            <Listbox
              :modelValue="selectedColumnOptions"
              :options="columnOptions"
              showSearch
              @update:model-value="$emit('updateColumnOptions', $event)"
            />
          </div>
        </template>
      </Dropdown>
    </GridItem>
    <GridItem :gridSpan="12">
      <div
        v-if="appliedFilters.length > 0"
        class="filters"
      >
        <ButtonDismissibleChip
          v-for="filter in appliedFilters.filter(
            (_, index) => showAllFilters || index < filterLimit
          )"
          :key="filter.relatedField + filter.value"
          :prefix="filter.relatedField"
          :text="filter.name"
          @click="$emit('removeFilter', filter)"
        />
        <IconButton
          v-if="appliedFilters.length > filterLimit"
          hasNoBackground
          :icon="showAllFilters ? 'chevronUp' : 'chevronDown'"
          iconPosition="right"
          :scale="0.75"
          :text="showAllFilters ? 'Show Less' : 'Show More'"
          type="informational"
          @click="toggleShowAllFilters"
        />
        <IconButton
          class="clearAll"
          hasNoBackground
          icon="closeCircle"
          :scale="0.75"
          text="Clear All"
          type="informational"
          @click="$emit('clearAllFilters')"
        />
      </div>
    </GridItem>
  </Grid>
</template>

<style scoped>
.toolbarContainer {
  align-items: center;
  padding: 0.75rem 0;
}

.addAppButton {
  flex-shrink: 0;
}

.searchContainer {
  align-items: center;
  display: flex;
  gap: var(--jc-spacer);
  justify-content: space-between;
}

@media (max-width: 768px) {
  .searchContainer {
    align-items: flex-start;
    flex-direction: column;
  }
}

.searchInputWrapper {
  width: 100%;
}

.dropdownContainer {
  display: flex;
  justify-content: end;
  margin-left: auto;
  margin-right: var(--jc-input-size);
}

.filterDropdown {
  background: var(--jc-background-light);
  border: var(--jc-border);
  border-radius: var(--jc-border-radius-small);
  box-shadow: var(--jc-box-shadow);
  padding: var(--jc-spacer);
  position: absolute;
  right: 0;
  top: 20px;
  width: 250px;
  z-index: 10;
}

.filterButton {
  color: var(--jc-color-informational-strong);
  padding: 0 var(--jc-spacer);
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: var(--jc-spacer-small);
  padding-top: var(--jc-spacer-small)
}
</style>
