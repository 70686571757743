<script setup>
import { allSelectableColumnOptions } from '@/pages/Accounts/constants';
import { useSaasAccountsTable } from './useSaasAccountsTable';
import SaasAccountsTableFilterContent from '@/pages/Accounts/SaasAccountsTableFilterContent.vue';
import SaasAccountsTableRow from '@/pages/Accounts/SaasAccountsTableRow.vue';
import SaasCommonTable from '@/components/SaasListTable/SaasCommonTable.vue';

const {
  accountsData,
  fetchAccounts,
  selectedOwnerOptions,
  selectedApps,
  selectedFindings,
  selectedDiscoveryMethods,
  selectedLoginMethods,
  selectedLastUsed,
  selectedDiscoveryDate,
  appliedFilters,
  currentPageNumber,
  handleApplyFilters,
  handleClearAllFilters,
  handlePageChange,
  handleRemoveFilter,
  handleSelect,
  handleSelectAll,
  handleSortColumn,
  handleUpdateColumnOptions,
  indeterminateSelectAll,
  isLoading,
  rowsPerPage,
  searchValue,
  selectAll,
  sortBy,
  sortDirection,
  tableData,
  exportData,
  exportAllData,
  actionDropdownItems,
  actionColumns,
  defaultFixedColumns,
  defaultScrollingColumns,
  columns,
  localStorageKey,
  selectedColumnOptions,
} = useSaasAccountsTable();

const onAssign = () => {
  fetchAccounts();
};

</script>

<template>
  <SaasCommonTable
    v-model:searchValue="searchValue"
    :actionColumns="actionColumns"
    :actionItems="actionDropdownItems"
    :appliedFilters="appliedFilters"
    class="px-4"
    :columns="columns"
    :currentPageNumber="currentPageNumber"
    :data="tableData.map((item) => ({ ...item, id: item.objectId + item.appId + item.account }))"
    :defaultFixedColumns="defaultFixedColumns"
    :defaultScrollingColumns="defaultScrollingColumns"
    :indeterminateSelectAll="indeterminateSelectAll"
    :isLoading="isLoading"
    :localStorageKey="localStorageKey"
    newTableLayout
    :rowsPerPage="rowsPerPage"
    :selectAll="selectAll"
    :selectable="false"
    :selectableColumnOptions="allSelectableColumnOptions"
    :selectedColumnOptions="selectedColumnOptions"
    showPaginationControls
    :sortBy="sortBy"
    :sortDirection="sortDirection"
    :totalCount="accountsData.count"
    @apply-filters="handleApplyFilters"
    @clear-all-filters="handleClearAllFilters"
    @export-all-data="exportAllData"
    @export-data="exportData"
    @handle-page-change="handlePageChange"
    @handle-select="handleSelect"
    @handle-select-all="handleSelectAll"
    @refresh="fetchAccounts"
    @remove-filter="handleRemoveFilter"
    @sort-column="handleSortColumn"
    @update-column-options="handleUpdateColumnOptions"
  >
    <template
      v-for="column in columns"
      #[column.dataFieldName]="{ record }"
    >
      <slot
        :name="column.dataFieldName"
        :record="record"
      >
        <SaasAccountsTableRow
          :column="column"
          :record="record"
          @assign="onAssign"
        />
      </slot>
    </template>

    <template v-slot:filterContent>
      <SaasAccountsTableFilterContent
        v-model:selectedApps="selectedApps"
        v-model:selectedDiscoveryDate="selectedDiscoveryDate"
        v-model:selectedDiscoveryMethods="selectedDiscoveryMethods"
        v-model:selectedFindings="selectedFindings"
        v-model:selectedLastUsed="selectedLastUsed"
        v-model:selectedLoginMethods="selectedLoginMethods"
        v-model:selectedOwnerOptions="selectedOwnerOptions"
        :appliedFilters="appliedFilters"
      />
    </template>
  </SaasCommonTable>
</template>
