<script setup>
import { $testId } from '@/test/testIdHelper';
import {
  HeadingExtraSmall,
  Icon,
  Link,
  ParagraphBody,
} from '@jumpcloud/ui-components';
import { SaasConstants } from '@/util/Constants/SaasConstants';
import { missingText } from '@/util/Constants/missingText';
import { ref } from 'vue';
import LicenseInformation from '@/pages/Applications/ApplicationDetails/Overview/LicenseInformation.vue';
import SaasContentView from '@/components/SaasContentView.vue';
import SsoConnectionStatusSection from './SsoConnectionStatusSection.vue';
import SsoConnectionsModal from '@/components/SsoConnectionsModal/SsoConnectionsModal.vue';
import UnassignedUsersNotification from './UnassignedUsersNotification.vue';
import UsageCard from '@/components/UsageCard.vue';

defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },

  dayCount: {
    type: Number,
    required: true,
  },

  id: {
    type: String,
    required: true,
  },

  isLoadingUsage: {
    type: Boolean,
    required: true,
  },

  usageData: {
    type: Array,
    default: () => [],
  },
});

defineEmits(['goToAccounts', 'openLicenseModal', 'updateDayCount']);

const isSsoModalOpen = ref(false);

const showNotification = ref(true);

</script>

<template>
  <SaasContentView
    leftContentClass="gap-6"
    rightContentClass="flex-col gap-6"
  >
    <template #leftContent>
      <UnassignedUsersNotification
        v-if="data?.unassignedAccountCount > 0 && showNotification"
        class="max-xl:hidden xl:block"
        :data="data"
        :showNotification="showNotification"
        @close-notification="showNotification = false"
        @review-button-click="$emit('goToAccounts')"
      />
      <UsageCard
        :appId="id"
        :data="usageData"
        :dayCount="dayCount"
        :emptyStateText="`No app usage within the last ${dayCount} days`"
        :isLoading="isLoadingUsage"
        title="App Usage"
        tooltipSuffix="used"
        @update-day-count="$emit('updateDayCount', $event)"
      />
      <LicenseInformation
        :appId="id"
        @open-license-modal="$emit('openLicenseModal')"
      />
    </template>

    <template #rightContent>
      <UnassignedUsersNotification
        v-if="data?.unassignedAccountCount > 0 && showNotification"
        class="max-xl:block xl:hidden"
        :data="data"
        :showNotification="showNotification"
        @close-notification="showNotification = false"
        @review-button-click="$emit('goToAccounts')"
      />
      <div
        class="ownerSectionWrapper"
      >
        <section>
          <HeadingExtraSmall :hasMargin="false">
            Owner
          </HeadingExtraSmall>
          <ParagraphBody :data-test-id="$testId('ownerCard')">
            <template v-if="!data?.owner">
              {{ missingText }}
            </template>
            <Link
              v-else
              :data-test-id="$testId('ownerCardLink')"
              :text="data?.owner?.name"
              :to="`${SaasConstants.tabs.users.route}/${data?.owner?.id}`"
            />
          </ParagraphBody>
        </section>
        <section>
          <HeadingExtraSmall :hasMargin="false">
            App Category
          </HeadingExtraSmall>
          <ParagraphBody :data-test-id="$testId('appCategoryCard')">
            {{ data?.category?.name }}
          </ParagraphBody>
        </section>
        <section v-if="data?.ssoApps">
          <SsoConnectionStatusSection
            bodySize="standard"
            isBackgroundExist
            :ssoApps="data?.ssoApps"
            @open-sso-connections-modal="isSsoModalOpen = true"
          />
        </section>
        <section>
          <HeadingExtraSmall :hasMargin="false">
            Home Page
          </HeadingExtraSmall>
          <ParagraphBody :data-test-id="$testId('homePageCard')">
            <template v-if="!data?.homePage">
              {{ missingText }}
            </template>
            <span
              v-else
              class="linkWrapperClass"
            >
              <Link
                :data-test-id="$testId('homePageCardLink')"
                :href="data?.homePage"
                target="_blank"
                :text="data?.homePage"
              />
              <Icon
                class="iconClass"
                icon="externalLink"
                :scale="1"
              />
            </span>
          </ParagraphBody>
        </section>
        <section :data-test-id="$testId('domainsSection')">
          <HeadingExtraSmall :hasMargin="false">
            Tracking Domains
          </HeadingExtraSmall>
          <ParagraphBody v-if="!data?.domains?.length">
            {{ missingText }}
          </ParagraphBody>
          <div v-else>
            <ParagraphBody
              v-for="domain in data.domains"
              :key="domain"
              :hasMargin="false"
            >
              {{ domain }}
            </ParagraphBody>
          </div>
        </section>

        <hr class="hrClass">

        <section class="paragraphSection">
          <ParagraphBody
            v-if="data?.description"
            bodySize="small"
            class="textNeutralDescriptive"
            :data-test-id="$testId('description')"
          >
            {{ data?.description }}
          </ParagraphBody>
          <ParagraphBody
            v-else
            bodySize="small"
            class="textDisabled"
          >
            There is no description.
          </ParagraphBody>
        </section>
      </div>

      <SsoConnectionsModal
        :data="data?.ssoApps"
        :isModalOpen="isSsoModalOpen"
        @close="isSsoModalOpen = false"
      />
    </template>
  </SaasContentView>
</template>

<style scoped>
.ownerSectionWrapper {
  @apply grid grid-cols-1 gap-4 *:grid *:gap-1 md:grid-cols-2 xl:flex xl:flex-col;
}

.textNeutralDescriptive {
  @apply text-neutral-descriptive;
}

.textDisabled {
  @apply text-disabled;
}

.hrClass {
  margin: 0;
  @apply w-full border-b border-neutral md:col-span-2 xl:col-span-1;
}

.iconClass {
  @apply mb-0.5 size-2.5 text-info-base;
}

.maxWFit {
  @apply max-w-fit;
}

.linkWrapperClass {
  @apply inline-flex items-center gap-2;
}

.paragraphSection {
  @apply pb-6 md:col-span-2 xl:col-span-1;
}
</style>
