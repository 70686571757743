<script setup>
import {
  Button, ButtonSecondary, Grid, GridItem, HeadingSmall, Icon, ModalStructure, ParagraphBody,
} from '@jumpcloud/ui-components';
import { ref } from 'vue';
import { useAccountsStore } from '@/stores/Accounts';
import { useLoadingState } from '@/util/useLoadingState';
import SaasUserSelection from '@/components/SaasUserSelection.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },

  appId: {
    type: String,
    required: true,
  },

  isModalOpen: {
    type: Boolean,
    required: true,
  },

  user: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close', 'assign']);

const { isLoading, runAsyncWithLoading } = useLoadingState();
const accountsStore = useAccountsStore();

const selectedOwnerOptions = ref(props?.user?.id
  ? { name: props.user.name, value: props.user.id }
  : null);

const closeModal = () => {
  emit('close');
};

const assignUser = async () => {
  const onAssignUserCallback = async () => {
    await accountsStore.assignUserToAccount({
      accountId: props.accountId,
      userId: selectedOwnerOptions?.value?.value,
      appId: props.appId,
    });
    emit('assign');
  };

  await runAsyncWithLoading(onAssignUserCallback);
  closeModal();
};
</script>

<template>
  <ModalStructure
    class="modalAssignUser"
    footerAlignment="right"
    hasBlur
    shouldCloseOnBlur
    :showModal="isModalOpen"
    showCloseButton
    @close="closeModal"
  >
    <template #header>
      <HeadingSmall>Assign a user</HeadingSmall>
    </template>
    <template #body>
      <SaasUserSelection
        v-model:selectedOwnerOptions="selectedOwnerOptions"
        appendToBody
        class="pb-1"
        headerText="User"
        required
      />
      <div
        v-if="selectedOwnerOptions && selectedOwnerOptions.value !== user.id"
        class="mt-5 flex items-center gap-4 rounded bg-info-50 p-4"
      >
        <Icon
          class="text-info-500"
          icon="info"
          :scale="1"
        />
        <ParagraphBody>
          You are about to assign a user to the <b>{{ selectedOwnerOptions.name }}</b> account.
          This action can be changed after the user is assigned.
        </ParagraphBody>
      </div>
    </template>
    <template #footer>
      <Grid hasGap>
        <GridItem :gridSpan="6">
          <ButtonSecondary
            text="Cancel"
            @click="closeModal"
          />
        </GridItem>
        <GridItem :gridSpan="6">
          <Button
            :isDisabled="!selectedOwnerOptions || selectedOwnerOptions.value === user.id"
            :isLoading="isLoading"
            text="Assign"
            @click="assignUser"
          />
        </GridItem>
      </Grid>
    </template>
  </ModalStructure>
</template>

<style scoped>
.modalAssignUser {
  --modal-width: 600px;
}
</style>
