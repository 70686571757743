<script setup>
import { NotificationInPage, Types } from '@jumpcloud/ui-components';
import { computed } from 'vue';
import pluralize from 'pluralize';

const { NotificationConfig } = Types;

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },

  showNotification: {
    type: Boolean,
    required: true,
  },

});

defineEmits(['reviewButtonClick', 'closeNotification']);
const accountCountText = computed(
  () => (props.data.unassignedAccountCount > 99 ? '99+' : String(props.data.unassignedAccountCount)),
);

const message = computed(() => `${accountCountText.value} ${pluralize(
  'unassigned account',
  props.data.unassignedAccountCount,
)} ${props.data.unassignedAccountCount === 1 ? 'is' : 'are'} using ${props.data.name}, please review and assign them.`);

const notificationConfig = computed(() => new NotificationConfig({
  icon: 'error',
  isMarkdown: false,
  isSingleLineAction: true,
  showCloseButton: true,
  title: message.value,
  type: 'error',
}));
</script>

<template>
  <div
    v-if="showNotification"
    v-bind="$attrs"
  >
    <NotificationInPage
      actionText="Review"
      :notification="notificationConfig"
      :showNotification="showNotification"
      @action="$emit('reviewButtonClick')"
      @close="$emit('closeNotification')"
    />
  </div>
</template>
