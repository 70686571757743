<script setup lang="ts">
import {
  Card,
  CardStatusButton,
  Grid,
  GridItem,
  HeadingExtraSmall,
  HeadingMedium,
  Link,
  ParagraphBody,
} from '@jumpcloud/ui-components';
import { SaasConstants } from '@/util/Constants/SaasConstants';
import { computed } from 'vue';
import { missingText } from '@/util/Constants/missingText';
import { useOverviewStore } from '@/stores/Overview';
import { useRouter } from 'vue-router';
import type { ComputedRef } from 'vue';

const path = SaasConstants.route;
const router = useRouter();
const overviewStore = useOverviewStore();
const overviewStats = computed(() => overviewStore.stats);
const totalApps = overviewStats.value?.totalApps || 0;

type OverviewValue = {
  id: string;
  count: number;
  label: string;
  clickHandler: () => void;
  countClass: string;
};

const overviewValues: ComputedRef<OverviewValue[]> = computed(() => [
  {
    id: 'newlyDiscoveredApps',
    count: overviewStats.value?.discoveredApps || 0,
    label: 'Newly Discovered Apps',
    clickHandler: () => router.push(`${path}/applications#newly_discovered`),
    countClass: 'newlyDiscoveredAppsCount',
  },
  {
    id: 'approvedApps',
    count: overviewStats.value?.approvedApps || 0,
    label: 'Approved Apps',
    clickHandler: () => router.push(`${path}/applications#approved`),
    countClass: 'approvedAppsCount',
  },
  {
    id: 'unapprovedApps',
    count: overviewStats.value?.unapprovedApps || 0,
    label: 'Unapproved Apps',
    clickHandler: () => router.push(`${path}/applications#unapproved`),
    countClass: 'unapprovedAppsCount',
  },
  {
    id: 'ignoredApps',
    count: overviewStats.value?.ignoredApps || 0,
    label: 'Ignored Apps',
    clickHandler: () => router.push(`${path}/applications#ignored`),
    countClass: 'ignoredAppsCount',
  },

]);

type NextCard = {
  id: string;
  count: number | string;
  label: string;
  icon: string;
  clickHandler: () => void;
};

const nextCards: ComputedRef<NextCard[]> = computed(() => [
  {
    id: 'usersMonitored',
    count: overviewStats.value?.monitoredUsers ?? missingText,
    label: 'Users monitored',
    icon: 'userGroup',
    clickHandler: () => router.push((`${path}/users`)),
  },
  {
    id: 'unapprovedAppsInLast7Days',
    count: overviewStats.value?.unapprovedAppsUsedInLastWeek ?? missingText,
    label: 'Unapproved apps used in the last 7 days',
    icon: 'error',
    clickHandler: () => router.push(`${path}/applications?lastUsed=IN_LAST_7_DAYS#unapproved`),
  },
  {
    id: 'upcomingRenewals',
    count: overviewStats.value?.upcomingRenewals ?? missingText,
    label: 'Upcoming renewals in the next 30 days',
    icon: 'calendar',
    clickHandler: () => router.push(`${path}/applications?renewal=IN_NEXT_30_DAYS#approved`),
  },
]);

const newlyDiscoveredApps = overviewValues.value.find((item) => item.id === 'newlyDiscoveredApps');
const approvedApps = overviewValues.value.find((item) => item.id === 'approvedApps');
const unapprovedApps = overviewValues.value.find((item) => item.id === 'unapprovedApps');
const ignoredApps = overviewValues.value.find((item) => item.id === 'ignoredApps');

const percentageOfNewlyDiscoveredApps = (newlyDiscoveredApps
  ? newlyDiscoveredApps.count : 0 / totalApps) * 100;

const percentageOfApprovedApps = (approvedApps
  ? approvedApps.count : 0 / totalApps) * 100;

const percentageOfUnapprovedApps = (unapprovedApps
  ? unapprovedApps.count : 0 / totalApps) * 100;

const percentageOfIgnoredApps = (ignoredApps
  ? ignoredApps.count : 0 / totalApps) * 100;
</script>

<template>
  <div class="gridContainer">
    <Grid>
      <GridItem :gridSpan="12">
        <Card>
          <template v-slot:body>
            <HeadingMedium :hasMargin="false">
              SaaS Management Overview
            </HeadingMedium>
            <ParagraphBody class="paragraphBody">
              Monitor and secure SaaS app use within your org.
              <Link
                :href="SaasConstants.getStartedSupport"
                isExternal
                target="_blank"
                text="Learn More"
              />
            </ParagraphBody>
            <div class="line" />
            <div class="insightsContainer">
              <div class="appCountsContainerAndTotalApps">
                <div class="appCountAndLabelContainer">
                  <button
                    v-for="{ id, count, label, clickHandler, countClass } in overviewValues"
                    :key="id"
                    class="appCountAndLabel"
                    @click="clickHandler"
                  >
                    <HeadingMedium
                      :class="countClass"
                      :hasMargin="false"
                    >
                      {{ count }}
                    </HeadingMedium>
                    <HeadingExtraSmall
                      class="appLabel"
                      weight="semibold"
                    >
                      {{ label }}
                    </HeadingExtraSmall>
                  </button>
                </div>
                <div class="totalAppsContainer">
                  <HeadingExtraSmall
                    class="totalAppsHeading"
                    :hasMargin="false"
                  >
                    {{ totalApps }}
                  </HeadingExtraSmall>
                  <ParagraphBody>
                    Total SaaS Applications
                  </ParagraphBody>
                </div>
              </div>
              <div
                class="lineBar"
              >
                <div
                  class="newlyDiscoveredAppsLine"
                  :style="{ width: `${percentageOfNewlyDiscoveredApps}%` }"
                />
                <div
                  class="approvedAppsLine"
                  :style="{ width: `${percentageOfApprovedApps}%` }"
                />
                <div
                  class="unapprovedAppsLine"
                  :style="{ width: `${percentageOfUnapprovedApps}%` }"
                />
                <div
                  class="ignoredAppsLine"
                  :style="{ width: `${percentageOfIgnoredApps}%` }"
                />
              </div>
            </div>
          </template>
        </Card>
      </GridItem>
      <GridItem
        v-for="{ id, count, label, icon, clickHandler } in nextCards"
        :key="id"
        :gridSpan="4"
      >
        <CardStatusButton
          :key="id"
          class="cardStatus"
          :icon="icon as any"
          iconPosition="right"
          :label="label"
          :title="String(count)"
          type="default"
          @click="clickHandler"
        />
      </GridItem>
    </Grid>
  </div>
</template>

<style scoped>
.gridContainer {
  background: var(--jc-background);
  flex: 1;
  padding: var(--jc-spacer-medium);
}

.line {
  background-color: var(--jc-scrollbar-background);
  height: 0.1rem;
  margin-bottom: var(--jc-size-1);
}

.insightsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--jc-spacer);
}

.appCountsContainerAndTotalApps {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.appCountAndLabelContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--jc-spacer-large);
}

.appCountAndLabel {
  background: transparent;
  display: flex;
  flex-direction: column;
  padding: var(--jc-spacer-x-small);
  text-align: left;
}

.appCountAndLabel:hover {
  background: var(--jc-color-secondary-fill-hover);
  border-radius: var(--jc-border-radius);
  cursor: pointer;
}

.totalAppsHeading {
  line-height: 1.5;
}

.paragraphBody {
  color: var(--jc-text-color-light);
  margin-top: var(--jc-spacer-small);
}

.newlyDiscoveredAppsCount {
  color: var(--jc-button-informational);
  margin-bottom: var(--jc-spacer-x-small);
}

.approvedAppsCount {
  color: var(--jc-color-success-base);
  margin-bottom: var(--jc-spacer-x-small);
}

.unapprovedAppsCount {
  color: var(--jc-color-error-base);
  margin-bottom: var(--jc-spacer-x-small);
}

.ignoredAppsCount {
  color: var(--jc-color-neutral-text-subdued);
  margin-bottom: var(--jc-spacer-x-small);
}

.appLabel {
  color: var(--jc-color-neutral-text-subdued);
}

.totalAppsContainer {
  align-items: end;
  display: flex;
  gap: var(--jc-spacer-x-small);
  min-height: 40px;
}

@media only screen and (max-width: 768px) {
  .appCountAndLabel {
    width: calc(50% - (var(--jc-spacer-large) / 2) );
  }
}

@media only screen and (max-width: 640px) {
  .appCountAndLabel {
    width: 100%;
  }
}

.lineBar {
  align-items: center;
  align-self: stretch;
  border-radius: var(--jc-border-radius-x-small);
  display: flex;
}

.approvedAppsLine {
  background-color: var(--jc-color-success-base);
  height: var(--jc-size-0p583);
}

.newlyDiscoveredAppsLine {
  background-color: var(--jc-button-informational);
  height: var(--jc-size-0p583);
}

.unapprovedAppsLine {
  background-color: var(--jc-color-error-base);
  height: var(--jc-size-0p583);
}

.ignoredAppsLine {
  background-color: var(--jc-color-neutral-stroke-base);
  height: var(--jc-size-0p583);
}

.cardStatus {
  cursor: pointer;
  width: 100%;
}
</style>
