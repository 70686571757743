<script setup>
import {
  Button,
  ButtonSecondary,
  DatePickerInput,
  Grid,
  GridItem,
  HeadingSmall,
  InputNumber,
  ModalStructure,
  RadioGroup,
  Select,
  Textarea,
} from '@jumpcloud/ui-components';
import { computed, reactive } from 'vue';
import { isEqual, isNaN } from 'lodash';
import { useApplicationsStore } from '@/stores/Applications';
import { useLoadingState } from '@/util/useLoadingState';
import AppAvatar from '@/components/AppAvatar.vue';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  isModalOpen: Boolean,
});

const emit = defineEmits(['close', 'save']);

const licenseInfo = computed(() => props.data.license);
const { isLoading, runAsyncWithLoading } = useLoadingState();
const applicationsStore = useApplicationsStore();

const licenseUsageOptions = [
  {
    label: 'Do not track license information for this app',
    value: 'DO_NOT_TRACK',
  },
  { label: 'Track license information for this app', value: 'TRACK' },
];

const termOptions = [
  { name: 'Free Subscription', value: 'FREE' },
  { name: 'Paid Monthly', value: 'MONTHLY' },
  { name: 'Paid Yearly', value: 'YEARLY' },
];

const formData = reactive({
  notes: licenseInfo.value?.notes,
  amount: licenseInfo.value?.price?.amount?.toFixed(2),
  term: licenseInfo.value?.price?.term || 'FREE',
  renewalDate: new Date(licenseInfo.value?.renewalDate) || null,
  totalLicenses: licenseInfo.value?.totalLicenses,
  licenseUsage: licenseInfo.value ? 'TRACK' : 'DO_NOT_TRACK',
});

const initialFormData = { ...formData };

const isSaveButtonDisabled = computed(() => {
  const isFormNotDirty = isEqual(formData, initialFormData);
  const {
    amount, licenseUsage, term, totalLicenses,
  } = formData;
  const isNotTracked = licenseUsage === 'DO_NOT_TRACK';
  const isFreeSubscription = term === 'FREE';
  const costIsValid = amount && !isNaN(Number(amount)) && Number(amount) >= 0;

  const totalLicensesIsValid = !totalLicenses
    || !isNaN(Number(totalLicenses));

  const isEnabled = isNotTracked
    || isFreeSubscription
    || (costIsValid && totalLicensesIsValid);

  return isFormNotDirty || !isEnabled;
});

const onSave = async () => {
  if (formData.licenseUsage === 'DO_NOT_TRACK') {
    const doNotTrackSaveCallback = async () => {
      const res = await applicationsStore.deleteAppLicense({
        id: props.data.appId,
      });
      await applicationsStore.fetchApplicationDetail(props.data.appId);
      if (res?.status === 200) {
        emit('close');
      }
    };

    await runAsyncWithLoading(doNotTrackSaveCallback);
  } else {
    const {
      term, notes, renewalDate, totalLicenses, amount,
    } = formData;

    const isFreeSubscription = term === 'FREE';

    const amountStringTwoDecimals = Number(amount).toFixed(2);
    const amountNumber = Number(amountStringTwoDecimals) || 0;

    const payload = {
      price: {
        amount: amountNumber,
        term,
      },
    };

    if (notes) {
      payload.notes = notes;
    }

    if (isFreeSubscription) {
      payload.price.amount = 0;
    }

    if (!isFreeSubscription) {
      // renewalDate is either null or a date object that is either valid or invalid
      if (renewalDate && !isNaN(renewalDate.getTime())) {
        payload.renewalDate = renewalDate;
      }
      if (totalLicenses) {
        payload.totalLicenses = Number(totalLicenses);
      }
    }

    const trackSaveCallback = async () => {
      const res = await applicationsStore.updateAppLicense({
        id: props.data.appId,
        payload,
      });

      await applicationsStore.fetchApplicationDetail(props.data.appId);

      if (res?.status === 200) {
        emit('close');
      }
    };
    await runAsyncWithLoading(trackSaveCallback);
  }
};
</script>

<template>
  <ModalStructure
    class="modal"
    footerAlignment="right"
    hasBlur
    shouldCloseOnBlur
    :showModal="isModalOpen"
    @close="$emit('close')"
  >
    <template #header>
      <HeadingSmall>Edit App License Information</HeadingSmall>
    </template>
    <template #body>
      <div class="avatarContainer">
        <AppAvatar
          :appId="data.appId"
          :category="data.category.name"
          inReviewModal
          large
          :name="data.name"
          onTitleClickDisabled
        />
      </div>
      <RadioGroup
        v-model="formData.licenseUsage"
        label="License Usage Settings"
        name="licenseUsage"
        :options="licenseUsageOptions"
        showValidationMessage
      />
      <Select
        v-if="formData.licenseUsage === 'TRACK'"
        v-model="formData.term"
        label="Term"
        :options="termOptions"
        required
      />
      <DatePickerInput
        v-if="formData.licenseUsage === 'TRACK' && formData.term !== 'FREE'"
        v-model="formData.renewalDate"
        label="Renewal Date"
        :showTimePicker="false"
      />
      <div
        v-if="formData.licenseUsage === 'TRACK' && formData.term !== 'FREE'"
        class="priceAndTotalLicenseContainer"
      >
        <InputNumber
          v-model="formData.amount"
          label="Cost ($)"
          min="0"
          name="Cost"
          placeholder="Enter cost"
          rules="required"
          showValidationMessage
        />
        <InputNumber
          v-model="formData.totalLicenses"
          label="Total Licenses"
          min="0"
          name="Total Licenses"
          placeholder="Enter total licenses"
          rules="integer"
          showValidationMessage
        />
      </div>
      <Textarea
        v-if="formData.licenseUsage === 'TRACK'"
        v-model="formData.notes"
        label="Notes"
        name="Notes"
        placeholder="Add notes here"
        rules="max:480"
        showValidationMessage
      />
    </template>
    <template #footer>
      <Grid hasGap>
        <GridItem :gridSpan="6">
          <ButtonSecondary
            :hasOutline="true"
            text="Cancel"
            @click="$emit('close')"
          />
        </GridItem>
        <GridItem :gridSpan="6">
          <Button
            :isDisabled="isSaveButtonDisabled"
            :isLoading="isLoading"
            text="Save"
            type="primary"
            @click="onSave"
          />
        </GridItem>
      </Grid>
    </template>
  </ModalStructure>
</template>

<style scoped>
.avatarContainer {
  background: var(--jc-background);
  border: var(--jc-border);
  border-radius: var(--jc-border-radius-large);
  margin-bottom: var(--jc-spacer-medium);
  padding: var(--jc-spacer-small);
}

.priceAndTotalLicenseContainer {
  display: flex;
  gap: var(--jc-spacer);
  width: 100%;
}

.modal {
  --modal-width: 34.4rem;
}
</style>
