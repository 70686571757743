<script setup>
import {
  ButtonLink,
  HeadingExtraSmall,
  HeadingLarge,
  Link,
  ParagraphBody,
} from '@jumpcloud/ui-components';
import { computed, ref } from 'vue';
import { useAppAvatarUrl } from '@/composables/useAppAvatarUrl';

const props = defineProps({
  appId: {
    type: String,
    required: true,
  },

  category: {
    type: String,
    default: '',
  },

  inReviewModal: Boolean,

  large: Boolean,

  manualAppAppAvatar: Boolean,

  name: {
    type: String,
    required: true,
  },

  onTitleClick: {
    type: [Function, undefined],
    default: undefined,
  },

  onTitleClickDisabled: Boolean,

  showCategory: {
    type: Boolean,
    default: true,
  },

  to: {
    type: [String, undefined],
    default: '',
  },
});

const appId = computed(() => {
  switch (props.appId) {
    case 'google-workspace':
      return 'google';
    case 'azure-ad':
      return 'microsoft';
    default:
      return props.appId;
  }
});

const srcUrl = useAppAvatarUrl(appId);

const imageDoesNotExist = ref(false);
const imageOnError = () => {
  imageDoesNotExist.value = true;
};

function appNameClickHandler() {
  props.onTitleClick?.();
}
</script>

<template>
  <div
    v-if="manualAppAppAvatar"
    class="manualAppAppAvatarWrapper"
  >
    <img
      v-if="!imageDoesNotExist"
      :alt="name"
      class="manualAppAppAvatarImg"
      :src="srcUrl"
      @error="imageOnError"
    >
    <span
      v-else
      class="manualAppAppAvatarImg manualAppAppAvatarNoImg"
    >
      {{ name.charAt(0).toUpperCase() }}
    </span>
    <HeadingExtraSmall
      class="manualAppAppAvatarName"
      :hasMargin="false"
      weight="semibold"
    >
      {{ name }}
    </HeadingExtraSmall>
  </div>
  <div
    v-else
    class="appAvatarContainer"
    :class="{
      gapLarge: onTitleClickDisabled && !inReviewModal,
      gapSmall: !onTitleClickDisabled || inReviewModal
    }"
  >
    <img
      v-if="!imageDoesNotExist"
      :alt="name"
      class="appImgRadius"
      :class="{ appImgLarge: large, appImgDefault: showCategory, appImgNoCategory: !showCategory }"
      :src="srcUrl"
      @error="imageOnError"
    >
    <span
      v-else
      class="noImg"
      :class="{
        appImgLarge: large,
        appImgDefault: showCategory,
        appImgNoCategory: !showCategory,
        hasGreyBg: inReviewModal
      }"
    >
      {{ name.charAt(0).toUpperCase() }}
    </span>
    <div
      class="avatarNameContainer"
      :class="{ avatarNameContainerGap: !onTitleClickDisabled && !inReviewModal }"
    >
      <Link
        v-if="!!to"
        :text="name"
        :to="to"
      />
      <component
        :is="inReviewModal ? ParagraphBody : HeadingLarge"
        v-else-if="onTitleClickDisabled"
        bodySize="large"
        :hasMargin="false"
        weight="semibold"
      >
        {{ name }}
      </component>
      <ButtonLink
        v-else
        :text="name"
        @click="appNameClickHandler"
      />
      <ParagraphBody
        v-if="showCategory"
        :bodySize="onTitleClickDisabled ? 'standard' : 'small'"
        :class="{ disabledCategory: onTitleClickDisabled }"
        :hasMargin="false"
        weight="normal"
      >
        {{ category }}
      </ParagraphBody>
    </div>
  </div>
</template>

<style scoped>
.disabledCategory {
  color: var(--jcGrey);
}

.appAvatarContainer {
  align-items: center;
  display: flex;
}

.gapSmall {
  gap: var(--jc-spacer-small);
}

.gapLarge {
  gap: var(--jc-spacer);
}

.avatarNameContainer {
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.avatarNameContainerGap {
  gap: var(--jc-spacer-x-small);
}

.appImgRadius {
  border-radius: var(--jc-border-radius-large);
}

.appImgDefault {
  height: var(--jc-size-2p5);
  width: var(--jc-size-2p5);
}

.manualAppAppAvatarWrapper {
  align-items: center;
  background-color: var(--jc-background);
  border-radius: var(--jc-border-radius);
  display: flex;
  gap: var(--jc-spacer-small);
  height: var(--jc-input-height);
  padding-left: var(--jc-spacer-x-small);
  padding-right: var(--jc-spacer-small);
}

.manualAppAppAvatarImg {
  border-radius: var(--jc-border-radius);
  height: var(--jc-size-1p5);
  width: var(--jc-size-1p5)
}

.manualAppAppAvatarNoImg {
  align-items: center;
  background-color: var(--jc-color-disabled-fill);
  border: var(--jc-border-width) solid var(--jc-background-light);
  border-radius: var(--jc-border-radius-large) ;
  color: var(--jc-color-neutral-text-subdued);
  display: flex;
  font-size: var(--jcHeadingExtraSmall);
  font-weight: 600;
  justify-content: center;
  line-height: var(--jc-size-1p5);
}

.manualAppAppAvatarName {
  color: var(--jc-text-color);
}

.appImgNoCategory {
  height: 2.5rem;
  width: 2.5rem;
}

.appImgLarge {
  height: var(--jc-size-2p75);
  width: var(--jc-size-2p75);
}

.noImg {
  align-items: center;
  background-color: var(--jc-color-disabled-fill);
  border-radius: var(--jc-border-radius-large) ;
  color: var(--jc-color-neutral-text-subdued);
  display: flex;
  font-size: var(--jcHeadingMedium);
  font-weight: 600;
  justify-content: center;
}

.hasGreyBg {
  border: var(--jc-border-width) solid var(--jc-background-light);
}
</style>
