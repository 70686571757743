<script setup>
import { HeadingSmall, InputText, ParagraphBody } from '@jumpcloud/ui-components';
import DynamicComponentWrapper from '@/components/DynamicComponents/DynamicComponentWrapper.vue';

const props = defineProps({
  connector: {
    type: Object,
    required: true,
  },

  connectorName: {
    type: String,
    required: true,
  },
});

defineEmits(['update:connectorName', 'update:config']);

const title = {
  // keys are taken from /api/v2/saas/connectors-meta as of jan 13
  'google-workspace': 'Google Workspace',
  'azure-ad': 'Microsoft Entra ID',
  slack: 'Slack',
  atlassian: 'Atlassian Cloud',
}[props.connector.type];
</script>

<template>
  <div class="container">
    <HeadingSmall
      class="heading"
      :hasMargin="false"
    >
      Connect Your {{ title }} Account
    </HeadingSmall>
    <div class="-mb-5">
      <InputText
        label="Connector name"
        :modelValue="connectorName"
        name="Connector name"
        placeholder="Enter connector name"
        required
        rules="max:48"
        showValidationMessage
        @update:model-value="$emit('update:connectorName', $event)"
      />
    </div>
    <div
      v-if="connector.config.length"
      id="connector-dynamic-components-container"
      class="container"
    >
      <DynamicComponentWrapper
        v-for="config in connector.config"
        :key="config.id"
        :config="config"
        @update:config="$emit('update:config', $event)"
      />
    </div>
    <div class="paragraphContainer">
      <ParagraphBody>
        Click <b class="heading">Connect</b> to finish the set up. You will be redirected to
        sign in and grant the necessary permissions to connect your account securely.
      </ParagraphBody>
    </div>
  </div>
</template>

<style scoped>
.heading {
  @apply !font-semibold
}

.container {
  @apply flex flex-col gap-4
}

.paragraphContainer {
  @apply flex flex-col gap-2
}
</style>
