<script setup>
import { $testId } from '@/test/testIdHelper';
import {
  Card,
  Constants,
  HeadingExtraSmall,
  HeadingLarge,
  HeadingSmall,
  Link,
  LoadingOverlay,
  ParagraphBody,
} from '@jumpcloud/ui-components';
import { saasUserDetailColumns as columns } from '@/pages/Users/UserDetails/saasUserDetailsConsts';
import { computed, onMounted, ref } from 'vue';
import { missingText } from '@/util/Constants/missingText';
import { snakeCase } from 'lodash/string';
import { useLoadingState } from '@/util/useLoadingState';
import { useRoute } from 'vue-router';
import { useUsersStore } from '@/stores/Users';
import SaasCommonTable from '@/components/SaasListTable/SaasCommonTable.vue';
import SaasContentView from '@/components/SaasContentView.vue';
import SaasUserDetailsTableRow from './SaasUserDetailsTableRow.vue';
import UsageCard from '@/components/UsageCard.vue';

const { sortDirections } = Constants;
const defaultSortBy = 'appId';

const route = useRoute();

const usersStore = useUsersStore();
const { id } = route.params;

const userDetails = computed(() => usersStore.getUserDetailsById(id));
const userAccounts = computed(() => usersStore.getUserAccountsById(id));
const { isLoading, runAsyncWithLoading } = useLoadingState();
const {
  isLoading: isLoadingUsage,
  runAsyncWithLoading: runAsyncWithLoadingUsage,
} = useLoadingState();

const rowsPerPage = ref(50);
const currentPageNumber = ref(0);
const sortBy = ref(defaultSortBy);
const sortDirection = ref(sortDirections.ascending);

const dayCount = ref(30);

const sortValue = computed(() => (sortDirection.value === sortDirections.ascending
  ? snakeCase(sortBy.value)
  : `-${snakeCase(sortBy.value)}`));

const overviewItems = computed(() => [
  {
    title: 'Job Title',
    text: userDetails.value?.jobTitle || missingText,
  },
  {
    title: 'Department',
    text: userDetails.value?.department || missingText,
  },
  {
    title: 'Manager',
    text: userDetails.value?.manager || missingText,
  },
]);

const fetchUserInfo = async () => {
  const fetchUserInfoCallback = async () => {
    await Promise.all([
      usersStore.fetchUserInfo({
        id,
        params: {
          skip: currentPageNumber.value * rowsPerPage.value,
          limit: rowsPerPage.value,
          sort: sortValue.value,
        },
      }),
      usersStore.fetchUserUsageById({ userId: id, dayCount: dayCount.value }),
    ]);
  };

  await runAsyncWithLoading(fetchUserInfoCallback);
};

const updateDayCount = async (newCount) => {
  dayCount.value = newCount;

  const refetchUsage = async () => {
    await usersStore.fetchUserUsageById({ userId: id, dayCount: dayCount.value });
  };

  await runAsyncWithLoadingUsage(refetchUsage);
};

onMounted(() => {
  fetchUserInfo();
});

const usageData = computed(() => usersStore.userUsageDataByUserId(id));

const chartDataIsReady = computed(() => usageData.value?.length > 0);

const handlePageChange = ({
  currentPageNum: newCurrentPageNum,
  rowsPerPage: newRowsPerPage,
}) => {
  currentPageNumber.value = newCurrentPageNum;
  rowsPerPage.value = newRowsPerPage;
  fetchUserInfo();
};

const handleSortColumn = ({
  dataFieldName,
  sortDirection: newSortDirection,
}) => {
  sortBy.value = dataFieldName;
  sortDirection.value = newSortDirection;
  fetchUserInfo();
};
</script>

<template>
  <div class="wrapper">
    <LoadingOverlay
      isLight
      :visible="isLoading"
    />
    <div
      v-if="userDetails"
      class="header"
      :data-test-id="$testId('header')"
    >
      <div class="infoRow">
        <HeadingLarge class="headingName">
          {{ userDetails.name }}
        </HeadingLarge>
        <ParagraphBody class="headingEmail">
          {{ userDetails.email }}
        </ParagraphBody>
      </div>
    </div>
    <div
      v-if="userAccounts && chartDataIsReady"
      class="content"
    >
      <SaasContentView singleContentClass="gap-4">
        <div class="contentGrid">
          <Card>
            <template #header>
              <HeadingSmall>
                User Overview
              </HeadingSmall>
            </template>
            <template #body>
              <div class="overviewItems">
                <div
                  v-for="overviewItem in overviewItems"
                  :key="overviewItem.title"
                >
                  <HeadingExtraSmall class="headingExtraSmall">
                    {{ overviewItem.title }}
                  </HeadingExtraSmall>
                  <ParagraphBody>
                    {{ overviewItem.text }}
                  </ParagraphBody>
                </div>
              </div>
              <Link
                class="jcUserDetailsLink"
                text="Go to JumpCloud User Detail"
                :to="`/users/${userDetails.userObjectId}/details`"
              />
            </template>
          </Card>
          <UsageCard
            :data="usageData"
            :dayCount="dayCount"
            :emptyStateText="`No account usage within the last ${dayCount} days`"
            :isLoading="isLoadingUsage"
            title="Account Usage"
            tooltipSuffix="accessed"
            :userId="id"
            @update-day-count="updateDayCount"
          />
        </div>
        <Card>
          <HeadingSmall>
            SaaS Access Information
          </HeadingSmall>
          <div class="w-full overflow-x-auto overflow-y-hidden">
            <SaasCommonTable
              allowTableOverflow
              :columns="columns"
              :currentPageNumber="currentPageNumber"
              :data="userAccounts?.accounts?.map?.((account) =>
                ({ id: account.account + account.appId, ...account })) || []"
              hideFilters
              :isLoading="isLoading"
              :itemRowComponent="SaasUserDetailsTableRow"
              :rowsPerPage="rowsPerPage"
              searchValue=""
              :selectable="false"
              :showSearchBar="false"
              :showSearchFilterBar="false"
              showPaginationControls
              :sortBy="sortBy"
              :sortDirection="sortDirection"
              :totalCount="userAccounts?.count || 0"
              @handle-page-change="handlePageChange"
              @sort-column="handleSortColumn"
            />
          </div>
        </Card>
      </SaasContentView>
    </div>
  </div>
</template>

<style scoped>
.contentGrid {
  @apply grid grid-cols-1 gap-4 lg:grid-cols-[2fr_3fr];
}

.wrapper {
  background-color: var(--jc-background-light);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.header {
  background: var(--jc-background-light);
  border-bottom: var(--jc-border);
}

.headingName {
  font-weight: 600;
  margin-bottom: var(--jc-spacer-x-small) !important;
}

.headingEmail {
  color: var(--jc-text-color-light);
}

.infoRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--jc-spacer);
}

.content {
  background-color: var(--jc-background);
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
}

.overviewItems {
  display: grid;
  gap: var(--jc-spacer);
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  margin-bottom: var(--jc-spacer-medium);
}

.headingExtraSmall {
  @apply mb-1 !important;
}

.jcUserDetailsLink {
  font-size: var(--jcBody);
}
</style>
