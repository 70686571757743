<script setup>
import { ButtonSecondary, Icon, Link } from '@jumpcloud/ui-components';
import { FINDING_OPTIONS } from './constants';
import { SaasConstants } from '@/util/Constants/SaasConstants';
import { computed, ref } from 'vue';
import { getDiffFromNowInDays } from '@/util/getDiffFromNowInDays';
import { missingText } from '@/util/Constants/missingText';
import AssignUserModal from '@/components/AssignUserModal.vue';
import DiscoverMethod from '@/components/DiscoverMethod.vue';
import LoginMethod from '@/pages/Applications/ApplicationDetails/Accounts/LoginMethod.vue';
import SaasAppIcon from '@/components/SaasAppIcon.vue';

const props = defineProps({
  column: {
    type: Object,
    required: true,
  },

  record: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['assign']);

const appLink = computed(() => {
  if (!props.record.appId) {
    return '';
  }
  return {
    name: SaasConstants.tabs.applications.routes.application.routes.overview.name,
    params: {
      id: props.record.appId,
    },
  };
});

const userLink = computed(() => {
  if (!props.record.user?.id) {
    return '';
  }
  return {
    name: SaasConstants.tabs.users.routes.user.name,
    params: {
      id: props.record.user.id,
    },
  };
});

const accountLink = computed(() => ({
  name: SaasConstants.tabs.accounts.routes.account.name,
  params: {
    accountId: props.record.objectId,
    appId: props.record.appId,
  },
}),
);

const showAssignUserModal = ref(false);

const openAssignUserModal = () => {
  showAssignUserModal.value = true;
};

const onAssign = () => {
  emit('assign');
  showAssignUserModal.value = false;
};

const findingsText = computed(() => props.record.findings.map(
  (finding) => FINDING_OPTIONS.find((opt) => finding === opt.value)?.name).join(' | '),
);
</script>

<template>
  <div
    v-if="column.dataFieldName === 'account'"
    class="flex gap-2"
  >
    <Link
      class="max-w-[285px] truncate"
      :text="record.account || 'Unknown'"
      :to="accountLink"
    />
    <Icon
      v-if="!record.account"
      icon="info"
      inline
    />
  </div>
  <template
    v-else-if="column.dataFieldName === 'username'"
  >
    <template
      v-if="record?.assignable && !record?.user?.id"
    >
      <ButtonSecondary
        class="assignUserButton mb-0 h-8"
        text="Assign a User"
        textCase="inherit"
        @click="openAssignUserModal"
      />
      <AssignUserModal
        v-if="showAssignUserModal"
        :accountId="record.objectId"
        :appId="record.appId"
        :isModalOpen="showAssignUserModal"
        :user="record.user"
        @assign="onAssign"
        @close="showAssignUserModal = false"
      />
    </template>
    <div
      v-else
    >
      <Link
        class="max-w-[230px] truncate"
        :text="record.user?.name || missingText"
        :to="userLink"
      />
      <div
        class="max-w-[230px] truncate text-xs text-neutral-descriptive"
      >
        {{ record.user?.email }}
      </div>
    </div>
  </template>
  <div
    v-else-if="column.dataFieldName === 'appId'"
    class="flex items-center gap-2"
  >
    <SaasAppIcon
      :appId="record.appId"
      :name="record.appName"
      size="24px"
    />
    <Link
      :text="record.appName"
      :to="appLink"
    />
  </div>
  <div
    v-else-if="column.dataFieldName === 'findings'"
    class="max-w-[250px] truncate"
  >
    <span v-if="record?.findings?.length">{{ findingsText }}</span>
    <span v-else>{{ missingText }}</span>
  </div>
  <div v-else-if="column.dataFieldName === 'loginMethods'">
    <div
      v-if="record.loginMethods.length > 0"
      class="flex items-center gap-1"
    >
      <LoginMethod
        v-for="loginMethod in record.loginMethods"
        :key="loginMethod"
        :loginMethod="loginMethod"
      />
    </div>
    <span v-else>
      {{ missingText }}
    </span>
  </div>
  <template v-else-if="column.dataFieldName === 'discoverMethods'">
    <div
      v-if="record.discoverMethods.length > 0"
      class="flex items-center gap-1"
    >
      <DiscoverMethod
        v-for="method in record.discoverMethods"
        :key="method.discoveryType"
        :method="method"
      />
    </div>
    <template v-else>
      {{ missingText }}
    </template>
  </template>
  <span
    v-else-if="column.dataFieldName === 'lastUsedAt'"
  >
    {{ getDiffFromNowInDays(record.lastUsed) }}
  </span>
  <span
    v-else-if="column.dataFieldName === 'discoveredAt'"
  >
    {{ getDiffFromNowInDays(record.discovered) }}
  </span>
  <template v-else>
    {{ record[column.dataFieldName] }}
  </template>
</template>

<style scoped>
.assignUserButton :deep() span[class^=Button__buttonContent] {
  @apply -mt-[2px];
}
</style>
