import { type AxiosInstance, createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';
import type { ApiFilterParams } from './shared/FilterParams';
import type { UserAccountItem, UserItem } from './shared/types';

export type Users = {
  count: number;
  users: UserItem[];
};

export type UserUsageType = { accountCount: number; atDay: string; };
export type UserUsageAtDayType = {
  account: string,
  appId: string,
  appName: string,
  category: {
    id: string,
    name: string
  }
};

class UsersApi {
  private baseUrl: string;
  private axiosInstance: AxiosInstance;

  constructor() {
    this.baseUrl = '/api/v2/saas/users';
    this.axiosInstance = createJcApiAxiosInstance();
  }

  async fetch(params?: Partial<ApiFilterParams> & { search?: string }): Promise<Users> {
    const searchFilter = params?.search ? `user_display_name:search:${params.search}` : false;

    const filter = [searchFilter].filter(Boolean);

    const limit = params?.limit || 50;
    const skip = (params?.skip || 0);
    const sort = params?.sort ? [params.sort] : [];

    const response = await this.axiosInstance.get(this.baseUrl, {
      params: {
        filter,
        limit,
        skip,
        sort,
      },
    });
    return response?.data;
  }

  async getUser(userId: string): Promise<UserItem> {
    const response = await this.axiosInstance.get<{ user: UserItem }>(`${this.baseUrl}/${userId}`);
    return response?.data.user;
  }

  async getUserAccounts(
    userId: string,
    params?: Partial<ApiFilterParams>,
  ): Promise<UserAccountItem[]> {
    const limit = params?.limit || 50;
    const skip = (params?.skip || 0);
    const sort = params?.sort ? [params.sort] : [];

    const response = await this.axiosInstance.get(`${this.baseUrl}/${userId}/accounts`, {
      params: {
        limit,
        skip,
        sort,
      },
    });
    return response?.data;
  }

  async getUserUsage(userId: string, dayCount: number): Promise<UserUsageType[]> {
    const response = await this.axiosInstance.post<{ dayCount: number; }, { data: { data: UserUsageType[] } }>(`${this.baseUrl}/${userId}/usage`, {
      dayCount,
    });

    if (!response) return [];

    const sortedUsageData = (response.data.data)
      .sort((d1, d2) => (new Date(d1.atDay) > new Date(d2.atDay) ? 1 : -1));

    return sortedUsageData;
  }

  async fetchUserUsageAtDay(userId: string, atDay: string): Promise<UserUsageAtDayType[]> {
    const response = await this.axiosInstance.post<
    { atDay: string },
    { data: { data: UserUsageAtDayType[] } }
    >(`${this.baseUrl}/${userId}/usage-at-day`, { atDay });

    return response?.data.data || [];
  }
}

export default new UsersApi();
