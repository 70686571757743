<script setup>
import {
  ButtonLink,
  ButtonSecondary,
  Card,
  HeadingExtraSmall,
  HeadingMedium,
  HeadingSmall,
  Icon,
  Link,
  LoadingOverlay,
  ParagraphBody,
} from '@jumpcloud/ui-components';
import {
  computed, onMounted, ref,
} from 'vue';
import { missingText } from '@/util/Constants/missingText';
import { saasApplicationAccountColumns } from '@/pages/Applications/ApplicationDetails/Accounts/ApplicationAccountDetail/saasApplicationAccountConsts';
import { useAccountsStore } from '@/stores/Accounts';
import { useLoadingState } from '@/util/useLoadingState';
import { useRoute } from 'vue-router';
import AssignUserModal from '@/components/AssignUserModal.vue';
import DiscoverMethod from '@/components/DiscoverMethod.vue';
import SaasAccountDeleteModal from '@/components/SaasAccountDeleteModal.vue';
import SaasApplicationAccountTableRow from '@/pages/Applications/ApplicationDetails/Accounts/ApplicationAccountDetail/SaasApplicationAccountTableRow.vue';
import SaasCommonTable from '@/components/SaasListTable/SaasCommonTable.vue';
import SaasContentView from '@/components/SaasContentView.vue';
import moment from 'moment';

const route = useRoute();

const assignUserModal = ref(false);
const deleteAccountModal = ref(false);
const { accountId, appId } = route.params;
const sortBy = ref('discoveredAt');
const sortDirection = ref('ascending');

const accountsStore = useAccountsStore();

const accountOverview = computed(() => accountsStore.getAccountDetailsById(accountId));
const discoverData = computed(() => accountsStore.getAccountDiscoveriesById(accountId));

const {
  isLoading: isLoadingOverview,
  runAsyncWithLoading: runAsyncWithLoadingOverview,
} = useLoadingState();

const {
  isLoading: isLoadingDiscover,
  runAsyncWithLoading: runAsyncWithLoadingDiscover,
} = useLoadingState();

const fetchAccountOverview = async () => {
  const fetchAccountOverviewCallback = async () => {
    await accountsStore.fetchAccountDetailsById({ accountId });
  };

  await runAsyncWithLoadingOverview(fetchAccountOverviewCallback);
};

const fetchDiscoverData = async () => {
  const fetchDiscoverDataCallback = async () => {
    await accountsStore.fetchAccountDiscoveriesById({
      accountId,
      params: { sort: (sortDirection.value === 'ascending' ? '' : '-') + sortBy.value },
    });
  };

  await runAsyncWithLoadingDiscover(fetchDiscoverDataCallback);
};

onMounted(async () => {
  Promise.all([
    fetchDiscoverData(),
    fetchAccountOverview(),
  ]);
});

const openAssignUserModal = () => {
  assignUserModal.value = true;
};

const handleSortColumn = ({ dataFieldName, sortDirection: newSortDirection }) => {
  sortBy.value = dataFieldName;
  sortDirection.value = newSortDirection;
  fetchDiscoverData();
};

const discoveryDateText = computed(() => (accountOverview.value.firstDiscoveredAt
  ? moment(accountOverview.value.firstDiscoveredAt).format('MMM DD, YYYY [@] hh:mma')
  : missingText));

const lastUpdatedDateText = computed(() => (accountOverview.value.lastLogin
  ? moment(accountOverview.value.lastLogin).format('MMM DD, YYYY [@] hh:mma')
  : missingText));

</script>

<template>
  <div class="wrapper">
    <LoadingOverlay
      isLight
      :visible="isLoadingOverview || isLoadingDiscover"
    />
    <div
      class="header"
      :data-test-id="$testId('header')"
    >
      <div class="flex items-center justify-between space-x-8 p-4">
        <HeadingMedium class="headingName">
          {{ accountOverview?.account }}
        </HeadingMedium>
        <ButtonSecondary
          class="shrink-0"
          :hasOutline="false"
          text="Delete Account"
          type="error"
          @click="deleteAccountModal = true"
        />
      </div>
    </div>
    <SaasContentView>
      <div class="flex h-full flex-1 flex-col gap-6 bg-app">
        <div class="flex w-full flex-wrap gap-6">
          <Card
            v-if="accountOverview"
            class="flex w-full flex-col flex-wrap sm:flex-1"
          >
            <HeadingSmall>Account Overview</HeadingSmall>
            <div class="flex w-full flex-wrap gap-6">
              <div class="w-full">
                <HeadingExtraSmall
                  class="pb-1"
                  :hasMargin="false"
                >
                  Owner
                </HeadingExtraSmall>
                <div
                  v-if="!accountOverview.user.id && accountOverview.assignable"
                  class="flex flex-wrap items-center gap-4"
                >
                  <ButtonSecondary
                    ButtonSecondary
                    text="Assign a User"
                    textCase="inherit"
                    @click="openAssignUserModal"
                  />
                  <div class="hidden items-center gap-1 md:flex">
                    <Icon
                      class="text-error-500"
                      icon="error"
                      :scale="1"
                    />
                    <ParagraphBody>
                      There is no owner assigned to this account.
                    </ParagraphBody>
                  </div>
                </div>
                <span v-if="!accountOverview.user.id && !accountOverview.assignable">
                  {{ missingText }}
                </span>
                <div
                  v-if="accountOverview.user.id"
                  class="mt-1 flex min-h-5 flex-wrap items-center gap-1"
                >
                  <Link
                    class="text-sm"
                    :text="accountOverview.user.name"
                    :to="`/saas-management/users/${accountOverview.user.id}`"
                  />
                  <div class="shrink-1 flex min-w-0 items-center gap-1">
                    <ParagraphBody
                      class="truncate"
                      :hasMargin="false"
                    >
                      {{ accountOverview.user.email }}
                    </ParagraphBody>
                    <div
                      v-if="accountOverview.assignable"
                      class="flex items-center gap-2 pl-1"
                    >
                      <div class="mt-0.5 h-3 border-l border-neutral" />
                      <ButtonLink
                        class="mt-0.5"
                        text="Edit"
                        @click="openAssignUserModal"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-[250px]">
                <HeadingExtraSmall
                  class="pb-1"
                  :hasMargin="false"
                >
                  Discovery Methods
                </HeadingExtraSmall>
                <div class="flex items-center gap-1">
                  <DiscoverMethod
                    v-for="method in accountOverview.discoverMethods"
                    :key="method.discoveryType"
                    :method="method"
                  />
                </div>
              </div>
              <div class="w-[250px]">
                <HeadingExtraSmall
                  class="pb-1"
                  :hasMargin="false"
                >
                  Discovery Date
                </HeadingExtraSmall>
                <ParagraphBody>
                  {{ discoveryDateText }}
                </ParagraphBody>
              </div>
              <div class="w-[250px]">
                <HeadingExtraSmall
                  class="pb-1"
                  :hasMargin="false"
                >
                  Last Updated Date
                </HeadingExtraSmall>
                <ParagraphBody>
                  {{ lastUpdatedDateText }}
                </ParagraphBody>
              </div>
            </div>
          </Card>
        </div>
        <Card>
          <HeadingSmall :hasMargin="false">
            Discovery History
          </HeadingSmall>
          <div class="w-full overflow-x-auto overflow-y-hidden">
            <SaasCommonTable
              allowTableOverflow
              :columns="saasApplicationAccountColumns"
              :data="discoverData || []"
              :isLoading="false"
              :itemRowComponent="SaasApplicationAccountTableRow"
              searchValue=""
              :selectable="false"
              :showSearchBar="false"
              :showSearchFilterBar="false"
              :sortBy="sortBy"
              :sortDirection="sortDirection"
              @sort-column="handleSortColumn"
            />
          </div>
        </Card>
      </div>
    </SaasContentView>
    <AssignUserModal
      v-if="assignUserModal"
      :accountId="accountId"
      :appId="appId"
      :isModalOpen="assignUserModal"
      :user="accountOverview.user"
      @assign="fetchAccountOverview"
      @close="assignUserModal = false"
    />
    <SaasAccountDeleteModal
      :accountId="accountId"
      :appId="appId"
      :showModal="deleteAccountModal"
      @close="deleteAccountModal = false"
    />
  </div>
</template>

<style scoped>
.wrapper {
  background-color: var(--jc-background-light);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.header {
  background: var(--jc-background-light);
  border-bottom: var(--jc-border);
}

.headingName {
  font-weight: 600;
  margin-bottom: var(--jc-spacer-x-small) !important;
  @apply truncate;
}
</style>
