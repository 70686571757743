import { SaasConstants } from '@/util/Constants/SaasConstants';
import { computed } from 'vue';
import { featureTrialModuleName as featureTrialModule } from '@jumpcloud-ap/feature-trials-vue3';
import { planModuleName } from '@jumpcloud-ap/plans';
import { useCoreUserInfo } from '@jumpcloud-ap/core-user-info';
import { useStore } from 'vuex';

export function useSaasFeatureTrial() {
  const featureCode = SaasConstants.type;

  const store = useStore();

  const hasFeature = computed(() => store.getters[`${planModuleName}/planHasFeature`](featureCode));

  const {
    isCustomer,
    isAnMspOrChild,
  } = useCoreUserInfo();

  const customerIsCandidate = computed(() => isCustomer.value && !isAnMspOrChild.value);

  const getFeatureUsage = computed(() => store.getters[`${featureTrialModule}/getFeatureUsage`](featureCode));
  const trialStartDate = computed(() => getFeatureUsage.value?.startDate || '');
  const trialEndDate = computed(() => getFeatureUsage.value?.endDate || '');
  const hasActiveTrial = computed(() => !!trialStartDate.value && !trialEndDate.value);

  const shouldCheckTrial = computed(() => (hasActiveTrial.value
     || (!hasFeature.value && !hasActiveTrial.value)));

  const refreshTrialData = () => {
    if (customerIsCandidate.value && shouldCheckTrial.value) {
      store.dispatch(`${featureTrialModule}/fetchFeatureUsage`, { featureCode });
    }
  };

  return {
    refreshTrialData,
  };
}
