import { type UnwrapRef, computed, ref } from 'vue';
import { defineStore } from 'pinia';
import OverviewApi from '@/api/OverviewApi';
import type { Overview } from '@/api/OverviewApi';

export const useOverviewStore = defineStore('saas-overview', () => {
  const data = ref<UnwrapRef<Overview>>({} as Overview);

  const fetchOverview = async () => {
    data.value = await OverviewApi.fetch();
  };

  const overview = computed(() => data.value);
  const isEnabled = computed(() => data.value.isEnabled);
  const stats = computed(() => data.value.stats);

  return {
    data,
    overview,
    isEnabled,
    stats,
    fetchOverview,
  };
});
