<script setup>
import {
  ButtonDismissibleChip,
  HeadingSmall,
  Icon,
  IconButton,
  InputLabel,
  InputText,
  Textarea,
  Tooltip,
} from '@jumpcloud/ui-components';
import { ref, watch } from 'vue';

const props = defineProps({
  description: {
    type: String,
    default: '',
  },

  domains: {
    type: Array,
    default: () => [],
  },

  initialName: {
    type: String,
    default: '',
  },

  name: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['updateAppInfo']);

const appName = ref(props?.name || props?.initialName);
const appDescription = ref(props?.description || '');

const appDomains = ref(
  props?.domains?.map(value => ({ value, label: value, disabled: false })) || []);

const domainText = ref('');

const handleSelectionsRemove = (selection) => {
  const selectedDomains = appDomains.value.filter(
    (option) => option.value !== selection.value,
  );
  appDomains.value = selectedDomains;
};
const handleAddDomain = () => {
  if (domainText.value) {
    const newDomains = [...appDomains.value];

    const existingDomain = appDomains.value.find(
      (domain) => domain.value === domainText.value,
    );

    if (existingDomain) {
      domainText.value = '';
      return;
    }

    const newDomain = {
      value: domainText.value,
      label: domainText.value,
      disabled: false,
    };

    newDomains.push(newDomain);
    appDomains.value = newDomains;
    domainText.value = '';
  }
};

watch([appName, appDescription, appDomains],
  ([newAppName, newAppDescription, newAppDomains]) => {
    emit('updateAppInfo', {
      name: newAppName,
      description: newAppDescription,
      domains: newAppDomains,
    });
  });
</script>

<template>
  <div class="container">
    <HeadingSmall weight="semibold">
      App Information
    </HeadingSmall>
    <div class="section">
      <InputText
        class="inputWidth"
        label="App Name"
        :modelValue="appName"
        name="App Name"
        placeholder="Enter app name"
        required
        rules="max:48"
        showValidationMessage
        @update:model-value="appName = $event"
      />
    </div>
    <div class="section marginBottom">
      <div class="domainsHeader">
        <InputLabel
          class="domainsLabel"
          :hasMargin="false"
          text="Domains"
        />
        <div class="domainTooltipWrapper">
          <Tooltip
            icon="info"
            orientation="top"
            text="Domain URLs are used to identify and track custom app use"
          >
            <Icon
              icon="info"
              :scale="1"
            />
          </Tooltip>
        </div>
      </div>
      <div class="inputTextButtonContainer">
        <InputText
          class="inputWidth"
          :modelValue="domainText"
          placeholder="Enter app domain"
          :showValidationMessage="false"
          @update:model-value="domainText = $event"
        />

        <Tooltip
          class="tooltipContainer"
          icon="add"
          :noHover="appDomains.length <= 4"
          orientation="top"
          text="You can add up to 5 domains"
        >
          <IconButton
            aria-label="Add domain"
            :class="appDomains.length > 4 ? 'upToFiveDomainsTooltipIcon' : ''"
            :disabled="appDomains.length > 4"
            hasNoBackground
            icon="add"
            type="secondary"
            @click="handleAddDomain"
          />
        </Tooltip>
      </div>
      <div
        v-if="appDomains.length > 0"
        class="dismissibleChipsContainer"
      >
        <ButtonDismissibleChip
          v-for="domain in appDomains"
          :key="domain.value"
          :text="domain.value"
          textCase="lowercase"
          @click="handleSelectionsRemove(domain)"
        />
      </div>
    </div>
    <div class="section">
      <Textarea
        v-model="appDescription"
        label="App Description"
        name="App Description"
        placeholder="Enter app description (optional)"
        :required="false"
        rules="max:620"
        showValidationMessage
      />
    </div>
  </div>
</template>

<style scoped>
.inputWidth {
  flex-grow: 1;
  max-width: var(--jc-size-25);
}

.container {
  display: flex;
  flex-direction: column;
}

.section {
  display: flex;
  flex-direction: column;
}

.marginBottom {
  margin-bottom: var(--jc-size-1p25);
}

.inputTextButtonContainer {
  align-items: start;
  display: flex;
  flex-wrap: wrap;
  gap: var(--jc-spacer-small);
  width: 100%;
}

.dismissibleChipsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--jc-spacer-x-small);
  margin-top: var(--jc-spacer-small);
}

.upToFiveDomainsTooltipIcon {
  color: var(--jcGrey400);
}

.upToFiveDomainsTooltipIcon:hover {
  color: var(--jcGrey400);
}

.domainsHeader {
  align-items: center;
  display: flex;
  gap: var(--jc-spacer-x-small);
  margin-bottom: var(--jc-spacer-small);
}

.domainsLabel {
  color: var(--jcGrey800);
  margin-bottom: 0;
}

.domainTooltipWrapper {
  align-items: center;
  color: var(--jcGrey800);
  display: flex;
  height: var(--jc-spacer);
  justify-content: center;
  width: var(--jc-spacer);
}

.domainTooltip {
  margin-top: var(--jc-size-negative-0p75);
}

span[class*='Tooltip__tooltipContainer']{
cursor: default;
display: flex
}

.tooltipContainer {
  @apply mt-px flex items-center justify-center
}

</style>
