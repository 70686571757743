<script setup>
import { Constants } from '@jumpcloud/ui-components';
import { computed, onMounted, ref } from 'vue';
import { saasApplicationAccountsColumns } from '@/pages/Applications/ApplicationDetails/Accounts/saasApplicationDetailsAccountsConsts';
import { snakeCase } from 'lodash';
import { useApplicationsStore } from '@/stores/Applications';
import { useConnectorsStore } from '@/stores/Connectors';
import { useLoadingState } from '@/util/useLoadingState';
import SaasApplicationDetailsAccountsTableRow from '@/pages/Applications/ApplicationDetails/Accounts/SaasApplicationDetailsAccountsTableRow.vue';
import SaasCommonTable from '@/components/SaasListTable/SaasCommonTable.vue';

const { sortDirections } = Constants;
const defaultSortBy = 'username';

const props = defineProps({
  appName: {
    type: String,
    required: true,
  },

  id: {
    type: String,
    required: true,
  },
});

const applicationsStore = useApplicationsStore();
const connectorsStore = useConnectorsStore();

const {
  isLoading: isLoadingAccounts,
  runAsyncWithLoading: runAsyncWithLoadingAccounts,
} = useLoadingState();

const {
  isLoading: isLoadingAvailableConnectors,
  runAsyncWithLoading: runAsyncWithLoadingAvailableConnectors,
} = useLoadingState(true);

const isLoading = computed(
  () => isLoadingAccounts.value || isLoadingAvailableConnectors.value);

const appAccountsData = computed(() => applicationsStore.getApplicationAccountsById(props.id));

const rowsPerPage = ref(50);
const currentPageNumber = ref(0);
const sortBy = ref(defaultSortBy);
const sortDirection = ref(sortDirections.ascending);

const sortValue = computed(() => (sortDirection.value === sortDirections.ascending
  ? snakeCase(sortBy.value)
  : `-${snakeCase(sortBy.value)}`));

const fetchApplicationAccounts = async () => {
  const fetchApplicationAccountsCallback = async () => {
    await applicationsStore.fetchApplicationAccounts(
      {
        id: props.id,
        params: {
          skip: currentPageNumber.value * rowsPerPage.value,
          limit: rowsPerPage.value,
          sort: sortValue.value,
        },
      },
    );
  };

  await runAsyncWithLoadingAccounts(fetchApplicationAccountsCallback);
};

const fetchAvailableConnectors = async () => {
  const fetchAvailableConnectorsCallback = async () => {
    connectorsStore.fetchConnectorsMeta();
  };

  await runAsyncWithLoadingAvailableConnectors(fetchAvailableConnectorsCallback);
};

onMounted(() => {
  Promise.all([
    fetchApplicationAccounts(),
    fetchAvailableConnectors(),
  ]);
});

const handlePageChange = ({
  currentPageNum: newCurrentPageNum,
  rowsPerPage: newRowsPerPage,
}) => {
  currentPageNumber.value = newCurrentPageNum;
  rowsPerPage.value = newRowsPerPage;
  fetchApplicationAccounts();
};

const handleSortColumn = ({
  dataFieldName,
  sortDirection: newSortDirection,
}) => {
  sortBy.value = dataFieldName;
  sortDirection.value = newSortDirection;
  fetchApplicationAccounts();
};
</script>

<template>
  <div class="saasUsersContainer">
    <SaasCommonTable
      class="-mt-0.5 px-4"
      :columns="saasApplicationAccountsColumns"
      :currentPageNumber="currentPageNumber"
      :data="appAccountsData?.accounts?.map
        ?.((item) => ({ id: item.objectId, appId: id, ...item })) || []"
      hideFilters
      :isLoading="isLoading"
      :itemRowComponent="SaasApplicationDetailsAccountsTableRow"
      :rowsPerPage="rowsPerPage"
      searchValue=""
      :selectable="false"
      showPaginationControls
      :sortBy="sortBy"
      :sortDirection="sortDirection"
      :totalCount="appAccountsData?.count || 0"
      @assign="fetchApplicationAccounts"
      @handle-page-change="handlePageChange"
      @sort-column="handleSortColumn"
    />
  </div>
</template>

<style scoped>
.saasUsersContainer {
  flex: 1;
  gap: var(--jc-spacer-small);
  height: 100%;
  overflow: auto;
}
</style>
