import { type MaybeRefOrGetter, computed, toValue } from 'vue';
import getEnvironment from '@/util/getEnvironment';

export function useAppAvatarUrl(appId: MaybeRefOrGetter<string>) {
  const appIdValue = toValue(appId);
  const environment = getEnvironment();
  const isProd = environment === 'production';
  const prodSrcUrl = computed(() => `https://cdn03.jumpcloud.com/saas-icons/${appIdValue}.png`);
  const otherSrcUrl = computed(() => `https://assets.stg01.jumpcloud.com/saas-icons/${appIdValue}.png`);

  return isProd ? prodSrcUrl : otherSrcUrl;
}
