export function getBarChartHeight(dayCount: number) {
  switch (dayCount) {
    case 30:
      return '740px';
    case 15:
      return '410px';
    default:
      return '230px';
  }
}
