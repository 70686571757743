<script setup>
import { $testId } from '@/test/testIdHelper';
import {
  ButtonLink,
  Card,
  HeadingExtraSmall,
  HeadingSmall,
  IconButton,
  ParagraphBody,
} from '@jumpcloud/ui-components';
import { computed } from 'vue';
import moment from 'moment';

import { missingText } from '@/util/Constants/missingText';
import { useApplicationsStore } from '@/stores/Applications';

const props = defineProps({
  appId: {
    type: String,
    required: true,
  },
});

defineEmits(['openLicenseModal']);

const applicationsStore = useApplicationsStore();
const data = computed(() => applicationsStore.getApplicationById(props.appId));
const licenseInformation = computed(() => data.value?.license);

const formattedPrice = computed(() => {
  const licensePrice = licenseInformation.value?.price;

  return licenseInformation.value && !Number.isNaN(licensePrice?.amount)
    ? `$${licensePrice.amount.toFixed(2)} / ${licensePrice.term?.toLowerCase()}`
    : missingText;
});

const licenseRenewalDate = computed(() => {
  const renewalDate = licenseInformation.value?.renewalDate;

  return renewalDate ? moment(licenseInformation.value.renewalDate).format('MM/DD/YYYY') : missingText;
});
</script>

<template>
  <Card>
    <template #header>
      <div class="cardHeaderContainer">
        <HeadingSmall weight="semibold">
          License Information
        </HeadingSmall>
        <IconButton
          aria-label="Edit License"
          hasNoBackground
          hasOutline
          icon="edit"
          iconPosition="left"
          type="secondary"
          @click="$emit('openLicenseModal')"
        />
      </div>
    </template>
    <template #body>
      <div v-if="!licenseInformation">
        <HeadingExtraSmall
          class="notTrackedBodyHeader"
          :data-test-id="$testId('licenseNotTracked')"
          :hasMargin="false"
          weight="semibold"
        >
          License is not tracked for this app.
          <ButtonLink
            :isMatchText="false"
            text="Track License"
            @click="$emit('openLicenseModal')"
          />
        </HeadingExtraSmall>
      </div>
      <div v-else>
        <div v-if="licenseInformation.price.term === 'FREE'">
          <HeadingExtraSmall
            class="bodyHeader"
            :hasMargin="false"
            weight="semibold"
          >
            Free Subscription
          </HeadingExtraSmall>
        </div>
        <div class="gridItemsTopRow">
          <div class="gridItemWrapper">
            <HeadingExtraSmall
              class="subHeader"
              :hasMargin="false"
            >
              Price
            </HeadingExtraSmall>
            <ParagraphBody :data-test-id="$testId('licensePrice')">
              {{ formattedPrice }}
            </ParagraphBody>
          </div>
          <div class="gridItemWrapper">
            <HeadingExtraSmall
              class="subHeader"
              :hasMargin="false"
            >
              Renewal Date
            </HeadingExtraSmall>
            <ParagraphBody
              :class="
                !licenseInformation.renewalDate
                  ? 'disableColor'
                  : undefined
              "
              :data-test-id="$testId('licenseRenewalDate')"
            >
              {{ licenseRenewalDate }}
            </ParagraphBody>
          </div>
          <div class="gridItemWrapper">
            <HeadingExtraSmall
              class="subHeader"
              :hasMargin="false"
            >
              Total Licenses
            </HeadingExtraSmall>
            <ParagraphBody
              :class="
                !licenseInformation.totalLicenses
                  ? 'disableColor'
                  : undefined
              "
            >
              {{ licenseInformation.totalLicenses || missingText }}
            </ParagraphBody>
          </div>
        </div>
        <HeadingExtraSmall
          class="subHeader"
          :hasMargin="false"
        >
          Notes
        </HeadingExtraSmall>
        <div class="notesContentContainer">
          <ParagraphBody
            :class="!licenseInformation.notes ? 'disableColor' : undefined"
          >
            {{ licenseInformation.notes || missingText }}
          </ParagraphBody>
        </div>
      </div>
    </template>
  </Card>
</template>

<style scoped>
.cardHeaderContainer {
  display: flex;
  justify-content: space-between;
}

.gridItemsTopRow {
  display: grid;
  gap: var(--jc-spacer);
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  margin: var(--jc-spacer) 0;
}

.gridItemWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--jc-spacer-x-small);
}

.bodyHeader {
  margin-bottom: var(--jc-spacer);
  margin-top: var(--jc-spacer-negative-medium);
}

.notTrackedBodyHeader {
  color: var(--jc-input-color-disabled);
  margin-top: var(--jc-spacer-negative-medium);
}

.subHeader {
  margin-bottom: var(--jc-spacer-x-small);
}

.disableColor {
  color: var(--jc-input-color-disabled);
}

.notesContentContainer {
  max-height: 15rem;
  overflow: auto;
}
</style>
