import { Types } from '@jumpcloud/ui-components';

const { Column } = Types;
export const saasApplicationAccountsColumns = [
  Column({
    label: 'Account', dataFieldName: 'account', width: 'minmax(300px, 2fr)', sortable: true,
  }),
  Column({
    label: 'User', dataFieldName: 'username', sortable: true, width: 'minmax(200px, 2fr)',
  }),
  Column({
    label: 'Login Methods', dataFieldName: 'loginMethods', width: '1fr',
  }),
  Column({
    label: 'Source', dataFieldName: 'source', width: '1fr',
  }),
  Column({
    label: 'Discovery Date', dataFieldName: 'discoveredAt', width: '135px', sortable: true,
  }),
  Column({
    label: 'Last Used', dataFieldName: 'lastUsedAt', width: '130px', sortable: true,
  }),
];
