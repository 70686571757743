import { createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';

// export type ConnectorsResponse = {
//   connectors: Connector[];
// };

export type Connector = {
  connectorName: string;
  connectorType: string;
  config: {
    [key: string]: string;
  };
};

export type ConnectorMeta = {
  connectors: ConnectorMetaItem[];
};

export type ConnectorMetaItem = {
  id: string;
  name: string;
  config: {
    id: string;
    name: string;
    type: string;
    required: boolean;
    placeholder: string;
  }[];
};

class ConnectorsApi {
  private baseUrl: string;
  private axiosInstance;

  constructor() {
    this.baseUrl = '/api/v2/saas/connectors';
    this.axiosInstance = createJcApiAxiosInstance();
  }

  async fetch(): Promise<Connector[]> {
    const response = await this.axiosInstance.get(this.baseUrl);
    return response?.data?.connectors ?? [];
  }

  async create(connector: Connector): Promise<{ id: string }> {
    const response = await this.axiosInstance.post(this.baseUrl, connector);
    return response?.data;
  }

  async update({
    id,
    name,
    config,
  }: {
    id: string,
    name: string,
    config: Connector['config'],
  }): Promise<void> {
    await this.axiosInstance.put(`${this.baseUrl}/${id}`, { name, config });
  }

  async delete(id: string): Promise<void> {
    await this.axiosInstance.delete(`${this.baseUrl}/${id}`);
  }

  async reconnect(id: string): Promise<Connector> {
    const response = await this.axiosInstance.post(`${this.baseUrl}/${id}/reconnect`);
    return response?.data;
  }

  async getConnector(id: string): Promise<Connector> {
    const response = await this.axiosInstance.get(`${this.baseUrl}/${id}`);
    return response?.data.connector ?? {};
  }

  async getConnectorsMeta(): Promise<ConnectorMetaItem[]> {
    const response = await this.axiosInstance.get<ConnectorMeta>('/api/v2/saas/connectors-meta');
    return response?.data?.connectors.map((connector) => ({
      ...connector,
      config: connector.config.map((config) => ({
        ...config,
        placeholder: `Enter ${connector.name} ${config.name}`,
      })).sort((a, b) => a.id.localeCompare(b.id)),
    })) ?? [];
  }
}

export default new ConnectorsApi();
