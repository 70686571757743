import { setupFeatureFlags } from '@jumpcloud/ui-feature-flags';

export const {
  FeatureFlagMixin,
  FeatureFlagsModule,
  initializeFlags,
  useFeatureFlags,
} = setupFeatureFlags([
  {
    localName: 'deviceAgentToggleButton',
    sourceId: 'VR-2025-03-25-device-agent-toggle-button-in-settings',
  },
]);
