<script setup>
import { ItemRow, ItemWithLabel, Link } from '@jumpcloud/ui-components';

import AppAvatar from '@/components/AppAvatar.vue';
import SsoConnectionStatusBadge from '@/components/SsoConnectionStatusBadge.vue';

const props = defineProps({
  columns: {
    type: Array,
    required: true,
  },

  itemData: {
    required: true,
    type: Object,
  },
});

const connectedAppUrl = `${window.location.origin}#/applications/${props.itemData.id}/details`;
const notConnectedAppUrl = `/saas-management/applications/${props.itemData.appId}`;

</script>

<template>
  <ItemRow
    v-bind="$attrs"
    :selected="itemData.selected"
  >
    <ItemWithLabel
      v-for="(column, index) in columns"
      :key="column.dataFieldName"
      class="specialRowClass"
      :column="column"
      :data-test-id="$testId(`cell-${index}`)"
    >
      <template v-if="column.dataFieldName === 'app'">
        <AppAvatar
          :appId="itemData.appId"
          category=""
          :name="itemData?.appName"
          :showCategory="false"
        />
      </template>
      <template v-else-if="column.dataFieldName === 'status'">
        <SsoConnectionStatusBadge
          bodySize="small"
          :shouldOpenTooltip="false"
          :ssoApps="[{ status: itemData.status }]"
        />
      </template>
      <template v-else-if="column.dataFieldName === 'actions'">
        <Link
          v-if="itemData.status === 'CONNECTED'"
          :href="connectedAppUrl"
          target="_blank"
          text="SSO Settings"
        />
        <Link
          v-else
          text="Go To App Detail"
          :to="notConnectedAppUrl"
        />
      </template>
      <template v-else>
        {{ itemData[column.dataFieldName] }}
      </template>
    </ItemWithLabel>
  </ItemRow>
</template>

<style scoped>
.specialRowClass {
  align-items: center;
  display: flex;
}
</style>
