<script setup>
import {
  ItemRow,
  ItemWithLabel,
  ParagraphBody,
} from '@jumpcloud/ui-components';
import { SaasConstants } from '@/util/Constants/SaasConstants';
import { getDiffFromNowInDays } from '@/util/getDiffFromNowInDays';
import AppAvatar from '@/components/AppAvatar.vue';
import LoginMethod from '@/pages/Applications/ApplicationDetails/Accounts/LoginMethod.vue';

defineProps({
  columns: {
    type: Array,
    default: () => ([]),
  },

  itemData: {
    required: true,
    type: Object,
  },
});

const navigateToOnClick = id => `${SaasConstants.tabs.applications.route}/${id}/overview`;
</script>

<template>
  <ItemRow
    v-bind="$attrs"
  >
    <ItemWithLabel
      v-for="column in columns"
      :key="column.dataFieldName"
      :class="[
        'specialRowClass',
        column.dataFieldName === 'loginMethods' && 'overflowUnset'
      ].filter(Boolean)"
      :column="column"
    >
      <template v-if="column.dataFieldName === 'appId'">
        <AppAvatar
          :appId="itemData.appId"
          :category="itemData.category.name"
          :name="itemData.appName"
          :to="navigateToOnClick(itemData.appId)"
        />
      </template>
      <template v-else-if="column.dataFieldName === 'account'">
        <ParagraphBody>{{ itemData.account }}</ParagraphBody>
      </template>
      <div
        v-else-if="column.dataFieldName === 'loginMethods'"
        class="flexCenteredWithGap"
      >
        <template v-if="itemData.loginMethods.length > 0">
          <LoginMethod
            v-for="loginMethod in itemData.loginMethods"
            :key="loginMethod"
            :loginMethod="loginMethod"
          />
        </template>
        <ParagraphBody v-else>
          --
        </ParagraphBody>
      </div>
      <template v-else-if="column.dataFieldName === 'lastLoginAt'">
        <ParagraphBody>
          {{ getDiffFromNowInDays(itemData.lastLogin) }}
        </ParagraphBody>
      </template>
    </ItemWithLabel>
  </ItemRow>
</template>

<style scoped>
.specialRowClass {
  align-items: center;
  display: flex;
}

.overflowUnset {
  overflow: unset;
}

.flexCenteredWithGap {
  align-items: center;
  display: flex;
  gap: var(--jc-spacer-x-small);
  justify-content: center;
}
</style>
