export enum AccessRestrictionType {
  DISMISSIBLEWARNING = 'DISMISSIBLE_WARNING',
  WARNING = 'WARNING',
  BLOCK = 'BLOCK',
  NOACTION = 'NO_ACTION',
  DEFAULTACTION = 'DEFAULT_ACTION',
}

export enum ApplicationLicensePriceTerm {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
  FREE = 'FREE',
}

export enum SSOConnectionStatus {
  CONNECTED = 'CONNECTED',
  NOTCONNECTED = 'NOT_CONNECTED',
  UNSUPPORTED = 'UNSUPPORTED',
}

export enum ApplicationStatus {
  APPROVED = 'APPROVED',
  UNAPPROVED = 'UNAPPROVED',
  IGNORED = 'IGNORED',
  NEWLYDISCOVERED = 'NEWLY_DISCOVERED',
}

export enum ApplicationUsage {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  NODATA = 'NO_DATA',
}

export enum LoginMethods {
  APPLICATION = 'APPLICATION',
  SSO = 'SSO',
  SSO_GOOGLE = 'SSO_GOOGLE',
  SSO_MICROSOFT = 'SSO_MICROSOFT',
}

export enum DiscoveryType {
  BROWSER_LOGIN = 'BROWSER_LOGIN',
  CUSTOM = 'CUSTOM',
  INTEGRATION = 'INTEGRATION',
  SSO_LOGIN = 'SSO_LOGIN',
}

export type ApplicationCategory = {
  id?: string;
  name?: string;
};

export type ApplicationLicense = {
  notes?: string;
  price?: ApplicationLicensePrice;
  renewalDate?: Date;
  totalLicenses?: number;
};

export type ApplicationLicensePrice = {
  amount?: number;
  term?: ApplicationLicensePriceTerm;
};

export type User = {
  email?: string;
  id?: string;
  name?: string;
};

export interface SSOApp {
  appId?: string;
  appName?: string;
  displayLabel?: string;
  id?: string;
  status?: SSOConnectionStatus;
}

export type ApplicationItem = {
  accessRestriction?: AccessRestrictionType;
  accountCount?: number;
  alternativeButton?: string;
  alternativeLink?: string;
  appId?: string;
  category?: ApplicationCategory;
  customRestrictionMessage?: string;
  discoveredAt?: Date;
  lastUsedAt?: Date;
  license?: ApplicationLicense;
  name?: string;
  owner?: User;
  ssoApps?: Array<SSOApp>;
  ssoTemplateName?: string;
  status?: ApplicationStatus;
  usage?: ApplicationUsage;
  userCount?: number;
  restrictionExcludedGroups?: { userGroupId: string, name: string }[]
};

export type DiscoveryMethod = {
  connectorTypes: string[]
  discoveryType: DiscoveryType
};

export type ApplicationAccountItem = {
  account: string
  assignable: boolean
  discoverMethods: DiscoveryMethod[]
  discovered: string
  lastLogin: string
  lastUsed: string
  loginMethods: string[]
  objectId: string
  status: string
  user: User
};

export type AccountItem = {
  objectId: string
  account: string
  assignable: boolean
  loginMethods: LoginMethods[]
  discoverMethods: DiscoveryMethod[]
  firstDiscoveredAt: string
  lastLogin: string
  user: User
};

export type AccountListItem = {
  account: string;
  appId: string;
  appName: string;
  assignable: boolean;
  category: ApplicationCategory;
  discoverMethods: DiscoveryMethod[];
  discovered: string;
  formerEmployee: boolean;
  lastLogin: string;
  lastUsed: string;
  loginMethods: string[];
  objectId: string;
  user: User;
};

export interface UserItem {
  accountCount?: number;
  appCount?: number;
  email?: string;
  lastUsedAt?: Date | string;
  name?: string;
  usage?: string;
  userObjectId?: string;
}

export type AccountDiscoveryItem = {
  device: {
    id: string;
    name: string;
  }
  discoveredAt: string;
  id: string;
  lastLoginAt: string;
  method: string;
  source: {
    extension: {
      browserId: string;
      browserType: string;
      browserVersion: string;
      extensionVersion: string;
    }
    source: {
      id: string;
      name: string;
    }
    type: string;
  }
  tenantName: string;
};

export type UserAccountItem = {
  appId: string,
  appName: string,
  category: {
    id: string
    name: string
  },
  account: string,
  loginMethods?: LoginMethods[],
  lastLogin?: string,
  lastUsed?: string,
  status: string,
  objectId: string,
};

export interface SystemUserItem {
  account_locked: boolean
  activated: boolean
  addresses: unknown[]
  allow_public_key: boolean
  alternateEmail: unknown
  attributes: unknown[]
  compunknown: string
  costCenter: string
  department: string
  description: string
  disableDeviceMaxLoginAttempts: boolean
  displayname: string
  email: string
  employeeIdentifier: unknown
  employeeType: string
  enable_managed_uid: boolean
  enable_user_portal_multifactor: boolean
  external_dn: string
  external_source_type: string
  externally_managed: boolean
  firstname: string
  jobTitle: string
  lastname: string
  ldap_binding_user: boolean
  location: string
  managedAppleId: string
  manager: unknown
  mfa: {
    exclusion: boolean
    configured: boolean
  }
  middlename: string
  password_never_expires: boolean
  passwordless_sudo: boolean
  phoneNumbers: unknown[]
  restrictedFields: unknown[]
  samba_service_user: boolean
  ssh_keys: unknown[]
  state: string
  sudo: boolean
  suspended: boolean
  systemUsername: string
  unix_guid: number
  unix_uid: number
  username: string
  created: string
  organization: string
  password_date: string
  password_expired: boolean
  totp_enabled: boolean
  _id: string
  id: string
  mfaEnrollment: {
    totpStatus: string
    webAuthnStatus: string
    pushStatus: string
    overallStatus: string
  }
}
