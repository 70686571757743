import { Constants } from '@jumpcloud/ui-components';
import { computed, ref } from 'vue';
import type { ComputedRef } from 'vue';

const { sortDirections } = Constants;

type UseTableSortAndFilterProps = {
  list: ComputedRef<Record<string, string>[]>;
  defaultSortBy?: string;
};

export function useTableSortAndFilter(
  list: UseTableSortAndFilterProps['list'],
  defaultSortBy:UseTableSortAndFilterProps['defaultSortBy'] = 'name',
) {
  const searchValue = ref('');
  const sortBy = ref(defaultSortBy);
  const sortDirection = ref<'ascending' | 'descending'>(sortDirections.ascending);

  const handleSortColumn = ({
    dataFieldName,
    sortDirection: newSortDirection,
  }: {
    dataFieldName: string;
    sortDirection: 'ascending' | 'descending';
  }) => {
    sortBy.value = dataFieldName;
    sortDirection.value = newSortDirection;
  };

  const filteredAndSortedItems = computed(() => {
    const searchValueLowerCase = searchValue.value.toLowerCase();
    return list?.value?.filter((item) => item.name.toLowerCase()
      .includes(searchValueLowerCase))
      .sort((a: Record<string, string>, b: Record<string, string>) => {
        if (a[sortBy.value].toLowerCase() < b[sortBy.value].toLowerCase()) {
          return sortDirection.value === sortDirections.ascending ? -1 : 1;
        }
        if (a[sortBy.value].toLowerCase() > b[sortBy.value].toLowerCase()) {
          return sortDirection.value === sortDirections.ascending ? 1 : -1;
        }
        return 0;
      });
  });

  return {
    searchValue,
    sortBy,
    sortDirection,
    handleSortColumn,
    filteredAndSortedItems,
  };
}
