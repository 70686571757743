<script setup lang="ts">
import {
  Button,
  HeadingLarge,
  Link,
  ParagraphBody,
} from '@jumpcloud/ui-components';
import { SaasConstants } from '@/util/Constants/SaasConstants';
import { useLoadingState } from '@/util/useLoadingState';
import { useOverviewStore } from '@/stores/Overview';
import { useSaasFeatureTrial } from '@/util/useSaasFeatureTrial';
import { useSettingsStatusStore } from '@/stores/SettingsStatus';
import { useSettingsStore } from '@/stores/Settings';
import GetStartedSvg from '@/img/getstarted.png';

const { isLoading, runAsyncWithLoading } = useLoadingState();
const { refreshTrialData } = useSaasFeatureTrial();

const enableSaasManagement = async () => {
  const enableSaasManagementCallback = async () => {
    const settingsStore = useSettingsStore();
    const settingsStatusStore = useSettingsStatusStore();
    const overviewStore = useOverviewStore();

    await settingsStatusStore.updateSettingsStatus({ isEnabled: true });
    await Promise.all([
      settingsStore.fetchSettings(),
      overviewStore.fetchOverview(),
    ]);
  };

  await runAsyncWithLoading(enableSaasManagementCallback);
  refreshTrialData();
};
</script>

<template>
  <div class="gettingStartedContainer">
    <img
      alt="Get Started"
      class="getStartedImage"
      :src="GetStartedSvg"
    >
    <HeadingLarge>Get Started with SaaS Management</HeadingLarge>
    <ParagraphBody
      bodySize="large"
      class="paragraphBody"
      :hasMargin="false"
    >
      SaaS Management lets your company monitor and secure the
      use of relevant SaaS platforms. <Link
        :href="SaasConstants.getStartedSupport"
        target="_blank"
      >
        Learn more.
      </Link>
    </ParagraphBody>
    <Button
      class="enableButton"
      :isLoading="isLoading"
      text="Enable SaaS Management"
      type="primary"
      @click="enableSaasManagement"
    >
      Enable SaaS Management
    </Button>
    <ParagraphBody
      bodySize="tiny"
      class="paragraphBody"
      :hasMargin="false"
    >
      Your company is responsible for ensuring your privacy notices cover
      the data processed by this feature.
    </ParagraphBody>
  </div>
</template>

<style scoped>
.gettingStartedContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--jcHeadingHuge);
}

.getStartedImage {
  height: var(--jc-size-15);
  margin-bottom: var(--jc-spacer-large);
  width: var(--jc-size-15);
}

.paragraphBody {
  color: var(--jc-text-color-light);
  margin-bottom: var(--jc-spacer-medium);
  text-align: center;
}

.enableButton {
  margin-bottom: var(--jc-spacer-small);
}
</style>
