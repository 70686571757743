export function getBadgePropsForAppStatus(status: string) {
  switch (status.toLocaleLowerCase()) {
    case 'newly_discovered':
      return {
        status: 'informational',
        text: 'Newly Discovered',
      };
    case 'approved':
      return {
        status: 'success',
        text: 'Approved',
      };
    case 'unapproved':
      return {
        status: 'error',
        text: 'Unapproved',
      };
    case 'ignored':
      return {
        status: 'inactive',
        text: 'Ignored',
      };
    default:
      return '';
  }
}
