<script setup>
import { $testId } from '@/test/testIdHelper';
import {
  ButtonSecondary,
  ItemRow,
  ItemWithLabel,
} from '@jumpcloud/ui-components';
import { SaasConstants } from '@/util/Constants/SaasConstants';
import { getDiffFromNowInDays } from '@/util/getDiffFromNowInDays';
import { ref } from 'vue';
import { useApplicationsStore } from '@/stores/Applications';
import { useLoadingState } from '@/util/useLoadingState';
import AppAvatar from '@/components/AppAvatar.vue';
import ManualApp from '@/components/ManualApp/ManualApp.vue';

const applicationsStore = useApplicationsStore();

const props = defineProps({
  columns: {
    type: Array,
    default: () => ([]),
  },

  itemData: {
    required: true,
    type: Object,
  },
});

const isModalOpen = ref(false);

const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};

function navigateToOnClick(id) {
  return `${SaasConstants.tabs.applications.route}/${id}/overview?category=newly_discovered`;
}

const {
  isLoading: isLoadingAppReview,
  runAsyncWithLoading: runAsyncWithLoadingAppReview,
} = useLoadingState();
const onSaveReviewApp = async (payload) => {
  const onSaveCallback = async () => applicationsStore.reviewAppFromList(
    { id: props.itemData.appId, payload },
  );

  const res = await runAsyncWithLoadingAppReview(onSaveCallback);

  if (res?.status === 200) {
    closeModal();
  }
};
</script>

<template>
  <ItemRow
    v-bind="$attrs"
    :selected="itemData.selected"
  >
    <ItemWithLabel
      v-for="(column, index) in columns"
      :key="column.dataFieldName"
      class="specialRowClass"
      :column="column"
      :data-test-id="$testId(`cell-${index}`)"
    >
      <template v-if="column.dataFieldName === 'appId'">
        <AppAvatar
          :appId="itemData.appId"
          :category="itemData.category.name"
          :name="itemData.name"
          :to="navigateToOnClick(itemData.appId)"
        />
      </template>
      <template v-else-if="column.dataFieldName === 'discoveredAt'">
        <span>{{ getDiffFromNowInDays(itemData.discoveredAt) }}</span>
      </template>
      <template v-else-if="column.dataFieldName === 'actions'">
        <ButtonSecondary
          text="Review"
          @click="openModal"
        />
      </template>
      <template v-else>
        {{ itemData[column.dataFieldName] }}
      </template>
    </ItemWithLabel>
    <ManualApp
      v-if="isModalOpen"
      :isLoading="isLoadingAppReview"
      :isModalOpen="isModalOpen"
      mode="REVIEW"
      :reviewAppInfo="itemData"
      @close="closeModal"
      @save="onSaveReviewApp"
    />
  </ItemRow>
</template>

<style scoped>
.specialRowClass {
  align-items: center;
  display: flex;
}

</style>
