type SaasAppStatusOption = {
  name: string;
  value: string;
};

export const saasAppStatusOptions: SaasAppStatusOption[] = [
  { name: 'Newly Discovered', value: 'NEWLY_DISCOVERED' },
  { name: 'Approved', value: 'APPROVED' },
  { name: 'Unapproved', value: 'UNAPPROVED' },
  { name: 'Ignored', value: 'IGNORED' },
];
