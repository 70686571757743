<script setup>
import DynamicCheckbox from '@/components/DynamicComponents/DynamicCheckbox.vue';
import DynamicSaasSelect from '@/components/DynamicComponents/DynamicSaasSelect.vue';
import DynamicTextArea from '@/components/DynamicComponents/DynamicTextArea.vue';
import DynamicTextInput from '@/components/DynamicComponents/DynamicTextInput.vue';
import DynamicToggleSwitch from '@/components/DynamicComponents/DynamicToggleSwitch.vue';

const props = defineProps({
  config: {
    type: Object,
    required: true,
  },
});

defineEmits(['update:config']);

const configTypeToComponentMap = {
  'text-input': DynamicTextInput,
  'text-area-input': DynamicTextArea,
  checkbox: DynamicCheckbox,
  'toggle-switch': DynamicToggleSwitch,
  select: DynamicSaasSelect,
};

const Component = configTypeToComponentMap[props.config.type];
</script>

<template>
  <component
    :is="Component"
    :config="config"
    @update:config="$emit('update:config', $event)"
  />
</template>

<style scoped>

</style>
