import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import SystemUsersApi from '@/api/SystemUsersApi';
import type { SystemUserItem } from '@/api/shared/types';

export type SystemUsers = {
  results: SystemUserItem[];
  totalCount: number;
};

export const useSystemUsersStore = defineStore('saas-system-users', () => {
  const systemUsersData = ref<SystemUsers>({} as SystemUsers);

  const fetchSystemUsers = async (
    skip: number,
    limit: number,
    sort: string,
    searchTeam: string,
  ) => {
    const res = await SystemUsersApi.fetch(skip, limit, sort, searchTeam);
    systemUsersData.value = res;
    return res;
  };

  const systemUsers = computed(() => systemUsersData.value);

  return {
    systemUsers,
    fetchSystemUsers,
  };
});
