<script setup>
import { computed, ref } from 'vue';
import { useAppAvatarUrl } from '@/composables/useAppAvatarUrl';

const props = defineProps({
  appId: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    default: '1.25rem',
  },
});

const appId = computed(() => {
  switch (props.appId) {
    case 'google-workspace':
      return 'google';
    case 'azure-ad':
      return 'microsoft';
    default:
      return props.appId;
  }
});

const srcUrl = useAppAvatarUrl(appId);

const imageDoesNotExist = ref(false);
const imageOnError = () => {
  imageDoesNotExist.value = true;
};
</script>

<template>
  <img
    v-if="!imageDoesNotExist"
    :alt="name"
    class="saasAppIconImg"
    :src="srcUrl"
    :style="{ height: size, width: size }"
    @error="imageOnError"
  >
  <span
    v-else
    class="noImg"
    :style="{ height: size, width: size }"
  >
    {{ name.charAt(0).toUpperCase() }}
  </span>
</template>

<style scoped>
.saasAppIconImg {
  border-radius: var(--jc-border-radius);
}

.noImg {
  align-items: center;
  background-color: var(--jc-color-disabled-fill);
  border-radius: var(--jc-border-radius);
  color: var(--jc-color-neutral-text-subdued);
  display: flex;
  font-size: var(--jcHeadingMedium);
  font-weight: 600;
  justify-content: center;
}

</style>
