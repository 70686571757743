import {
  type AccountItem, type UserAccountItem,
} from '@/api/shared/types';
import { DownloadUtil } from '@/util/downloadUtil';
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { useApplicationsStore } from './Applications';
import { useNotification } from '@/composables/useNotification';
import AccountsApi from '@/api/Accounts';
import type { AccountDiscoveryItemWithSourceName, AccountUsageReturnType, Accounts } from '@/api/Accounts';
import type { ApiFilterParams } from '@/api/shared/FilterParams';

type DateRange = {
  start: string;
  end: string;
};

export type AccountsTableFilters = {
  search: string;
  // filters
  user: string | string[];
  application: string | string[];
  findings: string | string[];
  loginMethod: string | string[];
  source: string | string[];
  lastUsed: DateRange[];
  discoveryDate: DateRange[];
} & Partial<ApiFilterParams>;

export const useAccountsStore = defineStore('saas-accounts', () => {
  const applicationsStore = useApplicationsStore();

  const {
    triggerErrorEnhancedNotification,
    triggerSuccessEnhancedNotification,
  } = useNotification();

  const accountsTableActiveFilters = ref<AccountsTableFilters>({
    search: '',
    skip: 0,
    limit: 50,
    sort: '',
    user: [],
    application: [],
    loginMethod: [],
    source: [],
    lastUsed: [],
    discoveryDate: [],
    findings: [],
  });

  const accountsData = ref<Accounts>({} as Accounts);
  const accountUsageData = ref<Record<string, AccountUsageReturnType['data']>>({});
  const accountUsageAtDay = ref<Pick<UserAccountItem, 'account' | 'appId' | 'appName' | 'category' >[]>([]);
  const accountDetailData = ref<Record<string, AccountItem>>({});
  const accountDiscoveries = ref<Record<string, AccountDiscoveryItemWithSourceName[]>>({});

  const fetchAccounts = async (params: AccountsTableFilters) => {
    accountsData.value = await AccountsApi.fetch(params);
  };

  const deleteAccount = async ({ accountId }: { accountId: string }) => {
    try {
      await AccountsApi.deleteAccount({ accountId });
      triggerSuccessEnhancedNotification({ message: 'Account was deleted successfully.' });
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'Account could not be deleted.',
        error,
      });
      throw error;
    }
  };

  const accounts = computed(() => accountsData.value);

  // account usage at day
  const accountUsageDataAtDay = computed(() => accountUsageAtDay.value);

  const fetchAccountUsageAtDay = async (
    { accountId, atDay }: { accountId: string, atDay: string },
  ) => {
    const data = await AccountsApi.getAccountUsageAtDay({ accountId, atDay });
    accountUsageAtDay.value = data;
  };

  const removeAccountUsageAtDay = async () => {
    accountUsageAtDay.value = [];
  };

  // account details
  const getAccountDetailsById = computed(() => (id: string) => accountDetailData.value[id]);

  const fetchAccountDetailsById = async ({ accountId }: { accountId: string }) => {
    const accountDetails = await AccountsApi.getAccountDetails({ accountId });
    accountDetailData.value[accountId] = accountDetails;
  };

  const assignUserToAccount = async (
    { accountId, userId, appId }: { accountId: string, userId: string, appId: string },
  ) => {
    try {
      const user = await AccountsApi.updateAccountUser({ accountId, userId });
      triggerSuccessEnhancedNotification({ message: 'User assigned to account successfully.' });

      const payload = { appId, accountId, user };

      applicationsStore.setApplicationAccountsRowData(payload);
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'User could not be assigned to account.',
        error,
      });
      throw error;
    }
  };

  // account discoveries
  const fetchAccountDiscoveriesById = async (
    { accountId, params }: { accountId: string, params: Partial<ApiFilterParams> },
  ) => {
    const response = await AccountsApi.getAccountDiscoveries({ accountId, params });
    accountDiscoveries.value[accountId] = response;
  };

  const getAccountDiscoveriesById = computed(() => (id: string) => accountDiscoveries.value[id]);

  // account usage
  const fetchAccountUsageById = async (
    { accountId, dayCount }: { accountId: string, dayCount: number },
  ) => {
    const data = await AccountsApi.getAccountUsage({ accountId, dayCount });
    accountUsageData.value[accountId] = data;
  };

  const accountUsageDataByAccountId = computed(
    () => (accountId: string) => accountUsageData.value[accountId],
  );

  const accountsTableFilters = computed(() => accountsTableActiveFilters.value);

  const setAccountsTableFilters = (filters: Partial<AccountsTableFilters>) => {
    accountsTableActiveFilters.value = {
      ...accountsTableActiveFilters.value,
      ...filters,
    };
  };

  const exportAllData = async () => {
    try {
      const base64 = await AccountsApi.exportAccounts({});
      const fileName = DownloadUtil.buildFileNameWithNowDate('accounts');
      DownloadUtil.downloadFile(base64, fileName, 'application/zip');
      triggerSuccessEnhancedNotification({ message: 'Data was exported successfully.' });
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'Data could not be exported.',
        error,
      });
      throw error;
    }
  };

  const exportData = async (params: Partial<AccountsTableFilters>) => {
    try {
      const base64 = await AccountsApi.exportAccounts(params);
      const fileName = DownloadUtil.buildFileNameWithNowDate('accounts');
      DownloadUtil.downloadFile(base64, fileName, 'application/zip');
      triggerSuccessEnhancedNotification({ message: 'Data was exported successfully.' });
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'Data could not be exported.',
        error,
      });
      throw error;
    }
  };

  return {
    accounts,
    accountsTableFilters,
    setAccountsTableFilters,
    fetchAccounts,
    getAccountDetailsById,
    fetchAccountDetailsById,
    assignUserToAccount,
    deleteAccount,
    fetchAccountDiscoveriesById,
    getAccountDiscoveriesById,
    accountUsageDataByAccountId,
    fetchAccountUsageById,
    accountUsageDataAtDay,
    fetchAccountUsageAtDay,
    removeAccountUsageAtDay,
    accountUsageAtDay,
    exportAllData,
    exportData,
  };
});
