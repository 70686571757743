import { Types } from '@jumpcloud/ui-components';

const { Column } = Types;
export const unapprovedColumns = [
  Column({
    label: 'App', dataFieldName: 'appId', width: '2fr', sortable: true,
  }),
  Column({
    label: 'Accounts', dataFieldName: 'accountCount', width: '1fr', sortable: true,
  }),
  Column({
    label: 'Discovery Date', dataFieldName: 'discoveredAt', sortable: true, width: '2fr',
  }),
  Column({
    label: 'Last Used', dataFieldName: 'lastUsedAt', width: '1fr', sortable: true,
  }),
  Column({
    label: 'Access Restriction', dataFieldName: 'accessRestriction', width: '200px', alignment: 'left', sortable: true,
  }),
  Column({
    label: 'Actions', dataFieldName: 'actions', width: '1fr', alignment: 'center',
  }),
];

export const unapprovedSelectableColumnOptions = [
  { name: 'App', value: 'appId', disabled: true },
  { name: 'Accounts', value: 'accountCount', disabled: false },
  { name: 'Discovery Date', value: 'discoveredAt', disabled: false },
  { name: 'Last Used', value: 'lastUsedAt', disabled: false },
  { name: 'Actions', value: 'actions', disabled: true },
  { name: 'Access Restriction', value: 'accessRestriction', disabled: false },
];

export const unapprovedSelectedColumnOptions = [
  { name: 'App', value: 'appId' },
  { name: 'Accounts', value: 'accountCount' },
  { name: 'Discovery Date', value: 'discoveredAt' },
  { name: 'Last Used', value: 'lastUsedAt' },
  { name: 'Actions', value: 'actions' },
  { name: 'Access Restriction', value: 'accessRestriction', disabled: false },
];
