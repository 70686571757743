<script setup>
import {
  Card,
  HeadingMedium,
  InputSearch,
  Link,
  ParagraphBody,
} from '@jumpcloud/ui-components';
import { computed, ref } from 'vue';
import { useConnectorsStore } from '@/stores/Connectors';
import ConnectorCard from '@/pages/Settings/Connectors/AddNewConnector/ConnectorCard.vue';
import ConnectorConfig from '@/pages/Settings/Connectors/AddNewConnector/ConnectorConfig.vue';
import SaasAppIcon from '@/components/SaasAppIcon.vue';

const connectorsStore = useConnectorsStore();

const searchText = ref('');

defineProps({
  connector: {
    type: Object,
    required: false,
    default: null,
  },
  connectorName: {
    type: String,
    required: false,
    default: '',
  },
  currentStep: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['update:connector', 'update:connectorName', 'update:currentStep', 'update:config']);

const connectorsMeta = computed(() => connectorsStore.connectorsMeta);

const availableConnectors = computed(
  () => connectorsMeta.value.filter(connector => connector.name.toLowerCase()
    .includes(searchText.value.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name)));

const handleSelect = (connector) => {
  const mappedConnector = {
    ...connector,
    config: connector.config.map((config) => ({
      ...config,
      value: null,
    })),
  };
  emit('update:connector', mappedConnector);
  emit('update:currentStep', 1);
};
</script>

<template>
  <div class="wrapper">
    <InputSearch
      v-model="searchText"
      placeholder="Search"
    />
    <div
      v-if="!connector"
      class="connectorCardContainer"
    >
      <ConnectorCard
        v-for="availableConnector in availableConnectors"
        :key="availableConnector.type"
        :connector="availableConnector"
        @select="handleSelect"
      />
    </div>
    <div
      v-else
      class="cardContainer"
    >
      <Card>
        <template v-slot:body>
          <div class="cardBodyContainer">
            <SaasAppIcon
              :appId="connector.type"
              :name="connector.name"
              size="40px"
            />
            <div class="connectorHeaderContainer">
              <div
                id="connector-header"
                class="connectorHeader"
              >
                <HeadingMedium
                  class="heading"
                  :hasMargin="false"
                >
                  {{ connector.name }}
                </HeadingMedium>
              </div>
              <div class="learnMoreContainer">
                <ParagraphBody
                  class="description"
                  :hasMargin="false"
                >
                  Learn how this connector works and how to set it up.
                </ParagraphBody>
                <Link
                  :href="connector.learnMoreLink"
                  :isExternal="true"
                  target="_blank"
                  text="Learn more"
                />
              </div>
              <div class="divider" />
              <ConnectorConfig
                :connector="connector"
                :connectorName="connectorName"
                @update:config="$emit('update:config', $event)"
                @update:connector-name="$emit('update:connectorName', $event)"
              />
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  @apply w-full
}

.statusBadgeIconCentered span {
  align-items: center;
  display: flex;
}

.connectorCardContainer {
  @apply mt-4 grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4
}

.cardContainer {
  @apply mt-4
}

.cardBodyContainer {
  @apply flex gap-6
}

.connectorHeaderContainer {
  @apply w-full
}

.connectorHeader{
  @apply mb-2 flex items-center justify-between
}

.heading {
  @apply !font-semibold
}

.learnMoreContainer {
  @apply flex items-center gap-2
}

.description {
  @apply text-black
}

.divider {
  @apply my-4 h-1 w-full border-t border-neutral
}
</style>
