export const numberToWords = (num: number) => {
  if (num === 0) return 'zero';

  const ones = [
    'zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine',
  ];

  const teens = [
    'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen',
  ];

  const tens = [
    '', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety',
  ];

  const convertHundreds = (number: number): string => {
    if (number < 10) return ones[number];
    if (number < 20) return teens[number - 10];
    if (number < 100) {
      const tenPart = Math.floor(number / 10);
      const onePart = number % 10;
      return tens[tenPart] + (onePart ? ` ${ones[onePart]}` : '');
    }
    if (number < 1000) {
      const hundredPart = Math.floor(number / 100);
      const rest = number % 100;
      return `${ones[hundredPart]} hundred${rest ? ` and ${convertHundreds(rest)}` : ''}`;
    }
    return '';
  };

  const convertThousands = (number: number) => {
    if (number < 1000) return convertHundreds(number);
    const thousandPart = Math.floor(number / 1000);
    const rest = number % 1000;
    return `${convertHundreds(thousandPart)} thousand${rest ? ` ${convertHundreds(rest)}` : ''}`;
  };

  return convertThousands(num);
};
