type SaasCategory = {
  id: string;
  name: string;
  description: string;
};

export const saasCategories: SaasCategory[] = [
  {
    id: 'accounting-and-finance',
    name: 'Accounting and Finance',
    description: 'Tools for bookkeeping, invoicing, and expense tracking.',
  },
  {
    id: 'advertising',
    name: 'Advertising',
    description:
      'Platforms for targeted marketing campaigns and brand promotion.',
  },
  {
    id: 'business-intelligence',
    name: 'Business Intelligence',
    description: 'Platforms for data analysis and informed decision-making.',
  },
  {
    id: 'custom',
    name: 'Custom',
    description:
      'Tailor-made solutions catering to specific needs and preferences.',
  },
  {
    id: 'crm',
    name: 'CRM',
    description:
      'Tools for managing customer interactions and relationships efficiently.',
  },
  {
    id: 'chat',
    name: 'Chat',
    description:
      'Real-time communication platforms for team collaboration and discussions.',
  },
  {
    id: 'cloud-computing-platform',
    name: 'Cloud Computing Platform',
    description:
      'Scalable cloud services for application hosting and management.',
  },
  {
    id: 'code-hosting',
    name: 'Code Hosting',
    description: 'Online spaces to collaborate on and store code repositories.',
  },
  {
    id: 'content-management',
    name: 'Content Management',
    description:
      'Systems for creating, organizing, and publishing digital content.',
  },
  {
    id: 'content-sharing',
    name: 'Content Sharing',
    description:
      'Platforms for easy distribution and sharing of digital content.',
  },
  {
    id: 'customer-support',
    name: 'Customer Support',
    description: 'Tools for assisting and resolving customer issues.',
  },
  {
    id: 'data-analytics',
    name: 'Data Analytics',
    description: 'Tools to analyze data and extract valuable insights.',
  },
  {
    id: 'design',
    name: 'Design',
    description: 'Software for creating visual designs and graphics.',
  },
  {
    id: 'development-tools',
    name: 'Development Tools',
    description: 'Tools for aiding software development and coding processes.',
  },
  {
    id: 'e-commerce',
    name: 'E-commerce',
    description: 'Online platforms for buying and selling products.',
  },
  {
    id: 'education',
    name: 'Education',
    description: 'Digital platforms for learning and educational resources.',
  },
  {
    id: 'enterprise-it-services',
    name: 'Enterprise IT Services',
    description: 'Services optimizing and managing enterprise-level IT needs.',
  },
  {
    id: 'forums',
    name: 'Forums',
    description: 'Online platforms for discussions and knowledge sharing.',
  },
  { id: 'generative-ai', name: 'Generative AI', description: 'AI tools.' },
  {
    id: 'hosting-services',
    name: 'Hosting Services',
    description: 'Platforms providing web hosting solutions.',
  },
  {
    id: 'human-resources-tooling',
    name: 'Human Resources (HR) Tooling',
    description: 'Tools for human resources management and operations.',
  },
  {
    id: 'identity-and-access',
    name: 'Identity and Access',
    description: 'Solutions for secure user identity and access management.',
  },
  {
    id: 'instant-personal-messaging',
    name: 'Instant Personal Messaging',
    description: 'Applications for real-time private communication.',
  },
  {
    id: 'mail',
    name: 'Mail',
    description: 'Platforms for electronic communication and messaging.',
  },
  {
    id: 'marketing',
    name: 'Marketing',
    description: 'Tools for promoting products and services.',
  },
  {
    id: 'monitoring',
    name: 'Monitoring',
    description: 'Systems tracking and analyzing performance metrics.',
  },
  {
    id: 'news-and-entertainment',
    name: 'News and Entertainment',
    description: 'Platforms for news and entertainment content consumption.',
  },
  {
    id: 'online-meetings',
    name: 'Online Meetings',
    description: 'Virtual platforms for remote meetings and collaboration.',
  },
  { id: 'others', name: 'Others', description: 'All remaining categories.' },
  {
    id: 'productivity',
    name: 'Productivity',
    description: 'Enhance efficiency and task management.',
  },
  {
    id: 'project-management',
    name: 'Project Management',
    description: 'Software for managing projects and tasks.',
  },
  {
    id: 'sales',
    name: 'Sales',
    description: 'Tools for managing and optimizing sales processes.',
  },
  {
    id: 'security',
    name: 'Security',
    description: 'Protect systems, data, and information from threats.',
  },
  {
    id: 'seo',
    name: 'SEO',
    description:
      'Tools for improving online visibility through search engine optimization.',
  },
  {
    id: 'social-media',
    name: 'Social Media',
    description: 'Platforms for social interaction and marketing.',
  },
  {
    id: 'storage-and-database',
    name: 'Storage and Database',
    description: 'Systems for storing and managing data.',
  },
  {
    id: 'transportation-and-travel',
    name: 'Transportation and Travel',
    description: 'Services for travel and transportation arrangements.',
  },
  {
    id: 'web-analytics',
    name: 'Web Analytics',
    description: 'Tools to analyze website performance and user behavior.',
  },
];
