<script setup>
import {
  ButtonLink, HeadingExtraSmall, Icon, ParagraphBody, Tooltip,
} from '@jumpcloud/ui-components';
import { capitalize } from 'lodash';
import { computed } from 'vue';
import { numberToWords } from '@/util/numberToWords';

const props = defineProps({
  bodySize: {
    type: String,
    required: true,
  },

  isBackgroundExist: Boolean,

  shouldOpenTooltip: {
    type: Boolean,
    default: true,
  },

  ssoApps: {
    type: Array,
    required: true,
  },
});

const emits = defineEmits(['openSsoConnectionsModal']);

const status = computed(() => {
  if (props.ssoApps.some((app) => app.status === 'CONNECTED')) {
    return 'connected';
  }
  if (props.ssoApps.every((app) => app.status === 'NOT_CONNECTED')) {
    return 'not_connected';
  }
  return 'unsupported';
});

const filteredConnectedApps = computed(() => props.ssoApps.filter(
  (app) => app.status === 'CONNECTED',
));
const filteredNotConnectedApps = computed(() => props.ssoApps.filter(
  (app) => app.status === 'NOT_CONNECTED',
));

const connectedText = computed(() => `Connected${filteredConnectedApps.value.length > 1
  ? ` (${filteredConnectedApps.value.length})`
  : ''
}`);

const connectedTooltipText = computed(() => (filteredConnectedApps.value.length === 1
  ? 'SSO is active for this application.'
  : `${capitalize(numberToWords(filteredConnectedApps.value.length))} SSO connections are active for this application.`));

const notConnectedText = computed(() => `Not Connected ${filteredNotConnectedApps.value.length > 1
  ? `(${filteredNotConnectedApps.value.length})`
  : ''
}`);

const notConnectedTooltipText = computed(() => (filteredNotConnectedApps.value.length === 1
  ? 'This app can be connected to JumpCloud SSO.'
  : 'These apps can be connected to JumpCloud SSO.'));

const notConnectedOnClick = computed(() => (filteredNotConnectedApps.value.length > 1
  ? () => emits('openSsoConnectionsModal', props.ssoApps)
  : () => window.open(`${window.location.origin}#/applications`, '_blank')));

const iconAndTextInfo = computed(() => ({
  connected: {
    icon: 'checkmarkCircle',
    iconClass: 'success',
    canOpenModal: true,
    text: connectedText.value,
    tooltipText: connectedTooltipText.value,
    actionButtonText: 'View',
    onClick: () => emits('openSsoConnectionsModal', props.ssoApps),
  },
  not_connected: {
    icon: 'error',
    iconClass: 'warning',
    canOpenModal: filteredNotConnectedApps.value.length > 1,
    text: notConnectedText.value,
    tooltipText: notConnectedTooltipText.value,
    actionButtonText: 'Connect',
    onClick: notConnectedOnClick.value,
  },
  unsupported: {
    icon: 'nullCircle',
    iconClass: 'neutral',
    canOpenModal: false,
    text: 'Not Available',
    textClass: 'neutral-text',
    tooltipText: 'This app is not in our app catalog. Click here to submit a request to add it to JumpCloud SSO.',
    actionButtonText: 'Submit Request',
    onClick: () => window.open('#/support/contact', '_blank'),
  },
}[status.value.toLowerCase()] || {
  text: 'Not Available',
  icon: 'nullCircle',
  iconClass: 'neutral',
  textClass: 'neutral-text',
  canOpenModal: false,
  tooltipText: '',
  tooltipButtonText: '',
  onClick: () => null,
}));
</script>

<template>
  <div :class="$style.header">
    <HeadingExtraSmall :hasMargin="false">
      SSO Connection
    </HeadingExtraSmall>
    <Tooltip
      :class="$style.infoTooltip"
      :linkText="iconAndTextInfo.tooltipButtonText"
      :noHover="!shouldOpenTooltip"
      :text="iconAndTextInfo.tooltipText"
    >
      <Icon
        :class="$style.iconClass"
        icon="info"
        :scale="1"
      />
    </Tooltip>
  </div>
  <div :class="$style.container">
    <Icon
      :class="$style[iconAndTextInfo.iconClass]"
      :icon="iconAndTextInfo.icon"
      :scale="1"
    />
    <ParagraphBody
      :bodySize="bodySize"
      :class="$style[iconAndTextInfo.textClass]"
      :hasMargin="false"
    >
      {{ iconAndTextInfo.text }}
    </ParagraphBody>

    <div :class="$style.borderClass" />

    <ButtonLink
      :text="iconAndTextInfo.actionButtonText"
      @click="iconAndTextInfo.onClick"
    />
  </div>
</template>

<style module scoped>
  .header {
      align-items: center;
      display: flex;
    }

  .container {
    align-items: center;
    border-radius: var(--jc-border-radius);
    display: flex;
    gap: var(--jc-spacer-x-small);
    width: fit-content;
  }

  .infoTooltip :deep() [class^="Tooltip__tooltip"] {
    margin-left: var(--jc-size-negative-0p583);
    max-width: 150px;
  }

  .infoTooltip {
    @apply ml-2 flex items-center;
  }

  .iconClass {
    @apply text-info-base;
  }

  .borderClass {
    @apply mx-2 h-3 border-l border-neutral;
  }

  .neutral {
    color: var(--jc-color-neutral-stroke-base);
  }

  .neutral-text {
    color: var(--jc-text-color-light);
  }

  .success {
    color: var(--jc-color-success-base);
  }

  .warning {
    color: var(--jc-color-warning-base);
  }
</style>
