<script setup>
import { $testId } from '@/test/testIdHelper';
import {
  ButtonDismissibleChip,
  Card,
  HeadingExtraSmall,
  HeadingSmall,
  IconButton,
  InputText,
  ParagraphBody,
  RadioGroup,
} from '@jumpcloud/ui-components';
import { defineEmits, defineProps, ref } from 'vue';

const props = defineProps({
  domainTrackingType: {
    type: String,
    default: 'all',
  },
  trackedDomains: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:domainTrackingType', 'update:trackedDomains']);

const domainTrackingTypeOptions = [
  { value: 'all', label: '**All Domains**', isMarkdown: true },
  { value: 'specific', label: '**Specific Domains**', isMarkdown: true },
];

const domainText = ref('');

const handleSelectionsRemove = (selection) => {
  const selectedDomains = props.trackedDomains.filter(
    (option) => option.value !== selection.value,
  );
  emit('update:trackedDomains', selectedDomains);
};

const handleAddDomain = () => {
  if (domainText.value) {
    const newDomains = [...props.trackedDomains];

    const existingDomain = newDomains.find(
      (domain) => domain.value === domainText.value,
    );

    if (existingDomain) {
      domainText.value = '';
      return;
    }

    const newDomain = {
      value: domainText.value,
      label: domainText.value,
      disabled: false,
    };

    newDomains.push(newDomain);
    emit('update:trackedDomains', newDomains);
    domainText.value = '';
  }
};
</script>

<template>
  <div class="cardContainer">
    <Card>
      <template v-slot:body>
        <div class="cardHeader">
          <HeadingSmall>Domain Tracking</HeadingSmall>
        </div>
        <div class="radioGroupContainer">
          <RadioGroup
            :modelValue="domainTrackingType"
            name="domain-radiogroup"
            :options="domainTrackingTypeOptions"
            :showValidationMessage="false"
            @update:model-value="$emit('update:domainTrackingType', $event)"
          >
            <template v-slot:itemDescription-all>
              <div class="itemDescription">
                <ParagraphBody>
                  All users will be discovered and tracked via email domains.
                </ParagraphBody>
              </div>
            </template>
            <template v-slot:itemDescription-specific>
              <div class="itemDescription">
                <ParagraphBody>
                  Only specified email domains will be discovered and tracked.
                </ParagraphBody>
              </div>
            </template>
          </RadioGroup>
        </div>
        <div
          v-if="domainTrackingType === 'specific'"
          class="inputContainer"
          :data-test-id="$testId('specificDomainsContentWrapper')"
        >
          <div class="extraSmallHeading">
            <HeadingExtraSmall>
              Domains to Track ({{ trackedDomains.length }})
            </HeadingExtraSmall>
          </div>
          <div class="inputTextButtonContainer">
            <div class="inputTextContainer">
              <InputText
                label=""
                :modelValue="domainText"
                placeholder="Add Domain"
                :showValidationMessage="false"
                @update:model-value="domainText = $event"
              />
            </div>
            <IconButton
              hasOutline
              icon="add"
              text="Add"
              type="secondary"
              @click="handleAddDomain"
            />
          </div>
          <div
            v-if="trackedDomains.length > 0"
            class="dismissibleChipsContainer"
          >
            <ButtonDismissibleChip
              v-for="tracked in trackedDomains"
              :key="tracked.value"
              :text="tracked.value"
              textCase="lowercase"
              @click="handleSelectionsRemove(tracked)"
            />
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<style scoped>
.cardContainer {
  margin-bottom: var(--jc-spacer);
}

.cardHeader {
  padding-bottom: var(--jc-spacer-small);
}

.itemDescription {
  color: var(--jc-text-color-light);
  margin-top: var(--jc-spacer-x-small);
}

.radioGroupContainer {
  margin-bottom: -2rem !important;
  margin-top: var(--jc-spacer-small);
  padding-bottom: var(--jc-spacer-small);
}

.inputContainer {
  display: flex;
  flex-direction: column;
  margin-left: var(--jc-spacer-medium);
  margin-top: var(--jc-spacer);
}

.extraSmallHeading {
  margin-bottom: var(--jc-spacer-negative-small);
  margin-top: var(--jc-spacer-small);
  padding-bottom: var(--jc-spacer-small);
}

.inputTextButtonContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--jc-spacer-small);
  margin-top: var(--jc-spacer-small);
  width: 100%;
}

.dismissibleChipsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--jc-spacer-x-small);
  margin-top: var(--jc-spacer-small);
}

.inputTextContainer {
  width: 600px;
}
</style>
