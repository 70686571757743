<script setup>
import { ItemListPagination } from '@jumpcloud/ui-components';
import { debounce } from 'lodash';
import {
  defineEmits, defineProps, onMounted, ref,
} from 'vue';
import NoResults from '@/components/SaasListTable/NoResult.vue';
import SaasListToolbar from '@/components/SaasListTable/SaasListToolbar.vue';
import SaasTableToolbar from '@/components/SaasListTable/SaasTableToolbar.vue';

const props = defineProps({
  actionColumns: {
    type: Array,
    default: () => [],
  },

  actionItems: {
    type: Array,
    default: () => [],
  },

  addButtonText: {
    type: String,
    default: 'Add',
  },

  allowTableOverflow: Boolean,

  appliedFilters: {
    type: Array,
    default: () => [],
  },

  columns: {
    type: Array,
    required: true,
  },

  currentPageNumber: {
    type: Number,
    default: 0,
  },
  data: {
    type: Array,
    required: true,
  },

  defaultFixedColumns: {
    type: Array,
    default: () => [],
  },

  defaultScrollingColumns: {
    type: Array,
    default: () => [],
  },

  hasAddConnectorButton: Boolean,

  hasAddManualAppButton: Boolean,

  hideFilters: Boolean,

  indeterminateSelectAll: Boolean,

  isLoading: {
    type: Boolean,
    required: true,
  },

  itemRowComponent: {
    type: Object,
    default: () => {},
  },

  localStorageKey: {
    type: String,
    default: '',
  },

  newTableLayout: Boolean,

  rowsPerPage: {
    type: Number,
    default: 10,
  },

  searchValue: {
    type: String,
    required: true,
  },

  selectAll: Boolean,

  selectable: {
    type: Boolean,
    default: true,
  },

  selectableColumnOptions: {
    type: Array,
    default: () => [],
  },

  selectedColumnOptions: {
    type: Array,
    default: () => [],
  },

  showPaginationControls: {
    type: Boolean,
    default: false,
  },

  showSearchBar: Boolean,

  showSearchFilterBar: {
    type: Boolean,
    default: true,
  },

  sortBy: {
    type: String,
    default: '',
  },

  sortDirection: {
    type: String,
    default: '',
  },

  tableShouldBeFullWidth: Boolean,

  totalCount: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits([
  'handleSelect',
  'update:searchValue',
  'updateColumnOptions',
  'handleSelectAll',
  'applyFilters',
  'clearAllFilters',
  'removeFilter',
  'handlePageChange',
  'sortColumn',
  'exportData',
  'exportAllData',
  'refresh',
]);

const newColumns = ref(props.columns);
const refreshKey = ref(0);
const handleUpdateSearchTerm = debounce((searchTerm) => {
  emit('update:searchValue', searchTerm);
}, 500);

const handleApplyColumnChange = ({ fixed, scrolling }) => {
  const orderBasedOnFixed = fixed.map((item) => item.key);
  const orderBasedOnScrolling = scrolling.map((item) => item.key);
  const newOrder = [...orderBasedOnFixed, ...orderBasedOnScrolling];

  newColumns.value = newColumns.value.map((column) => {
    const isFixed = orderBasedOnFixed.includes(column.dataFieldName);
    const isScrolling = orderBasedOnScrolling.includes(column.dataFieldName);

    return {
      ...column,
      isSticky: isFixed,
      visible: isFixed || isScrolling,
      alignment: column.alignment || 'left',
      displayName: column.displayName || column.label || column.dataFieldName,
    };
  }).sort((a, b) => {
    const aIndex = newOrder.indexOf(a.dataFieldName);
    const bIndex = newOrder.indexOf(b.dataFieldName);

    return aIndex - bIndex;
  });

  if (props.localStorageKey) {
    localStorage.setItem(props.localStorageKey, JSON.stringify(newColumns.value));
  }

  setTimeout(() => {
    refreshKey.value += 1;
  }, 0);
};

onMounted(() => {
  if (props.localStorageKey) {
    const columns = JSON.parse(localStorage.getItem(props.localStorageKey));
    if (columns) {
      newColumns.value = columns;
      setTimeout(() => {
        refreshKey.value += 1;
      }, 0);
    } else {
      localStorage.setItem(props.localStorageKey, JSON.stringify(newColumns.value));
    }
  }
});
</script>

<template>
  <div
    class="flex h-full min-w-full flex-col overflow-auto"
    :class="tableShouldBeFullWidth && !newTableLayout ? 'w-full' : 'sm:min-w-[768px]'"
  >
    <template v-if="!newTableLayout">
      <slot name="header" />
      <ItemListPagination
        :allowTableOverflow="allowTableOverflow"
        class="tableContainer"
        :class="data.length > 1 ? 'saasTableWithTooltip' : ''"
        :columns="columns"
        :currentPageNum="currentPageNumber"
        :indeterminateSelectAll="indeterminateSelectAll"
        :isLoading="isLoading"
        :itemComponent="itemRowComponent"
        :rowsPerPage="rowsPerPage"
        :selectAll="selectAll"
        :selectable="selectable"
        :showEmptyState="!isLoading && data.length === 0"
        :showPaginationControls="showPaginationControls"
        showHeaderBorder
        :sortBy="sortBy"
        :sortDirection="sortDirection"
        :tableData="data"
        :totalCount="totalCount"
        @page-change="$emit('handlePageChange', $event)"
        @select="$emit('handleSelect', $event)"
        @select-all="$emit('handleSelectAll', $event)"
        @sort-column="$emit('sortColumn', $event)"
      >
        <template
          v-slot:toolbar
        >
          <div v-if="hideFilters" />
          <SaasTableToolbar
            v-else
            :appliedFilters="appliedFilters"
            :columnOptions="selectableColumnOptions"
            :hasAddConnectorButton="hasAddConnectorButton"
            :hasAddManualAppButton="hasAddManualAppButton"
            :searchValue="searchValue"
            :selectedColumnOptions="selectedColumnOptions"
            :showSearchBar="showSearchBar"
            :showSearchFilterBar="showSearchFilterBar"
            @apply-filters="$emit('applyFilters')"
            @clear-all-filters="$emit('clearAllFilters')"
            @export-data="$emit('exportData')"
            @remove-filter="$emit('removeFilter', $event)"
            @update-column-options="$emit('updateColumnOptions', $event)"
            @update-search-term="handleUpdateSearchTerm"
          >
            <template v-slot:filterContent>
              <slot name="filterContent" />
            </template>
          </SaasTableToolbar>
        </template>
        <template v-slot:emptyState>
          <NoResults />
        </template>
      </ItemListPagination>
    </template>
    <template v-else>
      <SaasListToolbar
        :key="'toolbar' + refreshKey"
        :actionColumns="actionColumns"
        :actionItems="actionItems"
        :appliedFilters="appliedFilters"
        :columns="newColumns"
        :defaultFixedColumns="defaultFixedColumns"
        :defaultScrollingColumns="defaultScrollingColumns"
        :hasAddManualAppButton="hasAddManualAppButton"
        :isActionDropdownActive="data.filter((item) => item.selected).length > 0"
        :searchValue="searchValue"
        :selectable="selectable"
        @apply-filters="$emit('applyFilters')"
        @clear-all-filters="$emit('clearAllFilters')"
        @export-all-data="$emit('exportAllData')"
        @export-data="$emit('exportData')"
        @refresh="$emit('refresh')"
        @remove-filter="$emit('removeFilter', $event)"
        @update-column-options="handleApplyColumnChange"
        @update:search-value="$emit('update:searchValue', $event)"
      >
        <template v-slot:filterContent>
          <slot name="filterContent" />
        </template>
      </SaasListToolbar>
      <ItemListPagination
        :key="'table' + refreshKey"
        :allowTableOverflow="true"
        class="tableContainer newTableContainer"
        :class="data.length > 1 ? 'saasTableWithTooltip' : ''"
        :columns="newColumns"
        :currentPageNum="currentPageNumber"
        hasAlternatingRowColors
        :indeterminateSelectAll="indeterminateSelectAll"
        :indexToAddLine="true"
        :isLoading="isLoading"
        :rowsPerPage="rowsPerPage"
        :selectAll="selectAll"
        :selectable="selectable"
        :showEmptyState="!isLoading && data.length === 0"
        :showPaginationControls="showPaginationControls"
        showHeaderBorder
        :sortBy="sortBy"
        :sortDirection="sortDirection"
        :tableData="data"
        :totalCount="totalCount"
        @page-change="$emit('handlePageChange', $event)"
        @select="$emit('handleSelect', $event)"
        @select-all="$emit('handleSelectAll', $event)"
        @sort-column="$emit('sortColumn', $event)"
      >
        <template
          v-for="column in columns"
          v-slot:[column.dataFieldName]="{ record }"
        >
          <slot
            class="overflowUnset"
            :name="column.dataFieldName"
            :record="record"
          >
            {{ record[column.dataFieldName] }}
          </slot>
        </template>
        <template
          v-slot:toolbar
        />
        <template v-slot:emptyState>
          <NoResults />
        </template>
      </ItemListPagination>
    </template>
  </div>
</template>

<style scoped>
.saasTableWithTooltip :deep() li[class*='ItemListBodySlots']:nth-last-child(3)
  span[class*='Tooltip__bottom']{
    bottom: 2rem !important;
    top: auto !important;
  }

/* This is a temporary fix for the tooltip positioning issue for old layout */
.saasTableWithTooltip :deep() li[class*='ItemRow__row']:last-child span[class*='Tooltip__bottom']{
  bottom: 2rem !important;
  top: auto !important;
}

.newTableContainer {
  grid-template-rows: 1fr auto;
}

.tableContainer :deep() div[class*='ItemWithLabel'] {
  align-items: center;
  display: flex;
  overflow: unset !important;
}

.tableContainer :deep() div[class*='checkboxContainer'] {
  align-items: center;
  display: flex;
}

/** fix pagination */
.tableContainer :deep() footer[class*="PaginationControls__controlsContainer"]::before {
  content: none !important;
  display: none;
}

@media (max-width: 767px) {
  .tableContainer :deep() div[class*='ItemWithLabel'] {
    align-items: flex-start;
    display: block;
  }

  .tableContainer :deep() div[class*='checkboxContainer'] {
    align-items: flex-start;
    display: block;
  }

  .tableContainer :deep() header[class*='ItemListHeader__columnContainerNoGap'] {
    padding: var(--jc-spacer) 0;
  }
}

</style>
