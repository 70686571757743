import { AccessRestrictionType } from '@/api/shared/types';
import { DownloadUtil } from '@/util/downloadUtil';
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { useApplicationStatsStore } from './ApplicationStats';
import { useNotification } from '@/composables/useNotification';
import ApplicationsApi from '@/api/Applications';
import type { ApiFilterParams } from '@/api/shared/FilterParams';
import type { AppFilterParams } from '@/util/api/createAppFilterParams';
import type {
  AppUsageType,
  Applications,
  CreateAppPayload,
  ReviewAppStoreType,
} from '@/api/Applications';
import type {
  ApplicationAccountItem,
  ApplicationItem,
  User,
} from '@/api/shared/types';

export type ApplicationsTableFilters = {
  search: string;
  status: string | string[];
  category: string | string[];
  owner: string | string[];
  lastUsed: string | string[];
  renewal: string | string[];
  ssoConnectionStatus: string | string[];
  discoveryDate: string | string[];
} & Partial<ApiFilterParams>;

export const useApplicationsStore = defineStore('saas-applications', () => {
  const applicationStatsStore = useApplicationStatsStore();

  const applicationsData = ref<Applications>({} as Applications);

  const applicationDetailData = ref<Record<string, ApplicationItem>>({});

  const applicationsTableActiveFilters = ref<ApplicationsTableFilters>({
    status: '',
    search: '',
    category: '',
    owner: '',
    lastUsed: '',
    renewal: '',
    ssoConnectionStatus: '',
    discoveryDate: '',
    skip: 0,
    limit: 50,
    sort: '',
  });

  const {
    triggerErrorEnhancedNotification,
    triggerSuccessEnhancedNotification,
  } = useNotification();

  const fetchApplications = async (
    params?: ApplicationsTableFilters,
  ) => {
    try {
      const filters: Partial<ApiFilterParams> & { filter: AppFilterParams } = {
        sort: params?.sort,
        limit: params?.limit,
        skip: params?.skip,
        filter: {
          status: params?.status,
          search: params?.search,
          category: params?.category,
          lastUsed: params?.lastUsed,
          renewal: params?.renewal,
          discoveryDate: params?.discoveryDate,
        },
      };
      applicationsData.value = await ApplicationsApi.fetch(filters);
    } catch (error) {
      applicationsData.value = {
        apps: [],
        count: 0,
        defaultAccessRestriction: AccessRestrictionType.DEFAULTACTION,
      };
      triggerErrorEnhancedNotification({
        message: 'Could not fetch applications.',
        error,
      });
    }
  };

  const exportApps = async (args: AppFilterParams) => {
    try {
      const base64 = await ApplicationsApi.export(args);
      const fileName = DownloadUtil.buildFileNameWithNowDate('applications');
      DownloadUtil.downloadFile(base64, fileName, 'application/zip');
      triggerSuccessEnhancedNotification({ message: 'Data was exported successfully.' });
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'Data could not be exported.',
        error,
      });
    }
  };

  const fetchApplicationDetail = async (appId: string) => {
    applicationDetailData.value[appId] = (await ApplicationsApi.getApp(appId))?.app;
  };

  const createApplication = async (payload: CreateAppPayload) => {
    let response;
    try {
      response = await ApplicationsApi.createApp(payload);
      triggerSuccessEnhancedNotification({
        message: 'New App created successfully.',
      });
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'App could not be created.',
        error,
      });
    }

    return response;
  };

  const updateApp = async (payload: {
    appId: string;
    appInfo: {
      name: string;
      description: string;
      domains: { value: string }[];
    }
  }) => {
    let response;

    try {
      response = await ApplicationsApi.updateApp(payload);
      triggerSuccessEnhancedNotification({ message: 'App updated successfully.' });
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'App could not be updated.',
        error,
      });
    }
    return response;
  };

  const deleteApplication = async ({ id } : { id: string }) => {
    try {
      await ApplicationsApi.deleteApp(id);
      triggerSuccessEnhancedNotification({
        message: 'Application was deleted successfully.',
      });
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'Application could not be deleted.',
        error,
      });
    }
  };

  const getAppAccounts = async (appId: string, params: Partial<ApiFilterParams>) => {
    await ApplicationsApi.getAppAccounts(appId, params);
  };

  const updateAppLicense = async (
    { id, payload } : { id: string, payload: ApplicationItem['license'] },
  ) => {
    let response;
    try {
      response = await ApplicationsApi.updateAppLicense(id, { license: payload });
      triggerSuccessEnhancedNotification({
        message: 'License information was updated successfully.',
      });
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'License information could not be updated.',
        error,
      });
    }
    return response;
  };

  const deleteAppLicense = async ({ id }: { id: string }) => {
    let response;
    try {
      response = await ApplicationsApi.deleteAppLicense(id);
      triggerSuccessEnhancedNotification({
        message: 'License information was updated successfully.',
      });
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'License information could not be updated.',
        error,
      });
    }
    return response;
  };

  const reviewApp = async ({ id, payload }: { id: string, payload: ReviewAppStoreType }) => {
    let response;

    const { status, selectedOwner } = payload;
    const {
      selectedStatus,
      selectedAccessRestriction,
      isDismissibleWarning,
      isOverrideDefaultMessage,
      restrictionMessage,
      isCheckedRecommendation,
      alternativeButtonValue,
      alternativeLinkValue,
      excludeUserGroupsFromAccessRestriction,
      excludedUserGroups,
    } = status || {};

    const accessRestriction = (
      selectedAccessRestriction === AccessRestrictionType.WARNING && isDismissibleWarning
    )
      ? AccessRestrictionType.DISMISSIBLEWARNING
      : selectedAccessRestriction;

    const apiPayload = {
      status: selectedStatus,
      owner: selectedOwner,
      accessRestriction,
      customRestrictionMessage: isOverrideDefaultMessage ? restrictionMessage : '',
      alternativeButton: isCheckedRecommendation ? alternativeButtonValue : '',
      alternativeLink: isCheckedRecommendation ? alternativeLinkValue : '',
      restrictionExcludedGroups:
          excludeUserGroupsFromAccessRestriction
            ? excludedUserGroups?.map(group => ({
              userGroupId: group.value,
              name: group.name,
            }))
            : [],
    };

    try {
      response = await ApplicationsApi.reviewApp({ id, payload: apiPayload });
      triggerSuccessEnhancedNotification({
        message: 'Application details were updated successfully.',
      });
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'Application details could not be updated.',
        error,
      });
    }
    return response;
  };

  const reviewAppFromList = async (
    { id, payload }:{ id: string, payload: ReviewAppStoreType },
  ) => {
    const response = await reviewApp({ id, payload });
    await Promise.all([
      fetchApplications(applicationsTableActiveFilters.value),
      applicationStatsStore.fetchApplicationStats(),
    ]);
    return response;
  };

  const appUsageData = ref<Record<string, AppUsageType[]>>({});

  const fetchAppUsageById = async ({ appId, dayCount }: { appId: string, dayCount: number }) => {
    const res = await ApplicationsApi.fetchAppUsage({ appId, dayCount });
    appUsageData.value[appId] = res;
  };

  const appUsageDataByAppId = computed(() => (appId: string) => appUsageData.value[appId]);

  const setApplicationsTableFilters = (filters: Partial<ApplicationsTableFilters>) => {
    applicationsTableActiveFilters.value = {
      ...applicationsTableActiveFilters.value,
      ...filters,
    };
  };

  const applications = computed(() => applicationsData.value);

  const getApplicationById = computed(() => (id: string) => applicationDetailData.value[id]);

  const applicationsTableFilters = computed(() => applicationsTableActiveFilters.value);

  // app usage at day
  const appUsageAtDay = ref<{
    account: string;
    user: User;
  }[]>([]);

  const appUsageDataAtDay = computed(() => appUsageAtDay.value);

  const fetchAppUsageAtDay = async ({ appId, atDay }: { appId: string, atDay: string }) => {
    const data = await ApplicationsApi.fetchAppUsageAtDay({ appId, atDay });
    appUsageAtDay.value = data;
  };

  const removeAppUsageAtDay = async () => {
    appUsageAtDay.value = [];
  };

  // application accounts
  const applicationAccountsData = ref<
  Record<string, { accounts: ApplicationAccountItem[], count: number }>
  >({});

  const getApplicationAccountsById = computed(
    () => (id: string) => applicationAccountsData.value[id],
  );

  const fetchApplicationAccounts = async (
    { id, params }: { id: string, params: Partial<ApiFilterParams> },
  ) => {
    const data = await ApplicationsApi.getApplicationAccounts(id, params);
    applicationAccountsData.value[id] = data;
  };

  const setApplicationAccountsRowData = (
    { appId, accountId, user }: { appId: string, accountId: string, user: User },
  ) => {
    const appData = applicationAccountsData.value[appId];
    if (!appData) return;

    const accountIndex = appData.accounts.findIndex((acc) => acc.objectId === accountId);
    if (accountIndex === -1) return;

    appData.accounts[accountIndex].user = user;
  };

  return {
    applications,
    applicationsTableFilters,
    fetchApplications,
    exportApps,
    getApplicationById,
    fetchApplicationDetail,
    createApplication,
    updateApp,
    deleteApplication,
    getAppAccounts,
    updateAppLicense,
    deleteAppLicense,
    reviewApp,
    reviewAppFromList,
    fetchAppUsageById,
    appUsageDataByAppId,
    fetchAppUsageAtDay,
    setApplicationsTableFilters,
    appUsageDataAtDay,
    removeAppUsageAtDay,
    getApplicationAccountsById,
    fetchApplicationAccounts,
    setApplicationAccountsRowData,
  };
});
