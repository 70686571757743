<script setup>
import { Types } from '@jumpcloud/ui-components';
import { computed, onMounted, ref } from 'vue';
import { useAccountsStore } from '@/stores/Accounts';
import SaasAccountDetailsTableRow from '@/pages/Applications/ApplicationDetails/Accounts/ApplicationAccountDetail/SaasAccountDetailsTableRow.vue';
import SaasUsageModal from '@/components/SaasUsageModal.vue';
import moment from 'moment';

const { Column } = Types;

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },

  selectedDay: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(['close']);

const isLoading = ref(false);
const accountsStore = useAccountsStore();

const accountUsageDataAtDay = computed(() => accountsStore.accountUsageDataAtDay);

const fetchAccountUsageAtDay = async () => {
  isLoading.value = true;
  await accountsStore.fetchAccountUsageAtDay(
    { accountId: props.accountId, atDay: props.selectedDay.atDay },
  );
  isLoading.value = false;
};

onMounted(() => {
  fetchAccountUsageAtDay();
});

const closeModal = () => {
  emits('close');
  accountsStore.removeAccountUsageAtDay();
};

const title = computed(() => {
  const formattedDay = moment(props.selectedDay.atDay).format('MMMM D, YYYY');

  return `Account Usage on ${formattedDay}`;
});

const columns = [
  Column({ label: 'App', dataFieldName: 'appId', width: '1fr' }),
  Column({ label: 'Account', dataFieldName: 'account', width: '1fr' }),
];
</script>

<template>
  <SaasUsageModal
    v-if="Boolean(selectedDay)"
    :columns="columns"
    :data="accountUsageDataAtDay"
    :isLoading="isLoading"
    :isModalOpen="Boolean(selectedDay)"
    :itemRowComponent="SaasAccountDetailsTableRow"
    :selectedDay="selectedDay"
    :title="title"
    @close="closeModal"
  />
</template>

<style scoped>
</style>
