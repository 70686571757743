<script setup>
import { $testId } from '@/test/testIdHelper';
import {
  ItemRow,
  ItemWithLabel,
  ParagraphBody,
  StatusBadge,
} from '@jumpcloud/ui-components';
import { SaasConstants } from '@/util/Constants/SaasConstants';
import { computed, ref } from 'vue';
import { getBadgePropsForAppStatus } from '@/util/getBadgePropsForAppStatus';
import { getDiffFromNowInDays } from '@/util/getDiffFromNowInDays';
import { missingText } from '@/util/Constants/missingText';
import AppAvatar from '@/components/AppAvatar.vue';
import SsoConnectionStatusBadge from '@/components/SsoConnectionStatusBadge.vue';
import SsoConnectionsModal from '@/components/SsoConnectionsModal/SsoConnectionsModal.vue';
import moment from 'moment';

const props = defineProps({
  columns: {
    type: Array,
    default: () => [],
  },

  itemData: {
    required: true,
    type: Object,
  },
});

const isSsoModalOpen = ref(false);

const statusBadgeInfo = computed(() => getBadgePropsForAppStatus(props.itemData.status));
function navigateToOnClick(id) {
  return `${SaasConstants.tabs.applications.route}/${id}/overview?category=all`;
}

const formattedPrice = computed(() => {
  if (Number.isNaN(props.itemData?.license?.price?.amount)) {
    return missingText;
  }
  return `$${props.itemData.license.price.amount.toFixed(2)}
      / ${props.itemData.license.price.term.toLowerCase()}`;
});

const formattedRenewalDate = computed(() => {
  if (!props.itemData?.license?.renewalDate) {
    return missingText;
  }
  return `${moment(props.itemData.license.renewalDate).format('ll')}`;
});
</script>

<template>
  <ItemRow
    v-bind="$attrs"
    :selected="itemData.selected"
  >
    <ItemWithLabel
      v-for="(column, index) in columns"
      :key="column.dataFieldName"
      :class="[$style.specialRowClass, column.dataFieldName === 'ssoApp' && $style.overflowUnset]
        .filter(Boolean)"
      :column="column"
      :data-test-id="$testId(`cell-${index}`)"
    >
      <template v-if="column.dataFieldName === 'appId'">
        <AppAvatar
          :appId="itemData.appId"
          :category="itemData.category.name"
          :name="itemData.name"
          :to="navigateToOnClick(itemData.appId)"
        />
      </template>
      <template v-else-if="column.dataFieldName === 'status'">
        <StatusBadge
          :status="statusBadgeInfo.status"
          :text="statusBadgeInfo.text"
        />
      </template>
      <template v-else-if="column.dataFieldName === 'ssoApp'">
        <SsoConnectionStatusBadge
          bodySize="small"
          :ssoApps="itemData.ssoApps"
          @open-sso-connections-modal="isSsoModalOpen = true"
        />
      </template>
      <template v-else-if="column.dataFieldName === 'lastUsedAt'">
        <span :class="$style.ellipsis">{{ getDiffFromNowInDays(itemData.lastUsedAt) }}</span>
      </template>
      <template v-else-if="column.dataFieldName === 'licensePrice'">
        <ParagraphBody
          v-if="!itemData.license"
          :class="[$style.disableColor, $style.ellipsis]"
        >
          Not Tracked
        </ParagraphBody>
        <template v-else>
          <ParagraphBody
            v-if="itemData.license.price.term === 'FREE'"
            :class="$style.ellipsis"
          >
            Free Subscription
          </ParagraphBody>
          <ParagraphBody
            v-else
            :class="$style.ellipsis"
            :hasMargin="false"
          >
            {{ formattedPrice }}
          </ParagraphBody>
        </template>
      </template>
      <template v-else-if="column.dataFieldName === 'licenseRenewalDateSort'">
        <ParagraphBody
          v-if="!itemData.license"
          :class="[$style.disableColor, $style.ellipsis]"
        >
          Not Tracked
        </ParagraphBody>
        <ParagraphBody
          v-else-if="itemData.license.price.term === 'FREE'"
          :class="$style.ellipsis"
        >
          {{ missingText }}
        </ParagraphBody>
        <ParagraphBody
          v-else
          :class="$style.ellipsis"
          :hasMargin="false"
        >
          {{ formattedRenewalDate }}
        </ParagraphBody>
      </template>
      <template v-else>
        {{ itemData[column.dataFieldName] }}
      </template>
    </ItemWithLabel>
    <SsoConnectionsModal
      :data="itemData?.ssoApps"
      :isModalOpen="isSsoModalOpen"
      @close="isSsoModalOpen = false"
    />
  </ItemRow>
</template>

<style module scoped>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.specialRowClass {
  align-items: center;
  display: flex;
}

.overflowUnset {
  overflow: unset;
}

.disableColor {
  color: var(--jc-input-color-disabled);
}
</style>
