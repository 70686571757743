<!--eslint-disable-->
<script setup>
import { Icon, ParagraphBody, ChartWrapper } from '@jumpcloud/ui-components';
import { computed } from 'vue';
import moment from 'moment';

// eslint-disable-next-line no-irregular-whitespace
const valueFormatter = (val) => (val ? `　${val}　` : '');

const props = defineProps({
  emptyStateText: {
    type: String,
    default: '',
  },

  height: {
    type: String,
    default: '740px',
  },

  series: {
    type: Array,
    required: true,
  },

  showEmptyState: Boolean,

  tooltip: {
    type: Object,
    required: true,
  },

  xAxis: {
    type: Object,
    required: true,
  },
});

const options = computed(() => ({
  plotOptions: {
    bar: {
      barHeight: '90%',
      borderRadius: 4,
      horizontal: true,
      dataLabels: {
        position: 'start',
      },
    },
  },
  dataLabels: {
    enabled: true,
    textAnchor: 'start',
    formatter: valueFormatter,

  },
  chart: {
    height: props.height,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  grid: {
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  tooltip: props.tooltip,
  xaxis: {
    categories: props.xAxis.categories,
    labels: {
      formatter() {
        return '';
      },
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      formatter(val) {
        return moment(val).format('D MMM').toUpperCase();
      },
      style: {
        colors: 'var(--jc-color-neutral-text-descriptive)',
      },
      offsetY: 3,
    },
    max(max) {
      return Math.min(Math.max(1, Math.ceil(max * 1.2)), max + 10);
    },
  },
}));
</script>

<template>
  <div
    v-if="showEmptyState"
    class="emptyState"
  >
    <Icon
      class="colorGrey"
      icon="directoryInsights"
      :scale="2"
    />
    <ParagraphBody
      class="colorGrey center"
    >
      {{ emptyStateText }}
    </ParagraphBody>
  </div>
  <ChartWrapper
    v-else
    :options="options"
    :series="series"
    :style="$style.chart"
    type="bar"
  />
</template>

<style module>
.chart {
 width: 100%;
}

.emptyState {
  align-items: center;
  background-color: var(--jc-background);
  border-radius: var(--jc-border-radius);
  color: var(--jc-text-color-light);
  display: flex;
  flex-direction: column;
  gap: var(--jc-spacer);
  justify-content: center;
  @apply h-[315px] w-full p-4
}

.colorGrey {
  color: var(--jc-text-color-light);
}

.center {
  text-align: center;
}
</style>
