import { Types } from '@jumpcloud/ui-components';

const { Column } = Types;
export const saasUsersColumns = [
  Column({
    label: 'Name', dataFieldName: 'userDisplayName', width: '2fr', sortable: true,
  }),
  Column({
    label: 'Apps Used', dataFieldName: 'appCount', width: '1fr', sortable: true,
  }),
  Column({
    label: 'Accounts', dataFieldName: 'accountCount', width: '2fr', sortable: true,
  }),
  Column({
    label: 'Last Used', dataFieldName: 'lastUsedAt', width: '1fr', sortable: true,
  }),
];
