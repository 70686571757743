import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { useNotification } from '@/composables/useNotification';
import SettingsApi from '@/api/SettingsApi';
import type { Settings } from '@/api/SettingsApi';

export const useSettingsStore = defineStore('saas-settings', () => {
  const data = ref<Settings>({} as Settings);

  const {
    triggerErrorEnhancedNotification,
    triggerSuccessEnhancedNotification,
  } = useNotification();

  const fetchSettings = async () => {
    data.value = await SettingsApi.fetch();
  };

  const updateSettings = async (settings: Settings) => {
    try {
      data.value = await SettingsApi.update(settings);
      triggerSuccessEnhancedNotification({
        message: 'SaaS Management settings were updated successfully.',
      });
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'SaaS Management settings could not be updated.',
        error,
      });
    }
  };

  const settings = computed(() => data.value);
  const saasSettings = computed(() => data.value.saasSettings);
  const isEnabled = computed(() => data.value.isEnabled);
  const saasState = computed(() => data.value.state);

  return {
    data,
    settings,
    saasSettings,
    isEnabled,
    saasState,
    fetchSettings,
    updateSettings,
  };
});
