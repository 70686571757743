<script setup>
import {
  ButtonDismissibleChip,
  Card,
  Combobox,
  HeadingExtraSmall,
  HeadingSmall,
} from '@jumpcloud/ui-components';
import { debounce } from 'lodash';
import {
  defineEmits,
  defineProps,
  onMounted,
  ref,
} from 'vue';
import { useUserGroupsStore } from '@/stores/UserGroups';

const props = defineProps({
  restrictionExcludedGroups: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:restrictionExcludedGroups']);

const userGroupsStore = useUserGroupsStore();
const userGroupsData = ref({ groups: [], totalCount: 0 });

const userGroupsLoading = ref(false);

const fetchUserGroups = async (searchTerm) => {
  userGroupsLoading.value = true;
  userGroupsData.value = await userGroupsStore.fetchUserGroups(searchTerm);
  userGroupsLoading.value = false;
};

const handleSearchUpdated = debounce(fetchUserGroups, 300);

onMounted(() => {
  fetchUserGroups();
});

const handleSelectionsUpdated = (selections) => {
  const selectedGroups = selections.newSelectedOptions
    .map(opt => ({ disabled: false, ...opt }));
  emit('update:restrictionExcludedGroups', selectedGroups);
};
const handleSelectionsRemove = (selection) => {
  const selectedGroups = props.restrictionExcludedGroups.filter(
    (option) => option.value !== selection.value,
  );
  emit('update:restrictionExcludedGroups', selectedGroups);
};
</script>

<template>
  <div :class="$style.cardContainer">
    <Card>
      <template v-slot:body>
        <div :class="$style.cardHeader">
          <HeadingSmall>Exclude from Browser Access Restrictions</HeadingSmall>
        </div>
        <div :class="$style.selectionContainer">
          <div :class="$style.extraSmallHeading">
            <HeadingExtraSmall>
              User Groups to Exclude ({{ restrictionExcludedGroups.length }})
            </HeadingExtraSmall>
          </div>

          <div class="mt-1 max-w-[600px]">
            <Combobox
              multiselect
              :options="userGroupsData.groups"
              :optionsLoading="userGroupsLoading"
              :optionsTotalCount="userGroupsData.totalCount"
              placeholder="Search"
              :selectedOptions="restrictionExcludedGroups"
              :showSelectAll="false"
              @search-updated="handleSearchUpdated"
              @selections-updated="handleSelectionsUpdated"
            />
          </div>
          <div
            v-if="restrictionExcludedGroups.length > 0"
            :class="$style.dismissibleChipsContainer"
          >
            <ButtonDismissibleChip
              v-for="selected in restrictionExcludedGroups"
              :key="selected.value"
              :text="selected.name"
              textCase="inherit"
              @click="handleSelectionsRemove(selected)"
            />
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<style module scoped>
.cardContainer {
  margin-top: var(--jc-spacer);
}

.cardHeader {
  padding-bottom: var(--jc-spacer);
}

.selectionContainer {
  display: flex;
  flex-direction: column;
}

.dismissibleChipsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--jc-spacer-x-small);
  margin-top: var(--jc-spacer-x-small);
}

.extraSmallHeading {
  margin-bottom: var(--jc-spacer-negative-x-small);
  padding-bottom: var(--jc-spacer-small);
}
</style>
