<script setup>
import {
  ButtonSecondary,
  ItemRow,
  ItemWithLabel,
  Link,
  ParagraphBody,
} from '@jumpcloud/ui-components';
import { SaasConstants } from '@/util/Constants/SaasConstants';
import { computed, ref } from 'vue';
import { getDiffFromNowInDays } from '@/util/getDiffFromNowInDays';
import { missingText } from '@/util/Constants/missingText';
import AssignUserModal from '@/components/AssignUserModal.vue';
import DiscoverMethod from '@/components/DiscoverMethod.vue';
import LoginMethod from '@/pages/Applications/ApplicationDetails/Accounts/LoginMethod.vue';

const props = defineProps({
  columns: {
    type: Array,
    default: () => [],
  },

  itemData: {
    required: true,
    type: Object,
  },
});

defineEmits(['assign']);

const assignUserModal = ref(false);

const userLink = computed(() => {
  const usersRoute = SaasConstants.tabs.users.route;
  const userId = props.itemData.user.id;

  return `${usersRoute}/${userId}`;
});

const accountLink = computed(() => {
  if (!props.itemData.account) {
    return '';
  }
  return `accounts/${props.itemData.objectId}`;
});

const openAssignUserModal = () => {
  assignUserModal.value = true;
};
</script>

<template>
  <ItemRow
    v-bind="$attrs"
  >
    <ItemWithLabel
      v-for="(column, index) in columns"
      :key="column.dataFieldName"
      :class="[
        'specialRowClass',
        (column.dataFieldName === 'loginMethods' || column.dataFieldName)
          === 'source' ? 'overflowUnset' : ''
      ]"
      :column="column"
      :data-test-id="$testId(`cell-${index}`)"
      :style="(column.dataFieldName === 'account' || column.dataFieldName === 'username' )&&
        'overflow: hidden !important;'
        + ' '
        + 'white-space: nowrap !important; text-overflow: ellipsis !important;'
      "
    >
      <ParagraphBody
        v-if="column.dataFieldName === 'username'"
        class="paragraphContainer"
      >
        <span class="mb-1 mt-0.5 flex w-full">
          <Link
            v-if="itemData.user.id"
            class="truncate"
            :text="itemData.user.name || missingText"
            :to="userLink"
          />
          <ButtonSecondary
            v-if="!itemData.user.id && itemData.assignable"
            text="Assign a User"
            textCase="inherit"
            @click="openAssignUserModal"
          />
          <span v-if="!itemData.user.id && !itemData.assignable">
            {{ missingText }}
          </span>
        </span>
        <ParagraphBody
          bodySize="small"
          class="emailTextColor truncate"
        >
          {{ itemData.user.email }}
        </ParagraphBody>
      </ParagraphBody>
      <div
        v-if="column.dataFieldName === 'account'"
        class="truncate"
      >
        <Link
          v-if="itemData.objectId"
          class="w-full truncate"
          :text="itemData.account || missingText"
          :to="accountLink"
        />
        <span v-if="!itemData.objectId">{{ itemData.account || missingText }}</span>
      </div>
      <template v-if="column.dataFieldName === 'loginMethods'">
        <template v-if="itemData.loginMethods.length > 0">
          <div class="flexCenterWithGap">
            <LoginMethod
              v-for="loginMethod in itemData.loginMethods"
              :key="loginMethod"
              :loginMethod="loginMethod"
            />
          </div>
        </template>
        <template v-else>
          --
        </template>
      </template>
      <template v-if="column.dataFieldName === 'source'">
        <template v-if="itemData.discoverMethods?.length > 0">
          <div class="flexCenterWithGap">
            <DiscoverMethod
              v-for="method in itemData.discoverMethods"
              :key="method.discoveryType"
              :method="method"
            />
          </div>
        </template>
        <template v-else>
          --
        </template>
      </template>
      <span v-if="column.dataFieldName === 'discoveredAt'">
        {{ getDiffFromNowInDays(itemData.discovered) }}
      </span>
      <span v-if="column.dataFieldName === 'lastUsedAt'">
        {{ getDiffFromNowInDays(itemData.lastUsed) }}
      </span>
      <span v-if="column.dataFieldName === 'lastLoginAt'">
        {{ getDiffFromNowInDays(itemData.lastLogin) }}
      </span>
    </ItemWithLabel>
    <AssignUserModal
      v-if="assignUserModal"
      :accountId="itemData.objectId"
      :appId="itemData.appId"
      :isModalOpen="assignUserModal"
      :user="itemData.user"
      @assign="$emit('assign', $event)"
      @close="assignUserModal = false"
    />
  </ItemRow>
</template>

<style scoped>
.specialRowClass {
  align-items: center;
  display: flex;
}

.overflowUnset {
  overflow: unset;
}

.paragraphContainer {
  display: flex;
  flex-direction: column;
  @apply truncate;
}

.flexCenterWithGap {
  @apply flex items-center gap-2
}

.emailTextColor {
  color: var(--jc-color-neutral-text-descriptive);
}
</style>
