<script setup>
import { Types } from '@jumpcloud/ui-components';
import { computed, onMounted, ref } from 'vue';
import { useApplicationsStore } from '@/stores/Applications';
import SaasApplicationDetailsAccountsTableRow from '@/pages/Applications/ApplicationDetails/Accounts/SaasApplicationDetailsAccountsTableRow.vue';
import SaasUsageModal from '@/components/SaasUsageModal.vue';
import moment from 'moment';

const { Column } = Types;

const props = defineProps({
  appId: {
    type: String,
    required: true,
  },

  selectedDay: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(['close']);

const isLoading = ref(false);
const applicationsStore = useApplicationsStore();

const appUsageDataAtDay = computed(() => applicationsStore.appUsageDataAtDay);

const fetchAppUsageAtDay = async () => {
  isLoading.value = true;
  await applicationsStore.fetchAppUsageAtDay(
    { appId: props.appId, atDay: props.selectedDay.atDay },
  );
  isLoading.value = false;
};

onMounted(() => {
  fetchAppUsageAtDay();
});

const closeModal = () => {
  emits('close');
  applicationsStore.removeAppUsageAtDay();
};

const title = computed(() => {
  const formattedDay = moment(props.selectedDay.atDay).format('MMMM D, YYYY');

  return `App Usage on ${formattedDay}`;
});

const columns = [
  Column({ label: 'Name', dataFieldName: 'username', width: '1fr' }),
  Column({ label: 'Account', dataFieldName: 'account', width: '1fr' }),
];

const itemRowComponent = SaasApplicationDetailsAccountsTableRow;
</script>

<template>
  <SaasUsageModal
    v-if="Boolean(selectedDay)"
    :columns="columns"
    :data="appUsageDataAtDay.map(el => ({ ...el, appId: props.appId }))"
    :isLoading="isLoading"
    :isModalOpen="Boolean(selectedDay)"
    :itemRowComponent="itemRowComponent"
    :selectedDay="selectedDay"
    :title="title"
    @close="closeModal"
  />
</template>

    <style scoped>
    </style>
