<script setup lang="ts">
import { $testId } from '@/test/testIdHelper';
import { NotificationInPage, Types } from '@jumpcloud/ui-components';
import { computed, ref } from 'vue';
import { useOverviewStore } from '@/stores/Overview';
import { useRouter } from 'vue-router';
import SaasOverviewGettingStartedPanel from './SaasOverviewGettingStartedPanel.vue';
import SaasOverviewMainPanel from './SaasOverviewMainPanel.vue';

const { NotificationConfig } = Types;

const router = useRouter();
const overviewStore = useOverviewStore();
const isSaaSManagementEnabled = computed(() => overviewStore.isEnabled);

const hasUserClosedNotification = ref(false);
const shouldShowNotification = computed(
  () => !hasUserClosedNotification.value && !isSaaSManagementEnabled.value,
);

const notificationConfig = NotificationConfig({
  icon: 'error',
  isMarkdown: false,
  isSingleLineAction: true,
  showCloseButton: true,
  message:
    'To discover unmanaged applications, JumpCloud Go is required either as an enabled feature or as a simple browser extension.',
  type: 'warning',
});

const handleNotificationButtonClick = () => {
  router.push('/settings/features');
};

const handleCloseNotification = () => {
  hasUserClosedNotification.value = true;
};

</script>

<template>
  <div
    class="overviewContainer"
    :data-test-id="$testId('overviewPageContainer')"
  >
    <NotificationInPage
      actionText="Go To Settings"
      :notification="notificationConfig"
      :showNotification="shouldShowNotification"
      @action="handleNotificationButtonClick"
      @close="handleCloseNotification"
    />
    <SaasOverviewGettingStartedPanel v-if="!isSaaSManagementEnabled" />
    <SaasOverviewMainPanel v-else />
  </div>
</template>

<style scoped>
.overviewContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
</style>
