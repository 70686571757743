<script setup>
import { $testId } from '@/test/testIdHelper';
import {
  Button,
  ButtonSecondary,
  DropdownActions,
  LoadingOverlay,
  Tabs,
  Types,
} from '@jumpcloud/ui-components';
import { SaasConstants } from '@/util/Constants/SaasConstants';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { useApplicationsStore } from '@/stores/Applications';
import { useLoadingState } from '@/util/useLoadingState';
import { useRoute, useRouter } from 'vue-router';
import { useSettingsStore } from '@/stores/Settings';
import AppAvatar from '@/components/AppAvatar.vue';
import AppStatusBadge from '@/components/AppStatusBadge.vue';
import ManualApp from '@/components/ManualApp/ManualApp.vue';
import SaasAppDeleteModal from '@/components/SaasAppDeleteModal.vue';
import SaasApplicationDetailsAccounts from '@/pages/Applications/ApplicationDetails/Accounts/SaasApplicationDetailsAccounts.vue';
import SaasApplicationDetailsOverview from '@/pages/Applications/ApplicationDetails/Overview/SaasApplicationDetailsOverview.vue';
import SaasUpdateLicenseModal from '@/components/SaasUpdateLicenseModal.vue';

const { DropdownMenuItem, TabItem } = Types;

const props = defineProps({
  activeTab: {
    type: String,
    default: 'overview',
  },
});

const applicationsStore = useApplicationsStore();
const settingsStore = useSettingsStore();
const router = useRouter();
const route = useRoute();

const { id } = route.params;
const { category } = route.query;

const data = computed(() => applicationsStore.getApplicationById(id));
const usageData = computed(() => applicationsStore.appUsageDataByAppId(id));

const showOverviewTab = computed(() => props.activeTab === 'overview');
const showAccountsTab = computed(() => props.activeTab === 'accounts');

const tabListRouter = computed(() => [
  TabItem({
    key: 'overview',
    label: 'Overview',
    to: `${SaasConstants.tabs.applications.route}/${id}/overview?category=${category}`,
  }),
  TabItem({
    key: 'accounts',
    label: `Accounts (${data.value?.accountCount})`,
    to: `${SaasConstants.tabs.applications.route}/${id}/accounts?category=${category}`,
  }),
]);

const dayCount = ref(30);

const {
  isLoading: isLoadingApplicationData,
  runAsyncWithLoading: runAsyncWithLoadingApplicationData,
} = useLoadingState();

const {
  isLoading: isLoadingUsage,
  runAsyncWithLoading: runAsyncWithLoadingUsage,
} = useLoadingState();

const fetchApplicationDetails = async () => {
  const fetchApplicationDetailsCallback = async () => {
    await Promise.all([
      applicationsStore.fetchApplicationDetail(id),
      applicationsStore.fetchAppUsageById({ appId: id, dayCount: dayCount.value }),
    ]);
  };

  await runAsyncWithLoadingApplicationData(fetchApplicationDetailsCallback);
};

const isLicenseModalOpen = ref(false);

const openLicenseModal = () => {
  isLicenseModalOpen.value = true;
};

const isAppReviewModalOpen = ref(false);
const isDeleteModalOpen = ref(false);

const closeAppReviewModal = () => {
  isAppReviewModalOpen.value = false;
};

const openAppReviewModal = () => {
  isAppReviewModalOpen.value = true;
};

const openDeleteModal = () => {
  isDeleteModalOpen.value = true;
};

const {
  isLoading: isLoadingAppEdit,
  runAsyncWithLoading: runAsyncWithLoadingAppEdit,
} = useLoadingState();

const isAppEditModalOpen = ref(false);

const closeAppEditModal = () => {
  isAppEditModalOpen.value = false;
};

const openAppEditModal = () => {
  isAppEditModalOpen.value = true;
};

const saveAppEditModal = async (appData) => {
  const nonReactiveAppData = { appInfo: appData.appInfo, appId: id };
  const onSaveCallback = async () => {
    const newAppId = applicationsStore.updateApp(nonReactiveAppData);

    return newAppId;
  };

  const newAppId = await runAsyncWithLoadingAppEdit(onSaveCallback);
  if (!newAppId) return;

  await fetchApplicationDetails();
  closeAppEditModal();
};

const editAppInfo = computed(() => ({
  name: data?.value?.name,
  description: data?.value?.description,
  domains: data?.value?.domains,
}));

const isAppEditModalLoading = computed(
  () => isLoadingApplicationData.value || isLoadingAppEdit.value,
);

const isNewlyDiscovered = computed(() => data.value?.status === 'NEWLY_DISCOVERED');

const dropdownItems = computed(() => {
  const isCustom = data.value?.category.id === 'custom';
  const editAppButton = isCustom ? DropdownMenuItem({
    click: openAppEditModal,
    text: 'Edit App',
    type: 'informational',
  }) : undefined;

  const deleteAppButtonWithSeparator = [
    DropdownMenuItem({
      separator: true,
    }),
    DropdownMenuItem({
      click: openDeleteModal,
      text: 'Delete App',
      type: 'error',
      hasNoBackground: true,
      separator: false,
      hasOutline: true,
      disabled: false,
    }),
  ];

  return [
    editAppButton,
    DropdownMenuItem({
      click: openAppReviewModal,
      text: 'Review App',
      type: 'informational',
    }),
    DropdownMenuItem({
      click: openLicenseModal,
      text: 'Update License Information',
      type: 'informational',
    }),
    ...deleteAppButtonWithSeparator,
  ].filter(Boolean);
});

const {
  isLoading: isLoadingAppReview,
  runAsyncWithLoading: runAsyncWithLoadingAppReview,
} = useLoadingState();

const onSaveReviewApp = async (payload) => {
  const onSaveUpdateAppStatusCallback = async () => applicationsStore.reviewApp({ id, payload });

  const res = await runAsyncWithLoadingAppReview(onSaveUpdateAppStatusCallback);

  if (res?.status === 200) {
    closeAppReviewModal();
    await fetchApplicationDetails();
  }
};

const goToAccounts = () => {
  router.push(`${SaasConstants.tabs.applications.route}/${id}/accounts`);
};

const updateDayCount = async (newCount) => {
  dayCount.value = newCount;

  const refetchUsage = async () => {
    await applicationsStore.fetchAppUsageById({ appId: id, dayCount: dayCount.value });
  };

  await runAsyncWithLoadingUsage(refetchUsage);
};

const {
  isLoading: isLoadingSettingsData,
  runAsyncWithLoading: runAsyncWithLoadingSettingsData,
} = useLoadingState();

const fetchSettings = async () => {
  const fetchSettingsCallback = async () => {
    if (!settingsStore.data.saasSettings) {
      await settingsStore.fetchSettings();
    }
  };
  await runAsyncWithLoadingSettingsData(fetchSettingsCallback);
};

onMounted(() => {
  fetchSettings();
  fetchApplicationDetails();
});

watch(() => props.activeTab, async (newTab) => {
  if (newTab === 'overview') {
    await fetchApplicationDetails();
  }
});

const isLoading = computed(() => isLoadingApplicationData.value || isLoadingSettingsData.value);
const showLoadingOverlay = computed(() => Boolean(isLoading.value || data.value?.appIsDeleted));
</script>

<template>
  <div
    class="wrapper"
    :data-test-id="$testId('applicationDetailsPageHeader')"
  >
    <LoadingOverlay
      isLight
      :visible="showLoadingOverlay"
    />
    <div
      v-if="data"
    >
      <div class="infoRow">
        <div class="appContainer">
          <AppAvatar
            :appId="id"
            :name="data.name"
            onTitleClickDisabled
          />
          <AppStatusBadge :status="data.status.toLowerCase()" />
        </div>
        <div class="statusAndActionWrapper">
          <div
            v-if="isNewlyDiscovered"
            class="statusAndAction"
          >
            <Button
              text="Review Application"
              @click="openAppReviewModal"
            />
            <ButtonSecondary
              hasOutline="false"
              text="Delete"
              type="error"
              @click="openDeleteModal"
            />
          </div>
          <DropdownActions
            v-else
            buttonText="Actions"
            class="dropdownAction"
            :items="dropdownItems"
            position="right"
          />
        </div>
      </div>
      <div class="tabsContainer">
        <Tabs
          :activeTab="activeTab"
          :data-test-id="$testId('tabsContainer')"
          :tabs="tabListRouter"
        />
      </div>
    </div>
    <SaasApplicationDetailsOverview
      v-if="showOverviewTab"
      :id="id"
      :data="data"
      :dayCount="dayCount"
      :isLoadingUsage="isLoadingUsage"
      :usageData="usageData"
      @go-to-accounts="goToAccounts"
      @open-license-modal="openLicenseModal"
      @update-day-count="updateDayCount"
    />
    <SaasApplicationDetailsAccounts
      v-if="data && showAccountsTab"
      :id="id"
      :appName="data.name"
    />
    <ManualApp
      v-if="isAppReviewModalOpen"
      :isLoading="isLoadingAppReview"
      :isModalOpen="isAppReviewModalOpen"
      mode="REVIEW"
      :reviewAppInfo="data"
      @close="closeAppReviewModal"
      @save="onSaveReviewApp"
    />
    <SaasUpdateLicenseModal
      v-if="isLicenseModalOpen"
      :data="data"
      :isModalOpen="isLicenseModalOpen"
      @close="isLicenseModalOpen = false"
      @save="isLicenseModalOpen = false"
    />

    <ManualApp
      v-if="isAppEditModalOpen"
      :editAppInfo="editAppInfo"
      :isLoading="!!isAppEditModalLoading"
      :isModalOpen="isAppEditModalOpen"
      mode="EDIT"
      @close="closeAppEditModal"
      @save="saveAppEditModal"
    />
    <SaasAppDeleteModal
      v-if="isDeleteModalOpen"
      :data="data"
      :isModalOpen="isDeleteModalOpen"
      @close="isDeleteModalOpen = false"
    />
  </div>
</template>

<style scoped>
.wrapper {
  background-color: var(--jc-background-light);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding-top: var(--jc-spacer);
}

.statusAndActionWrapper {
  align-items: center;
  display: flex;
  @apply gap-4;
}

.statusAndAction {
  align-items: stretch;
  display: flex;
  gap: var(--jc-spacer);
  justify-content: space-between;
}

.infoRow {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--jc-spacer);
  justify-content: space-between;
  padding: 0 var(--jc-spacer) var(--jc-spacer);
}

.tabsContainer {
  @apply items-center border-b flex justify-between px-4 border-b-neutral h-12 shrink-0;
}

.tabsContainer :deep() li[class^='Tabs__tabItem'] {
  height: var(--jc-size-3);
  margin-right: var(--jc-spacer-small) !important;
  padding: 0 var(--jc-spacer);
}

.tabsContainer :deep() li[class^='Tabs__indicator'] {
  margin-bottom: var(--jc-size-negative-0p125);
}

.dropdownAction {
  --dropdown-menu-width: 20rem;
  @apply z-50
}

.appContainer {
  @apply flex items-center gap-4;
}
</style>
