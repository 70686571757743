import { useNotifications } from '@jumpcloud-ap/notifications';

export function useNotification() {
  const { triggerErrorNotification, triggerSuccessNotification } = useNotifications();

  const errorMessageSuffix = 'Please try again.';
  const withErrorMessageSuffix = (message) => `${message} ${errorMessageSuffix}`;

  const triggerErrorEnhancedNotification = ({ title = 'Error', message, error }) => {
    const errorDataMessage = error?.response?.data?.message;
    let notificationMessage = errorDataMessage || message;
    let currentTitle = title;

    const appAlreadyAddedMessage = 'The domain(s) entered already exists in our system.';
    if (errorDataMessage === appAlreadyAddedMessage) {
      currentTitle = 'App Already Added';
      notificationMessage = appAlreadyAddedMessage;
    }

    triggerErrorNotification({
      title: currentTitle,
      message: withErrorMessageSuffix(notificationMessage),
    });
  };

  const triggerSuccessEnhancedNotification = ({ title = 'Success', message }) => {
    triggerSuccessNotification({ title, message });
  };

  return {
    triggerErrorNotification,
    triggerSuccessNotification,
    triggerErrorEnhancedNotification,
    triggerSuccessEnhancedNotification,
  };
}
