import { missingText } from '@/util/Constants/missingText';
import moment from 'moment';

function isToday(momentDate: moment.Moment) {
  return momentDate.isSame(moment().startOf('day'), 'd');
}
function isYesterday(momentDate: moment.Moment) {
  return momentDate.isSame(moment().subtract(1, 'days').startOf('day'), 'd');
}

function isTomorrowOrBeyond(momentDate: moment.Moment) {
  return momentDate.isAfter(moment().add(1, 'days').startOf('day'));
}

export function getDiffFromNowInDays(from: string | null) {
  if (!from) {
    return missingText;
  }

  const fromMoment = moment(from);

  if (isTomorrowOrBeyond(fromMoment)) {
    return '--';
  }

  if (isToday(fromMoment)) {
    return 'Today';
  }

  if (isYesterday(fromMoment)) {
    return 'Yesterday';
  }

  return fromMoment.fromNow();
}
