import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import UserGroupsApi, { type UserGroupResponse } from '@/api/UserGroupsApi';

export const useUserGroupsStore = defineStore('saas-user-groups', () => {
  const data = ref<UserGroupResponse>({ groups: [], totalCount: 0 });

  const fetchUserGroups = async (searchTerm: string = '') => {
    data.value = await UserGroupsApi.fetch(searchTerm);
    return data.value;
  };

  const userGroups = computed(() => data.value);

  return {
    userGroups,
    fetchUserGroups,
  };
});
