export default function getEnvironment(hostname = window.location.hostname) {
  if (hostname === 'localhost' || hostname === 'console.jumpcloud.local') {
    return 'local';
  }

  if (hostname === 'console.stg01.jumpcloud.com') {
    return 'staging';
  }

  const startsWithWok = hostname.startsWith('console.wok');
  const endsWithWok = hostname.endsWith('dev-usw2-p02.jcplatform.dev');

  if (startsWithWok && endsWithWok) {
    return 'wok';
  }

  return 'production';
}
