import { createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';

export type UserGroupResponse = {
  groups: {
    value: string;
    name: string;
    disabled: boolean;
  }[],
  totalCount: number;
};

export type UserGroup = {
  attributes: string[] | null;
  description: string;
  email: string;
  id: string;
  name: string
  type: string;
};

class UserGroupsApi {
  private baseUrl: string;
  private axiosInstance;

  constructor() {
    this.baseUrl = '/api/v2/groups';
    this.axiosInstance = createJcApiAxiosInstance();
  }

  async fetch(searchTerm: string): Promise<UserGroupResponse> {
    const resp = await this.axiosInstance.get<UserGroup[]>(this.baseUrl, {
      params: {
        skip: 0,
        limit: 100,
        sort: 'name',
        filter: [
          ['type:eq:', 'user_group'],
          ['name:search:', searchTerm],
        ].filter(([, v]) => v).map(([k, v]) => `${k}${v}`),
      },
    });

    const groups = resp?.data?.map(group => ({
      value: group.id,
      name: group.name,
      disabled: false,
    })) ?? [];
    const totalCount = parseInt(resp?.headers?.['x-total-count'] ?? '0', 10);

    return { groups, totalCount };
  }
}

export default new UserGroupsApi();
