// Base route definition
type Route<T extends string = string> = {
  name: string;
  route: string;
  routes?: Record<T, Route<string>>;
};

type RouteWithSubRoutes<T extends Route['routes'] = Route['routes']> = Route & {
  routes: T;
};

type SaasOverviewRoute = Route;

type SaasApplicationsRoute = RouteWithSubRoutes<{
  application: RouteWithSubRoutes<{
    overview: Route;
    accounts: RouteWithSubRoutes<{
      account: Route;
    }>;
  }>;
}>;

type SaasUsersRoute = RouteWithSubRoutes<{
  user: Route;
}>;

type SaasSettingsRoute = RouteWithSubRoutes<{
  generalSettings: Route;
  connectors: RouteWithSubRoutes<{
    connector: Route;
  }>;
}>;

type SaasAccountsRoute = RouteWithSubRoutes<{
  account: Route;
}>;

type SaasTabsRoutes = {
  overview: SaasOverviewRoute;
  applications: SaasApplicationsRoute;
  users: SaasUsersRoute;
  settings: SaasSettingsRoute;
  accounts: SaasAccountsRoute;
};

type SaasConstantsType = {
  type: string;
  route: string;
  title: string;
  name: string;
  getStartedSupport: string;
  jcGoSupport: string;
  tabs: SaasTabsRoutes;
};

export const SaasConstants: SaasConstantsType = {
  type: 'saasMgmt',
  route: '/saas-management',
  title: 'SaaS Management',
  name: 'saasManagement',
  getStartedSupport: 'https://jumpcloud.com/support/get-started-saas-management',
  jcGoSupport: 'https://jumpcloud.com/support/get-started-saas-management#discovery-methods',
  tabs: {
    overview: {
      name: 'saasManagementOverview',
      route: '/saas-management/overview',
    },
    applications: {
      name: 'saasManagementApplications',
      route: '/saas-management/applications',
      routes: {
        application: {
          name: 'saasManagementApplication',
          route: '/saas-management/applications/:id',
          routes: {
            overview: {
              name: 'saasManagementApplicationOverview',
              route: '/saas-management/applications/:id/overview',
            },
            accounts: {
              name: 'saasManagementApplicationAccounts',
              route: '/saas-management/applications/:id/accounts',
              routes: {
                account: {
                  name: 'saasManagementApplicationAccountDetails',
                  route: '/saas-management/applications/:appId/accounts/:accountId',
                },
              },
            },
          },
        },
      },
    },
    users: {
      name: 'saasManagementUsers',
      route: '/saas-management/users',
      routes: {
        user: {
          name: 'saasManagementUser',
          route: '/saas-management/users/:id',
        },
      },
    },
    settings: {
      name: 'saasManagementSettings',
      route: '/saas-management/settings',
      routes: {
        generalSettings: {
          name: 'saasManagementSettingsGeneral',
          route: '/saas-management/settings/general',
        },
        connectors: {
          name: 'saasManagementSettingsConnectors',
          route: '/saas-management/settings/connectors',
          routes: {
            connector: {
              name: 'saasManagementSettingsConnector',
              route: '/saas-management/settings/connectors/:id',
            },
          },
        },
      },
    },
    accounts: {
      name: 'saasManagementAccounts',
      route: '/saas-management/accounts',
      routes: {
        account: {
          name: 'saasManagementAccount',
          route: '/saas-management/accounts/:accountId/app/:appId',
        },
      },
    },
  },
};
