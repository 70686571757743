import { type AxiosInstance, createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';

export type ExportDataArgs = Partial<{
  accountId: string
  appId: string
  dayCount: number
  userId: string
}>;

class ExportApi {
  private baseUrl: string;
  private axiosInstance: AxiosInstance;

  constructor() {
    this.baseUrl = '/api/v2/saas/export';
    this.axiosInstance = createJcApiAxiosInstance();
  }

  async exportData(args: ExportDataArgs) {
    const res = await this.axiosInstance.post(this.baseUrl, args, { responseType: 'arraybuffer' });
    return res.data;
  }
}

export default new ExportApi();
