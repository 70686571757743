<script setup lang="ts">
import { $testId } from '@/test/testIdHelper';

import {
  IconButton,
  LoadingOverlay,
  Tabs,
  Types,
} from '@jumpcloud/ui-components';
import { computed, onMounted, ref } from 'vue';
import { useApplicationStatsStore } from '@/stores/ApplicationStats';
import { useOverviewStore } from '@/stores/Overview';
import { useRouter } from 'vue-router';
import { useSettingsStore } from '@/stores/Settings';
import SaasAccounts from '@/pages/Accounts/SaasAccounts.vue';
import SaasApplications from '@/pages/Applications/SaasApplications.vue';
import SaasOverview from '@/pages/Overview/SaasOverview.vue';
import SaasUsersTable from '@/pages/Users/SaasUsersTable.vue';

const { TabItem } = Types;

const props = defineProps({
  activeTab: {
    type: String,
    default: 'overview',
  },
});

const showOverview = computed(() => props.activeTab === 'overview');
const showUsers = computed(() => props.activeTab === 'users');
const showApplications = computed(() => props.activeTab === 'applications');
const showAccounts = computed(() => props.activeTab === 'accounts');

const isLoading = ref(false);
const router = useRouter();
const overviewStore = useOverviewStore();
const settingsStore = useSettingsStore();
const applicationStatsStore = useApplicationStatsStore();
const isEnabled = computed(() => settingsStore.isEnabled);

onMounted(async () => {
  isLoading.value = true;

  await Promise.all([
    overviewStore.fetchOverview(),
    settingsStore.fetchSettings(),
    applicationStatsStore.fetchApplicationStats(),
  ]);
  isLoading.value = false;
});

const tabListRouter = computed(() => [
  TabItem({
    key: 'overview',
    label: 'Overview',
    to: '/saas-management/overview',
  }),
  TabItem({
    key: 'applications',
    label: 'Applications',
    to: '/saas-management/applications#newly_discovered',
    disabled: !isEnabled.value,
  }),
  TabItem({
    key: 'accounts',
    label: 'Accounts',
    to: '/saas-management/accounts',
    disabled: !isEnabled.value,
  }),
  TabItem({
    key: 'users',
    label: 'Users',
    to: '/saas-management/users',
    disabled: !isEnabled.value,
  }),
]);

const handleNavigateToSettings = () => {
  router.push('/saas-management/settings');
};
</script>

<template>
  <div class="wrapper">
    <LoadingOverlay
      :data-test-id="$testId('loadingOverlay')"
      :visible="isLoading"
    />
    <div
      v-if="!isLoading"
      class="pageContainer"
    >
      <div class="tabsContainer">
        <Tabs
          :activeTab="activeTab"
          :data-test-id="$testId('tabsContainer')"
          :tabs="tabListRouter"
        />
        <IconButton
          class="settingsButton"
          hasNoBackground
          icon="settings"
          iconPosition="left"
          size="small"
          text="Settings"
          type="secondary"
          @click="handleNavigateToSettings"
        />
      </div>
      <div class="contentContainer">
        <SaasOverview v-if="showOverview" />
        <SaasUsersTable v-if="showUsers" />
        <SaasApplications v-if="showApplications" />
        <SaasAccounts v-if="showAccounts" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  @apply h-full;
}

.wrapper :deep() span[class^="InputMessage__message"]:has(span[class^="Icon__container"]) {
  @apply mb-4;
}

.wrapper :deep() span[class^="InputMessage__message"] span[class^="Icon__container"] {
  @apply pt-[3px];
}

.pageContainer {
  @apply flex flex-col h-full;
}

.settingsButton {
  color: var(--jcInformational);
}

.tabsContainer {
  @apply items-center border-b flex justify-between px-4 border-b-neutral h-12 shrink-0;
}

.tabsContainer :deep() li[class^='Tabs__tabItem'] {
  height: var(--jc-size-3);
  margin-right: var(--jc-spacer-small) !important;
  padding: 0 var(--jc-spacer);
}

.tabsContainer :deep() li[class^='Tabs__indicator'] {
  margin-bottom: var(--jc-size-negative-0p125);
}

.contentContainer {
  @apply bg-white flex flex-col flex-1 overflow-auto;
}
</style>
