<script setup>

import { ButtonLink, ParagraphBody } from '@jumpcloud/ui-components';
import {
  computed,
  onMounted,
  onUnmounted,
  ref,
} from 'vue';
import { debounce } from 'lodash';
import { useAppCatalogStore } from '@/stores/AppCatalog';
import { useLoadingState } from '@/util/useLoadingState';
import SaasAppIcon from '@/components/SaasAppIcon.vue';
import SaasSelect from '@/components/SaasSelect.vue';

const props = defineProps({
  selectedApp: {
    type: Object,
    default: null,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  closeOnSelect: {
    type: Boolean,
    default: true,
  },
  label: {
    type: String,
    default: 'Choose SaaS App',
  },
  placeholder: {
    type: String,
    default: 'Search for an app',
  },
  required: {
    type: Boolean,
    default: true,
  },
  isFullWidth: {
    type: Boolean,
    default: false,
  },
  hasMarginBottom: {
    type: Boolean,
    default: true,
  },
  discovered: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['selectApp', 'selectCustomApp']);

const LIMIT = 50;
const { runAsyncWithLoading } = useLoadingState();

const searchValue = ref('');
const appCatalogStore = useAppCatalogStore();
const catalogApplications = computed(() => appCatalogStore.appCatalog);

const fetchCatalogApplications = async () => {
  const fetchCatalogApplicationsCallback = async () => {
    await appCatalogStore.fetchAppCatalog({
      discovered: props.discovered,
      search: searchValue.value,
      limit: LIMIT,
    });
  };

  await runAsyncWithLoading(fetchCatalogApplicationsCallback);
};

const handleUpdateSearchValue = debounce(async (searchText, loading, isOpen) => {
  if (isOpen) {
    loading(true);
  }

  searchValue.value = searchText;
  await fetchCatalogApplications();
  loading(false);
}, 300);

const fetchNextPage = async () => {
  const currentAppCount = catalogApplications.value.apps.length;
  const totalCount = catalogApplications.value.count;
  const hasMore = currentAppCount < totalCount;
  const currentPage = Math.ceil(currentAppCount / LIMIT) - 1;

  if (!hasMore) {
    return;
  }

  await appCatalogStore.fetchAppCatalog({
    discovered: false,
    search: searchValue.value,
    page: currentPage + 1,
    limit: LIMIT,
  });
};

const hasNextPage = computed(() => {
  const currentAppCount = catalogApplications.value?.apps?.length;
  const totalCount = catalogApplications.value?.count;
  return currentAppCount < totalCount;
});

onMounted(() => {
  fetchCatalogApplications();
});

onUnmounted(() => {
  appCatalogStore.clearAppCatalog();
});

const noOptionsDescription = "If you can't find the app you're looking for you can manually add a custom app.";
const noOptionsButtonLabel = 'Add Custom App';

const handleAddCustomApp = () => {
  emit('selectCustomApp', searchValue.value);
};
</script>

<template>
  <div
    class="wrapper"
    :class="{ wrapperMarginBottom: hasMarginBottom }"
  >
    <div
      class="select"
      :class="{ selectMaxWidth: !isFullWidth }"
    >
      <SaasSelect
        :closeOnSelect="closeOnSelect"
        :hasNextPage="hasNextPage"
        :inputLabel="label"
        isInfinite
        :modelValue="selectedApp"
        :multiple="multiple"
        :noOptionsButtonLabel="noOptionsButtonLabel"
        :noOptionsDescription="noOptionsDescription"
        :onNextPage="fetchNextPage"
        :options="catalogApplications.apps?.map(app=>({label:app.name,value:app.id}))"
        :placeholder="placeholder"
        :required="required"
        @no-options-button-on-click="handleAddCustomApp"
        @search="handleUpdateSearchValue"
        @update:model-value="$emit('selectApp', $event)"
      >
        <template #option="{ option }">
          <div class="appIconContainer">
            <SaasAppIcon
              :appId="option.value"
              :name="option.label"
            />
            <span>{{ option.label }}</span>
          </div>
        </template>

        <template #selected-option="{ option }">
          <div class="appIconContainer">
            <SaasAppIcon
              :appId="option.value"
              :name="option.label"
            />
            <span>{{ option.label }}</span>
          </div>
        </template>

        <template
          v-if="catalogApplications.apps?.length"
          #footer
        >
          <ParagraphBody
            bodySize="tiny"
            class="footerDescription"
            :hasMargin="false"
          >
            {{ noOptionsDescription }}
          </ParagraphBody>
          <ButtonLink
            class="footerButton"
            text="Add Custom App"
            @click="handleAddCustomApp"
          />
        </template>
      </SaasSelect>
    </div>
  </div>
</template>

<style scoped>
.footerDescription {
  color: var(--jcGrey);
}

.footerButton {
  cursor: pointer;
  font-size: var(--jc-size-0p625);
}

.wrapper {
  display: flex;
}

.wrapperMarginBottom {
    margin-bottom: var(--jc-spacer);
}

.select {
  flex-grow: 1;
}

.selectMaxWidth {
  max-width: var(--jc-size-25);
}

.appIconContainer {
  @apply flex space-x-2
}
</style>
