import { type AppFilterParams, createAppFilterParams } from '@/util/api/createAppFilterParams';
import { type AxiosInstance, createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';
import type {
  AccessRestrictionType,
  ApplicationAccountItem,
  ApplicationItem,
  ApplicationStatus,
  User,
} from './shared/types';
import type { ApiFilterParams } from './shared/FilterParams';

export type Applications = {
  count: number;
  apps: ApplicationItem[],
  defaultAccessRestriction: AccessRestrictionType,
};

export type CreateAppPayload = { appName: string, description: string; domains: string[] } &
Pick<
ApplicationItem,
'accessRestriction' | 'alternativeButton' | 'alternativeLink' | 'appId' | 'customRestrictionMessage' | 'owner' | 'status'
>;

export type UpdateAppPayload = {
  appId: string;
  appInfo: {
    name: string;
    description: string;
    domains: { value: string }[];
  }
};

export type ReviewAppPayload = Pick<
ApplicationItem,
'accessRestriction'
| 'alternativeButton'
| 'alternativeLink'
| 'customRestrictionMessage'
| 'owner'
| 'status'
| 'restrictionExcludedGroups'
>;

export type ReviewAppStoreType = {
  selectedOwner?: User
  status: {
    selectedStatus: ApplicationStatus,
    selectedAccessRestriction: AccessRestrictionType,
    isDismissibleWarning: boolean,
    restrictionMessage: string,
    isOverrideDefaultMessage: boolean,
    isCheckedRecommendation: boolean,
    alternativeButtonValue: string,
    alternativeLinkValue: string,
    excludeUserGroupsFromAccessRestriction: boolean,
    excludedUserGroups: { value: string, name: string }[]
  }
};

export type GetAppAccountsReturnType = {
  count: number;
  accounts: ApplicationAccountItem[]
};

export type AppUsageType = { accountCount: number; atDay: string; };

class ApplicationsApi {
  private baseUrl: string;
  private axiosInstance: AxiosInstance;

  constructor() {
    this.baseUrl = '/api/v2/saas/apps';
    this.axiosInstance = createJcApiAxiosInstance();
  }

  async fetch(
    params?: Partial<ApiFilterParams> & {
      filter?: AppFilterParams
    },
  ): Promise<Applications> {
    const filter = params?.filter ? createAppFilterParams(params.filter) : {};
    const limit = params?.limit || 50;
    const skip = (params?.skip || 0);
    const sort = params?.sort ? [params.sort] : [];

    const response = await this.axiosInstance.get(this.baseUrl, {
      params: {
        filter,
        limit,
        skip,
        sort,
      },
    });
    return response?.data;
  }

  /**
   * FIX: Refactor it, handle logic inside component
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async createApp(app: any): Promise<string | undefined> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: any = {};
    payload.owner = app.selectedOwner;

    if (app.isCustomApp) {
      payload.appName = app.appInfo.name;
      payload.domains = app.appInfo.domains.map((domain: { value: string }) => domain.value);
      payload.description = app.appInfo.description;
    }
    if (!app.isCustomApp) {
      payload.appId = app.selectedApp;
    }

    const isUnapproved = app.status.selectedStatus === 'UNAPPROVED';
    const isWarningOrBlock = app.status.selectedAccessRestriction === 'WARNING'
    || app.status.selectedAccessRestriction === 'BLOCK';

    if (isUnapproved) {
      payload.accessRestriction = app.status.selectedAccessRestriction === 'WARNING'
      && app.status.isDismissibleWarning
        ? 'DISMISSIBLE_WARNING'
        : app.status.selectedAccessRestriction;

      if (isWarningOrBlock && app.status.isCheckedRecommendation) {
        payload.alternativeButton = app.status.alternativeButtonValue;
        payload.alternativeLink = app.status.alternativeLinkValue;
      }
      if (isWarningOrBlock && app.status.isOverrideDefaultMessage) {
        payload.customRestrictionMessage = app.status.restrictionMessage;
      }

      if (isWarningOrBlock && app.status.excludeUserGroupsFromAccessRestriction) {
        payload.restrictionExcludedGroups = (
          app.status.excludedUserGroups as { value: string, name: string }[]
        ).map(group => ({
          userGroupId: group.value,
          name: group.name,
        }));
      }
    }

    payload.status = app.status.selectedStatus;

    const response = await this.axiosInstance.post<Pick<ApplicationItem, 'appId'>>(this.baseUrl, payload);
    return response?.data.appId;
  }

  async getApp(appId: string): Promise<{ app: ApplicationItem }> {
    const response = await this.axiosInstance.get(`${this.baseUrl}/${appId}`);
    return response?.data;
  }

  async updateApp(appData: UpdateAppPayload) {
    const payload = {
      appName: appData.appInfo.name,
      domains: appData.appInfo.domains.map((domain) => domain.value),
      description: appData.appInfo.description,
    };

    const response = await this.axiosInstance.put(`${this.baseUrl}/${appData.appId}`, payload);
    return response;
  }

  async deleteApp(appId: string): Promise<{ id: string }> {
    const response = await this.axiosInstance.delete(`${this.baseUrl}/${appId}`);
    return response?.data;
  }

  async getAppAccounts(
    appId: string,
    params?: Partial<ApiFilterParams>,
  ): Promise<GetAppAccountsReturnType> {
    const response = await this.axiosInstance.get(`${this.baseUrl}/${appId}/accounts`, { params });
    return response?.data;
  }

  async getApplicationAccounts(id: string, params: Partial<ApiFilterParams>)
    : Promise<GetAppAccountsReturnType> {
    const paginationFiltersAndSort = {
      skip: params?.skip,
      limit: params?.limit,
      sort: params?.sort ? [params?.sort] : undefined,
    };

    const resp = await this.axiosInstance.get(`${this.baseUrl}/${id}/accounts`, { params: paginationFiltersAndSort });

    return resp?.data ?? {};
  }

  async updateAppLicense(appId: string, data: Pick<ApplicationItem, 'license'>) {
    const response = await this.axiosInstance.put<Pick<ApplicationItem, 'license'>>(`${this.baseUrl}/${appId}/license`, data);
    return response;
  }

  async deleteAppLicense(appId: string): Promise<void> {
    await this.axiosInstance.delete(`${this.baseUrl}/${appId}/license`);
  }

  async reviewApp({ id, payload }: { id: string, payload: ReviewAppPayload }) {
    const res = await this.axiosInstance.put(`${this.baseUrl}/${id}/review`, payload);
    return res;
  }

  async fetchAppUsage(
    { appId, dayCount }: { appId: string, dayCount: number },
  ): Promise<AppUsageType[]> {
    const response = await this.axiosInstance.post<
    { dayCount: number },
    { data: { data: AppUsageType[] } }
    >(`${this.baseUrl}/${appId}/usage`, { dayCount });

    if (!response) return [];

    const sortedUsageData = response.data.data
      .sort((d1, d2) => (new Date(d1.atDay) > new Date(d2.atDay) ? 1 : -1));

    return sortedUsageData;
  }

  async fetchAppUsageAtDay({ appId, atDay }: { appId: string, atDay: string }): Promise<{
    account: string;
    user: User;
  }[]
  > {
    const response = await this.axiosInstance.post(`${this.baseUrl}/${appId}/usage-at-day`, { atDay });
    return response?.data.data || [];
  }

  async export(args: AppFilterParams) {
    const filter = createAppFilterParams(args);
    const response = await this.axiosInstance.post(`${this.baseUrl}/export`, { filter }, { responseType: 'arraybuffer' });
    return response?.data;
  }
}

export default new ApplicationsApi();
