<script setup>
import { $testId } from '@/test/testIdHelper';
import {
  Button,
  ButtonGroup,
  ButtonSecondary,
  ButtonTertiary,
  HeadingMedium,
} from '@jumpcloud/ui-components';
import { computed, defineProps, ref } from 'vue';
import { useConnectorsStore } from '@/stores/Connectors';
import { useLoadingState } from '@/util/useLoadingState';
import { useRouter } from 'vue-router';
import AddNewConnectorBodyHeader from '@/pages/Settings/Connectors/AddNewConnector/AddNewConnectorBodyHeader.vue';
import ConnectorSelection from '@/pages/Settings/Connectors/AddNewConnector/ConnectorSelection.vue';
import FullPageModal from '@/components/FullPageModal.vue';

defineProps({
  showModal: Boolean,
});

const emit = defineEmits(['close']);

const router = useRouter();
const { isLoading, runAsyncWithLoading } = useLoadingState();
const currentStep = ref(0);
const selectedConnector = ref(null);
const connectorName = ref('');
const backButtonText = 'Back';
const connectorsStore = useConnectorsStore();

const isConnectButtonDisabled = computed(() => {
  switch (currentStep.value) {
    case 0:
      return !selectedConnector.value;
    case 1:
      return !connectorName.value;
    default:
      return true;
  }
});

const isBackButtonDisabled = computed(() => currentStep.value === 0 || isLoading.value);

const resetForm = () => {
  selectedConnector.value = null;
  connectorName.value = '';
};

const onClose = () => {
  currentStep.value = 0;
  resetForm();
  emit('close');
};

const onBack = () => {
  currentStep.value -= 1;
  resetForm();
};

const createConnector = async () => {
  const mappedConnectorPayload = {
    name: connectorName.value,
    type: selectedConnector.value.type,
    config: selectedConnector.value.config.map((config) => {
      if (config.type === 'select') {
        if (config.multiple) {
          return {
            key: config.id,
            value: config.value.map((item) => item.value),
          };
        }
        return {
          key: config.id,
          value: config.value.value,
        };
      }
      return {
        key: config.id,
        value: config.value,
      };
    }),
  };

  const response = await connectorsStore.createConnector(mappedConnectorPayload);

  if (response?.redirectUrl) {
    window.open(response.redirectUrl, '_self');
  } else if (response?.id) {
    await router.push(`/saas-management/settings/connectors/${response.id}`);
  }
};

const onConnect = async () => {
  await runAsyncWithLoading(createConnector);
};

const onConfigUpdate = (config) => {
  const newConfigObj = selectedConnector.value.config.map((item) => {
    if (item.id === config.id) {
      return config;
    }
    return item;
  });

  selectedConnector.value = {
    ...selectedConnector.value,
    config: newConfigObj,
  };
};
</script>

<template>
  <FullPageModal
    :showModal="showModal"
    @close="onClose"
  >
    <template v-slot:header>
      <HeadingMedium
        class="heading"
        :data-test-id="$testId('headerText')"
        :hasMargin="false"
      >
        Set Up a New Connector
      </HeadingMedium>
    </template>
    <template v-slot:body>
      <AddNewConnectorBodyHeader />
      <ConnectorSelection
        v-model:connector="selectedConnector"
        v-model:connectorName="connectorName"
        v-model:currentStep="currentStep"
        @update:config="onConfigUpdate"
      />
    </template>
    <template v-slot:footer>
      <ButtonGroup
        class="buttonGroup"
        :isFullWidth="false"
      >
        <ButtonTertiary
          :data-test-id="$testId('cancel')"
          :isDisabled="isLoading"
          text="Cancel"
          @click="onClose"
        />
        <ButtonSecondary
          v-if="currentStep !== 0"
          :data-test-id="$testId('back')"
          :isDisabled="isBackButtonDisabled"
          :text="backButtonText"
          @click="onBack"
        />
        <Button
          :data-test-id="$testId('connect')"
          :isDisabled="formValidationFailed || isConnectButtonDisabled"
          :isLoading="isLoading"
          text="Connect"
          @click="onConnect"
        />
      </ButtonGroup>
    </template>
  </FullPageModal>
</template>

<style scoped>
.heading {
  @apply font-semibold;
}

.buttonGroup {
  @apply w-full gap-2
}
</style>
