<script setup>
import { computed } from 'vue';
import { unapprovedSelectableColumnOptions } from '@/components/Applications/Unapproved/unapprovedConsts';
import { useSaasApplicationsTable } from '@/pages/Applications/useSaasApplicationsTable';
import SaasCommonTable from '@/components/SaasListTable/SaasCommonTable.vue';
import UnapprovedFilterContent from '@/components/Applications/Unapproved/UnapprovedFilterContent.vue';
import UnapprovedTableRow from '@/components/Applications/Unapproved/UnapprovedTableRow.vue';

const {
  appliedFilters,
  currentPageNumber,
  handleApplyFilters,
  handleClearAllFilters,
  handlePageChange,
  handleRemoveFilter,
  handleSelect,
  handleSelectAll,
  handleSortColumn,
  handleUpdateColumnOptions,
  indeterminateSelectAll,
  isLoading,
  rowsPerPage,
  searchValue,
  selectAll,
  selectedCategories,
  selectedColumnOptions,
  selectedDiscoveryDate,
  selectedLastUsed,
  selectedOwners,
  sortBy,
  sortDirection,
  tableData,
  totalCount,
  visibleColumns,
  defaultAccessRestriction,
  exportData,
} = useSaasApplicationsTable('UNAPPROVED');

const data = computed(() => tableData.value.map((item) => ({
  ...item,
  id: item.appId,
  defaultAccessRestriction: defaultAccessRestriction.value,
})));
</script>

<template>
  <SaasCommonTable
    v-model:searchValue="searchValue"
    :appliedFilters="appliedFilters"
    class="px-4"
    :columns="visibleColumns"
    :currentPageNumber="currentPageNumber"
    :data="data"
    hasAddManualAppButton
    :indeterminateSelectAll="indeterminateSelectAll"
    :isLoading="isLoading"
    :itemRowComponent="UnapprovedTableRow"
    :rowsPerPage="rowsPerPage"
    :selectAll="selectAll"
    :selectable="false"
    :selectableColumnOptions="unapprovedSelectableColumnOptions"
    :selectedColumnOptions="selectedColumnOptions"
    showPaginationControls
    :sortBy="sortBy"
    :sortDirection="sortDirection"
    :totalCount="totalCount"
    @apply-filters="handleApplyFilters"
    @clear-all-filters="handleClearAllFilters"
    @export-data="exportData"
    @handle-page-change="handlePageChange"
    @handle-select="handleSelect"
    @handle-select-all="handleSelectAll"
    @remove-filter="handleRemoveFilter"
    @sort-column="handleSortColumn"
    @update-column-options="handleUpdateColumnOptions"
  >
    <template v-slot:filterContent>
      <UnapprovedFilterContent
        v-model:selectedCategories="selectedCategories"
        v-model:selectedDiscoveryDate="selectedDiscoveryDate"
        v-model:selectedLastUsed="selectedLastUsed"
        v-model:selectedOwners="selectedOwners"
        :appliedFilters="appliedFilters"
      />
    </template>
  </SaasCommonTable>
</template>
