import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import ApplicationStatsApi from '@/api/ApplicationStats';
import type { ApplicationStats } from '@/api/ApplicationStats';

export const useApplicationStatsStore = defineStore('saas-application-stats', () => {
  const applicationStatsData = ref<ApplicationStats>({} as ApplicationStats);

  const fetchApplicationStats = async () => {
    try {
      applicationStatsData.value = await ApplicationStatsApi.fetch();
    } catch (error) {
      // do nothing
    }
  };

  const applicationStats = computed(() => applicationStatsData.value);

  return {
    applicationStats,
    fetchApplicationStats,
  };
});
