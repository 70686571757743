import { createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';

type ExcludedGroup = {
  userGroupId: string;
  name: string;
};

type AccessRestriction = 'NO_ACTION' | 'WARNING' | 'DISMISSIBLE_WARNING' | 'BLOCK';

export type Settings = {
  isEnabled: boolean,
  saasSettings: {
    trackedDomains: string[],
    excludedGroups: ExcludedGroup[],
    warningRestrictionMessage: string,
    blockedRestrictionMessage: string,
    accessRestriction: AccessRestriction,
    showOnUserPortal: boolean,
    useBrowserExtension: boolean
    restrictionExcludedGroups: ExcludedGroup[],
  },
  state: 'ENABLED' | 'DISABLED'
};

class SettingsApi {
  private baseUrl: string;
  private axiosInstance;

  constructor() {
    this.baseUrl = '/api/v2/saas/settings';
    this.axiosInstance = createJcApiAxiosInstance();
  }

  async fetch(): Promise<Settings> {
    const response = await this.axiosInstance.get<Settings>(this.baseUrl);
    return response?.data;
  }

  async update(settings: Settings): Promise<Settings> {
    const response = await this.axiosInstance.put<Settings>(this.baseUrl, settings);
    return response?.data;
  }
}

export default new SettingsApi();
