import { ref } from 'vue';

export function useLoadingState(initialValue = false) {
  const isLoading = ref(initialValue);

  const runAsyncWithLoading = async <T>(fn: () => Promise<T>): Promise<T | undefined> => {
    let response;
    try {
      isLoading.value = true;
      response = await fn();
    } catch (_error) {
      // do nothing with the error
    } finally {
      isLoading.value = false;
    }

    return response;
  };

  return {
    isLoading,
    runAsyncWithLoading,
  };
}
