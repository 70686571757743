<script setup>
import { $testId } from '@/test/testIdHelper';
import { IconButton } from '@jumpcloud/ui-components';
import { SaasConstants } from '@/util/Constants/SaasConstants';
import { ref } from 'vue';
import { useApplicationsStore } from '@/stores/Applications';
import { useLoadingState } from '@/util/useLoadingState';
import { useRouter } from 'vue-router';
import ManualApp from '@/components/ManualApp/ManualApp.vue';

const router = useRouter();
const applicationsStore = useApplicationsStore();

const isOpen = ref(false);
const onOpen = () => {
  isOpen.value = true;
};
const onClose = () => {
  isOpen.value = false;
};

const { isLoading, runAsyncWithLoading } = useLoadingState();
const onSave = async (appData) => {
  const nonReactiveAppData = JSON.parse(JSON.stringify(appData));
  const onSaveCallback = async () => {
    const newAppId = await applicationsStore.createApplication(nonReactiveAppData);

    router.push(`${SaasConstants.tabs.applications.route}/${newAppId}/overview`);
  };

  await runAsyncWithLoading(onSaveCallback);
};
</script>

<template>
  <div>
    <IconButton
      class="addAppButton"
      :data-test-id="$testId('addAppButton')"
      :hasNoBackground="false"
      :hasOutline="false"
      icon="add"
      iconPosition="left"
      :isDisabled="false"
      :isMatchText="false"
      text="Add App"
      type="primary"
      @click="onOpen"
    />
    <ManualApp
      :isLoading="isLoading"
      :isModalOpen="isOpen"
      mode="ADD"
      @close="onClose"
      @save="onSave"
    />
  </div>
</template>

<style scoped>
.addAppButton {
  flex-shrink: 0;
}
</style>
