import { Types } from '@jumpcloud/ui-components';

const { Column } = Types;
export const ignoredColumns = [
  Column({
    label: 'App', dataFieldName: 'appId', width: '2fr', sortable: true,
  }),
  Column({
    label: 'Accounts', dataFieldName: 'accountCount', width: '1fr', sortable: true,
  }),
  Column({
    label: 'Discovery Date', dataFieldName: 'discoveredAt', width: '1fr', sortable: true,
  }),
  Column({
    label: 'Last Used', dataFieldName: 'lastUsedAt', width: '1fr', sortable: true,
  }),
  Column({
    label: 'Actions', dataFieldName: 'actions', width: '1fr', alignment: 'center',
  }),
];

export const ignoredSelectableColumnOptions = [
  { name: 'App', value: 'appId', disabled: true },
  { name: 'Accounts', value: 'accountCount', disabled: false },
  { name: 'Discovery Date', value: 'discoveredAt', disabled: false },
  { name: 'Last Used', value: 'lastUsedAt', disabled: false },
  { name: 'Actions', value: 'actions', disabled: true },
];

export const ignoredSelectedColumnOptions = [
  { name: 'App', value: 'appId' },
  { name: 'Accounts', value: 'accountCount' },
  { name: 'Discovery Date', value: 'discoveredAt' },
  { name: 'Last Used', value: 'lastUsedAt' },
  { name: 'Actions', value: 'actions' },
];
