import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { useNotification } from '@/composables/useNotification';
import SettingsStatusApi from '@/api/SettingsStatusApi';
import type { SettingsStatus } from '@/api/SettingsStatusApi';

export const useSettingsStatusStore = defineStore('saas-settings-status', () => {
  const data = ref<SettingsStatus | null>(null);

  const {
    triggerErrorEnhancedNotification,
    triggerSuccessEnhancedNotification,
  } = useNotification();

  const updateSettingsStatus = async ({ isEnabled }: { isEnabled: boolean }) => {
    const status = isEnabled ? 'enabled' : 'disabled';
    try {
      data.value = await SettingsStatusApi.update({ isEnabled });
      triggerSuccessEnhancedNotification({
        title: 'SaaS Management Enabled',
        message: 'We are collecting SaaS data for your organization, which may take a few minutes.',
      });
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: `SaaS Management could not be ${status}.`,
        error,
      });
    }
  };

  const settingsStatus = computed(() => data.value);

  return {
    data,
    settingsStatus,
    updateSettingsStatus,
  };
});
