import { CollectionApi } from '@jumpcloud-ap/collection-api';

export type SettingsStatus = null;

class SettingsStatusApi extends CollectionApi<SettingsStatus> {
  constructor() {
    super('/api/v2/saas/settings/status', {});
  }

  async update({ isEnabled }: { isEnabled: boolean }): Promise<SettingsStatus> {
    const response = await this.axiosInstance.put<SettingsStatus>(this.baseUrl, { isEnabled });
    return response?.data;
  }
}

export default new SettingsStatusApi();
