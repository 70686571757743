<script setup>

</script>

<template>
  <div class="row" />
</template>

<style scoped>
.row {
  background-color: var(--jcGrey200);
  height: var(--jc-size-0p0625);
  margin-bottom: var(--jc-spacer-medium);
  width: 100%;
}
</style>
