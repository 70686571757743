<script setup>
import { allSelectableColumnOptions } from '@/components/Applications/All/allConsts';
import { useSaasApplicationsTable } from '@/pages/Applications/useSaasApplicationsTable';
import AllFilterContent from '@/components/Applications/All/AllFilterContent.vue';
import AllTableRow from '@/components/Applications/All/AllTableRow.vue';
import SaasCommonTable from '@/components/SaasListTable/SaasCommonTable.vue';

const {
  applicationsData,
  appliedFilters,
  currentPageNumber,
  handleApplyFilters,
  handleClearAllFilters,
  handlePageChange,
  handleRemoveFilter,
  handleSelect,
  handleSelectAll,
  handleSortColumn,
  handleUpdateColumnOptions,
  indeterminateSelectAll,
  isLoading,
  rowsPerPage,
  searchValue,
  selectAll,
  selectedCategories,
  selectedColumnOptions,
  selectedLastUsed,
  selectedOwners,
  selectedRenewal,
  selectedSsoConnections,
  sortBy,
  sortDirection,
  tableData,
  visibleColumns,
  selectedStatus,
  exportData,
} = useSaasApplicationsTable('');
</script>

<template>
  <SaasCommonTable
    v-model:searchValue="searchValue"
    :appliedFilters="appliedFilters"
    class="px-4"
    :columns="visibleColumns"
    :currentPageNumber="currentPageNumber"
    :data="tableData.map((item) => ({ ...item, id: item.appId }))"
    hasAddManualAppButton
    :indeterminateSelectAll="indeterminateSelectAll"
    :isLoading="isLoading"
    :itemRowComponent="AllTableRow"
    :rowsPerPage="rowsPerPage"
    :selectAll="selectAll"
    :selectable="false"
    :selectableColumnOptions="allSelectableColumnOptions"
    :selectedColumnOptions="selectedColumnOptions"
    showPaginationControls
    :sortBy="sortBy"
    :sortDirection="sortDirection"
    :totalCount="applicationsData.count"
    @apply-filters="handleApplyFilters"
    @clear-all-filters="handleClearAllFilters"
    @export-data="exportData"
    @handle-page-change="handlePageChange"
    @handle-select="handleSelect"
    @handle-select-all="handleSelectAll"
    @remove-filter="handleRemoveFilter"
    @sort-column="handleSortColumn"
    @update-column-options="handleUpdateColumnOptions"
  >
    <template v-slot:filterContent>
      <AllFilterContent
        v-model:selectedCategories="selectedCategories"
        v-model:selectedLastUsed="selectedLastUsed"
        v-model:selectedOwners="selectedOwners"
        v-model:selectedRenewal="selectedRenewal"
        v-model:selectedSsoConnections="selectedSsoConnections"
        v-model:selectedStatus="selectedStatus"
        :appliedFilters="appliedFilters"
      />
    </template>
  </SaasCommonTable>
</template>
