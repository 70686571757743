import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import UsersApi from '@/api/Users';
import type { ApiFilterParams } from '@/api/shared/FilterParams';
import type { UserAccountItem, UserItem } from '@/api/shared/types';
import type { UserUsageAtDayType, UserUsageType, Users } from '@/api/Users';

type FetchUserInfoPayload = {
  id: string,
  params: Partial<ApiFilterParams>
};

export const useUsersStore = defineStore('saas-users', () => {
  const usersData = ref<Users | undefined>();

  const fetchUsersList = async (params: Partial<ApiFilterParams>) => {
    usersData.value = await UsersApi.fetch(params);
  };

  const users = computed(() => usersData.value);

  const userDetailsData = ref<Record<string, UserItem>>({});
  const userAccountsData = ref<Record<string, UserAccountItem[]>>({});
  const userUsageData = ref<Record<string, UserUsageType[]>>({});
  const userUsageAtDay = ref<UserUsageAtDayType[]>([]);

  const fetchUserInfo = async ({ id, params }: FetchUserInfoPayload) => {
    const [userDetailsDataResponse, userAccountsDataResponse] = await Promise.all([
      UsersApi.getUser(id),
      UsersApi.getUserAccounts(id, params),
    ]);

    userDetailsData.value[id] = userDetailsDataResponse;
    userAccountsData.value[id] = userAccountsDataResponse;
  };

  const getUserDetailsById = computed(() => (id: string) => userDetailsData.value[id]);

  const getUserAccountsById = computed(() => (id :string) => userAccountsData.value[id]);

  const fetchUserUsageById = async ({ userId, dayCount }: { userId: string, dayCount: number }) => {
    const data = await UsersApi.getUserUsage(userId, dayCount);

    userUsageData.value[userId] = data;
  };

  const fetchUserUsageAtDay = async ({ userId, atDay }: { userId: string, atDay: string }) => {
    const data = await UsersApi.fetchUserUsageAtDay(userId, atDay);

    userUsageAtDay.value = data;
  };

  const removeUserUsageAtDay = async () => {
    userUsageAtDay.value = [];
  };

  const userUsageDataByUserId = computed(() => (userId: string) => userUsageData.value[userId]);

  const userUsageDataAtDay = computed(() => userUsageAtDay.value);

  return {
    users,
    fetchUsersList,
    fetchUserInfo,
    getUserDetailsById,
    getUserAccountsById,
    fetchUserUsageById,
    fetchUserUsageAtDay,
    removeUserUsageAtDay,
    userUsageDataByUserId,
    userUsageDataAtDay,
  };
});
