import '../index.css';
import '@jumpcloud/ui-components/css';
import { EventsPlugin } from '@jumpcloud/ui-events-vue3';
import { FeatureFlagMixin, initializeFlags } from './feature-flags';
import { TestIdMixin } from '@jumpcloud-ap/test-id';
import { addRootClasses } from '@jumpcloud/ui-components';
import { buildUiEventsOptions } from '@jumpcloud-ap/ui-events-setup';
import { createApp } from 'vue';
import { createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';
import { createPinia } from 'pinia';
import { logAppMounted } from '@jumpcloud-ap/logger';
import { router } from './router';
import { useCoreUserInfo } from '@jumpcloud-ap/core-user-info';
import App from './App.vue';
import store from '@/store';
import type { App as VueApp } from 'vue';

const pinia = createPinia();

let app: VueApp<Element> | null = null;

const rootEl = (): HTMLElement => document.getElementById('single-spa-application:@jumpcloud-ap/saas_app')!;

export const destroy = () => {
  if (app) {
    app.unmount();
    rootEl().innerHTML = '';

    app = null;
  }
};

export const initialize = async () => {
  if (app) {
    destroy();
  }

  addRootClasses(null);

  const coreInfo = useCoreUserInfo();
  const axiosInstance = createJcApiAxiosInstance();

  await (async () => {
    const settings = await fetch('/ui-settings.json').then(r => r.json());

    try {
      await initializeFlags({
        axiosInstance,
        identity: {
          id: coreInfo.isAnMsp.value
            ? coreInfo.providerId.value ?? coreInfo.orgId.value!
            : coreInfo.orgId.value!,

          name: coreInfo.name.value ?? '',
        },

        source: settings.launchDarklySource === 'localStorage'
          ? 'localStorage'
          : 'api',
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error: Unable to fetch feature flags. Unexpected behavior may occur.', e);
    }
  })();

  const el = document.createElement('div');
  el.style.display = 'contents';
  rootEl().appendChild(el);

  app = createApp(App);

  app.use(router);
  app.use(store);
  app.use(pinia);
  app.use(EventsPlugin, buildUiEventsOptions());
  app.mixin(TestIdMixin);
  app.mixin(FeatureFlagMixin);
  app.mount(el);

  coreInfo.cleanup();

  try {
    await logAppMounted(process.env.VITE_APP_NAME, process.env.VITE_VERSION);
  } catch {
    // empty
  }
};
