/* eslint-disable no-underscore-dangle */
import { getCurrentInstance } from 'vue';

export const $testId = (id: string) => {
  const componentName = getCurrentInstance()?.type.__name || getCurrentInstance()?.type.name;
  return `${componentName}__${id}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function findTestId(this: any, id: string) {
  let idPrefix = '';

  try {
    const componentName = this.vm.$options.__name || this.vm.$options.name;

    if (componentName) {
      idPrefix = `${componentName}__`;
    }
  } catch (err) {
    // If component does not have a name, we just won't have a prefix
  }

  try {
    const component = this.findComponent(`[data-test-id="${idPrefix}${id}"]`);

    if (component.exists()) {
      return component;
    }
  } catch {
    // empty
  }

  return this.find(`[data-test-id="${idPrefix}${id}"]`);
}
