<script setup>
import {
  ItemRow,
  ItemWithLabel,
  Link,
  ParagraphBody,
} from '@jumpcloud/ui-components';

import { computed } from 'vue';
import ConnectorStatusBadge from '@/components/ConnectorStatusBadge.vue';
import SaasAppIcon from '@/components/SaasAppIcon.vue';

import { $testId } from '@/test/testIdHelper';
import { SaasConstants } from '@/util/Constants/SaasConstants';

const props = defineProps({
  columns: {
    type: Array,
    default: () => [],
  },

  itemData: {
    required: true,
    type: Object,
  },
});

const connectorLink = computed(() => {
  const connectorsRoute = SaasConstants.tabs.settings.routes.connectors.route;
  const connectorId = props.itemData.id;

  return `${connectorsRoute}/${connectorId}`;
});
</script>

<template>
  <ItemRow
    v-bind="$attrs"
    class="connectorRow"
  >
    <ItemWithLabel
      v-for="(column, index) in columns"
      :key="index"
      class="columnData"
      :column="column"
      :data-test-id="$testId(`cell-${index}`)"
      :showLabel="false"
    >
      <template v-if="column.dataFieldName === 'name'">
        <ParagraphBody>
          <Link
            :text="itemData.name || missingText"
            :to="connectorLink"
          />
        </ParagraphBody>
      </template>
      <template v-else-if="column.dataFieldName === 'type'">
        <div class="typeContainer">
          <SaasAppIcon
            :appId="itemData.type"
            :name="itemData.connector.name"
            size="24px"
          />
          <ParagraphBody weight="semibold">
            {{ itemData.connector.name }}
          </ParagraphBody>
        </div>
      </template>
      <template v-else-if="column.dataFieldName === 'status'">
        <ConnectorStatusBadge :status="itemData.status" />
      </template>
      <template v-else>
        {{ itemData[column.dataFieldName] }}
      </template>
    </ItemWithLabel>
  </ItemRow>
</template>

<style scoped>
.connectorRow {
  @apply border-neutral last:border-b
}

.columnData {
  align-items: center;
  display: flex;
  min-width: var(--jc-size-10);
}

.typeContainer {
  @apply flex h-10 items-center space-x-2
}

</style>
