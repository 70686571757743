import { type AxiosInstance, createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';
import type { ApiFilterParams } from './shared/FilterParams';

export type AppCatalog = {
  count: number;
  apps: {
    id: string;
    name: string;
    description: string;
    domains: string[];
  }[]
};

class AppCatalogApi {
  private baseUrl: string;
  private axiosInstance: AxiosInstance;

  constructor() {
    this.baseUrl = '/api/v2/saas/catalog';
    this.axiosInstance = createJcApiAxiosInstance();
  }

  async fetch(
    params: Partial<ApiFilterParams> & {
      discovered?: boolean;
      search?: string;
    },
  ): Promise<AppCatalog> {
    const discoveredParam = `discovered:eq:${String(params.discovered || false)}`;
    const searchParam = params?.search ? `name:search:${params.search}` : false;
    const filter = [discoveredParam, searchParam].filter(Boolean);
    const limit = (params?.limit || 50);
    const skip = (params?.page || 0) * limit;

    const response = await this.axiosInstance.get(this.baseUrl, {
      params: {
        filter,
        skip,
        limit,
      },
    });
    return response?.data;
  }
}

export default new AppCatalogApi();
