<script setup>
import { computed, onMounted } from 'vue';
import { connectorsTableColumns } from '@/pages/Settings/Connectors/ConnectorsTable/connectorsTableConsts';
import { useConnectorsStore } from '@/stores/Connectors';
import { useLoadingState } from '@/util/useLoadingState';
import { useTableSortAndFilter } from '@/util/useTableSortAndFilter';
import ConnectorsTableRow from '@/pages/Settings/Connectors/ConnectorsTable/ConnectorsTableRow.vue';
import SaasCommonTable from '@/components/SaasListTable/SaasCommonTable.vue';

const {
  isLoading: isLoadingConnectors,
  runAsyncWithLoading: runAsyncWithLoadingConnectors,
} = useLoadingState(true);

const {
  isLoading: isLoadingMetaConnectors,
  runAsyncWithLoading: runAsyncWithLoadingMetaConnectors,
} = useLoadingState(true);

const isLoading = computed(
  () => isLoadingConnectors.value || isLoadingMetaConnectors.value);

const connectorsStore = useConnectorsStore();

const {
  fetchConnectors,
  fetchConnectorsMeta,
} = connectorsStore;

const connectorsList = computed(() => connectorsStore.connectorsList);
const connectorsMeta = computed(() => connectorsStore.connectorsMeta);

const mappedConnectors = computed(() => connectorsList.value.map((connector) => ({
  ...connector,
  connector: {
    type: connector.type,
    name: connectorsMeta.value
      .find((availableConnector) => availableConnector.type === connector.type)?.name || '',
  },
})) || []);

const fetchConnectorsData = async () => {
  const fetchConnectorsCallback = async () => {
    await fetchConnectors();
  };
  await runAsyncWithLoadingConnectors(fetchConnectorsCallback);
};

const fetchMetaConnectorsData = async () => {
  const fetchMetaConnectorsCallback = async () => {
    await fetchConnectorsMeta();
  };
  await runAsyncWithLoadingMetaConnectors(fetchMetaConnectorsCallback);
};

onMounted(async () => {
  Promise.all([fetchConnectorsData(), fetchMetaConnectorsData()]);
});

const {
  searchValue,
  sortBy,
  sortDirection,
  handleSortColumn,
  filteredAndSortedItems,
} = useTableSortAndFilter(mappedConnectors);

</script>

<template>
  <div class="connectorsContainer">
    <SaasCommonTable
      v-if="!isLoading"
      v-model:searchValue="searchValue"
      allowTableOverflow
      :columns="connectorsTableColumns"
      :data="filteredAndSortedItems || []"
      hasAddConnectorButton
      :isLoading="!!isLoading"
      :itemRowComponent="ConnectorsTableRow"
      :selectable="false"
      :showSearchFilterBar="false"
      showSearchBar
      :sortBy="sortBy"
      :sortDirection="sortDirection"
      @sort-column="handleSortColumn"
    />
  </div>
</template>

<style scoped>
.connectorsContainer {
  height: 100%;
  padding: var(--jc-size-0p375) var(--jc-size-1) var(--jc-size-3);
}
</style>
