<script setup>
import {
  Card,
  HeadingMedium,
  ParagraphBody,
  SeverityModal,
  Types,
} from '@jumpcloud/ui-components';
// import { ValidationObserver } from 'vee-validate';
import { isEqual } from 'lodash';
import {
  onMounted, reactive, ref, watch,
} from 'vue';
import { useLoadingState } from '@/util/useLoadingState';
import { useSaasFeatureTrial } from '@/util/useSaasFeatureTrial';
import { useSettingsStore } from '@/stores/Settings';
import DefaultActionSelectionCard from '@/pages/Settings/GeneralSettings/DefaultActionSelectionCard.vue';
import DefaultMessagingCard from '@/pages/Settings/GeneralSettings/DefaultMessagingCard.vue';
import DomainTrackingCard from '@/pages/Settings/GeneralSettings/DomainTrackingCard.vue';
import ExcludeFromRestrictionCard from '@/pages/Settings/GeneralSettings/ExcludeFromRestrictionCard.vue';
import SaasConfirmationModal from '@/components/SaasConfirmationModal.vue';
import SaasManagementDiscoveryMethods from '@/pages/Settings/GeneralSettings/SaasManagementDiscoveryMethods.vue';
import SaasManagementEnablementCard from '@/pages/Settings/GeneralSettings/SaasManagementEnablementCard.vue';
import SaasSettingsFooter from '@/pages/Settings/GeneralSettings/SaasSettingsFooter.vue';
import UserTrackingCard from '@/pages/Settings/GeneralSettings/UserTrackingCard.vue';

const { PrimaryButtonProps } = Types;

const settingsStore = useSettingsStore();

const isSaveDisabled = ref(true);
const showModal = ref(false);
const disableShowModal = ref(false);

const formData = reactive({
  isEnabled: false,
  domainTrackingType: 'all',
  trackedDomains: [],
  userTrackingType: 'all',
  excludedGroups: [],
  restrictionExcludedGroups: [],
  defaultAction: 'BLOCK',
  warningMessage: '',
  blockingMessage: '',
  allowDismissWarning: false,
  showOnUserPortal: false,
  useBrowserExtension: false,
  useDeviceAgent: false,
  isDirty: false,
});

const initialFormData = ({ ...formData });

watch(formData, () => {
  isSaveDisabled.value = isEqual(formData, initialFormData);
}, { deep: true });

const generateInitialFormData = () => {
  const { saasSettings } = settingsStore.settings;
  Object.assign(formData, {
    isEnabled: settingsStore.settings.isEnabled,
    domainTrackingType: saasSettings?.trackedDomains.length > 0 ? 'specific' : 'all',
    trackedDomains: saasSettings?.trackedDomains.map((domain) => ({
      value: domain,
      label: domain,
      disabled: false,
    })) || [],
    userTrackingType: saasSettings?.excludedGroups.length > 0 ? 'exclude' : 'all',
    excludedGroups: saasSettings?.excludedGroups.map((group) => ({
      value: group.userGroupId,
      name: group.name,
      disabled: false,
    })) || [],
    restrictionExcludedGroups: saasSettings?.restrictionExcludedGroups.map((group) => ({
      value: group.userGroupId,
      name: group.name,
      disabled: false,
    })) || [],
    defaultAction: saasSettings?.accessRestriction === 'DISMISSIBLE_WARNING'
      ? 'WARNING'
      : saasSettings?.accessRestriction,
    warningMessage: saasSettings?.warningRestrictionMessage,
    blockingMessage: saasSettings?.blockedRestrictionMessage,
    allowDismissWarning: saasSettings?.accessRestriction === 'DISMISSIBLE_WARNING' || false,
    showOnUserPortal: saasSettings?.showOnUserPortal,
    useBrowserExtension: saasSettings?.useBrowserExtension,
    useDeviceAgent: saasSettings?.useDeviceAgent,
  });

  Object.assign(initialFormData, { ...formData });
};

watch([settingsStore.settings], () => {
  generateInitialFormData();
});

onMounted(() => {
  generateInitialFormData();
});

const getPayloadFromFormData = () => ({
  isEnabled: formData.isEnabled,
  saasSettings: {
    trackedDomains: formData.domainTrackingType === 'all' ? [] : formData.trackedDomains.map((domain) => domain.value),
    excludedGroups: formData.userTrackingType === 'all' ? [] : formData.excludedGroups.map((group) => ({
      userGroupId: group.value,
      name: group.name,
    })),
    restrictionExcludedGroups: formData.restrictionExcludedGroups.map((group) => ({
      userGroupId: group.value,
      name: group.name,
    })),
    accessRestriction: formData.defaultAction === 'WARNING' && formData.allowDismissWarning
      ? 'DISMISSIBLE_WARNING'
      : formData.defaultAction,
    warningRestrictionMessage: formData.warningMessage,
    blockedRestrictionMessage: formData.blockingMessage,
    showOnUserPortal: formData.showOnUserPortal,
    useBrowserExtension: formData.useBrowserExtension,
    useDeviceAgent: formData.useDeviceAgent,
  },
});

const cancelDisable = () => {
  formData.isEnabled = true;
  disableShowModal.value = false;
};

const { refreshTrialData } = useSaasFeatureTrial();
const { isLoading: isSaving, runAsyncWithLoading: runAsyncWithSaving } = useLoadingState();

const onSave = async () => {
  const onSaveCallback = async () => {
    await settingsStore.updateSettings(getPayloadFromFormData());
  };

  await runAsyncWithSaving(onSaveCallback);

  refreshTrialData();

  generateInitialFormData();
};

const checkOnSaveForDisablingSaas = async () => {
  if (settingsStore.settings.isEnabled && !formData.isEnabled) {
    disableShowModal.value = true;
  } else {
    await onSave();
  }
};

const revertAllChanges = () => {
  generateInitialFormData();
  showModal.value = false;
};

const getPrimaryButtonProps = PrimaryButtonProps({
  hasOutline: true,
  text: 'Disable',
  type: 'error',
});

const disableSaasNotificationMessage = 'Disabling SaaS Management will permanently delete '
  + 'all of your activity history for this feature, and this action can\'t be undone.';

const disableSaasNotificationCheckboxMessage = 'I understand the consequences of disabling SaaS Management.';
</script>

<template>
  <div class="settingsPanelContainer">
    <div
      class="mainContainer"
    >
      <div class="innerContainer">
        <SaasManagementEnablementCard
          v-model:isSaasManagementBrowserExtensionEnabled="formData.useBrowserExtension"
          v-model:isSaasManagementDeviceAgentEnabled="formData.useDeviceAgent"
          v-model:isSaasManagementEnabled="formData.isEnabled"
          v-model:isSaasManagementUserPortalEnabled="formData.showOnUserPortal"
        />
        <SaasManagementDiscoveryMethods
          v-model:isSaasManagementBrowserExtensionEnabled="formData.useBrowserExtension"
          v-model:isSaasManagementDeviceAgentEnabled="formData.useDeviceAgent"
          :isSaasManagementEnabled="formData.isEnabled"
        />
        <Card>
          <template v-slot:body>
            <div class="cardHeader">
              <HeadingMedium>Browser Extension Discoverability</HeadingMedium>
            </div>
            <DomainTrackingCard
              v-model:domainTrackingType="formData.domainTrackingType"
              v-model:trackedDomains="formData.trackedDomains"
            />
            <UserTrackingCard
              v-model:excludedGroups="formData.excludedGroups"
              v-model:userTrackingType="formData.userTrackingType"
            />
          </template>
        </Card>
        <Card>
          <template v-slot:body>
            <div class="cardHeader">
              <HeadingMedium>Browser Access Restrictions</HeadingMedium>
            </div>

            <DefaultActionSelectionCard v-model:defaultAction="formData.defaultAction" />
            <DefaultMessagingCard
              v-model:allowDismissWarning="formData.allowDismissWarning"
              v-model:blockingMessage="formData.blockingMessage"
              v-model:warningMessage="formData.warningMessage"
            />
            <ExcludeFromRestrictionCard
              v-model:restrictionExcludedGroups="formData.restrictionExcludedGroups"
            />
          </template>
        </Card>
      </div>
    </div>
    <SaasSettingsFooter
      :isLoading="isSaving"
      :isSaveDisabled="isSaveDisabled"
      @cancel="showModal = true"
      @save="checkOnSaveForDisablingSaas"
    />
    <SaasConfirmationModal
      :showModal="showModal"
      title="Unsaved Changes"
      @cancel="showModal = false"
      @continue="revertAllChanges"
    />
    <SeverityModal
      v-if="disableShowModal"
      :checkboxLabel="disableSaasNotificationCheckboxMessage"
      hasBlur
      isMarkdown
      :notificationMessage="disableSaasNotificationMessage"
      notificationTitle="Are you sure?"
      :primaryButtonProps="getPrimaryButtonProps"
      showModal
      title="Disable SaaS Management"
      useCheckbox
      variant="sev3"
      @close="cancelDisable"
      @save="onSave"
    >
      <ParagraphBody>
        Any changes you have made will be deleted.
      </ParagraphBody>
      <ParagraphBody>
        Would you like to continue?
      </ParagraphBody>
    </SeverityModal>
  </div>
</template>

<style scoped>
.settingsPanelContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  overflow: hidden;
}

.mainContainer {
  background: var(--jc-background);
  flex: 1;
  justify-content: center;
  overflow: auto;
  padding: var(--jc-spacer-medium);
}

.innerContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--jc-spacer-medium);
  margin: auto;
  max-width: 1024px;
}

.cardHeader {
  padding-bottom: var(--jc-spacer);
}
</style>
