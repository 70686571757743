import { FeatureFlagsModule } from '@/feature-flags';
import { createCoreUserInfoModule } from '@jumpcloud-ap/core-user-info';
import { createNotificationModule, notificationModuleName } from '@jumpcloud-ap/notifications';
import { createRouteInfoModule } from '@jumpcloud-ap/route-info';
import { createStore } from 'vuex';
import { setupFeatureTrialModule } from '@jumpcloud-ap/feature-trials-vue3';
import { setupPlanModule } from '@jumpcloud-ap/plans';

const store = createStore({
  modules: {
    CoreUserInfoModule: createCoreUserInfoModule(),
    FeatureFlagsModule,
    RouteInfoModule: createRouteInfoModule(),
    [notificationModuleName]: createNotificationModule(),
  },
});

setupFeatureTrialModule(store);
setupPlanModule(store);

export default store;
