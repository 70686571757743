import { CollectionApi } from '@jumpcloud-ap/collection-api';

const getSpecialCharacterEscapedString = (exp) => {
  const specialCharactersRegex = /[-\\^$*+?.()|[\]{}]/g;
  const slashCharacterRegex = /[/]/g;

  return exp.replace(specialCharactersRegex, '\\$&').replace(slashCharacterRegex, '/$&');
};

const getDefaultParams = (options = { extraFields: [] }) => {
  const fields = [
    'company',
    'costCenter',
    'email',
    'firstname',
    'lastname',
    'username',
    'displayname',
    'department',
    'employeeIdentifier',
    'employeeType',
    'jobTitle',
    'location',
    'state',
    'systemUsername',
    'mfaEnrollment.overallStatus',
  ];

  if (options.extraFields?.length) {
    const defaultFieldsSet = new Set(fields);

    options.extraFields.forEach((extraField) => {
      if (!defaultFieldsSet.has(extraField)) fields.push(extraField);
    });
  }

  return {
    skip: 0,
    limit: 50,
    sort: 'lastname',
    search: {
      fields,
      searchTerm: '',
    },
  };
};

class SystemUsersApi extends CollectionApi {
  constructor(baseUrl = '/api/systemusers', defaultParams = getDefaultParams()) {
    super(baseUrl, defaultParams, 100);
  }

  async fetch(skip, limit, sort, searchTerm, cancelToken, pageToken, skipToken, filters = []) {
    // Only use the legacy search endpoint for free form searches (i.e. not for filters and not for
    // context searches - First name: Ally).

    if (searchTerm
      && filters.length === 0
      && this.getSearchField(searchTerm).field === undefined) {
      const params = {
        filter: {
          or: this.getOrSearchCriteria(searchTerm),
        },
      };
      const response = await this.axiosInstance.post('/api/search/systemusers', params);

      return response.data;
    }
    return super.fetch(skip, limit, sort, searchTerm, cancelToken, filters, pageToken, skipToken);
  }

  /**
   * @description Format search string of words into array
   * of search criteria for all default user fields in order to
   * search every field for every term entereded into search
   * populate params.filter.or.value
   */
  getOrSearchCriteria(searchTerm) {
    // Remove extraneous white space and return an array of words to search on. e.g.:
    // ' Term1   Term2 ' is mapped to ['Term1', 'Term2']

    const fixedSearchTerm = getSpecialCharacterEscapedString(searchTerm);
    const words = fixedSearchTerm.split(' ').filter(word => !!word);

    // Create the array of or criteria to filter on, one for each field and word in the search
    // term. e.g.:
    //
    // "email:$regex:/Term1/i",
    // "email:$regex:/Term2/i",
    // "firstname:$regex:/Term1/i",
    // "firstname:$regex:Term2/i",

    return this.defaultParams.search.fields
      .flatMap(field => words.map(word => `${field}:$regex:/${word}/i`));
  }
}

export default new SystemUsersApi();
