<script setup>
import {
  ButtonSecondary,
  HeadingMedium,
  ModalStructure,
  ParagraphBody,
} from '@jumpcloud/ui-components';
import { useApplicationsStore } from '@/stores/Applications';
import { useLoadingState } from '@/util/useLoadingState';
import { useRouter } from 'vue-router';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  isModalOpen: Boolean,
});

defineEmits(['close']);

const router = useRouter();
const applicationsStore = useApplicationsStore();

const customAppDeleteMsg = 'Deleting this custom app will permanently remove all associated data. This action cannot be undone.';
const catalogAppDeleteMsg = 'Deleting this catalog app will permanently remove all associated data. However, it will continue to show up in Newly Discovered with new logins/signups. You can avoid this by setting the app\'s status to Ignore in the Review modal.';

const modalBodyMsg = props.data.category.id === 'custom' ? customAppDeleteMsg : catalogAppDeleteMsg;
const { isLoading, runAsyncWithLoading } = useLoadingState();

const onSave = async () => {
  const { data: { appId } } = props;

  const onSaveCallback = async () => {
    await applicationsStore.deleteApplication({ id: appId });
    await router.push('/saas-management/applications#newly_discovered');
  };

  await runAsyncWithLoading(onSaveCallback);
};
</script>

<template>
  <ModalStructure
    footerAlignment="right"
    hasBlur
    shouldCloseOnBlur
    :showCloseButton="false"
    :showModal="isModalOpen"
    @close="$emit('close')"
  >
    <template v-slot:header>
      <HeadingMedium :hasMargin="false">
        Delete app
      </HeadingMedium>
    </template>
    <template v-slot:body>
      <ParagraphBody :hasMargin="false">
        {{ modalBodyMsg }}
      </ParagraphBody>
    </template>
    <template v-slot:footer>
      <div class="flex items-center gap-2">
        <ButtonSecondary
          text="Cancel"
          @click="$emit('close')"
        />
        <ButtonSecondary
          :hasOutline="false"
          :isLoading="isLoading"
          text="Delete App"
          type="error"
          @click="onSave"
        />
      </div>
    </template>
  </ModalStructure>
</template>

<style scoped>

</style>
