<script setup>
import { Constants, InputSearch } from '@jumpcloud/ui-components';
import { saasUsersColumns as columns } from '@/pages/Users/saasUsersConsts';
import { computed, onMounted, ref } from 'vue';
import { debounce, snakeCase } from 'lodash';
import { useLoadingState } from '@/util/useLoadingState';
import { useUsersStore } from '@/stores/Users';
import SaasCommonTable from '@/components/SaasListTable/SaasCommonTable.vue';
import SaasUsersTableRow from '@/pages/Users/SaasUsersTableRow.vue';

const { sortDirections } = Constants;
const defaultSortBy = 'userDisplayName';

const searchValue = ref('');
const usersStore = useUsersStore();
const usersData = computed(() => usersStore.users);
const { isLoading, runAsyncWithLoading } = useLoadingState(!usersData.value);
const rowsPerPage = ref(50);
const currentPageNumber = ref(0);
const sortBy = ref(defaultSortBy);
const sortDirection = ref(sortDirections.ascending);

const sortValue = computed(() => (sortDirection.value === sortDirections.ascending
  ? snakeCase(sortBy.value)
  : `-${snakeCase(sortBy.value)}`));

const fetchUsers = async () => {
  const fetchUsersCallback = async () => {
    await usersStore.fetchUsersList({
      search: searchValue.value,
      skip: currentPageNumber.value * rowsPerPage.value,
      limit: rowsPerPage.value,
      sort: sortValue.value,
    });
  };

  await runAsyncWithLoading(fetchUsersCallback);
};

onMounted(() => {
  fetchUsers();
});

const handlePageChange = ({
  currentPageNum: newCurrentPageNum,
  rowsPerPage: newRowsPerPage,
}) => {
  currentPageNumber.value = newCurrentPageNum;
  rowsPerPage.value = newRowsPerPage;
  fetchUsers();
};

const handleSortColumn = ({
  dataFieldName,
  sortDirection: newSortDirection,
}) => {
  sortBy.value = dataFieldName;
  sortDirection.value = newSortDirection;
  fetchUsers();
};

const handleUpdateSearchValue = debounce((searchTerm) => {
  searchValue.value = searchTerm;
  currentPageNumber.value = 0;
  fetchUsers();
}, 500);
</script>

<template>
  <div class="h-full">
    <SaasCommonTable
      class="px-4"
      :columns="columns"
      :currentPageNumber="currentPageNumber"
      :data="usersData?.users.map((item) => ({ ...item, id: item.userObjectId })) || []"
      hideFilters
      :isLoading="isLoading"
      :itemRowComponent="SaasUsersTableRow"
      :rowsPerPage="rowsPerPage"
      searchValue=""
      :selectable="false"
      showPaginationControls
      :sortBy="sortBy"
      :sortDirection="sortDirection"
      :totalCount="usersData?.count"
      @handle-page-change="handlePageChange"
      @sort-column="handleSortColumn"
    >
      <template #header>
        <div class="inputContainer">
          <InputSearch
            :modelValue="searchValue"
            placeholder="Search by name or email"
            @update:model-value="handleUpdateSearchValue"
          />
        </div>
      </template>
    </SaasCommonTable>
  </div>
</template>

<style scoped>
.inputContainer {
  max-width: 640px;
  padding: var(--jc-spacer) 0;
}
</style>
