<script setup>
import { $testId } from '@/test/testIdHelper';

import {
  ItemRow,
  ItemWithLabel,
  Link,
  ParagraphBody,
} from '@jumpcloud/ui-components';
import { SaasConstants } from '@/util/Constants/SaasConstants';
import { computed } from 'vue';
import { getDiffFromNowInDays } from '@/util/getDiffFromNowInDays';
import { missingText } from '@/util/Constants/missingText';

const props = defineProps({
  columns: {
    type: Array,
    default: () => [],
  },

  itemData: {
    required: true,
    type: Object,
  },
});

const userLink = computed(() => {
  const usersRoute = SaasConstants.tabs.users.route;
  const userId = props.itemData.userObjectId;

  return `${usersRoute}/${userId}`;
});
</script>

<template>
  <ItemRow
    v-bind="$attrs"
  >
    <ItemWithLabel
      v-for="(column, index) in columns"
      :key="column.dataFieldName"
      class="specialRowClass"
      :column="column"
      :data-test-id="$testId(`cell-${index}`)"
    >
      <template v-if="column.dataFieldName === 'userDisplayName'">
        <ParagraphBody class="paragraphContainer">
          <span>
            <Link
              :text="itemData.name || missingText"
              :to="userLink"
            />
          </span>
          <ParagraphBody bodySize="small">
            {{ itemData.email }}
          </ParagraphBody>
        </ParagraphBody>
      </template>
      <template v-else-if="column.dataFieldName === 'lastUsedAt'">
        <span :data-test-id="$testId(`cell-${index}-lastUsedAt`)">
          {{ getDiffFromNowInDays(itemData.lastUsedAt) }}
        </span>
      </template>
      <template v-else>
        {{ itemData[column.dataFieldName] }}
      </template>
    </ItemWithLabel>
  </ItemRow>
</template>

<style scoped>
.specialRowClass {
  align-items: center;
  display: flex;
}

.paragraphContainer {
  display: flex;
  flex-direction: column;
}
</style>
