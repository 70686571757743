import { Types } from '@jumpcloud/ui-components';

const { Column } = Types;
export const connectorsTableColumns = [
  Column({
    label: 'Name', dataFieldName: 'name', width: '2fr', sortable: true,
  }),
  Column({
    label: 'Connector', dataFieldName: 'type', width: '2fr', sortable: true,
  }),
  Column({
    label: 'Status', dataFieldName: 'status', width: '1fr', sortable: true,
  }),
];
