<script setup>
import {
  ButtonDismissibleChip,
  Checkbox,
  Combobox,
  HeadingExtraSmall,
  HeadingSmall,
  InputText,
  Link,
  Textarea,
} from '@jumpcloud/ui-components';
import { SaasConstants } from '@/util/Constants/SaasConstants';
import {
  computed,
  onMounted,
  ref,
  watch,
} from 'vue';
import { debounce } from 'lodash';
import { useSettingsStore } from '@/stores/Settings';
import { useUserGroupsStore } from '@/stores/UserGroups';
import ManualAppHR from '@/components/ManualApp/ManualAppHR.vue';
import SaasSelect from '@/components/SaasSelect.vue';
import SaasUserSelection from '@/components/SaasUserSelection.vue';

const accessRestrictionOptions = [
  { value: 'DEFAULT_ACTION', name: 'Default Action' },
  { value: 'NO_ACTION', name: 'Take No Action' },
  { value: 'WARNING', name: 'Show a Warning' },
  { value: 'BLOCK', name: 'Block This Application' },
];

const statusOptions = [
  { name: 'Approved', value: 'APPROVED' },
  { name: 'Unapproved', value: 'UNAPPROVED' },
  { name: 'Ignored', value: 'IGNORED' },
];

const props = defineProps({
  status: {
    type: Object,
    default: () => ({}),
    required: false,
  },

  owner: {
    type: Object,
    default: () => ({}),
    required: false,
  },
});

const emit = defineEmits(['updateStatus', 'selectOwner']);

const settingsStore = useSettingsStore();

const settingsData = computed(() => settingsStore.settings);

const {
  accessRestriction: settingsAccessRestriction,
  warningRestrictionMessage: settingsWarningRestrictionMessage,
  blockedRestrictionMessage: settingsBlockedRestrictionMessage,
} = settingsData.value.saasSettings;

const showDefaultActionByAccessRestrictionType = computed(() => {
  if (settingsAccessRestriction === 'DISMISSIBLE_WARNING' || settingsAccessRestriction === 'WARNING') {
    return 'Show a warning';
  }
  if (settingsAccessRestriction === 'BLOCK') {
    return 'Block this application';
  }
  return 'Take no action';
});

const showDefaultMessageByAccessRestrictionType = computed(() => {
  if (settingsAccessRestriction === 'DISMISSIBLE_WARNING' || settingsAccessRestriction === 'WARNING') {
    return settingsWarningRestrictionMessage;
  }
  if (settingsAccessRestriction === 'BLOCK') {
    return settingsBlockedRestrictionMessage;
  }
  return '';
});

const selectedStatus = ref(statusOptions
  .find(option => option.value === props.status?.selectedStatus));
const selectedAccessRestriction = ref(accessRestrictionOptions.find(option => option.value === props.status?.selectedAccessRestriction) || { value: 'DEFAULT_ACTION', name: 'Default Action' });
const alternativeButtonValue = ref(props.status?.alternativeButtonValue || '');
const alternativeLinkValue = ref(props.status?.alternativeLinkValue || '');
const isCheckedRecommendation = ref(props.status?.isCheckedRecommendation || false);
const isDismissibleWarning = ref(props.status?.isDismissibleWarning || false);
const isOverrideDefaultMessage = ref(props.status?.isOverrideDefaultMessage || false);
const restrictionMessage = ref(props.status?.restrictionMessage || '');
const excludeUserGroupsFromAccessRestriction = ref(
  props.status?.excludeUserGroupsFromAccessRestriction || false,
);
const excludedUserGroups = ref(props.status?.excludedUserGroups || []);

watch([
  selectedStatus,
  selectedAccessRestriction,
  alternativeButtonValue,
  alternativeLinkValue,
  isCheckedRecommendation,
  isDismissibleWarning,
  isOverrideDefaultMessage,
  restrictionMessage,
  excludeUserGroupsFromAccessRestriction,
  excludedUserGroups,
],
([
  newSelectedStatus,
  newSelectedAccessRestriction,
  newAlternativeButtonValue,
  newAlternativeLinkValue,
  newIsCheckedRecommendation,
  newIsDismissibleWarning,
  newIsOverrideDefaultMessage,
  newRestrictionMessage,
  newExcludeUserGroupsFromAccessRestriction,
  newExcludedUserGroups,
]) => {
  emit('updateStatus', {
    // TODO: Get rid of .value
    selectedStatus: newSelectedStatus?.value,
    selectedAccessRestriction: newSelectedAccessRestriction.value,
    alternativeButtonValue: newAlternativeButtonValue,
    alternativeLinkValue: newAlternativeLinkValue,
    isCheckedRecommendation: newIsCheckedRecommendation,
    isDismissibleWarning: newIsDismissibleWarning,
    isOverrideDefaultMessage: newIsOverrideDefaultMessage,
    restrictionMessage: newRestrictionMessage,
    excludeUserGroupsFromAccessRestriction: newExcludeUserGroupsFromAccessRestriction,
    excludedUserGroups: newExcludedUserGroups,
  });
});

const selectedOwnerOptions = ref(props.owner);
watch(selectedOwnerOptions, (newSelectedOwnerOptions) => {
  emit('selectOwner', newSelectedOwnerOptions);
});

const overrideDefaultMessage = computed(() => `Override default ${selectedAccessRestriction.value.value === 'WARNING' ? 'warning' : 'block'} message`);
const showExcludeFromAccessRestriction = computed(() => selectedAccessRestriction.value.value === 'WARNING' || selectedAccessRestriction.value.value === 'BLOCK');
const excludeFromAccessRestrictionMessage = computed(() => `Exclude user groups from the ${selectedAccessRestriction.value.value === 'WARNING' ? 'Warn' : 'Block'} App restriction`);

const userGroupsStore = useUserGroupsStore();
const userGroupsData = ref({ groups: [], totalCount: 0 });
const userGroupsLoading = ref(false);
const fetchUserGroups = async (searchTerm) => {
  userGroupsLoading.value = true;
  userGroupsData.value = await userGroupsStore.fetchUserGroups(searchTerm);
  userGroupsLoading.value = false;
};
const handleSearchUpdated = debounce(fetchUserGroups, 300);
const handleSelectionsUpdated = (selections) => {
  excludedUserGroups.value = selections.newSelectedOptions
    .map(opt => ({ disabled: false, ...opt }));
};
const handleSelectionsRemove = (selection) => {
  excludedUserGroups.value = excludedUserGroups.value.filter(
    (option) => option.value !== selection.value,
  );
};
onMounted(() => {
  fetchUserGroups();
});

</script>

<template>
  <div>
    <div class="statusWrapper">
      <SaasSelect
        inputLabel="Status"
        label="name"
        :modelValue="selectedStatus"
        :options="statusOptions"
        placeholder="Select status"
        required
        :searchable="false"
        @update:model-value="selectedStatus = $event"
      />
    </div>

    <div class="ownerWrapper">
      <SaasUserSelection
        v-model:selectedOwnerOptions="selectedOwnerOptions"
        :required="selectedStatus?.value !== 'IGNORED'"
      />
    </div>

    <div
      v-if="selectedStatus?.value === 'UNAPPROVED'"
      class="mt-6"
    >
      <ManualAppHR />
      <HeadingSmall
        weight="semibold"
      >
        Access Restriction
      </HeadingSmall>
      <div class="saasAppReviewSelect">
        <SaasSelect
          :clearable="false"
          label="name"
          :modelValue="selectedAccessRestriction"
          :options="accessRestrictionOptions"
          placeholder="Select Access Restriction"
          :searchable="false"
          @update:model-value="selectedAccessRestriction = $event"
        />
      </div>
      <div
        v-if="selectedAccessRestriction.value === 'DEFAULT_ACTION'"
        class="accessRestrictionCardContainer"
      >
        <div class="accessRestrictionMessageBody">
          <div class="accessRestrictionTypeContainer">
            <HeadingExtraSmall
              class="fontSizeSmall"
              :hasMargin="false"
              weight="semibold"
            >
              Default action
            </HeadingExtraSmall>
            <div class="accessRestrictionTypeDetail">
              <div class="fontSizeSmall">
                {{ showDefaultActionByAccessRestrictionType }}
              </div>
              <template
                v-if="settingsAccessRestriction === 'WARNING' ||
                  settingsAccessRestriction === 'DISMISSIBLE_WARNING'"
              >
                <div class="pipe">
                  |
                </div>
                <div class="warnTypeDetail">
                  Message
                  {{ settingsAccessRestriction === 'WARNING' ? 'cannot' : 'can' }}
                  be dismissed
                </div>
              </template>
            </div>
          </div>
          <div
            v-if="settingsAccessRestriction === 'WARNING' ||
              settingsAccessRestriction === 'DISMISSIBLE_WARNING' ||
              settingsAccessRestriction === 'BLOCK'"
            class="accessRestrictionMessageContainer"
          >
            <HeadingExtraSmall
              class="fontSizeSmall"
              :hasMargin="false"
              weight="semibold"
            >
              {{ settingsAccessRestriction === 'BLOCK' ? 'Block' : 'Warn' }} message
            </HeadingExtraSmall>
            <div class="fontSizeSmall">
              {{ showDefaultMessageByAccessRestrictionType }}
            </div>
          </div>
        </div>
        <div class="accessRestrictionMessageFooter">
          <span class="fontSizeSmall">Change your default action on
            <Link
              :href="SaasConstants.tabs.settings.route"
              target="_blank"
              text="settings."
            />
          </span>
        </div>
      </div>
      <div v-else>
        <div
          v-if="
            selectedAccessRestriction.value === 'WARNING' ||
              selectedAccessRestriction.value === 'BLOCK'
          "
        >
          <div class="saasAppReviewCheckBoxContainer">
            <Checkbox
              :label="overrideDefaultMessage"
              :modelValue="isOverrideDefaultMessage"
              @update:model-value="isOverrideDefaultMessage = $event"
            />
          </div>
          <div
            v-if="isOverrideDefaultMessage"
            class="saasAppReviewRecommendedInputsContainer"
          >
            <Textarea
              v-model="restrictionMessage"
              fitContent
              :isDisabled="false"
              :label="
                `${selectedAccessRestriction.value === 'WARNING' ? 'Warning' : 'Block'} Message`
              "
              :name="
                `${selectedAccessRestriction.value === 'WARNING' ? 'Warning' : 'Block'} Message`
              "
              :placeholder="`Enter ${
                selectedAccessRestriction.value === 'WARNING' ? 'warning' : 'block'
              } message`"
              :required="false"
              rules="max:320"
              showValidationMessage
            />
          </div>
          <div class="saasAppReviewCheckBoxContainer">
            <Checkbox
              label="Recommend an alternate app or resource"
              :modelValue="isCheckedRecommendation"
              @update:model-value="isCheckedRecommendation = $event"
            />
          </div>
          <div
            v-if="isCheckedRecommendation"
            class="saasAppReviewRecommendedInputsContainer alternativeWrapper"
          >
            <InputText
              label="Link label"
              :modelValue="alternativeButtonValue"
              name="Link label"
              placeholder="Enter a link label"
              required
              @update:model-value="alternativeButtonValue = $event"
            />
            <InputText
              class="saasAppReviewFlexGrow"
              label="URL"
              :modelValue="alternativeLinkValue"
              name="URL"
              placeholder="Enter a URL"
              required
              @update:model-value="alternativeLinkValue = $event"
            />
          </div>
          <div
            v-if="selectedAccessRestriction.value === 'WARNING'"
            class="saasAppReviewCheckBoxContainer"
          >
            <Checkbox
              label="Allow users to dismiss this warning"
              :modelValue="isDismissibleWarning"
              @update:model-value="isDismissibleWarning = $event"
            />
          </div>
          <div
            v-if="showExcludeFromAccessRestriction"
            class="saasAppReviewCheckBoxContainer"
          >
            <Checkbox
              v-model="excludeUserGroupsFromAccessRestriction"
              :label="excludeFromAccessRestrictionMessage"
            />
          </div>
          <div
            v-if="excludeUserGroupsFromAccessRestriction"
            class="selectionContainer"
          >
            <div class="extraSmallHeading">
              <HeadingExtraSmall>
                User Groups to Exclude ({{ excludedUserGroups.length }})
              </HeadingExtraSmall>
            </div>
            <div class="mt-1 max-w-[600px]">
              <Combobox
                multiselect
                :options="userGroupsData.groups"
                :optionsLoading="userGroupsLoading"
                :optionsTotalCount="userGroupsData.totalCount"
                placeholder="Search"
                :selectedOptions="excludedUserGroups"
                :showSelectAll="false"
                @search-updated="handleSearchUpdated"
                @selections-updated="handleSelectionsUpdated"
              />
            </div>
            <div
              v-if="excludedUserGroups.length > 0"
              class="dismissibleChipsContainer"
            >
              <ButtonDismissibleChip
                v-for="selected in excludedUserGroups"
                :key="selected.value"
                :text="selected.name"
                textCase="inherit"
                @click="handleSelectionsRemove(selected)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.statusWrapper {
  margin-bottom: var(--jc-size-1);
  max-width: var(--jc-size-15);
}

.ownerWrapper {
  max-width: var(--jc-size-25);
}

.saasAppReviewSelect {
  margin-bottom: var(--jc-spacer);
  max-width: var(--jc-size-15);
}

.saasAppReviewCheckBoxContainer {
  display: flex;
  gap: var(--jc-spacer-small);
  margin-bottom: var(--jc-spacer-x-small);
  padding: 6px 0;
}

.checkboxText {
  margin-left: var(--jc-size-negative-0p25) !important;
}

.saasAppReviewRecommendedInputsContainer {
  margin-left: var(--jc-spacer-medium);
  margin-top: var(--jc-spacer-negative-x-small);
}

.alternativeWrapper {
  display: grid;
  gap: var(--jc-spacer);
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 640px) {
  .alternativeWrapper {
    gap: 0;
    grid-template-columns: 1fr;
  }
}

.saasAppReviewFlexGrow {
  flex: 1;
}

.accessRestrictionCardContainer {
  align-items: flex-start;
  background: var(--jc-background-light);
  border: var(--jc-border);
  border-radius: var(--jc-border-radius-small);
  display: flex;
  flex: 1 0 0;
  flex-wrap: wrap;
  padding: var(--jc-size-1);
}

.accessRestrictionMessageContainer {
  display: flex;
  flex-direction: column;
}

.accessRestrictionMessageBody {
  display: grid;
  gap: var(--jc-spacer-large);
  grid-template-columns: auto 1fr;
  margin-bottom: var(--jc-spacer-small);
  width: 100%
}

@media (max-width: 640px) {
  .accessRestrictionMessageBody {
    gap: var(--jc-spacer);
    grid-template-columns: 1fr;
  }
}

.accessRestrictionTypeContainer {
  flex-shrink: 0;
}

.accessRestrictionMessageFooter {
  border-top: var(--jc-border);
  padding-top: var(--jc-spacer-small);
  width: 100%;
}

.accessRestrictionTypeDetail {
  display: flex;
  flex-direction: row;
  font-size: var(--jcBodySmall) !important;
  gap: var(--jc-input-padding-side);
}

.pipe {
  color: var(--jc-color-neutral-stroke);
}

.warnTypeDetail {
  color: var(--jc-text-color-light);
  line-height: var(--jc-size-1p125) !important;
}

.fontSizeSmall {
  font-size: var(--jcBodySmall) !important;
  line-height: var(--jc-size-1p125) !important;
}

.inputHeader {
  margin-bottom: var(--jc-spacer-x-small) !important;
}

.selectionContainer {
  display: flex;
  flex-direction: column;
}

.selectionContainer :deep() footer {
  background-color: transparent !important;
  justify-content: flex-start;
  padding-top: var(--jc-spacer) !important;
}

.dismissibleChipsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--jc-spacer-x-small);
  margin-top: var(--jc-spacer-x-small);
}

.extraSmallHeading {
  margin-bottom: var(--jc-spacer-negative-x-small);
  margin-top: var(--jc-spacer-small);
  padding-bottom: var(--jc-spacer-small);
}

</style>
