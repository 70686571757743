<script setup>
import { $testId } from '@/test/testIdHelper';
import { Tooltip } from '@jumpcloud/ui-components';
import GoogleSvg from '@/img/google.svg?url';
import JumpCloudSvg from '@/img/jumpcloud.svg?url';
import MicrosoftSvg from '@/img/microsoft.svg?url';
import PasswordSvg from '@/img/password.svg?url';

const props = defineProps({
  loginMethod: {
    type: String,
    required: true,
  },
});

const loginMethodTooltip = {
  APPLICATION: 'Username & Password Login',
  SSO: 'JumpCloud SSO Login',
  SSO_GOOGLE: 'Google SSO Login',
  SSO_MICROSOFT: 'Microsoft SSO Login',
}[props.loginMethod];

const loginMethodIcon = {
  APPLICATION: PasswordSvg,
  SSO: JumpCloudSvg,
  SSO_GOOGLE: GoogleSvg,
  SSO_MICROSOFT: MicrosoftSvg,
}[props.loginMethod];
</script>

<template>
  <Tooltip
    class="tooltip"
    :text="loginMethodTooltip"
  >
    <img
      :alt="loginMethod"
      class="img"
      :data-test-id="$testId('icon')"
      :src="loginMethodIcon"
    >
  </Tooltip>
</template>

<style scoped>
.tooltip {
  display: flex;
}

.img {
  @apply size-6;
}
</style>
