<script setup>
import { $testId } from '@/test/testIdHelper';
import {
  ButtonDismissibleChip,
  Card,
  Combobox,
  HeadingExtraSmall,
  HeadingSmall,
  ParagraphBody,
  RadioGroup,
} from '@jumpcloud/ui-components';
import { debounce } from 'lodash';
import {
  defineEmits,
  defineProps,
  onMounted,
  ref,
} from 'vue';
import { useUserGroupsStore } from '@/stores/UserGroups';

const props = defineProps({
  excludedGroups: {
    type: Array,
    default: () => [],
  },
  userTrackingType: {
    type: String,
    default: 'all',
  },
});

const userGroupsStore = useUserGroupsStore();

const emit = defineEmits(['update:excludedGroups', 'update:userTrackingType']);

const userTrackingTypeOptions = [
  { value: 'all', label: '**All Users**', isMarkdown: true },
  { value: 'exclude', label: '**Exclude Specified User Groups**', isMarkdown: true },
];

const userGroupsData = ref({ groups: [], totalCount: 0 });

const userGroupsLoading = ref(false);

const fetchUserGroups = async (searchTerm) => {
  userGroupsLoading.value = true;
  userGroupsData.value = await userGroupsStore.fetchUserGroups(searchTerm);
  userGroupsLoading.value = false;
};

const handleSearchUpdated = debounce(fetchUserGroups, 300);

onMounted(() => {
  fetchUserGroups();
});

const handleSelectionsUpdated = (selections) => {
  const selectedGroups = selections.newSelectedOptions.map(opt => ({ ...opt, disabled: false }));
  emit('update:excludedGroups', selectedGroups);
};

const handleSelectionsRemove = (selection) => {
  const selectedGroups = props.excludedGroups
    .filter((option) => option.value !== selection.value);
  emit('update:excludedGroups', selectedGroups);
};
</script>

<template>
  <Card>
    <template v-slot:body>
      <div class="cardHeader">
        <HeadingSmall>User Tracking</HeadingSmall>
      </div>
      <div class="radioGroupContainer">
        <RadioGroup
          :modelValue="userTrackingType"
          name="user-radiogroup"
          :options="userTrackingTypeOptions"
          :showValidationMessage="false"
          @update:model-value="$emit('update:userTrackingType', $event)"
        >
          <template v-slot:itemDescription-all>
            <div class="itemDescription">
              <ParagraphBody>
                All users will be discovered and tracked.
              </ParagraphBody>
            </div>
          </template>
          <template v-slot:itemDescription-exclude>
            <div class="itemDescription">
              <ParagraphBody>
                Exclude groups of users from tracking.
              </ParagraphBody>
            </div>
          </template>
        </RadioGroup>
      </div>
      <div
        v-if="userTrackingType === 'exclude'"
        class="selectionContainer"
        :data-test-id="$testId('excludedUsers')"
      >
        <div class="extraSmallHeading">
          <HeadingExtraSmall>
            User Groups to Exclude ({{ excludedGroups.length }})
          </HeadingExtraSmall>
        </div>

        <div class="mt-1 max-w-[600px]">
          <Combobox
            multiselect
            :options="userGroupsData.groups"
            :optionsLoading="userGroupsLoading"
            :optionsTotalCount="userGroupsData.totalCount"
            placeholder="Search"
            :selectedOptions="excludedGroups"
            :showSelectAll="false"
            @search-updated="handleSearchUpdated"
            @selections-updated="handleSelectionsUpdated"
          />
        </div>
        <div
          v-if="excludedGroups.length > 0"
          class="dismissibleChipsContainer"
        >
          <ButtonDismissibleChip
            v-for="selected in excludedGroups"
            :key="selected.value"
            :text="selected.name"
            textCase="inherit"
            @click="handleSelectionsRemove(selected)"
          />
        </div>
      </div>
    </template>
  </Card>
</template>

<style scoped>

.cardHeader {
  padding-bottom: var(--jc-spacer-small);
}

.itemDescription {
  color: var(--jc-text-color-light);
  margin-top: var(--jc-spacer-x-small);
}

.radioGroupContainer {
  margin-bottom: var(--jc-spacer-negative-medium) !important;
  margin-top: var(--jc-spacer-small);
  padding-bottom: var(--jc-spacer-small);
}

.selectionContainer {
  display: flex;
  flex-direction: column;
  margin-left: var(--jc-spacer-medium);
  margin-top: var(--jc-spacer-small);
}

.dismissibleChipsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--jc-spacer-x-small);
  margin-top: var(--jc-spacer-x-small);
}

.extraSmallHeading {
  margin-bottom: var(--jc-spacer-negative-x-small);
  margin-top: var(--jc-spacer-small);
  padding-bottom: var(--jc-spacer-small);
}
</style>
