<script setup>
import { IconButton, InputText } from '@jumpcloud/ui-components';
import { ref } from 'vue';

const props = defineProps({
  config: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:config']);

const buttonIcon = ref('viewableOff');

const toggleVisibility = (event) => {
  event.preventDefault();
  buttonIcon.value = buttonIcon.value === 'viewable' ? 'viewableOff' : 'viewable';
};

const handleUpdateSecretInput = (value) => {
  emit('update:config', { ...props.config, value });
};
</script>

<template>
  <InputText
    v-if="!config.isSecret"
    :isDisabled="config.disabled || config.readonly"
    :label="config.name"
    :modelValue="config.value"
    :placeholder="config.placeholder"
    :required="config.required"
    :showValidationMessage="false"
    @update:model-value="$emit('update:config', { ...config, value: $event })"
  />
  <div
    v-else
    class="secretInputContainer"
  >
    <InputText
      class="secretInputText"
      :class="{ 'secretInput': buttonIcon === 'viewableOff' && config.value }"
      :isDisabled="config.disabled || config.readonly"
      :label="config.name"
      :modelValue="config.value"
      :placeholder="config.placeholder"
      :required="config.required"
      :showValidationMessage="false"
      @update:model-value="handleUpdateSecretInput"
    />
    <IconButton
      aria-label="toggle password visibility"
      :autoBlur="false"
      class="iconButton"
      hasNoBackground
      :icon="buttonIcon"
      @click="toggleVisibility"
    />
  </div>
</template>

<style scoped>
@font-face {
  font-family: 'password';
  src: url('../../fonts/password.ttf') format('truetype');
}

.iconButton {
  @apply !mt-5
}

.secretInputText {
  @apply flex-1
}

.secretInput :deep() input {
  font-family: 'password', -apple-system, "system-ui", "Segoe UI", roboto, helvetica,
  arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
}

.secretInputContainer {
  @apply flex w-full items-center gap-2
}
</style>
