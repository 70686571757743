import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import AppCatalogApi from '@/api/AppCatalog';
import type { ApiFilterParams } from '@/api/shared/FilterParams';
import type { AppCatalog } from '@/api/AppCatalog';

export const useAppCatalogStore = defineStore('saas-app-catalog', () => {
  const catalogData = ref<AppCatalog>({} as AppCatalog);

  const fetchAppCatalog = async (params: Partial<ApiFilterParams> & {
    discovered?: boolean;
    search?: string;
  }) => {
    catalogData.value = await AppCatalogApi.fetch(params);
  };

  const clearAppCatalog = () => {
    catalogData.value = {} as AppCatalog;
  };

  const appCatalog = computed(() => catalogData.value);

  return {
    appCatalog,
    fetchAppCatalog,
    clearAppCatalog,
  };
});
