<script setup>
import {
  ButtonSecondary,
  NotificationInPage,
  SeverityModal,
  Types,
} from '@jumpcloud/ui-components';
import { ref } from 'vue';
import { useConnectorsStore } from '@/stores/Connectors';
import { useLoadingState } from '@/util/useLoadingState';

const { NotificationConfig, PrimaryButtonProps } = Types;

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },

  onDeleteRedirect: {
    type: Function,
    required: true,
  },
});

const isModalOpen = ref(false);
const onModalOpen = () => {
  isModalOpen.value = true;
};
const onModalClose = () => {
  isModalOpen.value = false;
};

const { runAsyncWithLoading } = useLoadingState();
const connectorsStore = useConnectorsStore();

const deleteConnector = () => connectorsStore.deleteConnector(props.data.id);

const onDelete = async () => {
  const isSuccess = await runAsyncWithLoading(deleteConnector);

  if (isSuccess) {
    props.onDeleteRedirect();
  }
};

const primaryButtonProps = PrimaryButtonProps({
  text: 'Delete',
  type: 'error',
  hasOutline: true,
});

const notificationOptions = NotificationConfig({
  isMarkdown: true,
  isSingleLineAction: false,
  message: 'You are about to delete a connector that may be in use for discovering accounts and apps. '
    + 'Deleting this connector could have the following effects:'
    + '\n\n• Deleting this connector **will not affect apps** discovered through it.'
    + '\n\n• However, it will impact accounts discovered through this connector, '
    + 'and **all associated discovery data for these accounts will also be deleted**.'
    + "\n\n• If an account's discovery data comes solely from this connector, "
    + '**the account will also be deleted**.',
  showCloseButton: false,
  type: 'error',
});
</script>

<template>
  <div>
    <ButtonSecondary
      text="Delete Connector"
      type="error"
      @click="onModalOpen"
    />
    <SeverityModal
      v-if="isModalOpen"
      hasBlur
      :isMarkdown="false"
      :primaryButtonProps="primaryButtonProps"
      showModal
      title="Delete Connector"
      variant="sev1"
      @close="onModalClose"
      @save="onDelete"
    >
      <template v-slot:body>
        <NotificationInPage :notification="notificationOptions" />
      </template>
    </SeverityModal>
  </div>
</template>
