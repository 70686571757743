import { DownloadUtil } from '@/util/downloadUtil';
import { defineStore } from 'pinia';
import { useNotification } from '@/composables/useNotification';
import ExportApi from '@/api/Export';
import type { ExportDataArgs } from '@/api/Export';

export const useExportStore = defineStore('saas-export', () => {
  const {
    triggerErrorEnhancedNotification,
    triggerSuccessEnhancedNotification,
  } = useNotification();

  const exportData = async (args: ExportDataArgs) => {
    const { accountId, appId, userId } = args;
    try {
      const base64 = await ExportApi.exportData(args);
      const fileName = DownloadUtil.buildFileNameWithNowDate(`${appId || userId || accountId}`);
      DownloadUtil.downloadFile(base64, fileName, 'application/zip');
      triggerSuccessEnhancedNotification({ message: 'Data was exported successfully.' });
    } catch (error) {
      triggerErrorEnhancedNotification({
        message: 'Data could not be exported.',
        error,
      });
    }
  };

  return {
    exportData,
  };
});
