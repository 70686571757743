<script setup>
import { $testId } from '@/test/testIdHelper';
import { IconButton } from '@jumpcloud/ui-components';
import { ref } from 'vue';
import AddNewConnectorModal from '@/pages/Settings/Connectors/AddNewConnector/AddNewConnectorModal.vue';

const isOpen = ref(false);
const onOpen = () => {
  isOpen.value = true;
};
const onClose = () => {
  isOpen.value = false;
};
</script>

<template>
  <div>
    <IconButton
      class="addConnectorButton"
      :data-test-id="$testId('addConnectorButton')"
      :hasNoBackground="false"
      :hasOutline="false"
      icon="add"
      iconPosition="left"
      :isDisabled="false"
      :isMatchText="false"
      text="Add Connector"
      type="primary"
      @click="onOpen"
    />
    <AddNewConnectorModal
      :showModal="isOpen"
      @close="onClose"
    />
  </div>
</template>

<style scoped>
.addConnectorButton {
  flex-shrink: 0;
}
</style>
