<script setup>
import { StatusBadge } from '@jumpcloud/ui-components';
import { computed, defineProps } from 'vue';

const props = defineProps({
  status: {
    type: String,
    required: true,
  },
});

const badgeStatus = computed(() => ({
  CONNECTED: 'success',
  CONNECTOR_STATUS_CONNECTED: 'success',
  ERROR: 'error',
  CONNECTOR_STATUS_ERROR: 'error',
}[props.status] || 'error'));
// backend sends NOT_CONNECTED and UNKNOWN, we treat them as ERROR

const text = computed(() => ({
  CONNECTED: 'CONNECTED',
  CONNECTOR_STATUS_CONNECTED: 'CONNECTED',
  ERROR: 'ERROR',
  CONNECTOR_STATUS_ERROR: 'ERROR',
}[props.status] || 'ERROR'));
// backend sends NOT_CONNECTED and UNKNOWN, we treat them as ERROR
</script>

<template>
  <StatusBadge
    class="spacing"
    iconPosition="left"
    :status="badgeStatus"
    :text="text"
  />
</template>

<style scoped>
.spacing {
  align-items: center;
  border-radius: var(--jc-border-radius);
  display: flex;
  max-height: 100%;
}
</style>
