<script setup>
import {
  ButtonSecondary, HeadingSmall, ModalStructure, ParagraphBody,
} from '@jumpcloud/ui-components';
import { useAccountsStore } from '@/stores/Accounts';
import { useLoadingState } from '@/util/useLoadingState';
import { useRouter } from 'vue-router';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },

  appId: {
    type: String,
    required: true,
  },

  showModal: Boolean,
});

const emit = defineEmits(['close']);

const router = useRouter();
const accountsStore = useAccountsStore();

const { isLoading, runAsyncWithLoading } = useLoadingState();

const closeModal = () => {
  emit('close');
};

const handleDelete = async () => {
  const deleteAccountCallback = async () => {
    await accountsStore.deleteAccount({ accountId: props.accountId });
    router.push(`/saas-management/applications/${props.appId}/accounts`);
  };

  await runAsyncWithLoading(deleteAccountCallback);
};
</script>

<template>
  <ModalStructure
    class="modalAssignUser"
    footerAlignment="right"
    hasBlur
    shouldCloseOnBlur
    :showModal="showModal"
    @close="closeModal"
  >
    <template #header>
      <HeadingSmall>Delete Account</HeadingSmall>
    </template>

    <template #body>
      <ParagraphBody>
        You have an active connector and deleting this account won't be a permanent deletion.
        Once your connector syncs, this account might re-appear.
      </ParagraphBody>
    </template>

    <template #footer>
      <div class="footerContainer">
        <ButtonSecondary
          text="Cancel"
          @click="closeModal"
        />
        <ButtonSecondary
          :hasOutline="false"
          :isLoading="isLoading"
          text="Delete"
          type="error"
          @click="handleDelete"
        />
      </div>
    </template>
  </ModalStructure>
</template>

<style scoped>
.footerContainer {
  @apply flex items-center gap-2
}
</style>
