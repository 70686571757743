<script setup>
import { Tooltip } from '@jumpcloud/ui-components';
import { computed } from 'vue';
import { useConnectorsStore } from '@/stores/Connectors';
import BrowserExtension from '@/img/browser_extension.svg?url';
import ConnectorSvg from '@/img/connector.svg?url';
import JumpCloudSvg from '@/img/jumpcloud.svg?url';
import pluralize from 'pluralize';

const props = defineProps({
  method: {
    type: Object,
    required: true,
  },
});

const connectorsStore = useConnectorsStore();
const availableConnectors = computed(() => connectorsStore.connectorsMeta);

const methodIcon = {
  BROWSER_LOGIN: BrowserExtension,
  CUSTOM: BrowserExtension,
  INTEGRATION: ConnectorSvg,
  SSO_LOGIN: JumpCloudSvg,
}[props.method.discoveryType];

const mainMethodTooltip = {
  BROWSER_LOGIN: 'Browser Extension',
  CUSTOM: 'Custom Discovery',
  INTEGRATION: `${pluralize('Connector', props.method.connectorTypes?.length)} | `,
  SSO_LOGIN: 'JumpCloud SSO',
}[props.method.discoveryType];

const methodTooltip = computed(() => {
  const tooltip = `${mainMethodTooltip} ${(() => {
    if (props.method.connectorTypes) {
      return props.method.connectorTypes.map((connectorType) => {
        const connector = availableConnectors.value
          .find((availableConnector) => availableConnector.type === connectorType);
        return connector?.name || '';
      }).join(', ');
    }
    return '';
  })()}`;
  return tooltip;
});
</script>

<template>
  <Tooltip
    class="tooltip"
    :text="methodTooltip"
  >
    <img
      :alt="method"
      :src="methodIcon"
    >
  </Tooltip>
</template>

<style scoped>
.tooltip {
  display: flex;
}
</style>
