<script setup>
import { Icon, ParagraphBody, Tooltip } from '@jumpcloud/ui-components';
import { capitalize } from 'lodash';
import { computed } from 'vue';
import { numberToWords } from '@/util/numberToWords';
import pluralize from 'pluralize';

const props = defineProps({
  bodySize: {
    type: String,
    required: true,
  },

  isBackgroundExist: Boolean,

  shouldOpenTooltip: {
    type: Boolean,
    default: true,
  },

  ssoApps: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['openSsoConnectionsModal']);

const status = computed(() => {
  if (props.ssoApps.some((app) => app.status === 'CONNECTED')) {
    return 'connected';
  }
  if (props.ssoApps.every((app) => app.status === 'NOT_CONNECTED')) {
    return 'not_connected';
  }
  return 'unsupported';
});

const filteredConnectedApps = computed(() => props.ssoApps.filter(
  (app) => app.status === 'CONNECTED',
));
const filteredNotConnectedApps = computed(() => props.ssoApps.filter(
  (app) => app.status === 'NOT_CONNECTED',
));

const connectedText = computed(() => `Connected${filteredConnectedApps.value.length > 1
  ? ` (${filteredConnectedApps.value.length})`
  : ''
}`);

const connectedTooltipText = computed(() => (filteredConnectedApps.value.length === 1
  ? 'SSO is active for this application.'
  : `${capitalize(numberToWords(filteredConnectedApps.value.length))} SSO connections are active for this application.`));

const connectedButtonText = computed(() => `View SSO ${pluralize('Connection', filteredConnectedApps.value.length)}`);

const notConnectedText = computed(() => `Not Connected ${filteredNotConnectedApps.value.length > 1
  ? `(${filteredNotConnectedApps.value.length})`
  : ''
}`);

const notConnectedTooltipText = computed(() => (filteredNotConnectedApps.value.length === 1
  ? 'This app can be connected to JumpCloud SSO.'
  : 'These apps can be connected to JumpCloud SSO.'));

const notConnectedButtonText = computed(() => (filteredNotConnectedApps.value.length > 1
  ? 'View Connectable SSO Connections'
  : 'Connect To SSO'));

const notConnectedOnClick = computed(() => (filteredNotConnectedApps.value.length > 1
  ? () => emit('openSsoConnectionsModal', props.ssoApps)
  : () => window.open(`${window.location.origin}#/applications`, '_blank')));

const iconAndTextInfo = computed(() => ({
  connected: {
    backgroundClass: 'bgSuccess',
    icon: 'checkmarkCircle',
    iconClass: 'success',
    canOpenModal: true,
    text: connectedText.value,
    tooltipText: connectedTooltipText.value,
    tooltipButtonText: connectedButtonText.value,
    onClick: () => emit('openSsoConnectionsModal', props.ssoApps),
  },
  not_connected: {
    backgroundClass: 'bgWarning',
    icon: 'error',
    iconClass: 'warning',
    canOpenModal: filteredNotConnectedApps.value.length > 1,
    text: notConnectedText.value,
    tooltipText: notConnectedTooltipText.value,
    tooltipButtonText: notConnectedButtonText.value,
    onClick: notConnectedOnClick.value,
  },
  unsupported: {
    icon: 'nullCircle',
    iconClass: 'neutral',
    canOpenModal: false,
    text: 'Not Available',
    textClass: 'neutral-text',
    tooltipText: 'This app is not in our app catalog. Click here to submit a request to add it to JumpCloud SSO.',
    tooltipButtonText: 'Submit Request',
    onClick: () => window.open('#/support/contact', '_blank'),
  },
}[status.value.toLowerCase()] || {
  text: 'Not Available',
  icon: 'nullCircle',
  iconClass: 'neutral',
  textClass: 'neutral-text',
  canOpenModal: false,
  tooltipText: '',
  tooltipButtonText: '',
  onClick: () => null,
}));
</script>

<template>
  <Tooltip
    :linkText="iconAndTextInfo.tooltipButtonText"
    :noHover="!shouldOpenTooltip"
    :text="iconAndTextInfo.tooltipText"
    @click="iconAndTextInfo.onClick"
  >
    <div
      :class="[
        $style.container,
        isBackgroundExist && $style[iconAndTextInfo.backgroundClass],
      ].filter(Boolean)"
    >
      <Icon
        :class="$style[iconAndTextInfo.iconClass]"
        :icon="iconAndTextInfo.icon"
        :scale="bodySize === 'standard' ? 1.25 : 1"
      />
      <ParagraphBody
        :bodySize="bodySize"
        :class="$style[iconAndTextInfo.textClass]"
        :hasMargin="false"
      >
        {{ iconAndTextInfo.text }}
      </ParagraphBody>
    </div>
  </Tooltip>
</template>

<style module scoped>
.bgSuccess {
  background-color: var(--jc-background-primary-light);
  padding: 2px var(--jc-spacer-x-small);
}

.bgWarning {
  background-color: var(--jc-color-warning-surface);
  padding: 2px var(--jc-spacer-x-small);
}

.container {
  align-items: center;
  border-radius: var(--jc-border-radius);
  display: flex;
  gap: var(--jc-spacer-x-small);
  width: fit-content;
}

.neutral {
  color: var(--jc-color-neutral-stroke-base);
}

.neutral-text {
  color: var(--jc-text-color-light);
}

.success {
  color: var(--jc-color-success-base);
}

.warning {
  color: var(--jc-color-warning-base);
}
</style>
