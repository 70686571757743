<script setup>
import {
  ButtonLink,
  ButtonSecondary,
  HeadingSmall,
  ItemRow,
  ItemWithLabel,
  Link,
  ModalStructure,
} from '@jumpcloud/ui-components';
import { getDiffFromNowInDays } from '@/util/getDiffFromNowInDays';
import { missingText } from '@/util/Constants/missingText';
import { ref } from 'vue';
import moment from 'moment';

const props = defineProps({
  columns: {
    type: Array,
    default: () => ([]),
  },

  itemData: {
    required: true,
    type: Object,
  },
});

const detailsModal = ref(false);
const { extension, source: connector } = props.itemData.source;

const openDetailsModal = () => {
  detailsModal.value = true;
};

const getHeader = () => {
  if (extension) {
    return extension.browserType;
  }
  if (connector) {
    return connector.name;
  }
  return missingText;
};

const getContentOfSourceDetails = () => {
  if (extension) {
    return [
      {
        label: 'Browser ID',
        value: extension.browserId,
      },
      {
        label: 'Browser Version',
        value: extension.browserVersion,
      },
      {
        label: 'Extension Version',
        value: extension.extensionVersion,
      },
    ];
  }
  if (connector) {
    return [
      {
        label: 'Connector ID',
        value: connector.id,
      },
    ];
  }

  return null;
};

const sourceColumnHasDetail = props.itemData.source?.source || props.itemData.source?.extension;
</script>

<template>
  <ItemRow
    v-bind="$attrs"
  >
    <ItemWithLabel
      v-for="column in columns"
      :key="column.dataFieldName"
      :column="column"
    >
      <template v-if="column.dataFieldName === 'source'">
        <div class="class1">
          <div>{{ itemData.sourceName }}</div>
          <div
            v-if="sourceColumnHasDetail"
            class="class2"
          />
          <div
            v-if="sourceColumnHasDetail"
            class="class3"
          >
            <Link
              v-if="itemData.source?.source"
              :text="itemData.source.source.name"
              :to="`/saas-management/settings/connectors/${itemData.source.source.id}`"
            />
            <ButtonLink
              v-else
              :text="itemData.source.extension.browserType"
              @click="openDetailsModal"
            />
          </div>
        </div>
      </template>
      <template v-if="column.dataFieldName === 'method'">
        {{ itemData?.method || missingText }}
      </template>
      <template v-if="column.dataFieldName === 'tenant'">
        {{ itemData?.tenantName || missingText }}
      </template>
      <template v-if="column.dataFieldName === 'lastLoginAt'">
        {{ getDiffFromNowInDays(itemData?.lastLoginAt) }}
      </template>
      <template v-if="column.dataFieldName === 'discoveredAt'">
        {{ itemData?.discoveredAt ? moment(itemData.discoveredAt)
          .format('MMM DD, YYYY [@] hh:mma') : missingText }}
      </template>
      <template v-if="column.dataFieldName === 'device'">
        <Link
          v-if="itemData.device"
          :text="itemData.device?.name"
          :to="`/devices/${itemData.device?.id}/details/highlights`"
        />
        <template v-else>
          {{ missingText }}
        </template>
      </template>
    </ItemWithLabel>
    <ModalStructure
      class="modalDetails"
      footerAlignment="right"
      hasBlur
      shouldCloseOnBlur
      :showModal="detailsModal"
      @close="detailsModal = false"
    >
      <template #header>
        <HeadingSmall>
          {{ getHeader() + ' Detail' }}
        </HeadingSmall>
      </template>
      <template #body>
        <div class="class4">
          <div
            v-for="detail in getContentOfSourceDetails()"
            :key="detail.label"
            class="class5"
          >
            <div class="class6">
              {{ detail.label }}
            </div>
            <div>{{ detail.value }}</div>
          </div>
        </div>
      </template>
      <template #footer>
        <ButtonSecondary
          text="Close"
          @click="detailsModal = false"
        />
      </template>
    </ModalStructure>
  </ItemRow>
</template>

<style scoped>
.modalDetails {
  --modal-width: 600px;
}

.class1 {
  @apply flex items-center;
}

.class2 {
  @apply mx-2 h-3 border-l border-neutral;
}

.class3 {
  @apply flex;
}

.class4 {
  @apply grid grid-cols-2 gap-4;
}

.class5 {
  @apply flex flex-col gap-1;
}

.class6 {
  @apply font-bold;
}
</style>
