<script setup lang="ts">
import { $testId } from '@/test/testIdHelper';
import {
  Card,
  HeadingMedium,
  HeadingSmall,
  Link,
  ParagraphBody,
  ToggleSwitch,
  Tooltip,
} from '@jumpcloud/ui-components';
import { SaasConstants } from '@/util/Constants/SaasConstants';
import { computed, defineEmits, defineProps } from 'vue';
import { useFeatureFlags } from '@/feature-flags';

const props = defineProps({
  isSaasManagementBrowserExtensionEnabled: {
    type: Boolean,
    default: false,
  },
  isSaasManagementEnabled: {
    type: Boolean,
    default: false,
  },
  isSaasManagementDeviceAgentEnabled: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['update:isSaasManagementBrowserExtensionEnabled', 'update:isSaasManagementDeviceAgentEnabled']);

const statusText = computed(() => (props.isSaasManagementBrowserExtensionEnabled ? 'Enabled' : 'Disabled'));
const deviceAgentStatusText = computed(() => (props.isSaasManagementDeviceAgentEnabled ? 'Enabled' : 'Disabled'));

const { hasFeatureFlag } = useFeatureFlags();
</script>

<template>
  <Card>
    <template v-slot:body>
      <div class="header">
        <HeadingMedium>Discovery Methods</HeadingMedium>
      </div>
      <ParagraphBody
        bodySize="large"
        class="lightGreyText"
      >
        Manage how SaaS apps are discovered by enabling or disabling the following methods.
        <Link
          class="link"
          :href="SaasConstants.jcGoSupport"
          isExternal
          target="_blank"
        >
          Learn More
        </Link>
      </ParagraphBody>
      <div class="flex flex-col space-y-4">
        <section>
          <div class="header">
            <Tooltip
              :isMarkdown="false"
              :noHover="isSaasManagementEnabled"
              orientation="bottom"
              text="Enable SaaS Management to access and modify other settings."
            >
              <ToggleSwitch
                class="mt-half"
                :data-test-id="$testId('saasManagementUserPortalEnabledSwitch')"
                :isDisabled="!isSaasManagementEnabled"
                :modelValue="isSaasManagementBrowserExtensionEnabled"
                @update:model-value="
                  $emit('update:isSaasManagementBrowserExtensionEnabled', $event)"
              />
            </Tooltip>
            <HeadingSmall>
              JumpCloud Go Browser Extension {{ statusText }}
            </HeadingSmall>
          </div>
          <ParagraphBody
            bodySize="large"
            class="lightGreyText"
          >
            Enable seamless SaaS Management with the JumpCloud Go extension.
          </ParagraphBody>
        </section>
        <section v-if="hasFeatureFlag('deviceAgentToggleButton')">
          <div class="header">
            <Tooltip
              :isMarkdown="false"
              :noHover="isSaasManagementEnabled"
              orientation="bottom"
              text="Enable SaaS Management to access and modify other settings."
            >
              <ToggleSwitch
                class="mt-half"
                :data-test-id="$testId('saasManagementDeviceAgentEnabledSwitch')"
                :isDisabled="!isSaasManagementEnabled"
                :modelValue="isSaasManagementDeviceAgentEnabled"
                @update:model-value="
                  $emit('update:isSaasManagementDeviceAgentEnabled', $event)"
              />
            </Tooltip>
            <HeadingSmall>
              Device Agent {{ deviceAgentStatusText }}
            </HeadingSmall>
          </div>
          <ParagraphBody
            bodySize="large"
            class="lightGreyText"
          >
            The Device Agent provides data on active devices to SaaS Management.
          </ParagraphBody>
        </section>
      </div>
    </template>
  </Card>
</template>

<style scoped>
.mt-half {
  @apply mt-0.5
}

.header {
  @apply mb-2 flex items-start gap-2
}

.lightGreyText {
  @apply text-light
}

.link{
  @apply mt-0.5 text-sm
}
</style>
