export type AppFilterParams = {
  search?: string;
  status?: string | string[];
  category?: string | string[];
  owner?: string | string[];
  lastUsed?: string | string[] | { start: string; end: string }[];
  renewal?: string | string[] | { start: string; end: string }[];
  ssoConnectionStatus?: string | string[];
  discoveryDate?: string | string[] | { start: string; end: string }[];
};

type DateRange = {
  start: string;
  end: string;
};

export function createAppFilterParams(params: AppFilterParams) {
  let statusFilter: boolean | string = false;

  if (params?.status && params?.status?.length) {
    if (Array.isArray(params.status)) {
      statusFilter = `status:in:[${params.status}]`;
    } else {
      statusFilter = `status:eq:${params.status}`;
    }
  }

  const searchFilter = params?.search
    ? `app_id:search:${params.search}`
    : false;

  const categoryFilter = params?.category && params?.category.length
    ? `category:in:[${params.category}]`
    : false;

  const ssoFilter = params?.ssoConnectionStatus && params?.ssoConnectionStatus.length
    ? `sso_connection_status:in:[${params.ssoConnectionStatus}]`
    : false;

  const ownerFilter = params?.owner && params?.owner.length
    ? `owner:in:[${params.owner}]`
    : false;

  const startDiscoveryDateFilter = params?.discoveryDate && params?.discoveryDate.length
    ? `discovered_at:ge:${new Date((params.discoveryDate[0] as DateRange).start).toISOString()}`
    : false;

  const endDiscoveryDateFilter = params?.discoveryDate && params?.discoveryDate.length
    ? `discovered_at:le:${new Date((params.discoveryDate[0] as DateRange).end).toISOString()}`
    : false;

  const startLastUsedFilter = params?.lastUsed && params?.lastUsed.length
    ? `last_used_at:ge:${new Date((params.lastUsed[0] as DateRange).start).toISOString()}`
    : false;

  const endLastUsedFilter = params?.lastUsed && params?.lastUsed.length
    ? `last_used_at:le:${new Date((params.lastUsed[0] as DateRange).end).toISOString()}`
    : false;

  const startRenewalFilter = params?.renewal && params?.renewal.length
    ? `license_renewal_date:ge:${new Date((params.renewal[0] as DateRange).start).toISOString()}`
    : false;

  const endRenewalFilter = params?.renewal && params?.renewal.length
    ? `license_renewal_date:le:${new Date((params.renewal[0] as DateRange).end).toISOString()}`
    : false;

  return [
    statusFilter,
    searchFilter,
    categoryFilter,
    ssoFilter,
    ownerFilter,
    startDiscoveryDateFilter,
    endDiscoveryDateFilter,
    startLastUsedFilter,
    endLastUsedFilter,
    startRenewalFilter,
    endRenewalFilter,
  ].filter(Boolean);
}
