<script setup>
import { $testId } from '@/test/testIdHelper';
import {
  DropdownActions,
  Types,
} from '@jumpcloud/ui-components';

const { DropdownMenuItem } = Types;

defineProps({
  columns: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['exportData', 'exportAllData']);

const exportItems = [
  DropdownMenuItem({
    text: 'Current View (CSV)',
    type: 'informational',
    click: () => emit('exportData'),
  }),
  DropdownMenuItem({
    text: 'All Data (CSV)',
    type: 'informational',
    click: () => emit('exportAllData'),
  }),
];

</script>

<template>
  <DropdownActions
    buttonText="Export"
    :data-test-id="$testId('exportDropdown')"
    :items="exportItems"
    position="right"
  />
</template>

<style scoped>

</style>
