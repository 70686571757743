<script setup>
import {
  computed, defineEmits, defineProps, useSlots,
} from 'vue';

defineProps({
  leftContentClass: {
    type: String,
    default: '',
  },
  rightContentClass: {
    type: String,
    default: '',
  },
  singleContentClass: {
    type: String,
    default: '',
  },
});

const slots = useSlots();
const twoSideSlotsView = computed(() => Boolean(slots.leftContent && slots.rightContent));

defineEmits([]);
</script>

<template>
  <div class="flex size-full justify-center overflow-auto bg-app">
    <div class="outerInner">
      <template v-if="twoSideSlotsView">
        <div
          class="leftContent"
          :class="leftContentClass"
        >
          <slot name="leftContent" />
        </div>
        <div
          class="rightContent"
          :class="rightContentClass"
        >
          <slot name="rightContent" />
        </div>
      </template>
      <div
        v-else
        class="singleContent"
        :class="singleContentClass"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped>
.outerInner {
  @apply flex h-full flex-1 flex-col overflow-auto xl:size-full xl:flex-row
}

.leftContent {
  @apply flex flex-[3] flex-col p-6 xl:overflow-auto order-2 xl:order-1
}

.rightContent {
  @apply flex flex-1 p-6 xl:border-l border-neutral xl:overflow-auto order-1 xl:order-2
}

.singleContent {
  @apply flex flex-1 flex-col p-6 xl:overflow-auto
}

.leftContent, .rightContent, .singleContent {
  --max-content-width: 1680px;
  --sidebar-width: 240px;
  --left-content-width: calc(0.75 * var(--max-content-width));
  --right-content-width: calc(0.25 * var(--max-content-width));
  --content-padding: 24px;
  --variable-padding: calc((100vw - var(--max-content-width) - var(--sidebar-width)) / 2);
  --content-calculated-padding: max(var(--variable-padding), var(--content-padding));
}

@media (min-width: 1680px) {
  .leftContent {
    padding-left: var(--content-calculated-padding);
    width: min(var(--left-content-width), calc((0.75 * (100vw - var(--sidebar-width)))));
  }

  .rightContent {
    padding-right: var(--content-calculated-padding);
    width: min(var(--right-content-width), calc(0.25 * (100vw - var(--sidebar-width))));
  }

  .singleContent {
    padding-left: var(--content-calculated-padding);
    padding-right: var(--content-calculated-padding);
    width: min(var(--max-content-width), calc(100vw - var(--sidebar-width)));
  }
}
</style>
