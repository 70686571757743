<script setup lang="ts">
import {
  Card, HeadingSmall, ParagraphBody, RadioGroup,
} from '@jumpcloud/ui-components';
import { defineEmits, defineProps } from 'vue';

defineProps({
  defaultAction: {
    type: String,
    default: '',
  },
});

defineEmits(['update:defaultAction']);

const actionOptions = [
  { value: 'NO_ACTION', label: 'Take No Action' },
  { value: 'BLOCK', label: 'Block the Application' },
  { value: 'WARNING', label: 'Show a Warning' },
];
</script>

<template>
  <div class="cardContainer">
    <Card>
      <template v-slot:body>
        <div class="cardHeader">
          <HeadingSmall>Default Action for Unapproved Apps</HeadingSmall>
        </div>
        <ParagraphBody class="subheading">
          JumpCloud Go needed for this feature.
        </ParagraphBody>
        <div class="radioGroupContainer">
          <RadioGroup
            :modelValue="defaultAction"
            name="default-action-radiogroup"
            :options="actionOptions"
            :showValidationMessage="false"
            @update:model-value="$emit('update:defaultAction', $event)"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<style scoped>
.cardContainer {
  margin-bottom: var(--jc-spacer);
}

.cardHeader {
  padding-bottom: var(--jc-spacer-small);
}

.radioGroupContainer {
  margin-bottom: var(--jc-spacer-negative-medium) !important;
  margin-top: var(--jc-spacer-small);
  padding-bottom: var(--jc-spacer-small);
}

.subheading {
  color: var(--jc-text-color-light);
}
</style>
