import { type AxiosInstance, createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';
import { missingText } from '@/util/Constants/missingText';
import type {
  AccountDiscoveryItem, AccountItem, AccountListItem, UserAccountItem,
} from './shared/types';
import type { AccountsTableFilters } from '@/stores/Accounts';
import type { ApiFilterParams } from './shared/FilterParams';

export type Accounts = {
  count: number,
  accounts: AccountListItem[],
};

export type AccountDiscoveryItemWithSourceName = AccountDiscoveryItem & { sourceName: string };

export type AccountDiscoveryReturnType = {
  count: number,
  accountDiscoveries: AccountDiscoveryItemWithSourceName[]
};

export type AccountUsageReturnType = {
  count: number,
  data: { appCount: number; atDay: string; }[]
};

const createAccountFilterParams = (params: Partial<AccountsTableFilters>) => {
  const searchParam = params?.search ? `account:search:${params.search}` : false;

  const userFilter = params?.user && params?.user.length
    ? `user_id:in:[${params.user}]`
    : false;

  const appFilter = params?.application && params?.application.length
    ? `app_catalog_id:in:[${params.application}]`
    : false;

  const loginTypeFilter = params?.loginMethod && params?.loginMethod.length
    ? `login_types:in:[${params.loginMethod}]`
    : false;

  const findingsFilter = params?.findings && params?.findings.length
    ? `findings:in:[${params.findings}]`
    : false;

  const discoverySourceFilter = params?.source && params?.source.length
    ? `discovery_source_types:in:[${params.source}]`
    : false;

  const discoveryDateStartFilter = params?.discoveryDate && params?.discoveryDate.length
    ? `discovered_at:ge:${new Date((params.discoveryDate[0]).start).toISOString()}`
    : false;

  const discoveryDateEndFilter = params?.discoveryDate && params?.discoveryDate.length
    ? `discovered_at:le:${new Date((params.discoveryDate[0]).end).toISOString()}`
    : false;

  const startLastUsedFilter = params?.lastUsed && params?.lastUsed.length
    ? `last_used_at:ge:${new Date((params.lastUsed[0]).start).toISOString()}`
    : false;

  const endLastUsedFilter = params?.lastUsed && params?.lastUsed.length
    ? `last_used_at:le:${new Date((params.lastUsed[0]).end).toISOString()}`
    : false;

  return [
    searchParam,
    userFilter,
    appFilter,
    loginTypeFilter,
    findingsFilter,
    discoverySourceFilter,
    discoveryDateStartFilter,
    discoveryDateEndFilter,
    startLastUsedFilter,
    endLastUsedFilter,
  ].filter(Boolean);
};

class AccountsApi {
  private baseUrl: string;
  private axiosInstance: AxiosInstance;

  constructor() {
    this.baseUrl = '/api/v2/saas/accounts';
    this.axiosInstance = createJcApiAxiosInstance();
  }

  async fetch(params: AccountsTableFilters) {
    const filter = createAccountFilterParams(params);

    const limit = (params?.limit || 50);
    const skip = params?.skip ?? (params?.page || 0) * limit;
    const sort = params?.sort ? [params.sort] : undefined;

    const response = await this.axiosInstance.get<Accounts>(this.baseUrl, {
      params: {
        filter,
        skip,
        limit,
        sort,
      },
    });
    return response?.data;
  }

  async getAccountDetails({ accountId }: { accountId: string }): Promise<AccountItem> {
    const response = await this.axiosInstance.get(`${this.baseUrl}/${accountId}`);
    return response?.data?.account || {};
  }

  async updateAccountUser(
    { accountId, userId }: { accountId: string, userId: string },
  ): Promise<AccountItem['user']> {
    const response = await this.axiosInstance.patch(`${this.baseUrl}/${accountId}`, { userId });
    return response?.data?.user;
  }

  async deleteAccount({ accountId }:{ accountId: string }) {
    await this.axiosInstance.delete(`${this.baseUrl}/${accountId}`);
  }

  async getAccountDiscoveries(
    { accountId, params }:{ accountId: string, params: Partial<ApiFilterParams> },
  ): Promise<AccountDiscoveryItemWithSourceName[]> {
    const sort = params?.sort ? [params.sort] : undefined;
    const response = await this.axiosInstance.get<AccountDiscoveryReturnType>(`${this.baseUrl}/${accountId}/discoveries`, {
      params: {
        sort,
      },
    });

    if (!response?.data?.accountDiscoveries) {
      return [];
    }

    const getSourceNameByType = (sourceType: string) => {
      if (sourceType === 'BROWSER_LOGIN' || sourceType === 'CUSTOM') {
        return 'Browser Extension';
      }
      if (sourceType === 'INTEGRATION') {
        return 'Connector';
      }
      if (sourceType === 'SSO_LOGIN' || sourceType === 'SSO') {
        return 'JumpCloud';
      }
      return missingText;
    };

    const mappedData = response.data.accountDiscoveries.map((discovery) => ({
      ...discovery,
      sourceName: getSourceNameByType(discovery.source.type),
    }));

    const sortMap: {
      [key: string]: (
        a: AccountDiscoveryItemWithSourceName,
        b: AccountDiscoveryItemWithSourceName
      ) => number
    } = {
      discoveredAt: (a, b) => (new Date(b.discoveredAt) > new Date(a.discoveredAt) ? 1 : -1),
      lastLoginAt: (a, b) => (new Date(b.lastLoginAt) > new Date(a.lastLoginAt) ? 1 : -1),
      device: (a, b) => a.device?.name.localeCompare(b.device?.name),
      source: (a, b) => a.sourceName.localeCompare(b.sourceName),
    };

    const isDescending = (key: string) => key.startsWith('-');
    const getSortKey = (key: string) => (isDescending(key) ? key.slice(1) : key);

    if (!params?.sort) return mappedData;

    const sortKey = getSortKey(params.sort);
    if (!sortMap[sortKey]) return mappedData;

    const sortFn = sortMap[sortKey];

    return mappedData?.sort(
      (a, b) => (isDescending(params.sort!) ? -sortFn(a, b) : sortFn(a, b)),
    ) ?? [];
  }

  async getAccountUsage({ accountId, dayCount }:{ accountId: string, dayCount: number }): Promise<AccountUsageReturnType['data']> {
    const response = await this.axiosInstance.post<AccountUsageReturnType>(`${this.baseUrl}/${accountId}/usage`, { dayCount });

    if (!response.data) return [];

    const sortedUsageData = response.data.data
      .sort((d1, d2) => (new Date(d1.atDay) > new Date(d2.atDay) ? 1 : -1));

    return sortedUsageData;
  }

  async getAccountUsageAtDay(
    { accountId, atDay }: { accountId: string, atDay: string },
  ): Promise<Pick<UserAccountItem, 'account' | 'appId' | 'appName' | 'category'>[]> {
    const response = await this.axiosInstance.post(`${this.baseUrl}/${accountId}/usage-at-day`, { atDay });
    return response?.data.data || [];
  }

  async exportAccounts(params: Partial<AccountsTableFilters>) {
    const filter = createAccountFilterParams(params);

    const sort = params?.sort ? [params.sort] : undefined;

    const response = await this.axiosInstance.post(`${this.baseUrl}/export`,
      { filter, sort },
      { responseType: 'arraybuffer' },
    );

    return response?.data;
  }
}

export default new AccountsApi();
