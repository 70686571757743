<script setup>
import { StatusBadge } from '@jumpcloud/ui-components';
import { computed } from 'vue';
import { getBadgePropsForAppStatus } from '@/util/getBadgePropsForAppStatus';

const props = defineProps({
  status: {
    type: String,
    required: true,
  },
});

const statusBadgeInfo = computed(() => getBadgePropsForAppStatus(props.status));
</script>

<template>
  <StatusBadge
    v-if="statusBadgeInfo"
    :status="statusBadgeInfo.status"
    :text="statusBadgeInfo.text"
  />
</template>

<style scoped>
</style>
