<script setup>
import {
  ButtonDismissibleChip,
  Combobox,
  DatePickerRangeInput,
  InputLabel,
} from '@jumpcloud/ui-components';
import { ref } from 'vue';
import { saasCategories } from '@/util/Constants/saasCategories';
import { sortBy } from 'lodash';

const props = defineProps({
  selectedCategories: {
    type: Array,
    default: () => [],
  },

  selectedDiscoveryDate: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['update:selectedCategories', 'update:selectedDiscoveryDate']);

const initialCategoryOptions = sortBy(saasCategories, 'name').map(
  (category) => ({
    name: category.name,
    value: category.id,
    disabled: false,
  }),
);

const categoryOptions = ref(initialCategoryOptions);

const handleCategorySearchUpdated = (searchText) => {
  categoryOptions.value = initialCategoryOptions
    .filter((category) => category.name.toLowerCase().includes(searchText.toLowerCase()),
    );
};

const handleCategorySelectionsUpdated = (selections) => {
  emit('update:selectedCategories', selections.newSelectedOptions);
};

const deselectCategory = (value) => {
  const selectedCategories = props.selectedCategories.filter(
    (category) => category.value !== value,
  );
  emit('update:selectedCategories', selectedCategories);
};

</script>

<template>
  <div class="filterGroup">
    <div class="marginBottom">
      <div>
        <InputLabel text="App Category" />
        <Combobox
          class="saasAppCategoryCombobox"
          multiselect
          :options="categoryOptions"
          :optionsTotalCount="categoryOptions.length"
          placeholder="Search"
          :selectedOptions="selectedCategories"
          :showSelectAll="false"
          @search-updated="handleCategorySearchUpdated"
          @selections-updated="handleCategorySelectionsUpdated"
        />
      </div>
      <div
        v-if="selectedCategories.length"
        class="selectedCategories"
      >
        <ButtonDismissibleChip
          v-for="category in selectedCategories"
          :key="category.value"
          :text="category.name"
          @click="deselectCategory(category.value)"
        />
      </div>
    </div>
    <DatePickerRangeInput
      label="Discovery Date"
      :minuteIncrement="15"
      :modelValue="selectedDiscoveryDate"
      positionFixed
      preventFutureDates
      showTimePicker
      showValidationMessage
      :useUtcTime="false"
      @update:model-value="$emit('update:selectedDiscoveryDate', $event)"
    />
  </div>
</template>

<style scoped>
.filterGroup {
  display: flex;
  flex-direction: column;
}

.marginBottom {
  margin-bottom: var(--jc-spacer);
}

.selectedCategories {
  display: flex;
  flex-wrap: wrap;
  gap: var(--jc-spacer-small);
  margin-top: var(--jc-spacer-x-small);
}

.saasAppCategoryCombobox > div > div > div > div > span > span {
  border-radius: var(--jc-border-radius) !important;
}
</style>
