<script setup>
import { Types } from '@jumpcloud/ui-components';
import { computed, onMounted, ref } from 'vue';
import { useUsersStore } from '@/stores/Users';
import SaasUsageModal from '@/components/SaasUsageModal.vue';
import SaasUserDetailsTableRow from '@/pages/Users/UserDetails/SaasUserDetailsTableRow.vue';
import moment from 'moment';

const { Column } = Types;

const props = defineProps({
  selectedDay: {
    type: Object,
    required: true,
  },
  userId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['close']);

const isLoading = ref(false);
const store = useUsersStore();

const userUsageDataAtDay = computed(() => store.userUsageDataAtDay);

const fetchUserUsageAtDay = async () => {
  isLoading.value = true;
  await store.fetchUserUsageAtDay({ userId: props.userId, atDay: props.selectedDay.atDay });
  isLoading.value = false;
};

onMounted(() => {
  fetchUserUsageAtDay();
});

const closeModal = () => {
  emit('close');
  store.removeUserUsageAtDay();
};

const title = computed(() => {
  const formattedDay = moment(props.selectedDay.atDay).format('MMMM D, YYYY');
  return `Account Usage on ${formattedDay}`;
});

const columns = [
  Column({ label: 'App', dataFieldName: 'appId', width: '1fr' }),
  Column({ label: 'Account', dataFieldName: 'account', width: '1fr' }),
];
</script>

<template>
  <SaasUsageModal
    v-if="selectedDay"
    :columns="columns"
    :data="userUsageDataAtDay"
    :isLoading="isLoading"
    :isModalOpen="!!selectedDay"
    :itemRowComponent="SaasUserDetailsTableRow"
    :selectedDay="selectedDay"
    :title="title"
    @close="closeModal"
  />
</template>

    <style scoped>
    </style>
