<script setup>
import { $testId } from '@/test/testIdHelper';
import { ButtonSecondary, Icon, InputLabel } from '@jumpcloud/ui-components';
import {
  defineEmits, defineProps, h, nextTick, onMounted, ref,
} from 'vue';
import CaretIcon from '@/img/caret.svg?url';
import VSelect from 'vue-select';

const props = defineProps({
  appendToBody: Boolean,

  clearable: {
    type: Boolean,
    default: true,
  },

  closeOnSelect: {
    type: Boolean,
    default: true,
  },

  disabled: Boolean,

  filterable: Boolean,

  hasNextPage: Boolean,

  inputLabel: {
    type: String,
    default: '',
  },

  isInfinite: Boolean,

  label: {
    type: String,
    default: 'label',
  },

  loading: Boolean,

  multiple: Boolean,

  noOptionsButtonLabel: {
    type: String,
    default: '',
  },

  noOptionsDescription: {
    type: String,
    default: 'There are no items available.',
  },

  onNextPage: {
    type: Function,
    default: null,
  },

  options: {
    type: Array,
    default() {
      return [];
    },
  },

  placeholder: {
    type: String,
    default: '',
  },

  required: Boolean,

  searchable: {
    type: Boolean,
    default: true,
  },

  selectable: {
    type: Function,
    default: () => true,
  },

  taggable: Boolean,

  modelValue: {
    type: [String, Object, Array],
    default: null,
  },
});

const emit = defineEmits(['update:modelValue', 'search', 'noOptionsButtonOnClick']);

const isOpen = ref(false);

const handleChange = (event) => {
  emit('update:modelValue', event);
};

const OpenIndicator = {
  render() {
    return h('img', {
      src: CaretIcon,
      alt: 'toggle-select',
      width: '9px',
      style: {
        marginRight: '7px',
      },
    });
  },
};

const onSearch = (searchTerm, loading) => {
  emit('search', searchTerm, loading, isOpen.value);
};

const observer = ref(null);

const infiniteScroll = async ([{ isIntersecting, target }]) => {
  if (isIntersecting) {
    const ul = target.offsetParent;
    const { scrollTop } = target.offsetParent;
    await props.onNextPage();
    await nextTick();
    ul.scrollTop = scrollTop;
  }
};

const load = ref(null);

const onOpen = async () => {
  isOpen.value = true;
  if (props.hasNextPage) {
    await nextTick();
    observer.value?.observe(load.value);
  }
};

const onClose = () => {
  isOpen.value = false;
  observer.value?.disconnect();
};

onMounted(() => {
  if (!props.isInfinite) return;

  observer.value = new IntersectionObserver(infiniteScroll);
});
</script>

<template>
  <div class="flex w-full flex-col">
    <InputLabel
      v-if="inputLabel"
      :required="required"
      :text="inputLabel"
    />
    <VSelect
      v-bind="$props"
      class="jc-select"
      :components="{OpenIndicator}"
      :data-test-id="$testId('select')"
      :dropdownShouldOpen="({ open }) => open"
      @close="onClose"
      @open="onOpen"
      @search="onSearch"
      @update:model-value="handleChange"
    >
      <template #option="option">
        <slot
          name="option"
          :option="option"
        />
      </template>

      <template #selected-option="option">
        <slot
          name="selected-option"
          :option="option"
        />
      </template>

      <template #no-options>
        <div
          class="vs__no-option m-2 mt-[3px] flex flex-col items-center justify-center
          space-y-5 p-8"
        >
          <span>{{ noOptionsDescription }}</span>
          <ButtonSecondary
            v-if="noOptionsButtonLabel"
            class="btn btn-primary"
            :text="noOptionsButtonLabel"
            @click="$emit('noOptionsButtonOnClick')"
          />
        </div>
      </template>

      <template #list-footer>
        <li
          v-show="hasNextPage"
          ref="load"
          class="text-center"
        >
          <Icon icon="loading" />
        </li>

        <div
          v-if="$slots.footer"
          class="jc-select-footer"
        >
          <slot name="footer" />
        </div>
      </template>
    </VSelect>
  </div>
</template>

<!-- eslint-disable vue/no-restricted-syntax -->
<style>
@import "vue-select/dist/vue-select.css";

:root,
:host {
  --vs-colors--lightest: rgba(60, 60, 60, 0.26);
  --vs-colors--light: rgba(60, 60, 60, 0.5);
  --vs-colors--dark: #333;
  --vs-colors--darkest: rgba(0, 0, 0, 0.15);

  /* Search Input */
  --vs-search-input-color: inherit;
  --vs-search-input-bg: rgb(255, 255, 255);
  --vs-search-input-placeholder-color: inherit;

  /* Font */
  --vs-font-size: var(--jcBody);
  --vs-line-height: 20px;

  /* Disabled State */
  --vs-state-disabled-bg: rgb(248, 248, 248);
  --vs-state-disabled-color: var(--vs-colors--light);
  --vs-state-disabled-controls-color: var(--vs-colors--light);
  --vs-state-disabled-cursor: not-allowed;
  --vs-disabled-bg: transparent;

  /* Borders */
  --vs-border-color: var(--vs-colors--lightest);
  --vs-border-width: 1px;
  --vs-border-style: solid;
  --vs-border-radius: var(--jc-border-radius-small);

  /* Actions: house the component controls */
  --vs-actions-padding: 4px 6px 0 3px;

  /* Component Controls: Clear, Open Indicator */
  --vs-controls-color: var(--vs-colors--light);
  --vs-controls-size: 1;
  --vs-controls--deselect-text-shadow: 0 1px 0 #fff;

  /* Selected */
  --vs-selected-bg: #f0f0f0;
  --vs-selected-color: var(--vs-colors--dark);
  --vs-selected-border-color: var(--vs-border-color);
  --vs-selected-border-style: var(--vs-border-style);
  --vs-selected-border-width: var(--vs-border-width);

  /* Dropdown */
  --vs-dropdown-bg: #fff;
  --vs-dropdown-color: inherit;
  --vs-dropdown-z-index: 1000;
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 350px;
  --vs-dropdown-box-shadow: 0px 3px 6px 0px var(--vs-colors--darkest);

  /* Options */
  --vs-dropdown-option-bg: #000;
  --vs-dropdown-option-color: var(--jc-text-color);
  --vs-dropdown-option-padding: 3px 20px;

  /* Active State */
  --vs-dropdown-option--active-bg: var(--jc-background-primary-light);
  --vs-dropdown-option--active-color: var(--jc-text-color);

  /* Deselect State */
  --vs-dropdown-option--deselect-bg: #fb5858;
  --vs-dropdown-option--deselect-color: #fff;

  /* Transitions */
  --vs-transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
  --vs-transition-duration: 150ms;
}

.vs__dropdown-menu {
  background: var(--jc-background-light);
  border: var(--jc-border);
  border-radius: var(--jc-border-radius-large);

  /* Floating */
  box-shadow: 0 2px var(--jc-spacer-small) 0 rgba(32, 45, 56, 0.25);
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 5px;
  padding-bottom: 0;
  padding-top: var(--jc-spacer-small);
}

.vs__dropdown-menu li:nth-last-child(2).vs__dropdown-option {
  margin-bottom: var(--jc-spacer-small);
}

.jc-select .vs__dropdown-toggle {
  background: var(--jc-background-light);
  border: var(--jc-border);
  border-radius: var(--jc-border-radius-small);
}

.jc-select.vs--disabled .vs__dropdown-toggle {
  @apply !bg-disabled-fill;
}

.jc-select.vs--disabled .vs__dropdown-toggle .vs__selected {
  @apply text-input-disabled;
}

.jc-select.vs--disabled .vs__dropdown-toggle .vs__open-indicator {
  @apply opacity-30;
}

.jc-select .vs__dropdown-toggle:hover {
  @apply bg-secondary-fill-hover;

  cursor: pointer;
}

.jc-select .vs__dropdown-toggle:active {
  @apply bg-secondary-fill-hover;
}

.jc-select.vs--open .vs__dropdown-toggle, .jc-select.vs--loading .vs__dropdown-toggle {
  @apply bg-surface-light;

  border: var(--jc-border-width) solid var(--jc-color-secondary-stroke-default);
  border-radius: var(--jc-border-radius-small);

  /* Focus */
  box-shadow: var(--jc-box-shadow-clickable-focus);
}

.jc-select-footer {
  background: var(--jc-background-light);
  border-top: var(--jc-border);
  bottom: 0;
  color: var(--jc-text-color);
  font-size: var(--jcBodySmall);
  margin-top: 6px;
  padding: var(--jc-spacer-small) var(--jc-spacer);
  position: sticky;
}

.vs__dropdown-option {
  @apply bg-surface-light;

  border-radius: var(--jc-border-radius-small);
  color: var(--jc-text-color);
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: var(--jcBody);
  font-style: var(--jc-line-height-reset);
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin: 0 var(--jc-spacer-small);
  padding: 6px var(--jc-spacer-small);
}

.vs__dropdown-option:hover {
  @apply bg-secondary-fill-hover;
}

.vs__dropdown-option:active {
  @apply bg-[#F0F0F5];
}

.vs__dropdown-option--selected {
  @apply !bg-primary-surface;
}

.vs__selected-options {
  color: var(--jc-text-color-light);
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: var(--jcBody);
  font-style: var(--jc-line-height-reset);
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  overflow: hidden;
  text-overflow: ellipsis;
}

.vs__spinner {
  border-left-color: rgba(88, 151, 251, 0.71);
  height: 20px !important;
  width: 20px !important;
}

.vs__no-option {
  background-color: var(--jc-background);
}
</style>
