<script setup>
import { onMounted } from 'vue';
import { useConnectorsStore } from '@/stores/Connectors';
import { useLoadingState } from '@/util/useLoadingState';
import SaasAccountsTable from '@/pages/Accounts/SaasAccountsTable.vue';

const {
  runAsyncWithLoading: runAsyncWithLoadingAvailableConnectors,
} = useLoadingState(true);

const connectorsStore = useConnectorsStore();

const fetchAvailableConnectors = async () => {
  const fetchAvailableConnectorsCallback = async () => {
    connectorsStore.fetchConnectorsMeta();
  };

  await runAsyncWithLoadingAvailableConnectors(fetchAvailableConnectorsCallback);
};

onMounted(() => {
  fetchAvailableConnectors();
});

</script>

<template>
  <div class="saasAccountsTableContainer flex h-full flex-col">
    <div class="h-full grow overflow-auto bg-white">
      <SaasAccountsTable />
    </div>
  </div>
</template>

<style scoped>
.saasAccountsTableContainer :deep() div[class^=ItemWithLabel__item] {
 @apply min-h-12 py-1;
}
</style>
