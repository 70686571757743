<script setup>
import {
  Card, HeadingSmall, IconButton, LoadingOverlay,
} from '@jumpcloud/ui-components';
import { computed, ref } from 'vue';
import { getBarChartHeight } from '@/util/getBarChartHeight';
import { useExportStore } from '@/stores/Export';
import SaasAccountUsageModalWrapper from '@/pages/Applications/ApplicationDetails/Accounts/ApplicationAccountDetail/SaasAccountUsageModalWrapper.vue';
import SaasAppUsageModalWrapper from '@/pages/Applications/ApplicationDetails/Overview/SaasAppUsageModalWrapper.vue';
import SaasChartWrapper from '@/components/SaasChartWrapper.vue';
import SaasHorizontalBarChart from '@/components/SaasHorizontalBarChart.vue';
import SaasSelect from '@/components/SaasSelect.vue';
import SaasUserUsageModalWrapper from '@/pages/Users/UserDetails/SaasUserUsageModalWrapper.vue';
import useSaasChartConfig from '@/util/useSaasChartConfig';

const props = defineProps({
  accountId: {
    type: String,
    default: undefined,
  },

  appId: {
    type: String,
    default: undefined,
  },

  data: {
    type: Array,
    default: () => [],
  },

  dayCount: {
    type: Number,
    required: true,
  },

  emptyStateText: {
    type: String,
    required: true,
  },

  isLoading: {
    type: Boolean,
    required: true,
  },

  title: {
    type: String,
    required: true,
  },

  tooltipSuffix: {
    type: String,
    default: 'used',
  },

  userId: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(['updateDayCount']);

const usageOptions = [
  { label: 'Last 7 days', value: 7 },
  { label: 'Last 15 days', value: 15 },
  { label: 'Last 30 days', value: 30 },
];

const isFileDownloading = ref(false);
const selectedDay = ref(null);
const selectedUsage = ref(usageOptions.find((option) => option.value === props.dayCount));
const exportStore = useExportStore();

const handleDownloadFile = async () => {
  isFileDownloading.value = true;
  await exportStore.exportData({
    accountId: props.accountId,
    userId: props.userId,
    appId: props.appId,
    dayCount: props.dayCount,
  }).finally(() => {
    isFileDownloading.value = false;
  });
};

const setSelectedDay = (day) => {
  selectedDay.value = day;
};

const setSelectedUsage = (usage) => {
  selectedUsage.value = usage;
  emit('updateDayCount', usage.value);
};

const auxUsageData = computed(() => props.data);

const {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  series, xAxis, tooltip, dataPointClick, onUsageModalClose,
} = useSaasChartConfig({
  data: auxUsageData,
  tooltipSuffix: props.tooltipSuffix,
  setSelectedDay,
});

const showEmptyState = computed(() => auxUsageData.value.every(
  (dayData) => dayData.accountCount === 0 || dayData.appCount === 0,
));

</script>

<template>
  <div
    class="userUsageCardContainer"
  >
    <template v-if="Boolean(selectedDay) && userId">
      <SaasUserUsageModalWrapper
        :selectedDay="selectedDay"
        :userId="userId"
        @close="onUsageModalClose"
      />
    </template>
    <template v-if="Boolean(selectedDay) && appId">
      <SaasAppUsageModalWrapper
        :appId="appId"
        :selectedDay="selectedDay"
        @close="onUsageModalClose"
      />
    </template>
    <template v-if="Boolean(selectedDay) && accountId">
      <SaasAccountUsageModalWrapper
        :accountId="accountId"
        :selectedDay="selectedDay"
        @close="onUsageModalClose"
      />
    </template>
    <Card>
      <div class="cardHeaderContainer">
        <HeadingSmall
          :hasMargin="false"
          weight="semibold"
        >
          {{ title }}
        </HeadingSmall>
        <div class="cardContent">
          <div class="saasSelectWrapper">
            <SaasSelect
              :clearable="false"
              :disabled="isLoading"
              :modelValue="selectedUsage"
              :options="usageOptions"
              :searchable="false"
              @update:model-value="setSelectedUsage"
            />
          </div>
          <IconButton
            aria-label="Download Usage Data"
            hasNoBackground
            hasOutline
            icon="fileDownload"
            iconPosition="left"
            :isDisabled="isFileDownloading"
            type="secondary"
            @click="handleDownloadFile"
          />
        </div>
      </div>
      <div class="chartContainer">
        <LoadingOverlay
          isLight
          :visible="isLoading"
        />
        <div class="saasChartWrapper">
          <SaasChartWrapper
            :emptyStateText="emptyStateText"
            :series="series"
            :showEmptyState="showEmptyState"
            :tooltip="tooltip"
            :xAxis="xAxis"
            @data-point-click="dataPointClick"
          />
        </div>
        <div class="saasHorizontalBarChartWrapper">
          <SaasHorizontalBarChart
            :emptyStateText="emptyStateText"
            :height="getBarChartHeight(dayCount)"
            :series="series"
            :showEmptyState="showEmptyState"
            :tooltip="tooltip"
            :xAxis="xAxis"
          />
        </div>
      </div>
    </Card>
  </div>
</template>

<style scoped>
.userUsageCardContainer {
  background-color: var(--jc-background);
  height: auto;
}

.cardContent {
  @apply flex items-center gap-2;
}

.saasSelectWrapper {
  @apply flex-1 w-[182px];
}

.chartContainer {
  @apply relative pt-1.5;
}

.saasChartWrapper {
  @apply hidden h-auto sm:!flex;
}

.saasHorizontalBarChartWrapper {
  @apply flex h-auto sm:!hidden;
}

.cardHeaderContainer {
  display: flex;
  justify-content: space-between;
  @apply flex flex-wrap gap-2 pb-2;
}
</style>
