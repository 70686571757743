<script setup>
import { $testId } from '@/test/testIdHelper';
import {
  ButtonDismissibleChip,
  ButtonSecondary,
  CountBadge,
  DropdownActions,
  IconButton,
  InputSearch,
  Tooltip,
} from '@jumpcloud/ui-components';
import { debounce } from 'lodash';
import { ref } from 'vue';
import AddConnectorButton from '@/components/SaasListTable/AddConnectorButton.vue';
import AddManualAppWithButton from '@/components/SaasListTable/AddManualAppWithButton.vue';
import SaasEditColumnModal from '@/components/SaasListTable/SaasEditColumnModal.vue';
import SaasExportDropdown from '@/components/SaasListTable/SaasExportDropdown.vue';
import SaasFilterModal from '@/components/SaasListTable/SaasFilterModal.vue';

defineProps({
  actionColumns: {
    type: Array,
    default: () => [],
  },
  actionItems: {
    type: Array,
    default: () => [],
  },
  appliedFilters: {
    type: Array,
    default: () => [],
  },
  columns: {
    type: Array,
    required: true,
  },
  defaultFixedColumns: {
    type: Array,
    default: () => [],
  },
  defaultScrollingColumns: {
    type: Array,
    default: () => [],
  },
  hasAddConnectorButton: {
    type: Boolean,
    default: false,
  },
  hasAddManualAppButton: {
    type: Boolean,
    default: false,
  },
  isActionDropdownActive: Boolean,
  searchValue: {
    type: String,
    required: true,
  },
  selectable: Boolean,
});

const emit = defineEmits(['update:searchValue', 'applyFilters', 'clearAllFilters', 'removeFilter', 'refresh', 'exportData', 'exportAllData', 'updateColumnOptions']);

const FILTER_LIMIT = 8;
const showFilterModal = ref(false);
const showEditColumnModal = ref(false);
const showAllFilters = ref(false);

const isMobile = () => window.innerWidth <= 768;

const toggleFilterModal = (isOpen) => {
  showFilterModal.value = isOpen;
};

const toggleEditColumnModal = () => {
  showEditColumnModal.value = !showEditColumnModal.value;
};

const toggleShowAllFilters = () => {
  showAllFilters.value = !showAllFilters.value;
};

const handleUpdateSearchValue = debounce((searchTerm) => {
  emit('update:searchValue', searchTerm);
}, 500);

const handleApplyFilters = (filters) => {
  emit('applyFilters', filters);
  showFilterModal.value = false;
};

const handleApplyColumnChange = (columns) => {
  emit('updateColumnOptions', columns);
  showEditColumnModal.value = false;
};

const clearAllFilters = () => {
  emit('clearAllFilters');
  showFilterModal.value = false;
};
</script>

<template>
  <div class="saasListToolbarContainer">
    <div class="saasListToolbar">
      <AddManualAppWithButton
        v-if="hasAddManualAppButton"
        class="toolbarSpacing"
      />
      <AddConnectorButton
        v-if="hasAddConnectorButton"
        class="toolbarSpacing"
      />
      <InputSearch
        class="saasSearch toolbarSpacing"
        :data-test-id="$testId('inputSearch')"
        :modelValue="searchValue"
        placeholder="Search"
        @update:model-value="handleUpdateSearchValue"
      />
      <Tooltip
        class="toolbarSpacing toolbarTooltip"
        text="Filters"
      >
        <CountBadge
          ariaLabel="Filters"
          :count="appliedFilters.length"
          :data-test-id="$testId('filterCountBadge')"
          :isHidden="!isMobile"
          isFixed
          :offsetX="-8"
          :offsetY="0"
          status="success"
        >
          <IconButton
            ariaLabel="Filters"
            :data-test-id="$testId('filterIconButton')"
            hasNoBackground
            icon="filter"
            @click="toggleFilterModal(true)"
          />
        </CountBadge>
      </Tooltip>
      <Tooltip
        class="toolbarSpacing toolbarTooltip"
        text="Refresh List"
      >
        <IconButton
          ariaLabel="Refresh List"
          :data-test-id="$testId('refreshIconButton')"
          hasNoBackground
          icon="refresh"
          @click="$emit('refresh')"
        />
      </Tooltip>
      <div class="rightContainer">
        <ButtonSecondary
          :data-test-id="$testId('editColumnButton')"
          text="Edit Columns"
          @click="toggleEditColumnModal"
        />
        <SaasExportDropdown
          class="dropdownActions"
          :columns="columns"
          @export-all-data="$emit('exportAllData')"
          @export-data="$emit('exportData')"
        />
        <DropdownActions
          v-if="selectable"
          buttonText="Actions"
          :data-test-id="$testId('actionsDropdown')"
          :isDisabled="!isActionDropdownActive"
          :items="actionItems"
          position="right"
        />
      </div>
    </div>
    <SaasFilterModal
      :appliedFilters="appliedFilters"
      :data-test-id="$testId('saasFilterModal')"
      :showFilterModal="showFilterModal"
      @apply="handleApplyFilters"
      @clear-all="clearAllFilters"
      @close="toggleFilterModal(false)"
    >
      <template v-slot:filterContent>
        <slot name="filterContent" />
      </template>
    </SaasFilterModal>
    <SaasEditColumnModal
      :actionColumns="actionColumns"
      :columns="columns"
      :data-test-id="$testId('saasEditColumnModal')"
      :defaultFixedColumns="defaultFixedColumns"
      :defaultScrollingColumns="defaultScrollingColumns"
      :showModal="showEditColumnModal"
      @apply-col-change="handleApplyColumnChange"
      @close="toggleEditColumnModal(false)"
    />
    <div
      v-if="appliedFilters.length > 0"
      class="filters"
    >
      <ButtonDismissibleChip
        v-for="filter in appliedFilters.filter(
          (_, index) => showAllFilters || index < FILTER_LIMIT
        )"
        :key="filter.relatedField + filter.value"
        :prefix="filter.relatedField"
        :text="filter.name"
        @click="$emit('removeFilter', filter)"
      />
      <IconButton
        v-if="appliedFilters.length > FILTER_LIMIT"
        hasNoBackground
        :icon="showAllFilters ? 'chevronUp' : 'chevronDown'"
        iconPosition="right"
        :scale="0.75"
        :text="showAllFilters ? 'Show Less' : 'Show More'"
        type="informational"
        @click="toggleShowAllFilters"
      />
      <IconButton
        hasNoBackground
        icon="closeCircle"
        :scale="0.75"
        text="Clear All"
        type="informational"
        @click="$emit('clearAllFilters')"
      />
    </div>
  </div>
</template>

<style scoped>
.saasListToolbarContainer {
  display: flex;
  flex-direction: column;
  gap: var(--jc-spacer);
  padding: var(--jc-input-padding-side) var(--jc-spacer);
  padding-left: 0
}

.saasListToolbar {
  display: flex;
  padding: 0;
}

.rightContainer {
  @apply flex flex-1 justify-end gap-2;
}

.saasSearch {
  flex: 0 1 49ch;
}

.toolbarSpacing {
  margin-right: var(--jc-spacer);
}

.noPadding {
  padding: 0;
}

.dropdownActions,
.saasActions {
  --dropdown-menu-width: var(--jc-size-15);
}

.viewDropdown{
  z-index: 7;
}

.saveViews {
  align-items: center;
  display: flex;
}

.saasActions :deep() ul li:last-child button:first-child {
  --button-color: var(--jc-button-error);
  --button-hover-color: var(--jc-button-error);
}

.toolbarTooltip :deep() span[class*="Tooltip__tooltip"]{
  z-index: 8;
}

.dropdownActions :deep() ul[class*="DropdownMenuButtons__dropdownMenu"]{
  z-index: 200;
}

.saasActions :deep() ul[class*="DropdownMenuButtons__dropdownMenu"]{
  z-index: 200;
}

.addSaas{
  padding-left: var(--jc-size-0p625);
}

@media (max-width: 480px) {
  .toolbarSpacing {
    margin-right: var(--jc-spacer-x-small);
  }

  .rightContainer {
    justify-content: flex-start;
  }

  .saasListToolbarContainer{
    padding-right: 0;
  }
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: var(--jc-spacer-small);
}
</style>
