/**
 * This file contains utility functions for downloading data
 */
import moment from 'moment';

export const DownloadUtil = {
  /**
   * Downloads a file with the provided content using the Blob object and the URL API
   * @param {String} content - The content to be downloaded
   * @param {String} filename - The name of the file to be downloaded (with the file type)
   * @param {String} type - The type of the file to be downloaded (e.g. 'application/json')
   */
  downloadFile(content, filename = 'download', type = 'text/plain') {
    const blob = new Blob([content], { type });

    const a = document.createElement('a');
    a.download = filename;
    a.href = URL.createObjectURL(blob);
    a.rel = 'noopener';
    a.target = '_blank';
    a.click();
    URL.revokeObjectURL(blob);
  },

  /**
   * Downloads the provided data in JSON format
   * @param {Array} jsonData - An array of objects to be downloaded in JSON format
   * @param {String} filename - The name of the file to be downloaded (with the file type)
   */
  downloadJson(jsonData, filename = 'file.json') {
    this.downloadFile(JSON.stringify(jsonData), filename, 'application/json');
  },

  buildFileNameWithDateRange(fileName, startTime, endTime) {
    if (!startTime) {
      return fileName;
    }

    const startDate = moment(startTime).format('YYYYMMDD');
    const endDate = endTime ? moment(endTime).format('YYYYMMDD') : null;

    let formattedFileName = `${fileName}_${startDate}`;

    if (endDate && (startDate !== endDate)) {
      formattedFileName += `_${endDate}`;
    }

    return formattedFileName;
  },

  buildFileNameWithNowDate(fileName) {
    const startDate = moment().format('MMDDYY');
    return `${fileName}_${startDate}`;
  },
};
