<script setup>
import { $testId } from '@/test/testIdHelper';
import {
  Tabs,
  Types,
} from '@jumpcloud/ui-components';
import { computed } from 'vue';
import { useApplicationStatsStore } from '@/stores/ApplicationStats';
import { useRoute } from 'vue-router';
import SaasAllAppsTable from '@/pages/Applications/SaasAllAppsTable.vue';
import SaasApprovedAppsTable from '@/pages/Applications/SaasApprovedAppsTable.vue';
import SaasIgnoredAppsTable from '@/pages/Applications/SaasIgnoredAppsTable.vue';
import SaasNewlyDiscoveredAppsTable from '@/pages/Applications/SaasNewlyDiscoveredAppsTable.vue';
import SaasUnapprovedAppsTable from '@/pages/Applications/SaasUnapprovedAppsTable.vue';

const { TabItem } = Types;

const route = useRoute();

const applicationStatsStore = useApplicationStatsStore();
const statsData = computed(() => applicationStatsStore.applicationStats);

const activeTab = computed(() => route.hash ?? '#newly_discovered');

const allAppsCount = computed(() => {
  if (!statsData.value) return 0;

  if (statsData.value.totalApps) {
    return statsData.value.totalApps;
  }

  const {
    newlyDiscovered, approved, unapproved, ignored,
  } = statsData.value;

  return newlyDiscovered + approved + unapproved + ignored;
});

const tabs = computed(() => (statsData.value ? [
  TabItem({
    key: '#newly_discovered',
    label: `Newly Discovered (${statsData.value.newlyDiscovered})`,
    textCase: 'capitalize',
    to: '/saas-management/applications#newly_discovered',
  }),
  TabItem({
    key: '#approved',
    label: `Approved (${statsData.value.approved})`,
    textCase: 'capitalize',
    to: '/saas-management/applications#approved',
  }),
  TabItem({
    key: '#unapproved',
    label: `Unapproved (${statsData.value.unapproved})`,
    textCase: 'capitalize',
    to: '/saas-management/applications#unapproved',
  }),
  TabItem({
    key: '#ignored',
    label: `Ignored (${statsData.value.ignored})`,
    textCase: 'capitalize',
    to: '/saas-management/applications#ignored',
  }),
  TabItem({
    key: '#all',
    label: `All apps (${allAppsCount.value})`,
    textCase: 'capitalize',
    to: '/saas-management/applications#all',
  }),
] : []));

</script>

<template>
  <div
    class="container"
  >
    <div class="tabsContainer">
      <Tabs
        :activeTab="activeTab"
        :data-test-id="$testId('saasApplicationsTabs')"
        isRounded
        :tabs="tabs"
      />
    </div>

    <div class="containerFullHeight">
      <SaasNewlyDiscoveredAppsTable
        v-if="activeTab === '#newly_discovered'"
        :data-test-id="$testId('newlyDiscoveredTable')"
      />
      <SaasApprovedAppsTable
        v-else-if="activeTab === '#approved'"
        :data-test-id="$testId('approvedTable')"
      />
      <SaasUnapprovedAppsTable
        v-else-if="activeTab === '#unapproved'"
        :data-test-id="$testId('unapprovedTable')"
      />
      <SaasIgnoredAppsTable
        v-else-if="activeTab === '#ignored'"
        :data-test-id="$testId('ignoredTable')"
      />
      <SaasAllAppsTable
        v-else-if="activeTab === '#all'"
        :data-test-id="$testId('allTable')"
      />
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: var(--jc-spacer);
}

.tabs {
  /* margin: 0 var(--jc-spacer-medium) !important; */
  margin: 0 22px !important;
}

.containerFullHeight {
  background-color: white;
  flex-grow: 1;
  height: 100%;
  overflow: auto;
}

.toolbar > div > div:nth-child(2) > div > div:nth-child(2) {
  min-height: var(--jc-button-min-width);
  min-width: var(--jc-size-3);
}

.tabsContainer {
  @apply items-center border-b flex justify-between;
  @apply pr-4 pb-4 border-b-neutral h-12 shrink-0 pl-[22px];
}
</style>
