import {
  type NavigationGuardNext,
  type RouteLocationNormalizedGeneric,
  type RouteLocationNormalizedLoadedGeneric,
  type Router,
  createRouter,
  createWebHashHistory,
} from 'vue-router';
import { SaasConstants } from '@/util/Constants/SaasConstants';
import { TrialFeatures } from '@jumpcloud-ap/feature-trials-vue3';
import { provideRouteInfo } from '@jumpcloud-ap/route-info';

import { useSettingsStore } from '@/stores/Settings';
import ApplicationAccountDetail
  from '@/pages/Applications/ApplicationDetails/Accounts/ApplicationAccountDetail/ApplicationAccountDetail.vue';
import ConnectorDetail from '@/pages/Settings/Connectors/ConnectorDetail/ConnectorDetail.vue';
import MainPanel from '@/pages/MainPanel.vue';
import SaasApplicationsDetails from '@/pages/Applications/SaasApplicationsDetails.vue';
import SaasUserDetails from '@/pages/Users/UserDetails/SaasUserDetails.vue';
import SettingsMainPanel from '@/pages/Settings/SettingsMainPanel.vue';

const meta = {
  loadLegacyStyles: false,
  title: SaasConstants.title,
  feature: TrialFeatures[SaasConstants.type].code,
  modalKey: TrialFeatures[SaasConstants.type].code,
  defaultPremiumBadgeVisibility: true,
  navigateToFeaturesOnFeatureTrialLearnMoreClick: true,
  tooltipProps: {
    linkHref: 'https://jumpcloud.com/support/get-started-saas-management',
    pText: 'SaaS Management will enhance your security posture by discovering, monitoring, and controlling access to SaaS apps.',
  },
};

export const router = createRouter({
  history: createWebHashHistory('/saas-management'),
  routes: [
    {
      path: SaasConstants.route,
      name: SaasConstants.name,
      meta,
      redirect: () => ({ path: SaasConstants.tabs.overview.route }),
    },
    {
      path: SaasConstants.tabs.overview.route,
      name: SaasConstants.tabs.overview.name,
      meta,
      component: MainPanel,
      props: {
        activeTab: 'overview',
      },
    },
    {
      path: SaasConstants.tabs.applications.route,
      name: SaasConstants.tabs.applications.name,
      meta: {
        ...meta,
        keepPreviousReturnPath: true,
      },
      component: MainPanel,
      props: {
        activeTab: 'applications',
      },
    },
    {
      path: SaasConstants.tabs.applications.routes.application.routes.overview.route,
      name: SaasConstants.tabs.applications.routes.application.routes.overview.name,
      meta: {
        ...meta,
        returnPath: `${SaasConstants.tabs.applications.route}#newly_discovered`,
        keepPreviousReturnPath: true,
        showBackButton: true,
      },
      component: SaasApplicationsDetails,
      props: {
        activeTab: 'overview',
      },
    },
    {
      path: SaasConstants.tabs.applications.routes.application.routes.accounts.route,
      name: `${SaasConstants.tabs.applications.name} - Accounts`,
      meta: {
        ...meta,
        returnPath: `${SaasConstants.tabs.applications.route}#newly_discovered`,
        keepPreviousReturnPath: true,
        usePreviousReturnPath: true,
        showBackButton: true,
      },
      component: SaasApplicationsDetails,
      props: {
        activeTab: 'accounts',
      },
    },
    {
      path: SaasConstants.tabs.applications.routes.application.routes.accounts.routes.account.route,
      name: `${SaasConstants.tabs.applications.name} - Account Details`,
      meta: {
        ...meta,
        returnPath: `${SaasConstants.tabs.applications.route}#newly_discovered`,
        showBackButton: true,
      },
      component: ApplicationAccountDetail,
    },
    {
      path: SaasConstants.tabs.users.route,
      name: SaasConstants.tabs.users.name,
      meta,
      component: MainPanel,
      props: {
        activeTab: 'users',
      },
    },
    {
      path: SaasConstants.tabs.users.routes.user.route,
      name: SaasConstants.tabs.users.routes.user.name,
      meta: {
        ...meta,
        returnPath: SaasConstants.tabs.users.route,
        showBackButton: true,
      },
      component: SaasUserDetails,
    },
    {
      path: SaasConstants.tabs.settings.route,
      name: SaasConstants.tabs.settings.name,
      meta: {
        ...meta,
        returnPath: SaasConstants.tabs.overview.route,
        keepPreviousReturnPath: true,
        showBackButton: true,
      },
      redirect: () => ({
        path: SaasConstants.tabs.settings.routes.generalSettings.route,
      }),
    },
    {
      path: SaasConstants.tabs.settings.routes.generalSettings.route,
      name: SaasConstants.tabs.settings.routes.generalSettings.name,
      meta: {
        ...meta,
        returnPath: SaasConstants.tabs.overview.route,
        keepPreviousReturnPath: true,
        showBackButton: true,
      },
      component: SettingsMainPanel,
      props: {
        activeTab: 'general_settings',
      },
    },
    {
      path: SaasConstants.tabs.settings.routes.connectors.route,
      name: SaasConstants.tabs.settings.routes.connectors.name,
      meta: {
        ...meta,
        returnPath: SaasConstants.tabs.overview.route,
        keepPreviousReturnPath: true,
        usePreviousReturnPath: true,
        showBackButton: true,
      },
      component: SettingsMainPanel,
      props: {
        activeTab: 'connectors',
      },
    },
    {
      path: SaasConstants.tabs.settings.routes.connectors.routes.connector.route,
      name: SaasConstants.tabs.settings.routes.connectors.routes.connector.name,
      meta: {
        ...meta,
        returnPath: SaasConstants.tabs.settings.routes.connectors.route,
        useConstantReturnPath: true,
        showBackButton: true,
      },
      component: ConnectorDetail,
    },
    {
      path: SaasConstants.tabs.accounts.route,
      name: SaasConstants.tabs.accounts.name,
      meta,
      component: MainPanel,
      props: {
        activeTab: 'accounts',
      },
    },
    {
      path: SaasConstants.tabs.accounts.routes.account.route,
      name: SaasConstants.tabs.accounts.routes.account.name,
      meta: {
        ...meta,
        returnPath: SaasConstants.tabs.accounts.route,
        showBackButton: true,
      },
      component: ApplicationAccountDetail,
    },
  ],
});

const redirectIfSaasManagementIsNotEnabled = (
  to: RouteLocationNormalizedGeneric,
  _from: RouteLocationNormalizedLoadedGeneric,
  _next: NavigationGuardNext,
  _router: Router,
) => {
  const settingsStore = useSettingsStore();

  const allowedRoutes = [
    SaasConstants.tabs.overview.name,
    SaasConstants.tabs.settings.routes.generalSettings.name,
  ];

  const isOuterRoute = !to.path.startsWith(SaasConstants.route);

  if (settingsStore.isEnabled === undefined) {
    // initial fetch
    const unsubscribe = settingsStore.$subscribe((_mutation, state) => {
      if (state.data !== undefined) {
        unsubscribe();

        if (!state.data.isEnabled) {
          if (!allowedRoutes.includes(to.name?.valueOf() as string) && !isOuterRoute) {
            _router.push({ name: SaasConstants.tabs.overview.name });
          }
        }
      }
    });
  } else if (!settingsStore.isEnabled) {
    if (!allowedRoutes.includes(to.name?.valueOf() as string) && !isOuterRoute) {
      _router.push({ name: SaasConstants.tabs.overview.name });
    }
  }
};

export const beforeEachGuard = (
  to: RouteLocationNormalizedGeneric,
  from: RouteLocationNormalizedLoadedGeneric,
  next: NavigationGuardNext,
  _router: Router,
) => {
  redirectIfSaasManagementIsNotEnabled(
    to,
    from,
    next,
    _router,
  );

  _router.getRoutes().map(route => {
    /**
     * If the route does not have a beforeEnter guard,
     * we can modify the route and return it.
     */
    if (!route.beforeEnter && !route.meta?.useConstantReturnPath) {
      const newRoute = route;
      const routeName = route.name as string;
      const routePath = route.path;

      if (from.fullPath === '/' && newRoute.meta?.showBackButton) {
        const returnTo = SaasConstants.tabs.overview.route;
        newRoute.meta.returnPath = returnTo;
        newRoute.meta.previousReturnPath = returnTo;
        return newRoute;
      }

      /**
       * Settings page routes
       */
      if (
        [
          SaasConstants.tabs.settings.route,
          SaasConstants.tabs.settings.routes.generalSettings.route,
          SaasConstants.tabs.settings.routes.connectors.route,
        ].includes(routePath)
      ) {
        if (from.fullPath !== '/' && !from.fullPath.startsWith(SaasConstants.tabs.settings.route)) {
          newRoute.meta.returnPath = from.fullPath;

          const keepPreviousReturnPath = newRoute.meta?.keepPreviousReturnPath;

          if (keepPreviousReturnPath) {
            newRoute.meta.previousReturnPath = from.fullPath;
          }
        }

        const previousReturnPath = from.meta?.previousReturnPath;
        const usePreviousReturnPath = to.meta?.usePreviousReturnPath;

        if (previousReturnPath && usePreviousReturnPath) {
          newRoute.meta.returnPath = previousReturnPath;
          newRoute.meta.previousReturnPath = previousReturnPath;
        }

        return newRoute;
      }

      /*
        * Application details page routes
        */
      if (
        [
          SaasConstants.tabs.applications.routes.application.routes.overview.route,
          SaasConstants.tabs.applications.routes.application.routes.accounts.route,
        ].includes(routePath)
      ) {
        const fromAppDetails = from.fullPath.startsWith(`${SaasConstants.tabs.applications.route}/`);
        if (from.fullPath !== '/' && !fromAppDetails) {
          newRoute.meta.returnPath = from.fullPath;
        }

        const keepPreviousReturnPath = newRoute.meta?.keepPreviousReturnPath;

        if (keepPreviousReturnPath && !fromAppDetails) {
          newRoute.meta.previousReturnPath = from.fullPath;
        }

        const previousReturnPath = from.meta?.previousReturnPath;
        const usePreviousReturnPath = newRoute.meta?.usePreviousReturnPath;

        if (previousReturnPath && usePreviousReturnPath && !fromAppDetails) {
          newRoute.meta.returnPath = previousReturnPath;
          newRoute.meta.previousReturnPath = previousReturnPath;
        }

        return newRoute;
      }

      /**
       * All other routes that should have a back button
       */
      if (routeName === to.matched?.[0]?.name && to.meta.showBackButton) {
        newRoute.meta.returnPath = from.fullPath;
      }
      return newRoute;
    }

    return route;
  });

  next();
};

router.beforeEach((to, from, next) => beforeEachGuard(to, from, next, router));
router.afterEach(provideRouteInfo);
